import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IDocumentThread } from 'SHARED/validators/documents/documentSchema';
import { IDocumentType } from 'SHARED/validators/documents/documentTypeSchema';
import { asyncDocumentsActions } from './AsyncDocumentsActions';

interface IDocumentsState {
  isDocumentsLoading: boolean
  documents: IDocumentThread[]
  documentTypes: IDocumentType[]
  error: string | null
}

const initialState: IDocumentsState = {
  isDocumentsLoading: false,
  documents: [],
  documentTypes: [],
  error: null,
};

const documentsSlice = createSlice({
  name: 'documents',
  initialState,
  reducers: {
    resetDocumentsState: () => initialState,

    resetDocuments: (state) => {
      state.documents = [];
    },

    resetDocumentTypes: (state) => {
      state.documentTypes = [];
    },
  },
  extraReducers: (builder) => {
    // getDocuments
    builder.addCase(
      asyncDocumentsActions.getDocuments.pending,
      (state) => {
        state.isDocumentsLoading = true;
      },
    );

    builder.addCase(
      asyncDocumentsActions.getDocuments.fulfilled,
      (state, action) => {
        state.isDocumentsLoading = false;
        state.documents = action.payload;
      },
    );

    builder.addCase(
      asyncDocumentsActions.getDocuments.rejected.type,
      (state, action: PayloadAction<string>) => {
        state.isDocumentsLoading = false;
        state.error = action.payload;
      },
    );
    // getDocuments === END

    // getDocumentTypes
    builder.addCase(
      asyncDocumentsActions.getDocumentTypes.pending,
      (state) => {
        state.isDocumentsLoading = true;
      },
    );

    builder.addCase(
      asyncDocumentsActions.getDocumentTypes.fulfilled,
      (state, action) => {
        state.isDocumentsLoading = false;
        state.documentTypes = action.payload;
      },
    );

    builder.addCase(
      asyncDocumentsActions.getDocumentTypes.rejected.type,
      (state, action: PayloadAction<string>) => {
        state.isDocumentsLoading = false;
        state.error = action.payload;
      },
    );
    // getDocumentTypes === END
  },
});

export const documentsReducer = documentsSlice.reducer;
export const documentsActions = documentsSlice.actions;
