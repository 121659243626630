import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { InputOption } from 'SHARED/types/offerTypes';
import validationRules from 'SHARED/helpers/validation';
import Tooltip from '../Tooltip';

interface IRadioGroupProps {
  name: `${string}` | `${string}.${string}` | `${string}.${number}`,
  type?: string,
  rules?: any,
  values: InputOption[],
  label?: string,
  placeholder?: string,
  tooltip?: string,
  disabled?: boolean,
  defaultValue: InputOption | null,
}

const RadioGroup: React.FC<IRadioGroupProps> = (props) => {
  const {
    formState: { errors }, setValue, control,
  } = useFormContext();

  const {
    name,
    rules = validationRules.radio,
    label,
    tooltip,
    disabled = false,
    defaultValue,
    values,
  } = props;

  const customOnchange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(name, values.find((v) => v.value === e.target.value));
  };

  return (
    <div className={`form-input radio-group ${disabled ? 'disabled' : ''} ${errors[name] ? 'invalid' : 'valid'}`}>
      {label && (
        <label>
          {rules && rules.required ? `${label} *` : label}

          {
            tooltip && tooltip.length > 0 && <Tooltip text={tooltip} />
          }
        </label>
      )}

      <div className="radio-group-wrapper">

        <Controller
          name={name}
          control={control}
          rules={rules}
          defaultValue={defaultValue}
          render={({ field, fieldState }) => (
            <>
              {values?.map((item) => (
                <div className="custominput" key={item?.value}>
                  <input
                    checked={(field?.value?.value === item?.value) || false}
                    type="radio"
                    id={`${name}-${item?.value}`}
                    value={item?.value}
                    onChange={(e) => { customOnchange(e); }}
                    disabled={item?.isDisabled}
                  />
                  <label htmlFor={`${name}-${item?.value}`}>{item?.label}</label>
                </div>
              ))}
            </>
          )}
        />

      </div>

      {
        errors[name]
        && <div className="error-message">{errors[name]?.message}</div>
      }

    </div>
  );
};

export default RadioGroup;
