import { InputLabel, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import Controls from 'components/controls/Controls';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Form, useForm } from '../../hooks/useForm';
import api from '../../services/api';
import {
  CreditLine, emptyCondition,
  emptyLine,
  ICreditLineCondition,
} from '../../services/api/CreditService';
import { Dictionary } from '../../services/api/DictionaryService';
import { Organization } from '../../services/api/OrganizationService';
import { option } from '../../services/Utils';
import CreditLineCondition from './CreditLineCondition';

const FINANCING_PROVIDER = 'FINANCING_PROVIDER';
const TERMS_CREATION_TYPES = {
  INDIVIDUAL: 'INDIVIDUAL',
  SHARED: 'SHARED',
};

export interface CreditLineFormProps{
  lineId: number
  setOpen: (val: boolean) => void
  setPage: (val: string) => void
}

export default function CreditLineForm(props: CreditLineFormProps) {
  const { lineId, setOpen, setPage } = props;
  const {
    values, setValues, errors, setErrors, handleInputChange,
  } = useForm({ ...emptyLine, conditions: [emptyCondition] });

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [globalError, setGlobalError] = useState('');
  const [providers, setProviders] = useState<any[]>([]);
  const [sellers, setSellers] = useState([]);
  const [buyers, setBuyers] = useState([]);
  const [needValidate, setNeedValidate] = useState(false);

  // load select options
  useEffect(() => {
    setOrganizations('SELLER');
    setOrganizations('BUYER');
  }, []);

  useEffect(() => {
    if (lineId > 0) {
      api.credit.getLine(lineId)
        .then((res: CreditLine) => setValues(
          {
            ...res,
            buyer: res.buyer,
            provider: option(res.provider.id, res.provider.label),
            type: res.provider.providerType.value,
            termsCreationType: TERMS_CREATION_TYPES.INDIVIDUAL,
          },
        ))
        .catch((err) => setGlobalError(err.message));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lineId]);

  useEffect(() => {
    if (!isNaN(values.buyer?.value)) {
      setValues({ ...values, currency: getBuyerCurrency() });
      api.dictionary.getDictionaryByCode([FINANCING_PROVIDER], (res: any) => {
        setProviders(res[FINANCING_PROVIDER]);
      }, values.buyer?.value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.buyer]);

  const setOrganizations = (orgType: 'SELLER' | 'BUYER') => {
    api.organization.getOrganizationPage(orgType, {})
      .then((res) => (orgType === 'SELLER' ? setSellers(res.rows) : setBuyers(res.rows)))
      .catch((err) => setGlobalError(err.message));
  };

  const validate = () => {
    setErrors({});
    setGlobalError('');
    const temp: any = {};

    if (!values.totalLimit) {
      if (values.totalLimit === 0) {
        temp.totalLimit = 'Should be greater than 0.';
      } else {
        temp.totalLimit = 'This field is required.';
      }
    }

    if (!values.provider?.value) {
      temp.provider = 'This field is required.';
    }

    if (!values.validTo) {
      temp.validTo = 'This field is required.';
    } else if (moment(values.validTo).isBefore(moment())) {
      temp.validTo = 'Date must be in the future.';
    }

    if (!values.buyer || ((isNaN(values.buyer?.value)) || !values.buyer?.value)) {
      temp.buyer = 'This field is required.';
    }

    setErrors(temp);
    setNeedValidate(false);

    return Object.keys(temp).length === 0 && !values.conditions.find((it: any) => it.hasError);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { needValidate && handleSubmit(); }, [needValidate]);

  function prepareSharedConditions(conditions: ICreditLineCondition[]) {
    const shadedCondition = conditions[0];
    const sellersArray = shadedCondition?.sellers || []; // get sellers from first condition
    const sharedConditions = sellersArray.map((it) => {
      const conditionItem = { ...shadedCondition };
      // ? 'sellers' is a proxy field that used to duplicate condition for each seller
      delete conditionItem.sellers;

      return { ...conditionItem, seller: it };
    });

    return sharedConditions;
  }

  function prepareIndividualConditions(conditions: ICreditLineCondition[]) {
    return conditions.map((it) => {
      const conditionItem = {
        ...it,
        maxCreditTerm: !it.maxCreditTerm ? 0 : it.maxCreditTerm,
        seller: values.type === 'SELLER' ? option(getSeller().sellerOrgId, getSeller().label) : it.seller,
      };
      // ? 'sellers' is a proxy field that used to duplicate condition for each seller
      delete conditionItem.sellers;

      return conditionItem;
    });
  }

  const handleSubmit = () => {
    // console.clear();
    // console.log({ values });

    if (validate()) {
      setIsSubmitting(true);

      const isShared = values.termsCreationType === TERMS_CREATION_TYPES.SHARED;
      const conditionsValue = isShared ? prepareSharedConditions(values.conditions) : prepareIndividualConditions(values.conditions);

      const request = {
        buyer: values.buyer,
        provider: { id: values.provider?.value, label: values.provider?.label },
        totalLimit: values.totalLimit,
        validTo: values.validTo,
        status: 'ACTIVE',
        conditions: conditionsValue,
      };

      // console.log({ request });
      // return;

      try {
        lineId
          ? api.credit.updateLine(lineId, request)
            .then(() => setPage('VIEW'))
            .catch((err) => setGlobalError(err.message))
          : api.credit.createLine(request)
            .then(() => setOpen(false))
            .catch((err) => setGlobalError(err.message));
      } catch (error) {
        console.log('catch');
      } finally {
        console.log('finally');
        setIsSubmitting(false);
      }
    }
  };

  const getBuyerOptions = () => buyers.map((it: Organization) => option(it.id, it.orgName));
  const getSellerOptions = () => sellers.map((it: Organization) => option(it.id, it.orgName));

  const getProviderOptions = () => providers
    .filter((it: any) => it.providerType.value === values.type)
    .map((it: Dictionary) => option(it.id, it.label));

  const getCreditorTypeOptions = () => [
    option('THIRD_PARTY', '3rd party'),
    option('SELLER', 'Seller'),
  ];

  const termsCreationTypeOptions = [
    option(TERMS_CREATION_TYPES.INDIVIDUAL, 'Individual'),
    option(TERMS_CREATION_TYPES.SHARED, 'Shared'),
  ];

  const addNewCondition = () => {
    const tmp = values.conditions;
    tmp.push(emptyCondition);
    setValues({ ...values, conditions: tmp });
  };

  const getBuyerCurrency = () => (
  // @ts-ignore
    values.buyer && buyers.find((it) => it.id === values.buyer)?.currency) || 'EUR';

  const getSeller = () => values.type === 'SELLER'
    // @ts-ignore
    && providers.find((it: any) => it.id === values.provider?.value);

  const renderLineCondition = (cond: any, index: number) => (
    <CreditLineCondition
      index={index}
      key={`credit-line-conditions-${cond.id}`}
      item={cond}
      creditorType={values.type}
      currency={values.currency}
      sellers={getSellerOptions()}
      needValidate={needValidate}
      conditions={values.conditions}
      setConditions={(conditions) => setValues({ ...values, conditions })}
      termsCreationType={values.termsCreationType}
    />
  );

  // If type has changed, reset provider value (upon creation only)
  useEffect(() => {
    if (!values.id) {
      setValues({ ...values, provider: emptyLine.provider });
    }
  }, [values.type]);

  return (
    <Form name="credit-line-form">
      {globalError && <Alert severity="error">{globalError}</Alert>}

      <Controls.AutocompleteSelect
        label="BUYER"
        name="buyer"
        value={values.buyer}
        error={errors.buyer}
        options={getBuyerOptions()}
        onChange={handleInputChange}
        required
        disabled={!!values.id}
        groupOptions
      />
      <Controls.RadioGroup
        name="type"
        label="CREDITOR TYPE"
        value={values.type}
        items={getCreditorTypeOptions()}
        onChange={handleInputChange}
        disabled={!!values.id}
      />
      <Controls.AutocompleteSelect
        label={values.type === 'SELLER' ? 'CREDITOR SELLER ORGANISATION' : 'CREDITOR'}
        name="provider"
        value={values.provider}
        error={errors.provider}
        options={getProviderOptions()}
        onChange={handleInputChange}
        required
        disabled={!!values.id || isNaN(values.buyer?.value)}
      />
      { (values.provider
          // @ts-ignore
          && providers.find((it: any) => it.id === values.provider)?.isIncludeCreditPrice)
      && (
      <Typography variant="body2" style={{ marginLeft: 16 }}>
        This financial partner gives credit including the costs of the financing
      </Typography>
      )}
      <Controls.Input
        name="totalLimit"
        label="TOTAL CREDIT"
        value={values.totalLimit}
        error={errors.totalLimit}
        onChange={handleInputChange}
        type="number"
        InputProps={{
          endAdornment: getBuyerCurrency(),
          inputProps: { min: 0 },
        }}
        required
      />
      <div>
        <InputLabel>CREDIT VALID TILL</InputLabel>
        <Controls.Input
          style={{ width: 200 }}
          name="validTo"
          value={moment(values.validTo).format('yyyy-MM-DD')}
          error={errors.validTo}
          onChange={handleInputChange}
          InputProps={{
            inputProps: { min: moment().format('yyyy-MM-DD'), style: !values.validTo ? { color: 'lightgray' } : {} },
            endAdornment: values.validTo && <Close style={{ cursor: 'pointer' }} onClick={() => setValues({ ...values, validTo: null })} />,
          }}
          type="date"
        />
      </div>
      <br />
      <Typography variant="h5">Terms for seller</Typography>
      {values.type !== 'SELLER' && (
        <Controls.RadioGroup
          name="termsCreationType"
          label="TERMS CREATION TYPE"
          value={values.termsCreationType}
          items={termsCreationTypeOptions}
          onChange={handleInputChange}
          disabled={!!values.id}
          hint="With «Shared» terms you can duplicate the same terms for all selected sellers"
        />
      )}
      <br />
      { values.conditions && values.conditions.map(renderLineCondition) }
      { (values.type !== 'SELLER') && (values.termsCreationType === 'INDIVIDUAL') && (
      <Controls.Button
        variant="outlined"
        color="secondary"
        style={{ margin: '20px 8px' }}
        onClick={addNewCondition}
      >
        Add seller & terms
      </Controls.Button>
      )}
      <br />
      <Controls.Button type="submit" onClick={(e) => { e.preventDefault(); setNeedValidate(true); }} disabled={isSubmitting}>Save</Controls.Button>
      <Controls.Button type="reset" onClick={() => { lineId ? setPage('VIEW') : setOpen(false); }}>Cancel</Controls.Button>
    </Form>
  );
}
