import { Typography } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import React, { useEffect } from 'react';
import { useForm } from '../../hooks/useForm';
import { ICreditLineCondition } from '../../services/api/CreditService';
import { option } from '../../services/Utils';
import Controls from '../controls/Controls';
import { Option } from '../controls/Select';

const TERMS_CREATION_TYPES = {
  INDIVIDUAL: 'INDIVIDUAL',
  SHARED: 'SHARED',
};

export interface CreditLineConditionProps {
  index: number,
  item: ICreditLineCondition,
  creditorType: 'SELLER' | 'THIRD_PARTY',
  currency: string,
  sellers: Array<Option>
  conditions: Array<ICreditLineCondition>,
  setConditions: (val: Array<ICreditLineCondition>) => void,
  needValidate: boolean
  termsCreationType: keyof typeof TERMS_CREATION_TYPES;
}

export default function CreditLineCondition(props: CreditLineConditionProps) {
  const {
    index, item, creditorType, conditions, setConditions, sellers, currency, needValidate, termsCreationType,
  } = props;

  const isThirdPartyCreditor = creditorType === 'THIRD_PARTY';
  const isIndividualTermsCreation = termsCreationType === TERMS_CREATION_TYPES.INDIVIDUAL;

  const {
    values, errors, setErrors, handleInputChange,
  } = useForm({
    ...item, seller: item.seller, sellers: item.sellers, currency,
  });

  useEffect(() => {
    if (needValidate) {
      conditions[index] = {
        ...values,
        seller: values.seller || option(null),
        hasError: !validate(),
      };
      setConditions(conditions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [needValidate]);

  const validate = () => {
    setErrors({});
    const tmp: any = {};

    if (isThirdPartyCreditor && isIndividualTermsCreation && (!values.seller?.value)) {
      tmp.seller = 'This field is required';
    }
    if (isThirdPartyCreditor && !isIndividualTermsCreation && (values.sellers.length === 0)) {
      tmp.sellers = 'This field is required';
    }

    ['interestRate', 'commissionPerDeal', 'commissionPerUnit'].forEach((it) => {
      if (values[it] < 0) {
        tmp[it] = 'Should not be negative';
      }
    });
    if ((values.interestRate < 0) || (values.interestRate >= 100)) {
      tmp.interestRate = 'Should be in range 0 .. 99,99';
    }

    if (values.maxCreditTerm < 0) {
      tmp.maxCreditTerm = 'Should not be negative';
    }
    setErrors(tmp);

    return Object.keys(tmp).length === 0;
  };

  const handleDelete = () => {
    const arr = conditions.filter((it, idx) => idx !== index);
    setConditions(arr);
  };

  const handleRateChange = (e: any) => {
    const { value } = e.target;
    const decimal = value && value.indexOf('.') && value.split('.')[1];
    const decimalPart = decimal && value.split('.')[1];
    if (decimal && decimalPart.length <= 2) {
      handleInputChange(e);
    }
    if (!decimal) {
      handleInputChange(e);
    }
  };

  return (
    <div style={{ marginBottom: 15 }} key={index}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Typography variant="h6">{`Credit term ${conditions.length > 1 ? index + 1 : ''}`}</Typography>
        { conditions.length > 1 && <Delete style={{ cursor: 'pointer' }} onClick={handleDelete} /> }
      </div>
      { creditorType !== 'SELLER' && (

        termsCreationType === TERMS_CREATION_TYPES.SHARED ? (
          <Controls.ComboMultiSelect
            label="SELLERS LIST"
            name="sellers"
            value={values.sellers || []}
            error={errors.sellers}
            options={sellers}
            onChange={handleInputChange}
            required
          />
        ) : (
          <Controls.AutocompleteSelect
            label="SELLER"
            name="seller"
            value={values.seller}
            error={errors.seller}
            options={sellers}
            onChange={handleInputChange}
            required
            groupOptions
          />
        )
      )}
      <Controls.Input
        name="interestRate"
        label="INTEREST RATE %"
        value={values.interestRate}
        error={errors.interestRate}
        onChange={handleRateChange}
        type="number"
        InputProps={{
          endAdornment: '%ANNUM',
          inputProps: { min: 0, step: '0.01' },
        }}
        required
      />
      <Controls.Input
        name="commissionPerDeal"
        label="FIXED COST PER DEAL"
        value={values.commissionPerDeal}
        error={errors.commissionPerDeal}
        onChange={handleInputChange}
        type="number"
        InputProps={{
          endAdornment: values.currency,
          inputProps: { min: 0 },
        }}
        required
      />
      <Controls.Input
        name="commissionPerUnit"
        label="FIXED COST PER VOLUME UNIT"
        value={values.commissionPerUnit}
        error={errors.commissionPerUnit}
        onChange={handleInputChange}
        type="number"
        InputProps={{
          endAdornment: `${values.currency}/MT`,
          inputProps: { min: 0 },
        }}
        required
      />
      <Controls.Input
        name="maxCreditTerm"
        label="NUMBER OF DAYS FOR EXTENDED PAYMENT PERIOD"
        value={values.maxCreditTerm}
        error={errors.maxCreditTerm}
        onChange={handleInputChange}
        type="number"
        InputProps={{
          inputProps: { min: 0 },
        }}
      />
    </div>
  );
}
