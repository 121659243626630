import { z } from 'zod';

const documentTypeSchema = z.object({
  code: z.string().nonempty(),
  title: z.string().nonempty(),
  actions: z.object({
    request: z.boolean(),
    upload: z.boolean(),
  }),
});

export type IDocumentType = z.infer<typeof documentTypeSchema>;

export default documentTypeSchema;
