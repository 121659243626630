import React from 'react';
import {
  AppBar, Button, Tab, Tabs, Toolbar,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { ExitToAppRounded } from '@material-ui/icons';
import { logout } from '../services/api/RestUtil';
import ActiveChannels from './live-chat/ActiveChannels';
import colors from './StyleConfig';
import pages from '../navigation/pages';

export interface HeaderProps {
  tab: number,
  setTab(tab: number): void,
}

export default function Header({ tab, setTab }: HeaderProps) {
  const setTabCallback = React.useCallback((e, value) => {
    console.log('setTabCallback', value);
    console.log(window.location.pathname);
    setTab(value);
  }, [setTab]);

  return (
    <AppBar position="fixed" style={{ background: colors.primaryBlack }} className="main-header">
      <Toolbar
        style={{
          minHeight: '50px',
        }}
      >
        <Link to={pages.dashboard} className="logo header-logo">
          <img width={150} src="/images/logo-white.svg" alt="" />
        </Link>

        <Tabs
          value={tab}
          onChange={setTabCallback}
          style={{ flexGrow: 1 }}
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab label="Offers" value={0} to={pages.offers} component={Link} />
          <Tab label="Auctions" value={6} to={pages.offSpecOffers} component={Link} />
          <Tab label="RFPs" value={7} to={pages.rfp} component={Link} />
          <Tab label="Sellers" value={1} to={pages.seller} component={Link} />
          <Tab label="Buyers" value={2} to={pages.buyer} component={Link} />
          <Tab label="Catalogues" value={3} to={pages.catalogue} component={Link} />
          <Tab label="Admin users" value={4} to={pages.admin} component={Link} />
        </Tabs>

        <ActiveChannels />

        <Button
          color="inherit"
          startIcon={<ExitToAppRounded />}
          className="logout-button"
          onClick={(e) => {
            e.preventDefault();
            logout();
          }}
        >
          <span className="logout-button__text">Logout</span>
        </Button>

      </Toolbar>
    </AppBar>
  );
}
