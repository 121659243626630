import React, { FC, useEffect } from 'react';
import DataItemPreview from 'components/partials/DataItemPreview';
import type { IDataItemPreviewProps } from 'components/partials/DataItemPreview';
import { useFormContext } from 'react-hook-form';

interface IProps extends IDataItemPreviewProps {
  name: string;
  fieldValue: string | number | object | null
  requiredMessage?: string;
}

const FormOutput: FC<IProps> = ({
  name,
  fieldValue,
  requiredMessage,

  ...rest
}) => {
  const { register, setValue, formState } = useFormContext();
  const { errors } = formState;

  const displayLabel = fieldValue ? rest.value : null;

  useEffect(() => {
    setValue(name, fieldValue);
  }, [fieldValue]);

  return (
    <div className="form-output form-field">
      <output
        {...register(name, { required: requiredMessage || 'Required' })}
        className="visually-hidden"
      />

      <DataItemPreview {...rest} value={displayLabel} />

      {errors[name]?.message && <div className="form-field__error">{errors[name]?.message}</div>}
    </div>
  );
};

export default FormOutput;
