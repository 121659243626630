import moment from 'moment-timezone';
import { fallbackTimezone, IDate } from 'SHARED/helpers/dates';
import { useTypedSelector } from 'SHARED/redux/hooks/useTypedSelector';

interface IUseTimezoneDateArgs {
  date: IDate | null | undefined;
  displayFormat?: string
  showSeconds?: boolean
}

const useTimezoneDate = () => {
  const { me } = useTypedSelector((state) => state.users);

  const timeZone = me?.profile?.timeZone || fallbackTimezone;
  const timeZoneAbbr = me?.profile?.timeZoneAbbr || 'CET';

  function preparedDateWithTimezone(args: IUseTimezoneDateArgs) {
    const {
      date,
      displayFormat,
      showSeconds,
    } = args;
    if (!date) {
      return '[invalid date]';
    }

    const format = showSeconds ? 'D MMM YYYY HH:mm:ss' : 'D MMM YYYY HH:mm';

    const abbr = (!displayFormat || (displayFormat?.toLowerCase().includes('z'))) ? ` ${timeZoneAbbr}` : '';

    const preparedDisplayFormat = displayFormat
      ? displayFormat
        .replaceAll(' z', '')
        .replaceAll(' Z', '')
        .replaceAll('z', '')
        .replaceAll('Z', '')
      : format;

    return moment.tz(date, timeZone).format(`${preparedDisplayFormat}[${abbr}]`);
  }

  return preparedDateWithTimezone;
};

export default useTimezoneDate;
