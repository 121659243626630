import React, { FC } from 'react';
import { Typography } from '@material-ui/core';
import { formatMoney } from 'services/Utils';
import TextField from 'components/controls/TextField';
import { IOffspecOffer } from 'SHARED/types/offerTypes';

interface IProps {
  offer: IOffspecOffer
}

const VolumeBlock: FC<IProps> = ({ offer }) => {
  const { deal } = offer;
  return (
    <>
      <Typography variant="h5">Deal volume and price</Typography>
      <TextField label="PAYMENT TERMS" value={offer.paymentTerms?.label} />
      <TextField label="VOLUME" value={`${offer.totalVolume} ${offer.volumeUnits.label}`} />
      <TextField label="DEAL PRODUCT PRICE" value={`${formatMoney(deal?.price)} ${deal?.currency} / ${offer.volumeUnits.label}`} />
      <TextField label="TOTAL DEAL PRICE" value={`${formatMoney(deal?.amount)} ${deal?.currency}`} />
    </>
  );
};

export default VolumeBlock;
