import React, { useState } from 'react';
import { Button } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import DataTable from '../DataTable';
import Modal from '../Modal';
import api from '../../services/api';
import { emptyUser, IUser } from '../../services/api/UserService';
import UserForm, { UserFormProps } from '../forms/UserForm';
import colors from '../StyleConfig';
import { PageRequest } from '../../services/api/RestUtil';

export interface UserTableProps {
  tableLabel: string,
  userType: 'ADMIN' | 'SELLER' | 'BUYER',
  orgId?: number,
}

export default function UserTable(props: UserTableProps) {
  const [open, setOpen] = useState(false);
  const [recordForEdit, setRecordForEdit] = useState(emptyUser);
  const { tableLabel, userType, orgId } = props;
  const columns = [
    { field: 'id', label: 'ID', render: (row: any) => row.id },
    { field: 'name', label: 'Name', render: (row: any) => renderFullName(row) },
    { field: 'email', label: 'Email', render: (row: any) => row?.profile?.email },
    { field: 'roleName', label: 'Role', render: (row: any) => row.roleName },
    { field: 'status', label: 'Status', render: (row: any) => row.status },
  ];

  const openInPopup = (item: any) => {
    setRecordForEdit(item);
    setOpen(true);
  };

  const renderFullName = (item: IUser) => {
    const { profile: { firstName, middleName, lastName } } = item;
    return `${firstName?.trim() || ''} ${middleName?.trim() || ''} ${lastName?.trim() || ''}`;
  };

  const renderAddButton = () => (
    <Button
      variant="contained"
      color="primary"
      style={{ background: colors.primaryGreen }}
      startIcon={<Add />}
      onClick={() => { openInPopup(emptyUser); }}
    >
      Add New
    </Button>
  );

  const getModalTitle = () => (recordForEdit.id
    ? `Update ${userType.toLowerCase()} user`
    : `Create ${userType.toLowerCase()} user`);

  const getModalProps = (): UserFormProps => {
    if (userType === 'SELLER') {
      return {
        userType: 'SELLER',
        user: recordForEdit,
        roles: [{ value: '3', label: 'Trader' }, { value: '4', label: 'Company admin' }],
        defaultRole: { value: '3', label: 'Trader' },
        setOpen,
      };
    }
    if (userType === 'BUYER') {
      return {
        userType: 'BUYER',
        user: recordForEdit,
        roles: [{ value: '3', label: 'Trader' }, { value: '4', label: 'Company admin' }],
        defaultRole: { value: '3', label: 'Trader' },
        setOpen,
      };
    }
    return {
      userType: 'ADMIN',
      user: recordForEdit,
      roles: [{ value: '2', label: 'Admin' }, { value: '1', label: 'Super admin' }],
      defaultRole: { value: '2', label: 'Admin' },
      setOpen,
    };
  };

  const getUsers = (pageRequest: PageRequest) => (
    orgId
      ? api.organization.getOrgUsers(orgId, pageRequest)
      : api.user.getUsersPage({ ...pageRequest, userType })
  );

  return (
    <div className="page-body">
      <DataTable
        name={tableLabel}
        columns={columns}
        onRowClick={openInPopup}
        addButton={renderAddButton()}
        reload={open}
        fetchRows={getUsers}
      />
      <Modal title={getModalTitle()} open={open} setOpen={setOpen}>
        <UserForm {...getModalProps()} orgId={orgId} />
      </Modal>
    </div>
  );
}
