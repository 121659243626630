import { Alert } from '@material-ui/lab';
import React, { useEffect, useState } from 'react';
import { Form, useForm } from '../../hooks/useForm';
import api from '../../services/api';
import { Country } from '../../services/api/DictionaryService';
import { LocationModel, SeaPortModel } from '../../services/api/LocationService';
import { emailRegExp } from '../../services/api/UserService';
import Controls from '../controls/Controls';

export interface LocationFormProps {
  orgId: number,
  orgType: 'SELLER' | 'BUYER',
  location: LocationModel,
  setOpen(val: boolean): void
}
const getPersonName = (person: any) => (person
  ? `${person.firstName && person.firstName} ${person.middleName && person.middleName} ${person.lastName && person.lastName}`
  : '');

export default function LocationForm(props: LocationFormProps) {
  const {
    orgId, orgType, location, setOpen,
  } = props;
  const [globalError, setGlobalError] = useState('');
  const {
    values, errors, setErrors, handleInputChange, resetForm,
  } = useForm({
    ...location.contactPerson,
    ...location,
    organizationId: orgId,
    personName: getPersonName(location.contactPerson) || '',
    subType: orgType === 'BUYER' ? 'DELIVERY' : 'LOADING',
    active: location.active ? 'Active' : 'Not active',
  });
  const [countries, setCountries] = useState([]);
  const [ports, setPorts] = useState([]);
  const [submitLoading, setSubmitLoading] = useState(false);

  useEffect(() => {
    api.dictionary.getCountries(setCountries, setGlobalError);
  }, []);
  useEffect(() => {
    api.location.getSeaPorts(setPorts, setGlobalError, values.countryId);
  }, [values.countryId]);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (!validate()) {
      setGlobalError('Validation failed, please fill required fields');
      if (Object.keys(errors).length) {
        const input = document.querySelector('.MuiAlert-root, .Mui-error');
        input?.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'start',
        });
      }
      return;
    }

    const preparedContactPerson = parseContactPerson();

    const request = {
      ...values,
      contactPerson: location.contactPerson,
      active: values.active === 'Active',
    };

    if (preparedContactPerson) {
      request.contactPerson = {
        ...location.contactPerson,
        ...preparedContactPerson,
      };
    }

    setSubmitLoading(true);
    if (request.id) {
      await api.location.updateLocation(orgId, request.id, request)
        .then(() => { setGlobalError(''); setOpen(false); })
        .catch((err) => setGlobalError(err?.message));
    } else {
      await api.location.createLocation(orgId, request)
        .then(() => { setGlobalError(''); setOpen(false); })
        .catch((err) => setGlobalError(err?.message));
    }
    setSubmitLoading(false);
  };

  const validate = () => {
    setGlobalError('');
    const temp: any = {};
    const requiredFields = ['countryId'];
    (values.type === 'NON_PORT') && requiredFields.push('address1', 'zip', 'city');
    (values.category === 'LOGISTIC') && requiredFields.push('seaPortId');
    (orgType === 'BUYER') && requiredFields.push('incoterms');

    (values.email && !emailRegExp.test(String(values.email).toLowerCase())) && (temp.email = 'Invalid email format');
    requiredFields.forEach((key) => {
      if (!values[key] || values[key] <= 0) {
        temp[key] = 'This field is required.';
      }
    });

    setErrors({ ...temp });

    return Object.keys(temp).length === 0;
  };

  const parseContactPerson = () => {
    const fullName = values.personName?.split(' ');
    const person = {
      id: values.contactPerson?.id,
      email: values.email,
      phoneNumber: values.phoneNumber,
      firstName: fullName && fullName[0],
      middleName: (fullName && fullName.length > 2) ? fullName[1] : '',
      lastName: ((fullName && fullName.length > 2) && fullName[2]) || fullName[1],
      // for now this object always expects `timeZone`
      // already existing contact persons have this field
      timeZone: 'Europe/Amsterdam',
    };
    return Object.values(person).filter((val) => !!val).length > 0 ? person : null;
  };

  const handlePhoneNumberChange = (e: any) => {
    const val = e.target.value;
    const allowedSymbols = ['+', '-', '(', ')', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
    let correctValue = true;
    val?.split('').forEach((it: string) => {
      if (!allowedSymbols.includes(it)) {
        correctValue = false;
      }
    });
    if (correctValue) {
      handleInputChange(e);
    }
  };

  const getTypeOptions = () => (orgType === 'SELLER'
    ? [{ value: 'NON_PORT', label: 'Loading address' }, { value: 'PORT', label: 'Loading port' }]
    : [{ value: 'NON_PORT', label: 'Delivery address' }, { value: 'PORT', label: 'Delivery port' }]);

  const getCountryOptions = () => (countries
    ? countries.map((it: Country) => ({ label: `${it.name}(${it.iso2})`, value: it.id }))
    : []);

  const getPortOptions = () => (ports
    ? ports.map((it: SeaPortModel) => ({ label: `${it.name}(${it.code})`, value: it.id }))
    : []);

  const getIncotermsOptions = () => (
    values.type === 'PORT'
      ? [{ label: 'CFR', value: 'CFR' }, { label: 'CIF', value: 'CIF' }]
      : [{ label: 'CPT', value: 'CPT' }, { label: 'DAP', value: 'DAP' }]
  );

  return (
    <Form name="create-location">
      { globalError && <Alert severity="error">{globalError}</Alert> }
      {
        location.category === 'LOGISTIC'
      && (
      <Controls.RadioGroup
        name="type"
        label="LOCATION TYPE"
        value={values.type || 'NON_PORT'}
        items={getTypeOptions()}
        onChange={handleInputChange}
        disabled={!!values.id}
      />
      )
      }
      <Controls.Checkbox
        name="main"
        label="Main location in the category"
        value={values.main}
        onChange={handleInputChange}
        disabled={(location.id && location.main) || values.active === 'Not active'}
      />
      <Controls.Input
        name="name"
        label="LOCATION NAME"
        value={values.name}
        error={errors.name}
        onChange={handleInputChange}
      />
      { orgType === 'BUYER' && (
      <Controls.Select
        name="incoterms"
        label="INCOTERMS BUYER"
        value={values.incoterms}
        error={errors.incoterms}
        onChange={handleInputChange}
        options={getIncotermsOptions()}
        required
      />
      )}
      { location.category === 'LOGISTIC' && (
      <>
        <Controls.Input
          name="personName"
          label="LOCATION CONTACT PERSON FULL NAME"
          value={values.personName}
          error={errors.personName}
          onChange={handleInputChange}
        />
        <Controls.Input
          name="phoneNumber"
          label="LOCATION TELEPHONE NUMBER"
          value={values.phoneNumber}
          error={errors.phoneNumber}
          onChange={handlePhoneNumberChange}
        />
        <Controls.Input
          name="email"
          label="LOCATION EMAIL ADDRESS"
          value={values.email}
          error={errors.email}
          onChange={handleInputChange}
        />
      </>
      )}
      { values.type !== 'PORT' && (
      <>
        <Controls.Input
          name="address1"
          label="ADDRESS LINE 1 (STREET ADDRESS/PO BOX)"
          value={values.address1}
          error={errors.address1}
          onChange={handleInputChange}
          required
        />
        <Controls.Input
          name="address2"
          label="ADDRESS LINE 2 (APARTMENT/SUITE/UNIT/BUILDING)"
          value={values.address2}
          error={errors.address2}
          onChange={handleInputChange}
        />
        <Controls.Input
          name="zip"
          label="ZIP OR POSTAL CODE"
          value={values.zip}
          error={errors.zip}
          onChange={handleInputChange}
          required
        />
        <Controls.Input
          name="region"
          label="STATE/COUNTY/PROVINCE/REGION"
          value={values.region}
          error={errors.region}
          onChange={handleInputChange}
        />
        <Controls.Input
          name="city"
          label="CITY/TOWN"
          value={values.city}
          error={errors.city}
          onChange={handleInputChange}
          required
        />
      </>
      )}
      <Controls.Select
        name="countryId"
        label="COUNTRY"
        value={values.countryId}
        error={errors.countryId}
        onChange={handleInputChange}
        options={getCountryOptions()}
        required
      />
      {location.category === 'LOGISTIC' && (
      <Controls.Select
        name="seaPortId"
        label={values.type === 'PORT' ? 'SEA PORT' : 'NEAREST SEA PORT'}
        value={values.seaPortId}
        error={errors.seaPortId}
        onChange={handleInputChange}
        options={getPortOptions()}
        disabled={values.countryId <= 0}
        required
      />
      )}
      <Controls.RadioGroup
        name="active"
        label="STATUS"
        value={values.active}
        items={[{ value: 'Active', label: 'Active' }, { value: 'Not active', label: 'Not active' }]}
        onChange={handleInputChange}
      />

      <div>
        <Controls.Button type="submit" onClick={handleSubmit} disabled={submitLoading}>Submit</Controls.Button>
        <Controls.Button color="default" onClick={resetForm}>Reset</Controls.Button>
      </div>
    </Form>
  );
}
