import axios from 'api/axios';
import { OFFER_ENDPOINTS } from 'api/endpoints';
import { AxiosResponse } from 'axios';
import { VasFinancing } from 'types/offerTypes';

export interface IGetOfferFinancingParams {
  // params
  offerId: number | string;

  // payload
  volume: number | undefined;
  price: number | undefined | null;
  orgId: number | string | undefined;
  paymentTerm?: string;
  paymentOffset?: number | string;
}

export const getOfferFinancing = async (params: IGetOfferFinancingParams) => {
  const {
    offerId, volume, price, orgId, paymentTerm, paymentOffset,
  } = params;

  const res: AxiosResponse<VasFinancing> = await axios({
    method: 'get',
    url: OFFER_ENDPOINTS.getFinancingPrices(offerId),
    params: {
      volume,
      price,
      orgId,
      paymentTerm,
      paymentOffset,
    },
  });

  if (res.status === 200) {
    return res.data;
  }

  throw new Error(`Cannot get financing prices. Status: ${res.status}`);
};
