import React from 'react';
import ForwardIcon from '@material-ui/icons/Forward';
// import ForwardOutlinedIcon from '@material-ui/icons/ForwardOutlined';
import useLogisticsPricesStore from 'store/addLogisticsPrices';
import { prepareLanePreviewData } from 'helpers/logisticsLocations';
import ShowMore from './ShowMore';

interface ISellerBuyerItem {
  seller: string;
  buyer: string;
  key: string;
}

const LanesPreview = () => {
  const {
    sellers,
    destinationCountries,
    selectedLocations,
    laneData,
  } = useLogisticsPricesStore((state) => state);

  const previewData = prepareLanePreviewData(sellers, destinationCountries, selectedLocations);
  const sellerBuyerArr = (
    () => {
      if (!previewData) return [];

      const arr: ISellerBuyerItem[] = [];
      const { sellers: sellerOrgs, buyers: buyerOrgs } = previewData;
      sellerOrgs.forEach((s) => {
        buyerOrgs.forEach((b) => {
          arr.push({
            seller: s,
            buyer: b,
            key: `${s}-${b}`,
          });
        });
      });

      return arr;
    }
  )();

  if (previewData && laneData && laneData.length > 0) {
    return (
      <section>
        <h2 className="text-block-with-icon">
          Lane {laneData[0].laneId} {previewData.from.country}, {previewData.from.location}
          <ForwardIcon color="secondary" />
          {previewData.to.country}, {previewData.to.location}
        </h2>

        <div style={{ fontSize: 18 }}>
          {sellerBuyerArr.slice(0, 3).map((item) => (
            <div key={item.key} className="text-block-with-icon mb-1">
              {item.seller}
              {/* <ForwardOutlinedIcon color="secondary" /> */}
              <ForwardIcon color="secondary" />
              {item.buyer}
            </div>
          ))}

          {sellerBuyerArr.length > 3 && (
            <ShowMore>
              {sellerBuyerArr.slice(3).map((item) => (
                <div key={item.key} className="text-block-with-icon mb-1">
                  {item.seller}
                  {/* <ForwardOutlinedIcon color="secondary" /> */}
                  <ForwardIcon color="secondary" />
                  {item.buyer}
                </div>
              ))}
            </ShowMore>
          )}
        </div>
      </section>
    );
  }

  return <h2>There is no lane data</h2>;
};

export default LanesPreview;
