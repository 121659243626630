import { Alert, Button } from '@mui/material';
import { useOrgQuery } from 'api/queries/useOrgQuery';
import { useOrgUsersQuery } from 'api/queries/useOrgUsersQuery';
import FormAutocomplete from 'components/hook-form/FormAutocomplete';
import FormRadioGroup from 'components/hook-form/FormRadioGroup';
import Preloader from 'components/od-preloader/Preloader';
import DataItemPreview from 'components/partials/DataItemPreview';
import {
  numbersArrayToOptions, destinationLocationsToOptions, orgsToOptions, usersToOptions, financingPricesToOptions, getSellerFinancePrice,
} from 'helpers/common';
import React, { FC, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { formatMoney } from 'services/Utils';
import { DevTool } from '@hookform/devtools';
import { BuyerPaymentTerm, OnSpecOffer } from 'types/offerTypes';
import isDevEnv from 'helpers/isDevEnv';
import FormInput from 'components/hook-form/FormInput';
import FormDatePicker from 'components/hook-form/FormDatePicker';
import PreventLossUnsavedData from 'hooks/usePreventReload';
import LinkButton from 'components/hook-form/LinkButton';
import FormOutput from 'components/hook-form/FormOutput';
import { useLogisticsPricesQuery } from 'api/queries/useLogisticsPricesQuery';
import { useDictionaryQuery } from 'api/queries/useDictionaryQuery';
import { useOfferFinancingQuery } from 'api/queries/useOfferFinancingQuery';
import { IOption } from 'validators/common/optionSchema';
import { createKeyStrings } from 'helpers/createKeyStrings';
import { useUserPaymentTermsQuery } from 'api/queries/useUserPaymentTermsQuery';
import Popup from 'components/Popup';
import { IPlaceBidPayload } from 'api/post/postOfferBid';
import { useCreateDealMutation } from 'api/mutations/useCreateDealMutation';
import { PlaceBidResponse } from 'types/bidTypes';
import { toast } from 'react-hot-toast';
import { useHistory } from 'react-router-dom';
import moment from 'moment-timezone';
import pages from 'navigation/pages';

const logisticsProviderTypeOptions = [
  { label: 'by the buyer', value: 'OWN' },
  { label: 'by platform provider', value: 'THIRD_PARTY' },
];

const financingProviderTypeOptions = [
  { label: 'by the buyer', value: 'OWN' },
  { label: 'by the seller', value: 'SELLER' },
  { label: 'by platform provider', value: 'THIRD_PARTY' },
];

type ProviderType = 'OWN' | 'SELLER' | 'THIRD_PARTY';

interface FormValues {
  buyerOrg: IOption;
  buyer: IOption;

  dealCurrency: 'EUR' | 'USD';
  price: number;
  packaging?: IOption;
  volume: number;

  date: string;

  buyerIncoterms: IOption;
  buyerIncotermsLocation: IOption;
  containerTypeId: number;
  logisticsProviderType: ProviderType;
  logisticsProvider: IOption;
  logisticsPrice: number;

  paymentTerm: BuyerPaymentTerm;
  paymentOffset: IOption;
  financingProviderType: ProviderType;

  financingPriceBuyer?: number | string;
  financingPriceSeller?: number | string;
  financingPriceProvider?: number | string;
}

const formKeys = createKeyStrings<FormValues>();

interface IProps {
  offer: OnSpecOffer
}

const CreateDealForm: FC<IProps> = ({ offer }) => {
  const history = useHistory();

  const methods = useForm<FormValues>({ shouldUnregister: true });
  const {
    handleSubmit, watch, formState: { isDirty },
  } = methods;

  const [submitModalOpen, setSubmitModalOpen] = useState(false);
  const [placeBIdPayload, setPlaceBIdPayload] = useState<IPlaceBidPayload | null>(null);
  const [placeBidPreviewData, setPlaceBidPreviewData] = useState<PlaceBidResponse | null>(null);

  // form values
  const buyerOrgValue = watch('buyerOrg');
  const buyerValue = watch('buyer');
  const dealCurrencyValue = watch('dealCurrency');
  const logisticsProviderTypeValue = watch('logisticsProviderType');
  const buyerIncotermsValue = watch('buyerIncoterms');
  // const buyerIncotermsLocationValue = watch('buyerIncotermsLocation');
  const financingProviderTypeValue = watch('financingProviderType');
  const paymentTermValue = watch('paymentTerm');
  const paymentOffsetValue = watch('paymentOffset');
  // form values === END

  // queries
  const buyerOrgs = useOrgQuery('buyer');
  const buyerUsers = useOrgUsersQuery(buyerOrgValue?.value);
  const initialLogistics = useLogisticsPricesQuery({
    offerId: offer.id,
    orgId: buyerOrgValue?.value,
    buyerIncoterms: buyerIncotermsValue?.value,
  });
  const logisticsProviderTypes = useDictionaryQuery({ types: ['LOGISTIC_PARTNER'] });
  const availablePaymentTerms = useUserPaymentTermsQuery({
    offerId: offer.id,
    orgId: buyerOrgValue?.value,
    volume: offer.remainingVolume,
    price: offer.priceEur || offer.priceUsd,
  });
  const financingPrices = useOfferFinancingQuery({
    offerId: offer.id,
    orgId: buyerOrgValue?.value,
    volume: offer.remainingVolume,
    price: dealCurrencyValue === 'EUR' ? offer.priceEur : offer.priceUsd,

    paymentTerm: paymentTermValue?.value,
    paymentOffset: paymentOffsetValue?.value,

    offsetDaysOptions: paymentTermValue?.offsetDays,
  });
  // queries === END

  // mutations
  const placeBidMutation = useCreateDealMutation();
  // mutations === END

  const financingProvidersOptions = logisticsProviderTypes.data?.LOGISTIC_PARTNER?.map((item) => ({
    label: item.label,
    value: `${item.id}`,
  })) || [];

  // conditional flags
  const isTBP = (offer.type?.value === 'TO_BE_PRODUCED');
  const isSplittable = offer.splittable;
  const isShowPreloader = (
    buyerOrgs.isFetching
    || buyerUsers.isFetching
    || initialLogistics.isFetching
    || logisticsProviderTypes.isFetching
    || availablePaymentTerms.isFetching
    || financingPrices.isFetching
    || placeBidMutation.isLoading
  );
  const isOrgUsersEmpty = buyerOrgValue && (buyerUsers.data?.rows?.length === 0);
  const isCanProceed = buyerOrgValue && buyerValue;

  const isThirdPartyLogisticsProvider = logisticsProviderTypeValue === 'THIRD_PARTY';
  const isBuyerFinanceProvider = financingProviderTypeValue === 'OWN';
  const isSellerFinanceProvider = financingProviderTypeValue === 'SELLER';
  const isThirdPartyFinanceProvider = financingProviderTypeValue === 'THIRD_PARTY';
  // conditional flags === END

  // other data
  const sellerFinancePrice = getSellerFinancePrice(financingPrices.data?.options);
  const thirdPartyFinanceOptions = financingPricesToOptions(financingPrices.data?.options, 'THIRD_PARTY', dealCurrencyValue);
  // other data === END

  async function onSubmit(data: FormValues) {
    console.log('data', data);

    const financingPrice = () => {
      if (data.financingProviderType === 'THIRD_PARTY') {
        return data.financingPriceProvider;
      }

      if (data.financingProviderType === 'SELLER') {
        return data.financingPriceSeller;
      }

      return null;
    };

    const payload: IPlaceBidPayload = {
      // required
      offerId: offer.id,
      offerVersion: offer.version,

      buyerOrgId: data.buyerOrg?.value,
      buyerUserId: data.buyer?.value,

      currency: data.dealCurrency,
      price: data.price,
      volume: data.volume,

      creationDate: moment(data.date).valueOf(),

      paymentTerm: data.paymentTerm?.value,

      // optional
      packaging: data.packaging?.value ? `${data.packaging?.value}` : '',

      logisticLocationId: data.buyerIncotermsLocation.value,
      logisticPrice: data.logisticsPrice,
      containerTypeId: data.containerTypeId,
      logisticPartnerId: data.logisticsProvider?.value || null,

      paymentOffset: data.paymentOffset?.value,
      creditConditionId: financingPrice(),
    };

    setPlaceBIdPayload(payload);

    try {
      const res = await placeBidMutation.mutateAsync({
        commit: false,
        payload,
      });

      setPlaceBIdPayload(payload);
      setPlaceBidPreviewData(res);
      setSubmitModalOpen(true);
    } catch (error) {
      toast.error('Error while validating deal data at the server side');
      console.error(error);
    }
  }

  function handleModalClose() {
    setSubmitModalOpen(false);
    setPlaceBIdPayload(null);
    setPlaceBidPreviewData(null);
  }

  async function handleCreateDeal() {
    if (!placeBIdPayload) {
      toast.error('Cannot send deal with no data to server');
      return;
    }

    try {
      const res = await placeBidMutation.mutateAsync({
        commit: true,
        payload: placeBIdPayload,
      });

      console.log('created deal response', res);

      toast.success('Deal created successfully');

      // cleanup
      setSubmitModalOpen(false);
      setPlaceBidPreviewData(null);
      setPlaceBIdPayload(null);

      // redirect
      history.push(pages.goto_offerPage(offer.id));
    } catch (error) {
      toast.error('Error while creating deal');
      console.error(error);
    }
  }

  PreventLossUnsavedData(isDirty);

  const isValidOfferType = ((offer.type?.value === 'ALREADY_PRODUCED') || (offer.type?.value === 'TO_BE_PRODUCED'));

  if (!isValidOfferType) {
    return (
      <Alert severity="error">
        This offer type is not supported
      </Alert>
    );
  }

  return (
    <div className="pt-4">
      <h2>Offline deal details</h2>

      <Preloader isLoading={isShowPreloader} />

      {isDevEnv && <DevTool control={methods.control} placement="top-right" />}

      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off" className="form-grid ">
          <h3>Buyer</h3>
          <div>
            <FormAutocomplete
              name="buyerOrg"
              label="Buyer organization"
              options={orgsToOptions(buyerOrgs.data?.rows)}
              dependentFields={[formKeys.buyer, formKeys.buyerIncoterms, formKeys.buyerIncotermsLocation]}
              required
            />
            {isOrgUsersEmpty && (
            <Alert severity="warning">No users in this organisation</Alert>
            )}
          </div>

          <FormAutocomplete
            name="buyer"
            label="Buyer"
            // blocked or inactive users are not allowed to create offers
            // second param is for filtering out non-active users
            options={usersToOptions(buyerUsers?.data?.rows, true)}
            disabled={!buyerOrgValue || isOrgUsersEmpty}
            required
          />

          {/* {isCanProceed && ( */}
          {(isCanProceed) && (
            <>
              <h3>Deal price and volume</h3>
              <div className="form--sub-grid">
                {offer?.priceEur && (
                  <DataItemPreview label="Offer price EUR" value={formatMoney(offer.priceEur)} suffix="EUR/MT" />
                )}
                {offer?.priceUsd && (
                  <DataItemPreview label="Offer price USD" value={formatMoney(offer.priceUsd)} suffix="USD/MT" />
                )}
              </div>

              <FormRadioGroup
                name="dealCurrency"
                label="Deal in"
                options={[
                  { value: 'EUR', label: 'in EUR', disabled: !offer?.priceEur },
                  { value: 'USD', label: 'in USD', disabled: !offer?.priceUsd },
                ]}
                required
              />

              <FormInput
                name="price"
                label="DEAL PRICE EXCLUDING VAS"
                type="number"
                suffix={`${dealCurrencyValue}/MT`}
                rules={{
                  required: 'Price is required',
                  valueAsNumber: true,
                  min: {
                    value: 0,
                    message: 'Price must be greater than 0',
                  },
                }}
                required
              />

              {isTBP && (
                <FormAutocomplete
                  name="packaging"
                  label="Packaging"
                  options={offer.packages || []}
                  required
                />
              )}

              <DataItemPreview label="Offer volume" value={offer.totalVolume} suffix="MT" />
              <DataItemPreview label="Remaining volume" value={offer.remainingVolume} suffix="MT" />

              {isSplittable && (
              <FormInput
                name="volume"
                label="DEAL VOLUME"
                type="number"
                suffix="MT"
                rules={{
                  required: 'Volume is required',
                  valueAsNumber: true,
                  min: {
                    value: 0,
                    message: 'Volume must be greater than 0',
                  },
                }}
                required
              />
              )}

              {!isSplittable && (
                <FormOutput
                  name="volume"
                  fieldValue={offer.totalVolume || 0}
                  label="DEAL VOLUME"
                  value={offer.totalVolume}
                  suffix="MT"
                />
              )}

              <h3>Deal date</h3>
              <FormDatePicker
                name="date"
                label="OFFLINE DEAL MADE ON DATE"
                dateFormat="DD/MM/yyyy HH:mm"
                required
                rules={{
                  required: 'Deal creation date is required',
                }}
              />

              <h3 className="pt-3">Logistics</h3>
              <DataItemPreview
                label="Port of loading"
                value={`${offer.incoterms?.label}. ${offer.logisticLocation?.name}, ${offer.logisticLocation?.country}`}
              />

              <FormAutocomplete
                name="buyerIncoterms"
                label="BUYER INCOTERMS"
                options={initialLogistics.data?.availableBuyerIncoterms || []}
                dependentFields={[formKeys.buyerIncotermsLocation]}
                required
              />

              <FormAutocomplete
                name="buyerIncotermsLocation"
                label="BUYER INCOTERMS LOCATION"
                options={destinationLocationsToOptions(initialLogistics.data?.destinationLocations)}
                required
              />

              {/* {(
                buyerIncotermsValue
                && buyerIncotermsLocationValue
                && !initialLogistics.data?.logisticNeeded
              ) && (
                <Alert severity="warning">No logistics needed for this combination of incoterms and locations</Alert>
              )} */}

              {/* LOGISTICS DETAILS */}
              <>
                <FormRadioGroup
                  name="logisticsProviderType"
                  label="LOGISTICS"
                  options={logisticsProviderTypeOptions}
                  required
                />

                {isThirdPartyLogisticsProvider && (
                  <>
                    <FormRadioGroup
                      name="containerTypeId"
                      label="CONTAINER"
                      options={offer.productContainers?.map((c) => ({
                        label: c.containerTypeLabel,
                        value: c.containerTypeId,
                      })) || []}
                      required
                    />

                    <FormAutocomplete
                      name="logisticsProvider"
                      label="PROVIDER"
                      options={financingProvidersOptions}
                      required
                    />

                    <FormInput
                      name="logisticsPrice"
                      label="PRICE FOR LOGISTICS"
                      type="number"
                      suffix={`${dealCurrencyValue}/MT`}
                      rules={{
                        required: 'Price is required',
                        valueAsNumber: true,
                      }}
                      required
                    />
                  </>
                )}
              </>

              <h3 className="pt-3">Financing</h3>
              <DataItemPreview label="Payment terms" value={offer.paymentTerms?.label} />

              <div className="optional-sub-grid">
                <FormAutocomplete
                  name="paymentTerm"
                  label="BUYER PAYMENT TERMS"
                  options={availablePaymentTerms.data?.availableBuyerPaymentTerms || []}
                  dependentFields={[formKeys.paymentOffset]}
                  required
                />

                {paymentTermValue?.hasDayOffset && (
                <FormAutocomplete
                  name="paymentOffset"
                  label="Offset"
                  options={numbersArrayToOptions(paymentTermValue?.offsetDays) || []}
                  sorted={false}
                  rules={{
                    required: 'Required',
                  }}
                  required
                />
                )}
              </div>

              {(
                financingPrices?.data
                && !financingPrices.data?.financingNeeded
              ) && (
                <Alert severity="warning">No financing is needed for this combination of payment terms</Alert>
              )}

              <FormRadioGroup
                name="financingProviderType"
                label="Financing"
                options={financingProviderTypeOptions}
                required
              />

              {/* if 'buyer' => nothing to show */}
              {isBuyerFinanceProvider && (
                <FormOutput
                  name="financingPriceBuyer"
                  fieldValue="OWN"
                  label="Buyer finance"
                  value="Seller payment terms apply"
                />
              )}
              {/* if 'seller' => show price by seller */}
              {isSellerFinanceProvider && (
                <FormOutput
                  name="financingPriceSeller"
                  fieldValue={sellerFinancePrice?.id || null}
                  label="Seller finance"
                  value={sellerFinancePrice ? formatMoney(sellerFinancePrice?.price) : null}
                  suffix={sellerFinancePrice ? `${dealCurrencyValue}/MT` : '-error-'}
                  requiredMessage="There is no seller financing price for current settings"
                />
              )}

              {/* if 'third_party' => show radio buttons with options */}
              {isThirdPartyFinanceProvider && (
                <>
                  <FormRadioGroup
                    name="financingPriceProvider"
                    label="Prices"
                    layout="column"
                    options={thirdPartyFinanceOptions}
                    rules={{
                      required: 'Please select financing price',
                    }}
                    noOptionsText="No third-party financting options"
                    required
                  />
                </>
              )}
            </>
          )}

          <div className="button-group pt-2">
            <Button
              type="submit"
              variant="contained"
              color="secondary"
              disabled={!isCanProceed || isShowPreloader}
            >
              Preview deal summary
            </Button>

            <LinkButton
              to={pages.goto_offerPage(offer?.id)}
              variant="outlined"
              label="Dismiss"
              disabled={!offer.id || isShowPreloader}
            />
          </div>
        </form>
      </FormProvider>

      <Popup
        title="You are about to create a deal"
        open={submitModalOpen}
        handleClose={handleModalClose}
        maxWidth="sm"
      >
        <Preloader isLoading={isShowPreloader} />
        {placeBidPreviewData && (
          <>
            <DataItemPreview
              label="Net deal price"
              value={formatMoney(placeBidPreviewData.netPrice)}
              suffix={`${placeBidPreviewData.currency}/MT`}
            />

            {!!(
              (offer?.type?.value === 'TO_BE_PRODUCED')
              && (placeBidPreviewData?.packaging)
            ) && (
              <>
                <DataItemPreview
                  label="Packaging"
                  value={placeBidPreviewData.packaging?.label}
                />

                {!!placeBidPreviewData?.packagingPrice && (
                  <DataItemPreview
                    label="Packaging price"
                    value={formatMoney(placeBidPreviewData.packagingPrice)}
                    prefix={placeBidPreviewData.currency}
                    suffix="/MT"
                  />
                )}
              </>
            )}

            <DataItemPreview
              label="Logistics"
              value={placeBidPreviewData.logisticPrice ? formatMoney(placeBidPreviewData.logisticPrice) : null}
              suffix={`${placeBidPreviewData.currency}/MT`}
            />

            <DataItemPreview
              label="Financing"
              value={placeBidPreviewData.creditPrice ? formatMoney(placeBidPreviewData.creditPrice) : null}
              suffix={`${placeBidPreviewData.currency}/MT`}
            />

            <DataItemPreview
              label="Total deal price /MT"
              value={formatMoney(placeBidPreviewData.totalPrice)}
              suffix={`${placeBidPreviewData.currency}/MT`}
            />

            <DataItemPreview
              label="Deal volume"
              value={placeBidPreviewData.volume}
              suffix="MT"
            />

            <DataItemPreview
              label="Total deal price"
              value={formatMoney(placeBidPreviewData.totalPrice * placeBidPreviewData.volume)}
              prefix={placeBidPreviewData.currency}
              valueClass="accent-green"
            />
          </>
        )}

        <div className="button-group pt-2">
          <Button
            variant="contained"
            color="secondary"
            onClick={handleCreateDeal}
            disabled={isShowPreloader}
          >
            Create deal
          </Button>

          <Button
            variant="outlined"
            color="secondary"
            onClick={handleModalClose}
            disabled={isShowPreloader}
          >
            Back to creation
          </Button>
        </div>
      </Popup>
    </div>
  );
};

export default CreateDealForm;
