import React, { FC, useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import {
  FormControl, FormControlLabel, Radio, RadioGroup,
} from '@mui/material';
import { IFormFieldBase, IOption } from './FormSelect';
import validationRules from './validationRules';

interface IFormRadioGroupProps extends IFormFieldBase {
  options: IOption[];
  defaultValue?: string | number;
  layout?: 'row' | 'column';
  noOptionsText?: string;
}

const FormRadioGroup: FC<IFormRadioGroupProps> = ({
  name,
  label,
  options,
  rules = validationRules.required,
  disabled,
  // defaultValue = options[0]?.value || null,
  defaultValue,
  layout = 'row',
  noOptionsText = 'No options',
}) => {
  const {
    formState, control, setValue,
  } = useFormContext();
  const { errors } = formState;

  const firstNotDisabledOption = options.find((opt) => !opt.disabled);
  const fieldDefaultValue = defaultValue || firstNotDisabledOption?.value || null;

  useEffect(() => {
    setValue(name, fieldDefaultValue);
  }, []);

  return (
    <div className="form-field form-radio-group">
      {label && <div className="label-text">{rules && rules.required ? `${label} *` : label}</div>}

      {options.length > 0 && (
      <Controller
        name={name}
        control={control}
        rules={rules}
        defaultValue={fieldDefaultValue}
        render={({ field: { value, onChange } }) => (
          <FormControl component="fieldset">
            <RadioGroup
              value={value}
              onChange={onChange}
              row={layout === 'row'}
            >
              {options.map((option) => (
                <FormControlLabel
                  key={option.value}
                  value={option.value}
                  control={<Radio color="secondary" />}
                  label={option.label}
                  disabled={option.disabled || disabled}
                />
              ))}
            </RadioGroup>
          </FormControl>
        )}
      />
      )}

      {options.length === 0 && <div className="form-field__error">{noOptionsText}</div>}

      {errors[name]?.message && <div className="form-field__error">{errors[name]?.message}</div>}
    </div>
  );
};

export default FormRadioGroup;
