import QueryString from 'qs';
import { useLocation } from 'react-router-dom';

const useQueryItem = (itemName: string) => {
  const { search } = useLocation();
  const query = QueryString.parse(search, { ignoreQueryPrefix: true });
  const item = query[itemName] ? `${query[itemName]}` : null;

  return item;
};

export default useQueryItem;
