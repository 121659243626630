import { Snackbar, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React, { useEffect, useState } from 'react';
import api from '../../services/api';
import Controls from '../controls/Controls';
import PackagingDifferential from './PackagingDifferential';

export default function PackagingDiffPage({ orgId }: any) {
  const [packages, setPackages] = useState([]);
  const [open, setOpen] = useState(false);
  const [globalError, setGlobalError] = useState('');

  const handleSubmit = () => {
    setGlobalError('');
    api.transportation.setPackagePrices(orgId, packages)
      .then(() => setOpen(true))
      .catch((err) => setGlobalError(err.message));
  };

  useEffect(() => {
    api.transportation.getPackagePrices(orgId)
      .then((res) => setPackages(res.prices))
      .catch((err) => setGlobalError(err.message));
  }, [orgId]);

  return (
    <>
      <Typography variant="h5">Packaging differentials for To-Be-Produced offers</Typography>
      { globalError && <Alert severity="error">{globalError}</Alert> }
      {
        packages.map((it: any) => (
          <PackagingDifferential
            item={it}
            key={it.packaging.value}
            array={packages}
            setArray={setPackages}
          />
        ))
      }
      <br />
      <div>
        <Controls.Button type="submit" onClick={handleSubmit}>Save</Controls.Button>
      </div>
      <Snackbar open={open} autoHideDuration={3000} onClose={() => setOpen(false)} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
        <Alert onClose={() => setOpen(false)} severity="success" variant="filled">
          Success!
        </Alert>
      </Snackbar>
    </>
  );
}
