import React, { CSSProperties } from 'react';
import {
  FormControl, InputLabel, Select as MuiSelect, MenuItem, FormHelperText, SelectProps,
} from '@material-ui/core';

export interface Option {
  label: string,
  value: any
}

export default function Select(
  // eslint-disable-next-line react/require-default-props
  props: SelectProps & { options: Array<Option> } & CSSProperties & { rootStyle?: CSSProperties },
) {
  const {
    label, options, error = null, rootStyle,
  } = props;

  return (
    <FormControl
      variant="outlined"
      style={{ ...rootStyle, marginBottom: 10 }}
      {...(error && { error: true })}
    >
      <InputLabel>{label}</InputLabel>
      <MuiSelect {...props}>
        {
          options.map(
            (item: Option) => (
              <MenuItem key={`${item.label}-${item.value}`} value={item.value}>{item.label}</MenuItem>
            ),
          )
        }
      </MuiSelect>
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
}
