import React from 'react';
import {
  FormControl, FormLabel, RadioGroup as MuiRadioGroup, FormControlLabel, Radio,
} from '@material-ui/core';
import Hint from 'components/hook-form/Hint';

export default function RadioGroup(props: any) {
  const {
    name, label, value, onChange, items, disabled, hint = '',
  } = props;

  return (
    <FormControl disabled={disabled}>
      <FormLabel className="hint-wrapper form-label">
        <span>{label}</span>
        { hint && <Hint text={hint} /> }
      </FormLabel>
      <MuiRadioGroup
        row
        name={name}
        value={value}
        onChange={onChange}
      >
        {
          items.map(
            (item: any) => (
              <FormControlLabel
                key={`checkbox-${item.label}`}
                value={item.value}
                control={<Radio />}
                label={item.label}
              />
            ),
          )
        }
      </MuiRadioGroup>
    </FormControl>
  );
}
