import { fallbackTimezone } from 'SHARED/helpers/dates';
import { useTypedSelector } from 'SHARED/redux/hooks/useTypedSelector';

// should be used only inside of Redux Provider
const useUserTimezone = () => {
  const { me } = useTypedSelector((state) => state.users);
  const userTimezone = me.profile?.timeZone;
  const userTimezoneAbbr = me.profile?.timeZoneAbbr;

  return {
    userTimezone: userTimezone || fallbackTimezone,
    userTimezoneAbbr,
  };
};

export default useUserTimezone;
