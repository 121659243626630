import React, { FC, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import FormRadioGroup from 'components/hook-form/FormRadioGroup';
import FormSelect from 'components/hook-form/FormSelect';
import validationRules from 'components/hook-form/validationRules';
import { locationTypeOptions } from 'helpers/logisticsLocations';
import { Alert } from '@material-ui/lab';

interface IProps {
  type: 'From' | 'To';
  title: string;

  countryOptions: any[];
  portOptions: any[];
  cityOptions: any[];
  zipOptions: any[];
}

const LocationSelector: FC<IProps> = ({
  type,
  title,
  countryOptions,
  portOptions,
  cityOptions,
  zipOptions,
}) => {
  const { watch, setValue } = useFormContext();

  // === field names ===
  const countryField = `countryId${type}`;
  const locationTypeField = `locationType${type}`;
  const portField = `seaPortId${type}`;
  const cityField = `city${type}`;
  const zipCodeField = `zip${type}`;
  // === field names ===

  // === watch fields ===
  const seller = watch('seller');
  const country = watch(countryField);
  const locationType = watch(locationTypeField);
  const city = watch(cityField);
  // === watch fields ===

  // === condition check flags ===
  const isFrom = type === 'From';
  const isLocationTypePort = locationType === 'PORT';
  const isLocationTypeNonPort = locationType === 'NON-PORT';

  const noPortOptions = !portOptions.length;
  const noCityOptions = !cityOptions.length;
  // === condition check flags ===

  // === disabled conditions ===
  const isCountryDisabled = isFrom ? !seller : false;
  const isLocationTypeDisabled = isFrom ? (!seller || !country) : !country;
  const isPortDisabled = isFrom ? (!seller || !country) : !country;
  const isCityDisabled = isFrom ? (!seller || !country) : !country;
  const isZipCodeDisabled = isFrom ? (!seller || !country || !city) : (!country || !city);
  // === disabled conditions ===

  // === reset fields handlers ===
  const resetLocation = () => {
    setValue(portField, '');
    setValue(cityField, '');
    setValue(zipCodeField, '');
  };
  const resetZipCode = () => {
    setValue(zipCodeField, '');
  };
  // === reset fields handlers ===

  // === side effects ===
  useEffect(() => {
    resetLocation();
  }, [country, locationType]);
  useEffect(() => {
    resetZipCode();
  }, [city]);
  // === side effects ===

  return (
    <div className="location-selector">
      <h3>{title}</h3>
      <FormSelect
        name={countryField}
        label="country*"
        options={countryOptions}
        rules={validationRules.notRequired}
        disabled={isCountryDisabled}
      />

      <FormRadioGroup
        name={locationTypeField}
        label="Location type*"
        options={locationTypeOptions}
        rules={validationRules.notRequired}
        disabled={isLocationTypeDisabled}
      />

      {/* // ? add here info messages if we have no ports or non-ports (instead of respected fields) */}

      <div className="two-col-grid two-col-grid--s">
        {/* conditional fields, to pick PORT or CITY with ZIP-CODE */}
        {isLocationTypePort && (
          <>
            {!noPortOptions && (
              <FormSelect
                name={portField}
                label="Port*"
                options={portOptions}
                rules={validationRules.notRequired}
                disabled={isPortDisabled}
              />
            )}

            {noPortOptions && country && (
              <Alert severity="warning">There is no port locations with current parameters</Alert>
            )}
          </>
        )}

        {isLocationTypeNonPort && (
        <>
            {!noCityOptions && (
              <>
                <FormSelect
                  name={cityField}
                  label="City*"
                  options={cityOptions}
                  rules={validationRules.notRequired}
                  disabled={isCityDisabled}
                />
                <FormSelect
                  name={zipCodeField}
                  label="postal code*"
                  options={zipOptions}
                  rules={validationRules.notRequired}
                  disabled={isZipCodeDisabled}
                />
              </>
            )}

            {noCityOptions && country && (
              <Alert severity="warning">There is no non-port locations with current parameters</Alert>
            )}
        </>
        )}
      </div>

    </div>
  );
};

export default LocationSelector;
