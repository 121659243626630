import { Option } from '../../components/controls/Select';
import { option } from '../Utils';
import {
  del, get, post, put,
} from './RestUtil';

const TERMS_CREATION_TYPES = {
  INDIVIDUAL: 'INDIVIDUAL',
  SHARED: 'SHARED',
};

export interface CreditLineFilter {
  activeOnly?: boolean,
  archivedOnly?: boolean,
  sellerId?: number | undefined
  buyerId?: number | undefined
  requiredLimit?: number | undefined
}

export interface CreditLineRow {
  buyer: Option
  conditions: Array<CreditLineRowCondition>
}

export interface CreditLineRowCondition {
  lineId: number
  conditionId: number
  provider: Option
  providerType: Option
  buyer: Option
  seller: Option
  validTo: string
  currency: string
  totalLimit: number
  remainingLimit: number
  status: Option
}

export interface CreditLine {
  id?: number | null
  buyer: Option
  provider: CreditLineProvider
  currency: string
  totalLimit: number
  availableLimit: number
  validTo: number | undefined
  status: string
  conditions: Array<ICreditLineCondition>
}

export interface ICreditLineCondition {
  id?: number | undefined
  creditLineId?: number | undefined
  seller: Option
  sellers?: Option[]
  currency?: string | undefined
  interestRate: number
  commissionPerDeal: number
  commissionPerUnit: number
  maxCreditTerm: number
}

export interface CreditLineProvider {
  id: number
  type: string
  value: string
  label: string
  displayOrder: number
  providerType: Option
  isIncludeCreditPrice: boolean
  isDisabled: boolean
}

export interface CreditTransaction {
  amount: number
  created: number
  createdBy: Option
  type: Option
  dealId?: number | null
  limitAfter: number
  limitBefore: number
}

export const emptyCondition = {
  seller: option(null),
  sellers: [],
  interestRate: 0,
  currency: 'EUR',
  commissionPerDeal: 0,
  commissionPerUnit: 0,
  maxCreditTerm: 0,
};

export const emptyLine = {
  buyer: option(null),
  provider: { label: '', value: 0, providerType: option('THIRD_PARTY', '3rd party') },
  currency: 'EUR',
  type: 'THIRD_PARTY',
  totalLimit: 0,
  availableLimit: 0,
  validTo: null,
  status: 'ACTIVE',
  conditions: [emptyCondition],
  transactions: [],
  id: null,
  termsCreationType: TERMS_CREATION_TYPES.INDIVIDUAL,
};

export class CreditService {
  getLine = (id: number) => get(`/v1/financing/credit-lines/${id}`);

  getLines = (filter?: CreditLineFilter) => get('/v1/financing/credit-lines/grouped', filter);

  createLine = (line: any) => post('/v1/financing/credit-lines', line);

  updateLine = (id: number, line: any) => put(`/v1/financing/credit-lines/${id}`, line);

  withdrawLine = (id: number | null) => del(`/v1/financing/credit-lines/${id}`);

  makeTransaction = (id: number, amount: number) => post(`/v1/financing/credit-lines/${id}/transaction`, { amount });
}
