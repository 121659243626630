import {
  get, PageRequest, post, put,
} from './RestUtil';
import { IProfile } from './UserService';

export interface LocationModel {
  id: number | null | undefined
  name?: string | ''
  category: 'PRODUCTION' | 'LOGISTIC'
  type: 'PORT' | 'NON_PORT'
  subType: 'LOADING' | 'DELIVERY'
  main: boolean
  active: boolean | string
  incoterms: string
  fullAddress: string
  address1: string
  address2?: string | ''
  city: string
  region?: string | ''
  zip: string
  contactPerson?: IProfile | {}
  countryId: number | null | undefined
  organizationId: number | null | undefined
  seaPortId: number | null | undefined
  seaPortName?: string
}

export const emptyLocation: LocationModel = {
  id: null,
  name: '',
  category: 'LOGISTIC',
  type: 'NON_PORT',
  subType: 'LOADING',
  main: true,
  active: 'Active',
  incoterms: '',
  fullAddress: '',
  address1: '',
  address2: '',
  city: '',
  region: '',
  zip: '',
  contactPerson: {
    firstName: '',
    middleName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
  },
  countryId: null,
  organizationId: null,
  seaPortId: null,
};

export interface SeaPortModel {
  id: number
  code: string
  name: string
  countryId: number
}

export class LocationService {
  getSeaPorts = (setPorts: any, setError: any, countryId?: number) => {
    get('/v1/sea-ports', { countryId })
      .then((res) => setPorts(res))
      .catch((err) => setError(err));
  };

  getLocations = (orgId: number, category: string, pageRequest: PageRequest) => get(`/v1/organizations/${orgId}/locations/${category}`, pageRequest);

  createLocation = (orgId: number, location: LocationModel) => post(`/v1/organizations/${orgId}/locations`, location);

  updateLocation = (orgId: number, locId: number, location: LocationModel) => put(`/v1/organizations/${orgId}/locations/${locId}`, location);

  importLogisticPrices = (file: any) => post('/v1/logistic/lane-prices', file);
}
