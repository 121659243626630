import axios from 'api/axios';

const getContainerTypes = async () => {
  const rest = await axios({
    method: 'get',
    url: '/v1/dictionary/container-type',
  });

  if (rest.status === 200) {
    return rest.data;
  }
  throw new Error(`Cannot get container types. Status: ${rest.status}`);
};

export default getContainerTypes;
