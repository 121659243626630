import React, { useEffect } from 'react';
import {
  FormControl, FormHelperText, TextField,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import _ from 'lodash';
import { Option } from './Select';

interface IProps {
  label: string,
  name: string,
  value: Option | null,
  options: Option[],
  onChange: (event: any) => void,
  error?: string | null,
  multiple?: boolean,
  groupOptions?: boolean,
  rootStyle?: React.CSSProperties,
  sorted?: boolean,

  [key: string]: any
}

export default function AutocompleteSelect(props: IProps) {
  const {
    label,
    error = null,
    rootStyle,
    onChange: onChangeHandler,
    multiple,
    options,
    groupOptions = false,
    sorted = true,
  } = props;

  const sortedOptions = _.sortBy(options, 'label');

  useEffect(() => {
    const proxy = {
      target: {
        name: props.name,
        value: props.value,
      },
    };
    onChangeHandler(proxy);
  }, []);

  return (
    <FormControl
      variant="outlined"
      style={{ ...rootStyle, marginBottom: 10 }}
      {...(error && { error: true })}
    >
      <Autocomplete
        {...props}
        options={sorted ? sortedOptions : options}
        groupBy={(option: any) => (groupOptions ? option?.label[0] : null)}
        getOptionLabel={(option: Option) => option.label}
        multiple={multiple}
        onChange={(event: any, newValue: any) => {
          // ? this needed only for handmade 'useForm'
          // proxy needed to avoid error:
          // because `onChangeHandler` uses event.target.value and event.target.name
          // even thought it shouldn't
          const proxy = {
            target: {
              name: props.name,
              value: newValue,
            },
          };
          onChangeHandler(proxy);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            variant="outlined"
            style={{ margin: 0 }}
            error={!!error}
          />
        )}
      />

      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
}
