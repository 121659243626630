import React, { FC, useEffect } from 'react';
import CurrencyInput from 'react-currency-input-field';
import { Controller, useFormContext } from 'react-hook-form';
import texts from 'SHARED/helpers/texts';
import validationRules from 'SHARED/helpers/validation';
import { SingleCheckbox } from '../Checkbox';
import { IPriceInputProps } from '../PriceInput';
import Tooltip from '../Tooltip';

interface ISingleCurrencyInputProps extends IPriceInputProps {
  currencyName?: string,
  currencyDisabledDefault?: boolean,
  offerType?: 'ON_SPEC' | 'OFF_SPEC',
}

const SingleCurrencyInput: FC<ISingleCurrencyInputProps> = ({
  name,
  label,
  currency = '€',
  tooltip = texts.tooltips.price,
  disabled = false,
  value,
  rules = validationRules.price,
  // disable price feature
  currencyName = '€',
  currencyDisabledDefault = false,
  offerType = 'ON_SPEC',
}) => {
  const { formState: { errors }, control, watch, register, unregister, setValue } = useFormContext();

  // current statuses of the currencies (disable/enable)
  const eurCurrencyDisabled = watch('priceEurDisabled');
  const usdCurrencyDisabled = watch('priceUsdDisabled');

  // disable status of current currency
  const currencyDisabled = watch(`${name}Disabled`);

  // offerType flag
  const isOnSpec = offerType === 'ON_SPEC';

  // label for `disable this currency` checkbox
  const manuallyDisabledLabel = `Don't publish this ${isOnSpec ? 'offer' : 'auction'} in ${currencyName}`;

  // choose validation rules depending on currencyDisabled status
  // ? currently might not work for now
  const chooseRules = currencyDisabled ? validationRules.notRequired : rules;

  // check if other currency is disabled
  const checkIfAltCurrencyDisabled = (): boolean => {
    const isUsd = `${name}Disabled` === 'priceUsdDisabled';
    const isEur = `${name}Disabled` === 'priceEurDisabled';

    if (eurCurrencyDisabled && isUsd) { return true; }
    if (usdCurrencyDisabled && isEur) { return true; }
    return false;
  };

  // format price depending on: value, currencyDisabled status
  const choosePriceValue = (priceValue: any) => {
    if (currencyDisabled) { return ''; }
    if (priceValue === null) { return ''; }
    return priceValue;
  };

  // unregister field if disabled, to avoid validation blocking
  useEffect(() => {
    if (currencyDisabled) {
      unregister(name);
    }
    if (!currencyDisabled) {
      register(name, chooseRules);
    }
  }, [currencyDisabled]);

  useEffect(() => {
    setValue(`${name}Disabled`, currencyDisabledDefault);
  }, []);

  return (
    <div className={`form-input price-input short ${errors[name] ? 'invalid' : 'valid'}`}>
      {label && (
        <label htmlFor={name}>
          {label}
          <span>{' *'}</span>

          {
            tooltip && tooltip.length > 0 && <Tooltip text={tooltip} />
          }
        </label>
      )}

      {/* checkbox to disable current currency */}
      <SingleCheckbox
        label={manuallyDisabledLabel}
        name={`${name}Disabled`}
        defaultChecked={currencyDisabledDefault}
        disabled={checkIfAltCurrencyDisabled()}
      />

      <Controller
        name={name}
        control={control}
        rules={chooseRules}
        defaultValue={value}
        render={({ field, fieldState }) => (
          <>
            <div className="form-input-wrapper">
              <div className="prefix">{currency}</div>
              <CurrencyInput
                allowDecimals
                disabled={disabled || currencyDisabled}
                disableGroupSeparators
                decimalScale={2}
                decimalSeparator=","
                allowNegativeValue={false}
                groupSeparator=" "
                value={choosePriceValue(field.value)}
                onValueChange={(e) => {
                  field.onChange(e);
                }}
              />
              <div className="suffix">/MT</div>
            </div>

            {fieldState?.error && !currencyDisabled && <div className="error-message">{fieldState?.error?.message}</div>}
          </>
        )}
      />
    </div>
  );
};

export default SingleCurrencyInput;
