import { useMutation } from '@tanstack/react-query';
import { postCreateOffer } from 'api/post/postCreateOffer';
import { toast } from 'react-hot-toast';

interface ICreateOfferParams {
  onSuccess?: () => void;
}

export const useCreateOfferMutation = (
  params: ICreateOfferParams = {},
) => {
  const { onSuccess } = params;

  return useMutation({
    mutationKey: ['createOffer'],
    mutationFn: (offer: any) => postCreateOffer(offer),
    onError: (error: unknown) => {
      console.error(error);
      toast.error('Something went wrong with sending offer data');
    },
    onSuccess: () => {
      toast.success('Offer was created successfully');
      if (onSuccess) {
        onSuccess();
      }
    },
  });
};
