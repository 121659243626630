import { DOCUMENT_ENDPOINTS } from 'SHARED/api/endpoints';
import { axios_Documents } from 'SHARED/helpers/axios';
import notification from 'SHARED/helpers/notifications';
import { IUploadDocumentPayload } from 'SHARED/validators/documents/uploadDocumentSchema';

async function uploadDocument(document: IUploadDocumentPayload) {
  try {
    const res = await axios_Documents.post(
      DOCUMENT_ENDPOINTS.postDocumentUpload(document.dealId),
      document,
    );

    if (res.status === 200) {
      notification({
        type: 'success',
        title: document.documentType.title,
        message: 'Document uploaded successfully',
      });

      return 'success';
    }
    return res.status;
  } catch (error) {
    console.error('Upload document error:', error);

    return 'error';
  }
}

export default uploadDocument;
