import React, { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Button from 'components/hook-form/Button';
import FormSelect from 'components/hook-form/FormSelect';
import { prepareContainersData } from 'helpers/logisticsLocations';
import useLogisticsPricesStore from 'store/addLogisticsPrices';
import FormInput from 'components/hook-form/FormInput';
import clsx from 'clsx';
import validationRules from 'components/hook-form/validationRules';
// import { Alert } from '@material-ui/lab';

interface Props {
  index: number,
  remove: (idx: number) => any,
}

const Container: FC<Props> = ({
  index,
  remove,
}) => {
  const { containerTypes, laneData } = useLogisticsPricesStore((state) => state);
  const { watch, formState: { errors } } = useFormContext();

  const providerValue = watch('provider');
  const containersValue = watch('containers');

  const checkForErrors = (name: string) => {
    const errorsArr = errors?.containers as any;
    const error = errorsArr?.[index]?.[name];
    return !!error;
  };

  const containersData = prepareContainersData(laneData, providerValue, containerTypes)
    .map((c) => ({ ...c, disabled: containersValue.some((v: any) => v.typeId === c.id) }));

  return (
    <div
      className={clsx('form-container')}
      style={{
        padding: 12,
        boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
        marginBottom: 20,
      }}
    >
      <FormSelect
        name={`containers.${index}.typeId`}
        label="CONTAINER OR FTL TYPE"
        // options={providerValue ? containersData : []}
        options={containersData || []}
        error={checkForErrors('typeId')}
      />

      <div className="two-col-grid two-col-grid--s">
        <div>
          <FormInput
            name={`containers.${index}.priceEur`}
            label="PRICE IN EUR"
            type="number"
            error={checkForErrors('priceEur')}
            rules={{
              required: true,
              validate: validationRules.numberInput,
            }}
          />
        </div>

        <div>
          <FormInput
            name={`containers.${index}.priceUsd`}
            label="PRICE IN USD"
            type="number"
            error={checkForErrors('priceUsd')}
            rules={{
              required: true,
              validate: validationRules.numberInput,
            }}
          />
        </div>
      </div>

      {containersValue.length > 1 && (
        <Button
          label="Remove container"
          className="remove-btn"
          variant="outlined"
          color="secondary"
          size="medium"
          iconOnly
          icon={<DeleteForeverIcon color="secondary" />}
          onClick={() => remove(index)}
        />
      )}
    </div>
  );
};

export default Container;
