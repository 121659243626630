import React from 'react';
import { TextField, TextFieldProps } from '@material-ui/core';

export default function Input(props: TextFieldProps) {
  const { size, error = null, ...other } = props;
  return (
    <TextField
      variant="outlined"
      size={size || 'small'}
      {...(error && { error: true, helperText: error })}
      style={{ marginBottom: 10 }}
      {...other}
    />
  );
}
