import React from 'react';
import { IAnalyticsVisit } from 'services/api/OfferService';
import { dateFormatWithSecondsTimezone, amsDateWithTimezone } from 'services/Utils';

interface IProps {
  visit: IAnalyticsVisit
}

const OfferVisitor: React.FC<IProps> = ({ visit }) => {
  const {
    user: {
      // value: userId,
      label: userName,
    },
    company: {
      label: companyName,
    },
    userType,
    events,
  } = visit;

  if (!visit) {
    return null;
  }

  return (
    <div className="offer-visitor">

      <div className="header">
        <span className="name">{`${userName} `}</span>
        {`${userType}: ${companyName}. `}
        <span className="visits-amount">
          {`(${events.length} visit${(events.length !== 1) ? 's' : ''})`}
        </span>
      </div>

      <table className="visits-table">
        <tbody>
          {events.map((event) => (
            <tr key={event.eventTime}>
              {
                amsDateWithTimezone(event.eventTime, dateFormatWithSecondsTimezone)
                  .split(' ')
                  .map((part, index) => (
                    <td key={index}>
                      {part}
                    </td>
                  ))
              }
            </tr>
          ))}
        </tbody>
      </table>

    </div>
  );
};

export default OfferVisitor;
