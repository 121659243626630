import { Button, Typography } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import React, { useState } from 'react';
import api from '../../services/api';
import { emptyOrganization, Organization } from '../../services/api/OrganizationService';
import { SortOrder } from '../../services/api/RestUtil';
import DataTable from '../DataTable';
import OrganizationForm from '../forms/OrganizationForm';
import Modal from '../Modal';
import colors from '../StyleConfig';

export interface OrganizationTableProps {
  tableLabel: string,
  orgType: 'SELLER' | 'BUYER',
}

export default function OrganizationTable(props: OrganizationTableProps) {
  const [open, setOpen] = useState(false);
  const [recordForEdit, setRecordForEdit] = useState(emptyOrganization);
  const { tableLabel, orgType } = props;
  const columns = [
    { field: 'orgName', label: 'Organization name', render: (row: Organization) => row.orgName },
    { field: 'country', label: 'Country', render: (row: Organization) => row.country },
    { field: 'status', label: 'Status', render: (row: Organization) => renderStatus(row.status) },
  ];

  const renderStatus = (status: string) => {
    switch (status) {
      case 'ONBOARDED': return 'Onboarded';
      case 'TRADING': return 'Trading';
      case 'BLOCKED': return 'Blocked';
      case 'FORMER': return 'Former';
      default: return status;
    }
  };

  const openInPopup = (item: any) => {
    setRecordForEdit(item);
    setOpen(true);
  };

  const renderAddButton = () => (
    <Button
      variant="contained"
      color="primary"
      style={{ background: colors.primaryGreen }}
      startIcon={<Add />}
      onClick={() => { openInPopup(emptyOrganization); }}
    >
      Add New
    </Button>
  );

  const getModalTitle = () => (recordForEdit.id
    ? (
      <>
        <Typography variant="h5">
          {`Update ${orgType.toLowerCase()} ${recordForEdit.orgName} `}
        </Typography>
        <Typography>
          {`Status: ${recordForEdit.status} `}
        </Typography>
      </>
    )
    : `Create ${orgType.toLowerCase()}`);

  return (
    <div className="page-body">
      <DataTable
        name={tableLabel}
        columns={columns}
        defaultSort={{ sort: 'orgName', order: SortOrder.Asc }}
        addButton={renderAddButton()}
        onRowClick={openInPopup}
        reload={open}
        fetchRows={(pageRequest) => api.organization.getOrganizationPage(orgType, pageRequest)}
      />
      <Modal title={getModalTitle()} open={open} setOpen={setOpen}>
        <OrganizationForm orgType={orgType} setOpen={setOpen} organization={recordForEdit} />
      </Modal>
    </div>
  );
}
