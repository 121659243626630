import { useQuery } from '@tanstack/react-query';
import { toast } from 'react-hot-toast';
import type { IGetOfferFinancingParams } from 'api/get/getOfferFinancing';
import { getOfferFinancing } from 'api/get/getOfferFinancing';

interface IUseOfferFinancingQueryParams extends IGetOfferFinancingParams {
  offsetDaysOptions?: number[];
}

export const useOfferFinancingQuery = (params: IUseOfferFinancingQueryParams) => {
  const {
    offerId,
    volume,
    price,
    orgId,
    paymentTerm,
    paymentOffset,

    offsetDaysOptions = [],
  } = params;

  const baseFlags = (
    !!offerId
    && (!!volume || volume === 0)
    && !!price
    && !!orgId
    && !!paymentTerm
  );

  const paymentTermsFlags = (
    offsetDaysOptions?.length > 0
      ? !!paymentOffset
      : true
  );

  const isEnabled = baseFlags && paymentTermsFlags;

  return useQuery({
    queryKey: ['offerFinancing', offerId, volume, price, orgId, paymentTerm, paymentOffset],
    queryFn: () => getOfferFinancing(params),
    onError: (error) => {
      console.error(error);
      toast.error('Error loading offer financing');
    },
    enabled: isEnabled,
  });
};
