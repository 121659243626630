import axios from 'api/axios';
import endpoints from 'api/endpoints';

export const postCreateOffer = async (payload: any) => {
  const res = await axios({
    method: 'post',
    url: endpoints.offers,
    data: payload,
  });

  if (res.status === 200) {
    return res.data;
  }

  throw new Error(`Cannot create offer. Status: ${res.status}`);
};
