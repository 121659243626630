import { createAsyncThunk } from '@reduxjs/toolkit';
import { CORE_ENDPOINTS } from 'SHARED/api/endpoints';
import { axios_Core } from 'SHARED/helpers/axios';
import notification from 'SHARED/helpers/notifications';
import { UserResponse } from 'SHARED/redux/models/IUserModels';
import { dictionariesActions } from '../dictionaries/DictionariesSlice';

const getUser = createAsyncThunk(
  'users/getUser',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios_Core.get<UserResponse>(CORE_ENDPOINTS.getUser);

      // even though side-effects are not recommended in reducers
      // we have to use it here due to the current architecture
      dispatch(dictionariesActions.setDictionary({
        key: 'LOADING_ADDRESS',
        value: response.data.organization?.offSpecLocations || [],
      }));

      return response.data;
    } catch (error) {
      console.log('getUser error', error);

      notification({
        type: 'danger',
        title: 'Error',
        message: 'Could not get user...',
      });

      return rejectWithValue('Could not get user...');
    }
  },
);

export const asyncUsersActions = {
  getUser,
};
