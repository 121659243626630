const exactLinkCheck = (path: string) => {
  const location = window.location.pathname;

  if (
    location === path
    || location === `${path}/`
  ) return true;

  return false;
};

export default exactLinkCheck;
