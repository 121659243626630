import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { removeTokens } from 'SHARED/helpers/axios';
import { asyncOauthActions } from './AsyncOauthActions';

interface IOauthState {
  isLoggedIn: boolean
  isLoading: boolean
  token: string | null
  refreshToken: string | null
  error: string | null
}

const initialState: IOauthState = {
  isLoggedIn: !!localStorage.getItem('token'),
  // isLoggedIn: false,
  isLoading: false,
  token: null,
  refreshToken: null,
  error: null,
};

const oauthSlice = createSlice({
  name: 'oauth',
  initialState,
  reducers: {
    resetOauthState: () => initialState,

    setIsLoggedIn: (state, action: PayloadAction<boolean>) => {
      if (!action.payload) {
        removeTokens();
      }

      state.isLoggedIn = action.payload;
    },

    setToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
    },

    setRefreshToken: (state, action: PayloadAction<string>) => {
      state.refreshToken = action.payload;
    },
  },
  extraReducers: (builder) => {
    // getAccessCode
    builder.addCase(
      asyncOauthActions.getAccessCode.pending,
      (state) => {
        state.isLoading = true;
        state.error = null;
      },
    );

    builder.addCase(
      asyncOauthActions.getAccessCode.fulfilled,
      (state, action) => {
        state.token = action.payload.accessToken;
        state.refreshToken = action.payload.refreshToken;
        state.isLoading = false;
        state.isLoggedIn = true;
      },
    );

    builder.addCase(
      asyncOauthActions.getAccessCode.rejected.type,
      (state, action: PayloadAction<string>) => {
        state.isLoading = false;
        state.isLoggedIn = false;
        state.error = action.payload;
      },
    );
  },
});

export const oauthReducer = oauthSlice.reducer;
export const oauthActions = oauthSlice.actions;
