import React, { useEffect } from 'react';
import {
  FormControl, FormHelperText, MenuItem, TextField,
} from '@material-ui/core';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import _ from 'lodash';
import { Autocomplete } from '@material-ui/lab';
import clsx from 'clsx';
import { Option } from './Select';

interface IProps {
  label: string,
  name: string,
  value: Option[],
  options: Option[],
  onChange: (event: any) => void,
  error?: string | null,
  rootStyle?: React.CSSProperties,

  [key: string]: any
}

export default function ComboMultiSelect(props: IProps) {
  const {
    label, error = null, rootStyle, onChange: onChangeHandler, options,
  } = props;

  const sortedOptions = _.sortBy(options, 'label');

  const selectAllOption: Option = {
    label: 'Select All',
    value: 'select-all',
  };

  const isEmptyOptions = props.options?.length === 0;

  const checkIfSelected = (option: Option) => props.value.some((item: Option) => item.value === option.value);

  const comboSelectChangeHandler = (event: any, newValue: Option[]) => {
    let nextValue: Option[] = newValue;

    // if 'slect-all' option is selected, then select all options
    if (newValue.some((item) => item.value === 'select-all')) {
      nextValue = props.options;
    }

    // if clicked option already exists in value, then remove it
    const duplicates = _.chain(newValue)
      .groupBy('value')
      .filter((group) => group.length > 1)
      .flatten()
      .value();
    const hasDuplicates = duplicates.length > 0;

    if (hasDuplicates) {
      nextValue = _.differenceBy(newValue, duplicates, 'value');
    }

    // create proxy object for 'useForm' custom abstraction
    const proxy = {
      target: {
        name: props.name,
        value: nextValue || [],
      },
    };

    onChangeHandler(proxy);
  };

  useEffect(() => {
    const proxy = {
      target: {
        name: props.name,
        value: props.value || [],
      },
    };
    onChangeHandler(proxy);
  }, []);

  return (
    <FormControl
      variant="outlined"
      style={{ ...rootStyle, marginBottom: 10 }}
      {...(error && { error: true })}
    >
      <Autocomplete
        {...props}
        multiple
        disableCloseOnSelect
        // filterSelectedOptions
        // open
        classes={{
          option: 'select-option',
          groupUl: 'select-list',
        }}
        options={isEmptyOptions ? [] : [selectAllOption, ...sortedOptions]}
        noOptionsText={isEmptyOptions ? 'No options' : 'No options found'}
        getOptionLabel={(option: Option) => option.label}
        onChange={comboSelectChangeHandler}
        groupBy={(option: any) => (option.value === 'select-all' || option.value === 'no-options-present' ? null : option.label[0])}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            variant="outlined"
            style={{ margin: 0 }}
            error={!!error}
          />
        )}
        renderOption={(option: Option, { selected }) => (
          <MenuItem
            key={`${option.label}-${option.value}`}
            value={option.value}
            className={clsx(
              'select-option--item',
              option.value === 'select-all' && 'select-option--control',
              option.value === 'no-options-present' && 'select-option--control',
              checkIfSelected(option) && 'select-option--selected',
            )}
          >
            {checkIfSelected(option) && (
              <CheckBoxIcon
                className="select-option--icon"
                color="secondary"
                fontSize="small"
              />
            )}
            <span>
              {option.value === 'select-all' && (
                <DoneAllIcon
                  style={{ fontSize: 20 }}
                  color="secondary"
                  className="select-control--icon"
                />
              )}
              {option.label}
            </span>
          </MenuItem>
        )}
      />

      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
}
