import moment from 'moment';
import { capitalize } from 'lodash';
import { IProfile } from 'SHARED/redux/models/IUser';
import { Dictionary, Location, Person } from 'SHARED/types/offerTypes';
import { ICodeTitle } from 'SHARED/validators/common/codeTitleSchema';
import { IOption } from 'SHARED/validators/common/optionSchema';

export const isDevEnv = (!process.env.NODE_ENV || process.env.NODE_ENV === 'development');

export const MpcDocLink = 'https://issuu.com/nnkc.nl/docs/mpc_-_2018_engels';

export const FULL_DATE = 'DD MMM YYYY HH:mm';

export const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'auto',
  });
};

export const createAndDownloadFile = (data: Blob, fileName: string) => {
  const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName); // or any other extension

  // Simulate a click event without appending the link to the DOM
  const clickEvent = new MouseEvent('click', {
    view: window,
    bubbles: true,
    cancelable: true,
  });

  // Dispatch the event (trigger the download)
  link.dispatchEvent(clickEvent);

  // Cleanup
  URL.revokeObjectURL(url);
};

export const preparePrice = (price?: number) => (price ? price.toString().replace('.', ',') : '');
export const preparePricePrefill = (price?: number | string) => (
  price
    ? price.toString().replace('.', ',')
    : '0'
);

export const preparePriceInputValue = (price: string | number | null | undefined): number => {
  if (!price) return 0;
  if (typeof price === 'number') return price;

  const priceString = price.replace(',', '.');
  const priceNumber = Number(parseFloat(priceString).toFixed(2));

  if (Number.isNaN(priceNumber)) return 0;

  return priceNumber;
};

export const roundPrice = (price: number | undefined) => (price ? Math.round(price * 100) / 100 : 0);
export const ceilPrice = (price: number) => Math.ceil(price * 100) / 100;
export const roundHalfUp = (num: number, precision = 2) => {
  const factor = Math.pow(10, precision);
  const rounded = Math.round(num * factor);

  return rounded / factor;
};

export const convertToDictionary: (val: number) => Dictionary = (val) => ({ value: val.toString(), label: val.toString() });

type HandleDate = (payload: {
  date: Date | number | string | null | undefined,
  format?: string
}) => string;

type DatesRange = (payload: {
  from: Date | number | string | null,
  to: Date | number | string | null,
  format?: string,
}) => string;

export const getFullName = (
  profile: Person | IProfile | null | undefined,
  includeSalutation = true,
) => {
  const {
    salutation, firstName, lastName, middleName,
  } = profile || {};

  if (!firstName && !lastName) return 'Anonymous User';

  let fullName = '';

  if (salutation && includeSalutation) { fullName += `${capitalize(salutation)}.`; }
  if (firstName) { fullName += ` ${firstName}`; }
  if (middleName) { fullName += ` ${middleName}`; }
  if (lastName) { fullName += ` ${lastName}`; }

  return fullName.trim();
};

export const BID_ENDPOINTS = {
  VALIDATE_ACCEPT_OFFER: 'v1/offer/accept?commit=false',
  ACCEPT_OFFER: 'v1/offer/accept?commit=true',
  VALIDATE_BID: 'v1/negotiation/bid?commit=false',
  SUBMIT_BID: 'v1/negotiation/bid?commit=true',
  VALIDATE_ACCEPT_BID: 'v1/negotiation/accept?commit=false',
  ACCEPT_BID: 'v1/negotiation/accept?commit=true',
} as const;
type IMakeTimestamp = (date:Date | null) => number | null;
export const makeTimestamp:IMakeTimestamp = (date) => (date ? parseInt(moment(date).utcOffset(0, true).format('x'), 10) : null);

export const handleDate:HandleDate = ({ date, format = 'DD-MM-YYYY' }) => moment(date).format(format);

export const handleDateRange:DatesRange = ({ from, to, format = 'DD MMM' }) => `${moment(from).format(format)} - ${moment(to).format(format)} ${moment(to).format('YYYY')}`;

export const clearProxyFields = (object: any) => {
  const newObject: any = { ...object };

  for (const key in newObject) {
    if (key.toLowerCase().includes('proxy')) {
      delete newObject[key];
    }
  }
  return newObject;
};

export const clearProxyFieldsTypeSafe = <T>(object: T): Omit<T, keyof Pick<T, { [K in keyof T]: T[K] extends string ? K : never }[keyof T]> & '{proxy}'> => {
  const newObject: any = { ...object };

  for (const key in newObject) {
    if (key.toLowerCase().includes('proxy')) {
      delete newObject[key];
    }
  }
  return newObject;
};

export const optionToCodeValue = (option: IOption): ICodeTitle => ({ code: option.value, title: option.label });
export const codeValueToOption = (codeValue: ICodeTitle): IOption => ({ value: codeValue.code, label: codeValue.title });

export const megabytesToBytes = (megabytes: number) => megabytes * 1024 * 1024;
export const bytesToMegabytes = (bytes: number) => bytes / 1024 / 1024;
export const isFileTooBig = (fileSizeBytes: number, maxFileSizeMB: number) => fileSizeBytes > megabytesToBytes(maxFileSizeMB);

type RFPVolume = string | number | null | undefined;
export const minMaxVolumeToString = (minVolume: RFPVolume, maxVolume: RFPVolume) => {
  if (!minVolume && !maxVolume) return 'N/A';

  if (minVolume === maxVolume) return `${maxVolume} mt`;
  return `${minVolume} - ${maxVolume} mt`;
};

export function filterLocationsByIncotermsLocationType(
  locations: Location[],
  incoterms: Dictionary | null | undefined,
) {
  if (!incoterms) return locations;

  return locations.filter((location) => location.type === incoterms?.locationType);
}

const all = { handleDate, handleDateRange };

export default all;
