import zustand from 'zustand';
import { ILocationType } from 'helpers/logisticsLocations';

export interface ISelectedLocations {
  // from
  seller: string | number;
  countryIdFrom: string | number;
  seaPortIdFrom?: string | number;
  locationTypeFrom: ILocationType;
  cityFrom?: string;
  zipFrom?: string;

  // to
  countryIdTo: string | number;
  seaPortIdTo?: string | number;
  locationTypeTo: ILocationType;
  cityTo?: string;
  zipTo?: string;
}

interface ILogisticsPricesState {
  // Locations (departure and destination)
  sellers: any;
  destinationCountries: any;

  // Container types (20', 40', etc.)
  containerTypes: any;

  // Selected locations params
  selectedLocations: ISelectedLocations | null;

  // Lane data (all providers inside)
  laneData: any;

  // ACTIONS
  resetStore: () => void;

  setSellers: (data: any) => any;
  setDestinationCountries: (data: any) => any;
  setContainerTypes: (data: any) => any;
  setSelectedLocations: (data: ISelectedLocations) => any;
  setLaneData: (data: any) => any;
}

type InitialState = Pick<
ILogisticsPricesState,
'sellers'
| 'destinationCountries'
| 'containerTypes'
| 'selectedLocations'
| 'laneData'
>;

const initialState: InitialState = {
  sellers: [],
  destinationCountries: [],
  containerTypes: [],
  selectedLocations: null,
  laneData: [],
};

const useLogisticsPricesStore = zustand<ILogisticsPricesState>((set, get) => ({
  ...initialState,

  // ACTIONS
  resetStore: () => set(initialState),

  setSellers: (data: any[]) => set({ sellers: data }),

  setDestinationCountries: (data: any[]) => set({ destinationCountries: data }),

  setContainerTypes: (data: any[]) => set({ containerTypes: data }),

  setSelectedLocations: (data: ISelectedLocations) => set({ selectedLocations: data }),

  setLaneData: async (params) => set({ laneData: params }),
}));

export default useLogisticsPricesStore;
