import ActionButton from './ActionButton';
import Button from './Button';
import Checkbox from './Checkbox';
import CheckboxGroup from './CheckboxGroup';
import Input from './Input';
import MultiSelect from './MultiSelect';
import RadioGroup from './RadioGroup';
import Select from './Select';
import AutocompleteSelect from './AutocompleteSelect';
import ComboMultiSelect from './ComboMultiSelect';

const Controls = {
  ActionButton,
  Button,
  Checkbox,
  CheckboxGroup,
  Input,
  RadioGroup,
  Select,
  MultiSelect,
  AutocompleteSelect,
  ComboMultiSelect,
};

export default Controls;
