type KeyStrings<T> = {
  [K in keyof T]: K;
};

export function createKeyStrings<T>(): KeyStrings<T> {
  const result: Partial<KeyStrings<T>> = {};

  const handler = {
    get(target: any, prop: string) {
      return prop;
    },
  };

  return new Proxy(result as KeyStrings<T>, handler);
}
