import { createAsyncThunk } from '@reduxjs/toolkit';
import { CORE_ENDPOINTS } from 'SHARED/api/endpoints';
import { axios_Core, removeTokens, setTokens } from 'SHARED/helpers/axios';
import notification from 'SHARED/helpers/notifications';
import { OauthResponce } from 'SHARED/types/offerTypes';
import routes from 'SHARED/types/routes';

const getAccessCode = createAsyncThunk(
  'oauth/getAccessCode',
  async (authCode: string, { rejectWithValue }) => {
    const REDIRECT_URL = `${window.location.origin}${routes.auth.oauthLogin}`;

    // ? this case (commented below) is not covered
    // ? this might be used only with local backed
    // const hostname = window.location.hostname;
    // if (hostname === 'localhost') {
    //   REDIRECT_URL = window.APP_CONFIG.app_url;
    // }

    try {
      const response = await axios_Core.post<OauthResponce>(CORE_ENDPOINTS.postAuth(authCode, REDIRECT_URL));

      const {
        access_token: accessToken,
        refresh_token: refreshToken,
      } = response.data;

      setTokens(accessToken, refreshToken);

      return { accessToken, refreshToken };
    } catch (error) {
      console.log('getAccessCode error', error);

      notification({
        type: 'danger',
        title: 'Error',
        message: 'Something went wrong with auth...',
      });

      removeTokens();
      window.location.replace(`${routes.auth.oauth}/login`);

      return rejectWithValue('Could not get access code...');
    }
  },
);

export const asyncOauthActions = {
  getAccessCode,
};
