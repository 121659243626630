import React from 'react';
import { IDataCollection, File } from 'SHARED/types/offerTypes';
import renderMultiline from 'SHARED/helpers/renderMultiline';
import { dateWithTimezone } from 'SHARED/helpers/dates';
import useUserTimezone from 'SHARED/hooks/useUserTimezone';
import useTimezoneDate from 'SHARED/hooks/useTimezoneDate';
import clsx from 'clsx';
import prepareDataAttr from 'SHARED/helpers/prepareDataAttr';
import isValidMomentDate from 'SHARED/helpers/isValidMomentDate';
import { downloadCoreFile } from 'SHARED/api/common/get/downloadFile';

// TODO: item should also accept React.ReactNode (for custom rendering)
type Item = Date | String | Number | IDataCollection | IDataCollection[] | null;
type Range = Date | number | string | null;

export interface IDataItemPreviewProps {
  title: string,
  label?: string, // TODO: refactor SELLER and BUYER to use label instead of title
  item?: Item,
  value?: Item, // TODO: refactor SELLER and BUYER to use value instead of item
  suffix?: string,
  prefix?: string,
  dateFormat?: string,
  isDate?: boolean, // TODO: temp
  isMailLink?: boolean, // TODO: temp
  datesRange?: Range[],
  multiline?: boolean,
  multilineText?: string | null,
  mods?: string[],
  file?: File,
  className?: string,
  icon?: string,
  suffixIcon?: string,
  isHeading?: boolean,
  children?: React.ReactNode,
  isAlert?: boolean,
  link?: {
    url: string,
    displayName: string,
  }
}

const DataItemPreview: React.FC<IDataItemPreviewProps> = ({
  item,
  // value, // ? TODO: refactor SELLER and BUYER to use value instead of item
  title,
  label, // ? TODO: refactor SELLER and BUYER to use label instead of title
  suffix,
  prefix,
  dateFormat,
  datesRange,
  isDate = false,
  mods,
  file,
  className = '',
  isMailLink,
  icon = '',
  suffixIcon = '',
  multiline = false,
  multilineText,
  isHeading,
  children,
  isAlert,
  link,
}) => {
  const { userTimezone } = useUserTimezone();
  const timezoneDate = useTimezoneDate();

  const handleDownloadFile = async (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    if (file) {
      downloadCoreFile({
        name: file.fileName,
        originalFileName: file.originalFileName,
      });
    }
  };
  const handleDataType = () => {
    // handling different data types
    // to properly render in component depending on data item type

    if (children) {
      return children;
    }

    if (link) {
      return (
        <a
          href={link.url}
          target="_blank"
          rel="noreferrer"
          className="file-link"
        >
          <i className="icon-flag" />
          <div className="file-name">
            {link.displayName || '_Filename is missing_'}
          </div>
        </a>
      );
    }

    if (file) {
      return (
        <a
          href="download-file"
          target="_blank"
          rel="noreferrer"
          onClick={(e) => { handleDownloadFile(e); }}
          className="file-link"
        >
          <i className="icon-flag" />
          <div className="file-name">
            {file.originalFileName || '_Filename is missing_'}
          </div>
        </a>
      );
    }

    if (datesRange) {
      return (
        <div className={`data-preview-row ${className}`}>
          {dateWithTimezone(datesRange[0], userTimezone, 'DD MMM')}
          <span> - </span>
          {dateWithTimezone(datesRange[1], userTimezone, 'DD MMM')}
          <span>{' '}</span>
          {dateWithTimezone(datesRange[1], userTimezone, 'YYYY')}
        </div>
      );
    }

    if (multiline && multilineText) {
      return <>{renderMultiline(multilineText)}</>;
    }

    if (item === null || item === '') {
      return '-';
    }

    const type: string = typeof item;

    if (isDate && item && isValidMomentDate(item)) {
      return timezoneDate({ date: item as string, displayFormat: dateFormat });
      // return dateWithTimezone(item as string, userTimezone, dateFormat);
    }

    if (isMailLink) {
      return <span className={mods?.join(' ')}><a href={`mailto:${item?.toString()}`}>{item}</a></span>;
    }

    if (['string', 'number'].includes(type)) {
      return <span className={mods?.join(' ')}>{item}</span>;
    }

    // if (item instanceof Date) {
    //   return moment(item).format('DD MMM');
    // }

    if (type === 'object' && !!item) {
      if (item instanceof Date) {
        return dateWithTimezone(item, userTimezone, 'DD MMM');
      }
      if (Array.isArray(item)) {
        return item.map((i: any) => (<div key={i?.value}>{i?.label}</div>));
      }

      // @ts-expect-error:
      return item?.label;
    }

    return '-';
  };
  return (
    <div
      className={clsx('data-preview-row', className, isAlert && 'alert')}
      data-name={prepareDataAttr(title || label)}
    >
      {(title || label) && <div className="label-text">{title || label}</div>}
      <div
        className={
          clsx(
            'preview-content',
            (icon || suffixIcon) && 'flex-align-center', // use flex only with icons, of multiline will break
            isHeading && 'preview-content--is-heading',
          )
        }
      >

        {prefix && <span>{`${prefix} `}</span>}

        {icon && <i className={`${icon} icon--prefix`} />}

        {handleDataType()}

        {/* marginLeft needed because of possible flex */}
        {suffix && <span style={{ marginLeft: '0.4ch' }}>{`${suffix}`}</span>}

        {suffixIcon && <i className={`${suffixIcon} icon--suffix`} />}

      </div>
    </div>
  );
};

export default DataItemPreview;
