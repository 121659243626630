import axios from 'api/axios';
import { BASE_ENDPOINTS } from 'api/endpoints';
import type { IPageResponse } from 'api/types';
import type { IUnknownId } from 'types/common';
import type { OwnerUser } from 'types/offerTypes';

type UsersRes = IPageResponse<OwnerUser>;

export const getOrgUsers = async (orgId: IUnknownId): Promise<UsersRes> => {
  if (!orgId) {
    throw new Error('Cannot get users data. No orgId');
  }

  const res = await axios({
    method: 'get',
    url: BASE_ENDPOINTS.getOrgUsers(orgId),
  });

  if (res.status === 200) {
    return res.data;
  }
  throw new Error(`Cannot get users data. Status: ${res.status}`);
};
