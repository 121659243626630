import React, { FC } from 'react';
import { Typography } from '@material-ui/core';
import { Deal } from 'SHARED/types/offerTypes';
import { amsDateWithTimezone, dateTimeFormat } from 'services/Utils';
import TextField from 'components/controls/TextField';

interface IProps {
  deal: Deal | undefined | null
}

const DealBlock: FC<IProps> = ({ deal }) => (
  <>
    <Typography variant="h5">Deal info</Typography>
    <TextField label="DEAL ID" value={deal?.number} />
    <TextField label="DEAL DONE ON" value={amsDateWithTimezone(deal?.created, dateTimeFormat)} />
  </>
);

export default DealBlock;
