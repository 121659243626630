import React, { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import {
  Autocomplete, FormControl, TextField,
} from '@mui/material';
import { sortBy } from 'lodash';
import { resetDependentFields } from 'helpers/common';
import type { IOption } from 'validators/common/optionSchema';
import validationRules from './validationRules';
import type { IFormFieldBase } from './FormSelect';

interface IProps extends IFormFieldBase {
  options: IOption[];
  sorted?: boolean;
  defaultValue?: string | number;

  multiple?: boolean,
  groupOptions?: boolean,
  rootStyle?: React.CSSProperties,
}

const FormAutocomplete: FC<IProps> = ({
  name,
  placeholder = 'Select...',
  label,
  options,
  sorted = true,
  rules = validationRules.required,
  disabled,
  error,
  required = false,
  multiple = false,
  groupOptions = false,
  rootStyle,
  dependentFields,
}) => {
  const { formState, control, resetField } = useFormContext();
  const { errors } = formState;

  const sortedOptions = sortBy(options, 'label');

  const isError = !!errors[name]?.message || error;

  return (
    <div className="form-field form-select">

      {label && <div className="label-text">{label}{required && ' *'}</div>}

      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field: { onChange, value } }) => (
          <FormControl
            variant="outlined"
            size="small"
            style={{ ...rootStyle }}
            {...(error && { error: true })}
          >
            <Autocomplete
              id={`${name}-combo-box`}
              value={value || null}
              onChange={(event, newValue) => {
                resetDependentFields(dependentFields, resetField);

                onChange(newValue);
              }}
              options={sorted ? sortedOptions : options}
              groupBy={(option) => (groupOptions ? option?.label[0] : '')}
              getOptionLabel={(option) => option.label}
              disabled={disabled}
              multiple={multiple}
              className="form-autocomplete"
              renderOption={(props, option) => (
                <li {...props} key={option.value}>
                  {option.label}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  style={{ margin: 0 }}
                  error={!!isError}
                  placeholder={placeholder}
                />
              )}
            />
          </FormControl>
        )}
      />

      {errors[name]?.message && <div className="form-field__error">{errors[name]?.message}</div>}
    </div>
  );
};

export default FormAutocomplete;
