import {
  Grid, Link, Paper, Tab, Tabs, Typography,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React, { useEffect, useState } from 'react';
import useOfferAnalytics from 'hooks/useOfferAnalytics';
import OfferAnalytics from 'components/analytics/OfferAnalytics';
import CallSplitIcon from '@material-ui/icons/CallSplit';
import colors from 'components/StyleConfig';
import DealsTable from 'pages/auctionDeal/partials/DealsTable';
import pages from '../../navigation/pages';
import api from '../../services/api';
import {
  Bid,
  emptyOffer,
  OrganizationBids,
} from '../../services/api/OfferService';
import Controls from '../controls/Controls';
import { Option } from '../controls/Select';
import TextField from '../controls/TextField';
import DownloadField from '../controls/DownloadField';
import FilesIcon from '../icons';
import {
  amsDateWithTimezone,
  date, dateTime, formatMoney, getUserName, prettyDateTimeFormat,
} from '../../services/Utils';

export interface OfferOffSpecFormProps {
  id: number
  setOpen(val: boolean): void
}

enum OfferDocType {
  SALES_TERMS = 'SALES_TERMS',
  COA = 'COA',
}

const containerStyle = { justifyContent: 'space-between' };
const itemStyle = { width: '33%', padding: '15px' };

export default function OfferOffSpecForm(props: any) {
  const { match: { params: { id }, path } } = props;
  const isDealPath = () => (path.indexOf('deal') !== -1);
  const [offer, setOffer] = useState(emptyOffer);
  const [history, setHistory] = useState([]);
  const [error, setError] = useState('');
  const [reason, setReason] = useState('');
  const [tab, setTab] = useState(isDealPath() ? 2 : 0);

  // ANALYTICS
  const { analytics, analyticsError } = useOfferAnalytics(id);

  useEffect(() => api.offer.getOfferById(id, setOffer, setError), [id]);
  useEffect(() => api.offer.getBidHistory(id, setHistory, setError), [id]);

  const renderFileField = (label: string, docType: OfferDocType) => {
    const docs = offer?.documents;
    const requestedDocs = docs ? docs[docType] : [];
    const files = (requestedDocs || []).map((doc: any) => ({
      fileName: doc.fileName,
      originalFileName: doc.originalFileName,
    }));
    return (
      <div style={{ margin: '15px 10px' }} key={label}>
        <DownloadField label={label} files={files} />
      </div>
    );
  };

  const handleCancellation = () => {
    if (reason && reason.trim()) {
      api.offer.cancelOffer(id, reason)
        .then(() => props.history.push(pages.offSpecOffers))
        .catch((err) => setError(err?.message));
    } else {
      setError('Cancellation reason required');
    }
  };

  const renderMPCLink = () => (
    offer.documents && offer.documents[OfferDocType.SALES_TERMS]
      ? renderFileField('SALES TERMS', OfferDocType.SALES_TERMS)
      : (
        <div style={{ margin: '15px 10px' }}>
          <Typography variant="body1" color="textSecondary">SALES TERMS</Typography>
          <Link
            component="a"
            href="https://issuu.com/nnkc.nl/docs/mpc_-_2018_engels"
            target="_blank"
            rel="noreferrer"
            style={{ display: 'inline-flex' }}
          >
            <FilesIcon />
            <Typography variant="body1" style={{ color: '#009FD1' }}>MPC sales terms</Typography>
          </Link>
        </div>
      ));

  const joinOptions = (options: Option[]) => options && options.map((it) => it.label).join(', ');

  const offerBlock = () => (
    <>
      <Typography variant="h5">Offer</Typography>
      <TextField label="OFFER ID" value={offer.id} />
      <TextField label="OFFER TYPE" value={offer.type.label} />
      <TextField label="OFFER PLACED" value={dateTime(offer.created, prettyDateTimeFormat)} />
      <TextField label="STATUS" value={offer.status} />
      { offer.status === 'CANCELLED' && <TextField label="CANCELLATION REASON" value={offer.cancellationReason} /> }
    </>
  );

  const sellerBlock = () => (
    <>
      <Typography variant="h5">Seller</Typography>
      <TextField label="SELLER COMPANY" value={offer.ownerOrg?.orgName} />
      <TextField label="COUNTRY" value={offer.ownerOrg?.country} />
      <TextField label="SELLER NAME" value={getUserName(offer.ownerUser)} />
      <TextField label="EMAIL" value={offer.ownerUser?.profile?.email} />
      <TextField label="TELEPHONE" value={offer.ownerUser?.profile?.phoneNumber} />
    </>
  );

  const productBlock = () => (
    <>
      <Typography variant="h5">Product</Typography>
      <TextField label="OFFER TITLE" value={offer.title} />
      <TextField label="DESCRIPTION" value={offer.description} />
      {renderFileField('PRODUCT COA DOCUMENT', OfferDocType.COA)}
      <TextField label="DOCUMENTS INCLUDED" value={joinOptions(offer.documentCapabilities)} />
      <TextField label="PRODUCTION LOCATION" value={offer.ownerOrg.offSpecLocation1} />
      <TextField label="PACKAGING" value={offer.packaging.label} />
      {renderMPCLink()}
    </>
  );

  const shippingBlock = () => (
    <>
      <Typography variant="h5">Shipping</Typography>
      <TextField label="INCOTERMS" value={offer.incoterms.label} />
      {!!offer?.loadAddress?.value && <TextField label="LOADING ADDRESS" value={offer.loadAddress?.label} />}
      {!!offer.logisticLocation?.fullAddress && <TextField label="SELLER INCOTERMS LOCATION" value={offer.logisticLocation?.fullAddress} />}
      <TextField label="LOADING DETAILS" value={joinOptions(offer.loadOptions)} />
      <TextField label="PICK-UP DATE RANGE" value={`${date(offer.loadDateFrom)} - ${date(offer.loadDateTo)}`} />
    </>
  );

  const paymentBlock = () => (
    <>
      <Typography variant="h5">Volume and price</Typography>
      <TextField
        label="VOLUME"
        prefix={offer.splittable && <CallSplitIcon />}
        value={`${offer.totalVolume} ${offer.volumeUnits.label}`}
      />
      <TextField label="SPLITTABLE" value={offer.splittable ? 'Yes' : 'No'} />
      <TextField
        label="MINIMUM BID VOLUME"
        value={offer.minBidVolume}
        suffix={`${offer.volumeUnits.label}`}
      />

      {offer?.priceUsd && <TextField label="ASK PRICE USD" value={`${formatMoney(offer.priceUsd)} USD / ${offer.volumeUnits.label}`} />}
      {offer?.priceEur && <TextField label="ASK PRICE EUR" value={`${formatMoney(offer.priceEur)} EUR / ${offer.volumeUnits.label}`} />}

      <TextField label="PAYMENT TERMS" value={offer.paymentTerms.label} />

      {offer?.priceUsd && <TextField label="TOTAL PRICE USD" value={`${formatMoney(offer.priceUsd * offer.totalVolume)} USD`} />}
      {offer?.priceEur && <TextField label="TOTAL PRICE EUR" value={`${formatMoney(offer.priceEur * offer.totalVolume)} EUR`} />}
    </>
  );

  const auctionBlock = () => (
    <>
      <Typography variant="h5">Auction details</Typography>
      <TextField label="AUCTION END DATE" value={dateTime(offer.endDate)} />
      <br />
      <Typography variant="h5">Invited buyers</Typography>
      <TextField label="BUYERS THAT CAN SEE THIS OFFER" value={joinOptions(offer.buyers)} />
    </>
  );

  const renderDeals = () => (offer.deals?.length > 0 && (
    <Grid container style={{ display: 'flex', flexDirection: 'column', paddingLeft: 10 }}>
      <DealsTable offer={offer} deals={offer.deals} />
      {/* {offer?.deals?.map((deal: Deal) => (
        <AuctionDeal offer={offer} deal={deal} isDeal key={deal.id} />
      ))} */}
    </Grid>
  ));

  const renderOffer = () => (offer.id && (
    <Grid container>
      <Grid container style={containerStyle}>
        <Grid item style={itemStyle}>{offerBlock()}</Grid>
        <Grid item style={itemStyle}>{sellerBlock()}</Grid>
        <Grid item style={itemStyle}>{productBlock()}</Grid>
      </Grid>
      <Grid container style={containerStyle}>
        <Grid item style={itemStyle}>{shippingBlock()}</Grid>
        <Grid item style={itemStyle}>{paymentBlock()}</Grid>
        <Grid item style={itemStyle}>{auctionBlock()}</Grid>
      </Grid>
    </Grid>
  ));

  const renderHistory = () => history && history.map(renderBidder);

  const renderBidder = (item: OrganizationBids) => (
    <Paper variant="outlined" style={{ padding: 16, marginBottom: 16 }} key={item.buyer.id}>
      <Typography variant="h5">Bidder</Typography>
      <TextField label="BUYER" value={item.buyer.orgName} />
      <TextField label="COUNTRY" value={item.buyer.country} />
      <br />
      <Typography variant="h6">Latest active bid</Typography>
      { renderBid(item.latestBid, true) || '--' }

      { item.deletedBids.length > 0 && (
      <>
        <br />
        <Typography variant="h6">Previous bids by this organization</Typography>
        { item.deletedBids.map((it) => renderBid(it, true)) }
      </>
      )}
    </Paper>
  );

  const renderBid = (bid: Bid, forHistory?: boolean) => {
    const isWinning = offer?.winningBid?.id === bid?.id;
    const color = isWinning ? colors.odGreen : 'none';
    const singleBidStyle = { background: color, padding: 24 };
    const historyBidStyle = {
      background: color, padding: 24, display: 'flex', justifyContent: 'space-between', border: 0,
    };

    return (bid && (
    <Paper variant="outlined" style={forHistory ? historyBidStyle : singleBidStyle} key={bid.id}>
      <TextField label="BID" value={`${formatMoney(bid.value)} ${bid.currency} / ${offer.volumeUnits.label}`} />
      <TextField label="BID VOLUME" value={`${bid.bidVolume} ${offer.volumeUnits.label}`} />
      <TextField label="TOTAL" value={`${formatMoney(bid.offerTotalPrice)} ${bid.currency}`} />
      <TextField label="BID TIME" value={amsDateWithTimezone(bid.actionTime)} />
      <TextField label="USER" value={bid.userName} />
      <TextField label="ACTION" value={bid.action} />
      <br />
    </Paper>
    ));
  };

  const renderCancellation = () => (
    <>
      <br />
      <Controls.Input
        name="cancellationReason"
        label="Reason"
        onChange={(e) => setReason(e.target.value)}
        required
      />
      <Controls.Button color="primary" onClick={handleCancellation}>Cancel</Controls.Button>
    </>
  );
  const makeFormTitle = () => {
    if (offer) {
      const type = isDealPath() ? 'Deal' : 'Offer';
      return `${type}: ${offer.title}, ${offer.totalVolume} ${offer.volumeUnits?.label}`;
    }
    return 'Offer';
  };

  return (
    <div className="page-body">
      { error && <Alert severity="error">{error}</Alert> }
      <div style={{ display: 'flex' }}>
        <Typography variant="h5" style={{ flexGrow: 1 }}>
          {makeFormTitle()}
        </Typography>
        <Controls.Button
          type="button"
          color="secondary"
          onClick={() => props.history.push(
            isDealPath()
              ? pages.deals
              : (
                offer?.status === 'ACTIVE'
                  ? pages.offSpecOffers
                  : pages.offSpecOffers_archived
              ),
          )}
        >
          <span style={{ fontSize: 16, color: '#FFF' }}>
            {isDealPath() ? '< Back to deals' : '< Back to offers'}
          </span>
        </Controls.Button>
      </div>

      <Tabs value={tab} onChange={(e, val) => setTab(val)}>
        <Tab label="Offer" value={0} />
        { history.length > 0 && <Tab label="Bids" value={1} />}
        {offer.deals?.length > 0 && <Tab label="Deals" value={2} />}
        <Tab label="Analytics" value={3} />
      </Tabs>

      <br />

      { tab === 0 && renderOffer() }
      { tab === 1 && renderHistory() }
      { tab === 2 && renderDeals() }
      { tab === 3 && (
        <OfferAnalytics
          offerCreationDate={offer.created}
          analytics={analytics}
          error={analyticsError}
        />
      )}

      { offer.status === 'ACTIVE' && renderCancellation() }
    </div>
  );
}
