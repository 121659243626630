import { Link, Paper, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Form, useForm } from '../../hooks/useForm';
import { formatMoney } from '../../services/Utils';
import ProductDetailsPopup from '../forms/ProductDetailsPopup';
import Controls from './Controls';

export interface ProductPackageProps {
  index: number
  productTypes: Array<any>,
  productSpecs:any,
  packages: Array<any>
  setPackages: any
}

const emptyProduct = { type: '', productId: '' };

export default function ProductPackage(props: ProductPackageProps) {
  const [open, setOpen] = useState(false);
  const [specOptions, setSpecOptions] = useState([]);

  const {
    index, productTypes, productSpecs, packages, setPackages,
  } = props;

  const {
    values, setValues, errors, setErrors, handleInputChange,
  } = useForm(emptyProduct);

  useEffect(() => {
    // eslint-disable-next-line prefer-spread
    const products = [].concat.apply([], Object.values(productSpecs));
    const productId = packages[index];
    if (products.length > 0 && productId > 0) {
      const product = products.find((it: any) => it.value === productId);
      if (product) {
        // @ts-ignore
        setValues({ type: product.type, productId: product.value });
      }
    } else {
      setValues(emptyProduct);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.productId, packages]);

  useEffect(() => {
    if (values.type && !productSpecs[values.type]) {
      setErrors({ ...errors, type: 'You have no Product Specification for this product type. You can create new product specification in your organization profile' });
      setSpecOptions([]);
    } else {
      setSpecOptions(productSpecs[values.type] || []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.type]);

  const renderPremium = () => {
    if (values.type && values.productId) {
      const product = productSpecs[values.type]?.find((it: any) => it.value === values.productId);
      if (product) {
        return (
          <Typography style={{ marginLeft: 8 }} color="secondary">
            {`Premium for this package EUR ${formatMoney(product.eurPremium)} | USD ${formatMoney(product.usdPremium)} / mt`}
          </Typography>
        );
      }
    }
    return null;
  };

  const renderSpecDetails = () => {
    if (values.productId) {
      return (
        <>
          <Link
            style={{
              cursor: 'pointer', fontSize: 16, color: '#009FD1', marginLeft: 8,
            }}
            onClick={() => setOpen(true)}
          >
            View product specification
          </Link>
          <ProductDetailsPopup productId={values.productId} open={open} setOpen={setOpen} />
        </>
      );
    }

    return null;
  };

  const deletePackage = () => {
    const temp = packages.filter((it) => it !== values.productId);
    setValues(emptyProduct);
    setPackages(temp);
  };

  const changeProducts = (e: any) => {
    setErrors({});
    const array = packages;
    const id = e.target.value;
    if (array.indexOf(id) === -1) {
      array[index] = e.target.value;
      setPackages(array);
      handleInputChange(e);
    } else {
      setErrors({ productId: 'You have already added this product' });
    }
  };

  return (
    <Paper elevation={3} style={{ padding: 10, margin: '16px 8px', width: '50%' }}>
      <Form name={`${index + 1}-package-form`} label={`To be produced product package ${index + 1}`}>
        <Controls.Select
          name="type"
          label="PRODUCT TYPE *"
          options={productTypes}
          value={values.type}
          error={errors.type}
          onChange={handleInputChange}
          rootStyle={{ width: '90%' }}
        />
        <Controls.Select
          name="productId"
          label="PRODUCT SPEC *"
          options={specOptions}
          value={values.productId}
          error={errors.productId}
          onChange={(e: any) => changeProducts(e)}
          rootStyle={{ width: '90%' }}
          disabled={!values.type || specOptions.length === 0}
        />
        { renderPremium() }
        { renderSpecDetails() }
        <br />
        {values.productId && <Controls.Button type="reset" onClick={() => deletePackage()}>Delete package</Controls.Button>}
      </Form>
    </Paper>
  );
}
