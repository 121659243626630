import {
  get, post, put, Page, PageRequest,
} from './RestUtil';

export const emailRegExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const phoneNumberPattern = /^[+]*[0-9]*[(]?[0-9]{1,4}[)]?[-\s./0-9]*$/g;

export interface IUser {
  id: number | null,
  type: string,
  status: string,
  login: string,
  password?: string | null,
  roleId: any,
  orgId?: number | null,
  profile: IProfile
}

export interface IProfile {
  id: number | null,
  salutation: string;
  firstName: string,
  middleName: string,
  lastName: string,
  email: string,
  jobTitle: string,
  phoneNumber: string | null,
  language?: string,
  timeZone?: string,
  operationLimit?: number
  smsEnabled?: boolean
}

export const emptyUser: IUser = {
  id: null,
  type: '',
  status: 'ACTIVE',
  login: '',
  password: '',
  roleId: '',
  profile: {
    id: null,
    salutation: 'MR',
    firstName: '',
    middleName: '',
    lastName: '',
    email: '',
    jobTitle: '',
    phoneNumber: '',
    language: 'English',
    timeZone: 'UTC+1/CET',
    smsEnabled: false,
  },
};

export class UserService {
  getUser = (id: number): Promise<IUser> => get(`/users/${id}`);

  getUsersPage = (pageRequest?: PageRequest & { userType: string }): Promise<Page<IUser>> => get('/users', pageRequest);

  createUser = (user: IUser) => post('/users', user);

  updateUser = (id: number, user: IUser) => put(`/users/${id}`, user);
}
