import { useQuery } from '@tanstack/react-query';
import { toast } from 'react-hot-toast';
import { getDictionary } from 'api/get/getDictionary';
import type { IGetDictionaryParams } from 'api/get/getDictionary';

export const useDictionaryQuery = (params: IGetDictionaryParams) => {
  const { types } = params;

  return useQuery({
    queryKey: ['dictionary', types],
    queryFn: () => getDictionary(params),
    onError: (error) => {
      console.error(error);
      toast.error('Error loading orgs');
    },
  });
};
