import React from 'react';
import {
  makeStyles,
  Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
} from '@material-ui/core';
import { useFormContext, useWatch } from 'react-hook-form';
import clsx from 'clsx';
import FormSelect from 'components/hook-form/FormSelect';
import useLogisticsPricesStore from 'store/addLogisticsPrices';
import { prepareContainersData, prepareProviderOptions } from 'helpers/logisticsLocations';
import { prettyDate } from 'services/Utils';
import { Alert } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 200,
    width: 'auto',
    '& thead th': {
      fontWeight: '700',
      fontSize: 14,
      padding: '12px 16px',
      background: '#252422',
      color: '#f6f5f3',
      textTransform: 'uppercase',
      whiteSpace: 'nowrap',
    },
    '& tbody td': {
      fontWeight: '500',
      fontSize: 14,
      border: 'unset',
      borderBottom: '1px solid lightgray',
      whiteSpace: 'nowrap',
    },
    '& tbody td:hover': {
      backgroundColor: '#ebffc1',
      cursor: 'pointer',
      whiteSpace: 'nowrap',
    },
  },
}));

const ProviderSelector = () => {
  const classes = useStyles();

  const { containerTypes, laneData } = useLogisticsPricesStore((state) => state);

  const { control } = useFormContext();
  const providerValue = useWatch({ control, name: 'provider' });

  const providerData = laneData.find((item: any) => item.id === providerValue);
  const containersData = prepareContainersData(laneData, providerValue, containerTypes);

  return (
    <>
      <div className="two-col-grid two-col-grid--l">
        <div>
          <FormSelect
            name="provider"
            label="Provider"
            rules={{ required: true }}
            options={prepareProviderOptions(laneData)}
            disabled={prepareProviderOptions(laneData)?.length === 0}
          />

          {!providerValue && (
          <Alert severity="warning" style={{ marginBottom: 20, marginTop: -14 }}>
            Please select a provider to continue
          </Alert>
          )}
        </div>

        <div />
      </div>

      {/* provider lane preview */}
      <TableContainer
        component={Paper}
        style={{ marginBottom: '20px', borderRadius: 0 }}
        elevation={0}
        className={clsx(!providerValue && 'block--hidden')}
      >
        <Table className={classes.table} aria-label="Provider pricing">
          <TableHead>
            <TableRow>
              {/* PRICE VALID range */}
              <TableCell align="center">FOR DEALS MADE</TableCell>
              {/* DEPARTURE range */}
              <TableCell align="center">FOR DEPARTURES</TableCell>

              {/* CONTAINERS PRICES */}
              {containersData.map((type: any) => (
                <TableCell align="center" key={type.id}>{type.label}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              {/* PRICE VALID range */}
              <TableCell align="center">
                <div>FROM: {providerData?.validFrom ? prettyDate(providerData.validFrom) : '-'}</div>
                <div>TO: {providerData?.validFrom ? prettyDate(providerData.validTo) : '-'}</div>
              </TableCell>
              {/* DEPARTURE range */}
              <TableCell align="center">
                <div>FROM: {providerData?.departureFrom ? prettyDate(providerData.departureFrom) : '-'}</div>
                <div>TO: {providerData?.departureTo ? prettyDate(providerData.departureTo) : '-'}</div>
              </TableCell>

              {/* CONTAINERS PRICES */}
              {containersData.map((item) => (
                <TableCell align="center" key={item.id}>
                  <div>EUR: {item.priceEur || '-'}</div>
                  <div>USD: {item.priceUsd || '-'}</div>
                </TableCell>
              ))}
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default ProviderSelector;
