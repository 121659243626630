import { useQuery } from '@tanstack/react-query';
import { getOrgsByType } from 'api/get/getOrgsByType';
import { toast } from 'react-hot-toast';

export const useOrgQuery = (orgType: 'seller' | 'buyer') => useQuery({
  queryKey: ['orgs', orgType],
  queryFn: () => getOrgsByType(orgType),
  onError: (error) => {
    console.error(error);
    toast.error('Error loading orgs');
  },
});
