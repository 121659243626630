import React from 'react';
import { FormControl, FormControlLabel, Checkbox as MuiCheckbox } from '@material-ui/core';

export default function Checkbox(props: any) {
  const {
    name, label, value, onChange, disabled,
  } = props;

  const convertToDefEventPara = (paramName: string, paramValue: any) => ({
    target: {
      name: paramName, value: paramValue,
    },
  });

  return (
    <FormControl disabled={disabled}>
      <FormControlLabel
        label={label}
        control={(
          <MuiCheckbox
            name={name}
            color="secondary"
            checked={value}
            onChange={(e, val) => onChange(convertToDefEventPara(name, val))}
          />
        )}
      />
    </FormControl>
  );
}
