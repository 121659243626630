import { ReactNotificationOptions, store } from 'react-notifications-component';

export const defaultNotification:ReactNotificationOptions = {
  type: 'success',
  insert: 'bottom',
  container: 'bottom-right',
  animationIn: ['animate__animated', 'animate__fadeIn'],
  animationOut: ['animate__animated', 'animate__fadeOut'],
  dismiss: {
    duration: 3000,
    onScreen: true,
  },
};

// making all additional props optional
type Options = {
  [K in keyof ReactNotificationOptions]?: ReactNotificationOptions[K];
};

const notification = (props:Options) => {
  store.addNotification({
    ...defaultNotification,
    ...props,
  });
};

type NotificationType = 'CONNECTION_ERROR' | 'COMMON_ERROR' | 'BID_PLACED' | 'BID_DELETED' | 'PRODUCT_UPDATED' | 'PRODUCT_CREATED';
export const showNotification = (type: NotificationType) => {
  let message:Options = {
    title: 'Success',
    message: 'Success',
  };

  switch (type) {
    case 'BID_PLACED':
      message = {
        title: 'Your bid has been placed!',
        message: 'The seller will review your bid and respond as soon as possible.',
        dismiss: {
          duration: 6000,
        },
      };
      break;

    case 'BID_DELETED':
      message = {
        title: 'Success',
        message: 'Bid has been successfully deleted',
      };
      break;

    case 'PRODUCT_CREATED':
      message = {
        title: 'Success',
        message: 'Product has been created',
      };
      break;

    case 'PRODUCT_UPDATED':
      message = {
        title: 'Success',
        message: 'Product has been updated',
      };
      break;

    case 'COMMON_ERROR':
      message = {
        title: 'Error',
        message: 'Something went wrong',
      };
      break;

    case 'CONNECTION_ERROR':
      message = {
        type: 'danger',
        title: 'Internet connection',
        message: 'Network error',
        dismiss: {
          duration: 10000,
        },
      };
      break;
  }
  return notification(message);
};

export default notification;
