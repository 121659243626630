import React, { useEffect, useState } from 'react';
import {  Tab, Tabs, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Form, useForm } from '../../hooks/useForm';
import { FileData } from '../../services/api/FileService';
import Controls from '../controls/Controls';
import {
  emptyOrganization,
  Organization,
} from '../../services/api/OrganizationService';
import api from '../../services/api';
import DocumentInput from '../controls/DocumentInput';
import LocationTable from '../tables/LocationTable';
import UserTable from '../tables/UserTable';
import { emailRegExp } from '../../services/api/UserService';
import ApprovedSellersForm from './ApprovedSellersForm';
import PackagingDiffPage from './PackagingDiffPage';
import TransportationForm from './TransportationForm';
import ProductSpecPage from './ProductSpecPage';
import { option } from '../../services/Utils';

export interface OrganizationFormProps {
  organization: Organization
  orgType: 'SELLER' | 'BUYER'
  setOpen(val: boolean): void
}

const salutations = [{ value: 'MR', label: 'Mr.' }, { value: 'MRS', label: 'Mrs.' }];
const allowedSymbols = ['+', '-', '/', '.', '(', ')', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
const PAYMENT_TERMS = 'PAYMENT_TERMS';

export default function OrganizationForm(props: OrganizationFormProps) {
  const { organization, orgType, setOpen } = props;
  const [tab, setTab] = useState(0);
  const [templates, setTemplates] = useState([]);
  const [paymentTerms, setPaymentTerms] = useState<Array<any>>([]);
  const [globalError, setGlobalError] = useState('');
  const [documents, setDocuments] = useState(organization.documents);

  useEffect(() => {
    api.organization.getOrganizationTemplatesList(setTemplates, setGlobalError);
    api.dictionary.getDictionaryByCode(
      [PAYMENT_TERMS],
      (res: any) => setPaymentTerms(res[PAYMENT_TERMS]),
      organization.id,
      'ALREADY_PRODUCED',
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initialState = {
    ...organization,
    ...organization.contactPerson,
    id: organization.id,
    phoneNumber: organization.contactPerson?.phoneNumber || '',
    salutation: organization.contactPerson?.salutation || 'MR',
    paymentTerm: organization.paymentTerm?.value,
    template: {},
  } || {
    ...emptyOrganization,
    ...emptyOrganization.contactPerson,
    offSpecLocation1: '',
    orgType,
    salutation: 'MR',
    template: {},
  };

  const {
    values, setValues, errors, setErrors, handleInputChange, resetForm,
  } = useForm(initialState);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (!validate()) {
      setGlobalError('Validation failed, please fill required fields');
      return;
    }

    const request: Organization = {
      id: values.id || null,
      orgName: values.orgName,
      orgType,
      documents,
      status: values.id ? values.status : 'ONBOARDED',
      coc: values.coc,
      vat: values.vat,
      feesDescription: values.feesDescription,
      notes: values.notes,
      offSpecLocation1: values.offSpecLocation1,
      offSpecLocation2: values.offSpecLocation2,
      offSpecLocation3: values.offSpecLocation3,
      currency: values.currency,
      country: values.country,
      legalName: values.legalName,
      paymentTerm: values.paymentTerm && option(values.paymentTerm),
      paymentOffset: values.paymentOffset > 0 ? values.paymentOffset : undefined,
      isFinancingProvider: values.isFinancingProvider,
      isTotalPriceCredited: values.isTotalPriceCredited,
    };

    if (values.firstName && values.email) {
      request.contactPerson = {
        id: organization.contactPerson?.id || null,
        salutation: values.salutation || 'MR',
        firstName: values.firstName,
        middleName: values.middleName,
        lastName: values.lastName,
        email: values.email,
        jobTitle: values.jobTitle,
        phoneNumber: values.phoneNumber,
      };
    }

    if (request.id) {
      await api.organization.updateOrganization(request.id, request)
        .then(() => { setGlobalError(''); setOpen(false); })
        .catch((err) => setGlobalError(err));
    } else {
      await api.organization.createOrganization(request)
        .then(() => { setGlobalError(''); setOpen(false); })
        .catch((err) => setGlobalError(err?.message));
    }
  };

  function validate() {
    setGlobalError('');
    const temp: any = {};
    const requiredFields = ['orgName', 'country'];

    if (values.firstName || values.lastName || values.email || values.phoneNumber) {
      requiredFields.push('firstName', 'lastName', 'email', 'phoneNumber');
    }

    requiredFields.forEach((key) => {
      if (!values[key]) {
        temp[key] = 'This field is required.';
      }
    });

    (values.email && !emailRegExp.test(String(values.email).toLowerCase())) && (temp.email = 'Invalid email format');

    if (orgType === 'SELLER') {
      if (!values.offSpecLocation1) {
        temp.offSpecLocation1 = 'This field is required.';
      }
    }
    if (orgType === 'BUYER') {
      if (!values.paymentTerm) {
        temp.paymentTerm = 'This field is required';
      }

      if (hasDayOffset() && !values.paymentOffset) {
        temp.paymentOffset = 'This field is required';
      }
    }

    setErrors({ ...temp });

    return Object.keys(temp).length === 0;
  }

  const getOptions = () => templates
    // @ts-ignore
    .filter((it) => it.orgType === orgType && it.status !== 'ACTIVE')
    .map((it: any) => ({ label: `${it.orgName} (${it.countryName})`, value: it }));

  const setOrgData = (e: any) => {
    setErrors({ ...errors, orgName: '', country: '' });
    const { orgName, countryName } = e.target.value;
    setValues({
      ...values, orgName, country: countryName, legalName: orgName, template: e.target.value,
    });
  };

  const handlePhoneNumberChange = (e: any) => {
    const val: string = e.target.value;
    let correctValue = true;
    val.split('').forEach((it) => {
      if (!allowedSymbols.includes(it)) {
        correctValue = false;
      }
    });
    if (correctValue) {
      handleInputChange(e);
    } else {
      e.preventDefault();
    }
  };

  const handleFileSave = (file: FileData) => {
    const document = {
      documentType: 'SALES_TERMS',
      fileName: file.fileName,
      originalFileName: file.originalFileName,
      id: file.id,
    };
    setDocuments([document]);
  };

  const hasDayOffset = () => {
    if (paymentTerms && values.paymentTerm) {
      return paymentTerms.find((it: any) => it.value === values.paymentTerm)?.hasDayOffset;
    }

    return false;
  };

  const getOffsetOptions = () => {
    if (paymentTerms && values.paymentTerm) {
      const term = paymentTerms.find((it: any) => it.value === values.paymentTerm);
      return term && term.offsetDays.map((it: number) => option(it, `${it}`));
    }

    return [];
  };

  return (
    <>
      <Tabs value={tab} onChange={(e, val) => setTab(val)}>
        <Tab label="Organizational profile" value={0} />
        <Tab label="Users" value={1} disabled={!values.id} />
        <Tab label="Locations" value={2} disabled={!values.id} />
        { orgType === 'SELLER' && <Tab label="Transportation" value={3} disabled={!values.id} /> }
        { orgType === 'SELLER' && <Tab label="Package differentials" value={6} disabled={!values.id} /> }
        <Tab label="Product specs" value={4} disabled={!values.id} />
        { orgType === 'BUYER' && <Tab label="Approved suppliers" value={5} disabled={!values.id} /> }
      </Tabs>
      { tab === 0 && (
      <Form name="create-organization">
        { globalError && <Alert severity="error">{globalError}</Alert> }
        <Typography variant="h6">Company information</Typography>
        <br />
        { !values.id
          ? (
            <Controls.Select
              name="template"
              label="ORGANISATION FROM CATALOGUE"
              value={values.template.orgName}
              error={errors.orgName}
              onChange={setOrgData}
              options={getOptions()}
              required
            />
          )
          : (
            <Controls.Input
              name="orgName"
              label="ORGANIZATION NAME"
              value={values.orgName}
              disabled
            />
          )}
        <Controls.Input
          name="legalName"
          label="LEGAL COMPANY NAME FOR CONTACTS AND INVOICES"
          value={values.legalName}
          onChange={handleInputChange}
        />
        <Controls.Input
          name="country"
          label="COUNTRY HEADOFFICE"
          value={values.country}
          disabled
        />
        <Controls.Input
          name="coc"
          label="CHAMBER OF COMMERCE NUMBER"
          value={values.coc}
          error={errors.coc}
          onChange={handleInputChange}
        />
        <Controls.Input
          name="vat"
          label="VAT NUMBER"
          value={values.vat}
          error={errors.vat}
          onChange={handleInputChange}
        />
        <Controls.Input
          name="feesDescription"
          label="FEES DESCRIPTION (PUBLIC PROFILE)"
          value={values.feesDescription}
          error={errors.feesDescription}
          onChange={handleInputChange}
          multiline
        />
        <Controls.Input
          name="notes"
          label="INTERNAL NOTES (E.G. ABOUT FEES)"
          value={values.notes}
          error={errors.notes}
          onChange={handleInputChange}
          multiline
        />
        { orgType === 'SELLER' && (
        <DocumentInput
          label="SALES TERMS"
          file={documents && documents[0]}
          onDelete={() => setDocuments([])}
          onSave={handleFileSave}
        />
        )}
        {
          orgType === 'BUYER' && (
          <>
            <div>
              <Controls.Select
                name="paymentTerm"
                label="PREFERRED PAYMENT TERMS"
                value={values.paymentTerm}
                error={errors.paymentTerm}
                onChange={(e) => {
                  setValues({ ...values, paymentTerm: e.target.value, paymentOffset: 0 });
                }}
                options={paymentTerms}
                required
              />
              { hasDayOffset() && (
                <Controls.Select
                  name="paymentOffset"
                  label="X DAYS"
                  value={values.paymentOffset}
                  error={errors.paymentOffset}
                  options={getOffsetOptions()}
                  onChange={handleInputChange}
                  required
                />
              )}
            </div>
            <Controls.RadioGroup
              name="currency"
              label="TRADING CURRENCY"
              value={values.currency}
              items={[{ value: 'EUR', label: 'EUR' }, { value: 'USD', label: 'USD' }]}
              onChange={handleInputChange}
            />
          </>
          )
        }
        <br />
        <Typography variant="h6">General contact info for internal use</Typography>
        <Controls.RadioGroup
          name="salutation"
          label="TITLE"
          value={values.salutation}
          items={salutations}
          onChange={handleInputChange}
        />
        <Controls.Input
          name="firstName"
          label="FIRST NAME"
          value={values.firstName}
          error={errors.firstName}
          onChange={handleInputChange}
        />
        <Controls.Input
          name="middleName"
          label="MIDDLE NAME"
          value={values.middleName}
          error={errors.middleName}
          onChange={handleInputChange}
        />
        <Controls.Input
          name="lastName"
          label="LAST NAME"
          value={values.lastName}
          error={errors.lastName}
          onChange={handleInputChange}
        />
        <Controls.Input
          name="jobTitle"
          label="POSITION IN THE ORGANISATION"
          value={values.jobTitle}
          error={errors.jobTitle}
          onChange={handleInputChange}
        />
        <Controls.Input
          name="phoneNumber"
          label="TELEPHONE NUMBER"
          value={values.phoneNumber}
          error={errors.phoneNumber}
          onChange={handlePhoneNumberChange}
        />
        <Controls.Input
          name="email"
          label="GENERAL CONTACT EMAIL"
          type="email"
          autoComplete="off"
          value={values.email}
          error={errors.email}
          onChange={handleInputChange}
        />
        <br />
        { orgType === 'SELLER'
              && (
                <>
                  <br />
                  <Typography variant="h6">Off-spec logistics locations</Typography>
                  <Controls.Input
                    name="offSpecLocation1"
                    label="PICK-UP LOCATION 1"
                    value={values.offSpecLocation1}
                    error={errors.offSpecLocation1}
                    onChange={handleInputChange}
                    required
                  />
                  <Controls.Input
                    name="offSpecLocation2"
                    label="PICK-UP LOCATION 2"
                    value={values.offSpecLocation2}
                    error={errors.offSpecLocation2}
                    onChange={handleInputChange}
                  />
                  <Controls.Input
                    name="offSpecLocation3"
                    label="PICK-UP LOCATION 3"
                    value={values.offSpecLocation3}
                    error={errors.offSpecLocation3}
                    onChange={handleInputChange}
                  />
                </>
              )}
        {
          orgType === 'SELLER' && (
            <>
              <Typography>Seller financing</Typography>
              <Controls.Checkbox
                name="isFinancingProvider"
                label="This seller provides financing"
                value={values.isFinancingProvider}
                onChange={handleInputChange}
              />
              { values.isFinancingProvider
              && (
                <div style={{ marginLeft: 24 }}>
                  <Controls.Checkbox
                    name="isTotalPriceCredited"
                    label="Gives credit including the costs of the financing"
                    value={values.isTotalPriceCredited}
                    onChange={handleInputChange}
                  />
                </div>
              )}
            </>
          )
        }
        <div>
          <Controls.Button type="submit" onClick={handleSubmit}>Submit</Controls.Button>
          <Controls.Button color="default" onClick={resetForm}>Reset</Controls.Button>
        </div>
      </Form>
      )}
      { tab === 1 && (
      <UserTable orgId={values.id} userType={orgType} tableLabel="Organization users" />
      )}
      { tab === 2 && (<LocationTable orgType={orgType} orgId={values.id} />)}
      { tab === 3 && (<TransportationForm orgId={values.id} />)}
      { tab === 4 && (<ProductSpecPage orgType={orgType} orgId={values.id} />)}
      { tab === 5 && (<ApprovedSellersForm orgId={values.id} />)}
      { tab === 6 && (<PackagingDiffPage orgId={values.id} />)}
    </>
  );
}
