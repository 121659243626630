import { DevTool } from '@hookform/devtools';
import { useOrgListQuery, useOrgUserListQuery } from 'SHARED/api/organizations';
import { SingleCheckbox } from 'SHARED/components/Checkbox';
import Checkbox from 'SHARED/components/Checkboxes';
import FormFileInput from 'SHARED/components/FileInput/FormFileInput';
import FormInput from 'SHARED/components/FormInput';
import FormMultiSelect from 'SHARED/components/MultiSelect';
import Radio from 'SHARED/components/Radio';
import RadioGroup from 'SHARED/components/RadioGroup';
import FormSelect from 'SHARED/components/Select';
import SingleCurrencyInput from 'SHARED/components/SingleCurrencyInput';
import Preloader from 'SHARED/components/ThePreloader';
import ZonedDatePicker from 'SHARED/components/ZonedDatePicker/ZonedDatePicker';
import { buyersFetchType, volumeUnits } from 'SHARED/helpers/OfferData';
import { MpcDocLink, clearProxyFields, getFullName, isDevEnv } from 'SHARED/helpers/common';
import prepareBuyerGroups from 'SHARED/helpers/prepareBuyerGroups';
import preparePreview from 'SHARED/helpers/prepareOfferData';
import texts, { tooltips } from 'SHARED/helpers/texts';
import validationRules from 'SHARED/helpers/validation';
import PreventLossUnsavedData from 'SHARED/hooks/usePreventReload';
import { useUserType } from 'SHARED/hooks/useUserType';
import { useActions } from 'SHARED/redux/hooks/useActions';
import { useTypedSelector } from 'SHARED/redux/hooks/useTypedSelector';
import { BuyerPaymentTerm, BuyersFetchTypes, Dictionary } from 'SHARED/types/offerTypes';
import routes from 'SHARED/types/routes';
import { cloneDeep, groupBy } from 'lodash';
import moment from 'moment-timezone';
import React, { FC, useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { Helmet } from 'react-helmet';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

// ----------------------------------------------

interface FormValues {
  paymentTerms?: BuyerPaymentTerm | null;
  paymentOffset?: Dictionary | null;

  [key: string]: any,
}

// ----------------------------------------------

const PAGE_STYLES: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
  gap: '40px',
};

const SECTION_STYLES: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
};

// ----------------------------------------------

export const AuctionCreateAdminPage: FC = () => {
  // hooks
  const { isAdmin } = useUserType();
  const history = useHistory();
  // hooks === END

  // hook form
  const methods = useForm();
  const {
    watch,
    setValue,
    formState: { isDirty, dirtyFields },
  } = methods;
  // hook form === END

  // redux hooks
  const { me } = useTypedSelector((state) => state.users);
  const {
    dictionaries,
    locations,
    loading: isDictionariesLoading,
  } = useTypedSelector((state) => state.dictionaries);
  const {
    loading: isSpecsLoading,
  } = useTypedSelector((state) => state.specs);
  const {
    OFF_SPEC,
    loading: isOfferLoading,
  } = useTypedSelector((state) => state.offer);
  // redux hooks === END

  // redux actions
  const {
    resetOffer,
    getDictionaries,
    getLocations,
    saveOffer,
  } = useActions();
  // redux actions === END

  // form values
  const sellerOrganizationValue = watch('sellerOrganization', null);
  const incotermsValue = watch('incoterms');
  const startDate = watch('loadDateFrom') || OFF_SPEC.loadDateFrom;
  const totalVolumeValue = watch('totalVolume');
  const splittableValue = watch('splittable');

  const paymentTermsValue: BuyerPaymentTerm = watch('paymentTerms');

  const buyersFetchTypeValue: BuyersFetchTypes = watch(
    'buyersFetchType',
    OFF_SPEC.buyersFetchType?.value || BuyersFetchTypes.includeAll,
  );

  const salesTermsTypeValue = watch('salesTerms', OFF_SPEC.salesTerms);
  const isOwnTermsChoosed = salesTermsTypeValue === 'OWN';
  // form values === END

  // form validation
  const buyersValidation = {
    required: (
      buyersFetchTypeValue === 'EXCLUDE'
        ? 'Select at least one buyer'
        : false
    ),
    validate: validationRules.notAllSelected(dictionaries.BUYERS_LIST?.length),
  };
  // form validation === END

  // queries
  const orgListQuery = useOrgListQuery('seller');
  const orgUserListQuery = useOrgUserListQuery(sellerOrganizationValue?.value);

  const orgListOptions = orgListQuery.data?.rows?.map((org) => ({
    value: org.id.toString(),
    label: org.orgName,
  })) || [];
  const orgUserListOptions = orgUserListQuery.data?.rows?.map((user) => ({
    value: user.id.toString(),
    label: getFullName(user.profile, false),
  })) || [];
  // queries === END

  // flags
  const isLoading = (
    isDictionariesLoading
    || isSpecsLoading
    || isOfferLoading
    || orgListQuery.isLoading
    || orgUserListQuery.isLoading
  );
  const isExcludeBuyers = buyersFetchTypeValue === BuyersFetchTypes.exclude;
  // flags === END

  // data
  const groupedLocations = groupBy(locations.LOGISTIC, 'type');
  // data === END

  // handlers
  const handleSellerOrganizationChange = (value: any) => {
    // reset dependent fields
    setValue('sellerOrganizationUserId', null);
  };

  const handleDiscard = () => {
    resetOffer();
    history.push(routes.onspec.listDefault);
  };

  const onSubmit = (data: FormValues) => {
    // makes some data converting such default radios or checkboxes
    // which needs to be converted to objects
    const clearedData = clearProxyFields(data);
    const prepared = preparePreview(cloneDeep(clearedData), dictionaries);

    // add identity fields
    prepared.organizationId = data.sellerOrganization.value;
    prepared.ownerUserId = data.sellerUser.value;

    // Temp solution
    // additional data needed for offer creation
    prepared.volumeUnits = volumeUnits;
    prepared.currency = 'EUR';
    prepared.documents = OFF_SPEC.documents;
    prepared.params = {
      salesTerms: prepared.salesTerms,
    };

    prepared.logisticLocationId = prepared.logisticLocation.id;

    // single currency (if currency is disabled -- set corresponding price to null)
    // but at least one price should be set
    if (prepared.priceUsdDisabled && prepared.priceEurDisabled) { return false; }
    if (prepared.priceEurDisabled && !prepared.priceUsdDisabled) { prepared.priceEur = null; }
    if (prepared.priceUsdDisabled && !prepared.priceEurDisabled) { prepared.priceUsd = null; }

    prepared.paymentTerms = data.paymentTerms;

    // manually update documents
    // because they are stored in the wrong fields
    prepared.documents = {
      COA: prepared.coaDocument,
      SALES_TERMS: prepared.termsDocument,
    };

    // in case if term has day offset
    // if (data.paymentTerms?.hasDayOffset) {
    //   prepared.paymentOffset = data.paymentOffset?.value;
    // }

    // if buyers groups selected, buyers list must be set from group
    if (prepared.groups?.length > 0) {
      prepared.buyers = prepareBuyerGroups(prepared.groups);
    }

    console.log('prepared auction', prepared);
    saveOffer({ offer: prepared, offerType: 'OFF_SPEC' });
    // return true;
    history.push(routes.admin.auction_preview);

    return true;
  };
  // handlers === END

  // effects
  PreventLossUnsavedData(isDirty);

  // reset seller user if organization changed
  useEffect(() => {
    if (dirtyFields.sellerOrganization) {
      setValue('sellerUser', null);
    }
  }, [sellerOrganizationValue]);

  useEffect(() => {
    // load all necessary data for offer creation
    if (me.id && sellerOrganizationValue?.value) {
      getDictionaries({
        offerType: 'OFF_SPEC',
        isAdmin: true,
        orgId: sellerOrganizationValue?.value,
      });
      getLocations({ type: 'LOGISTIC', orgId: sellerOrganizationValue.value });
    }
  }, [me, sellerOrganizationValue?.value]);
  // effects === END

  if (!isAdmin) {
    return <>You are not authorized to view this page.</>;
  }

  return (
    <>
      <Helmet>
        <title>Create an auction</title>
      </Helmet>

      <Preloader isLoading={isLoading} />

      {isDevEnv && <DevTool control={methods.control} placement="top-right" /> }

      <div className="breadcrumbs">
        <a href={routes.admin.auction_list_active}>&lt; All auctions </a>
      </div>

      <main className="page-body is-logged-in">
        <h1 className="page-title">Create an auction</h1>
        <div className="separator" />

        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <input type="hidden" {...methods.register('type')} value="OFF_SPEC" />

            <div className="wrapper-sm manual-form-layout" style={PAGE_STYLES}>

              <section style={SECTION_STYLES}>
                <h2 className="page-section-title no-margin">Seller</h2>

                <div className="wrapper-xxs">
                  <FormSelect
                    label="Seller organization"
                    name="sellerOrganization"
                    options={orgListOptions}
                    selected={OFF_SPEC.sellerOrganization || null}
                    onChange={handleSellerOrganizationChange}
                  />
                </div>

                <div className="wrapper-xxs">
                  <FormSelect
                    label="Seller user"
                    name="sellerUser"
                    options={orgUserListOptions}
                    selected={OFF_SPEC.sellerUser || null}
                    disabled={
                      !sellerOrganizationValue?.value
                      || (
                        sellerOrganizationValue?.value
                        && orgUserListOptions?.length === 0
                      )
                    }
                  />

                  {
                    sellerOrganizationValue?.value
                    && orgUserListOptions?.length === 0
                    && !orgUserListQuery.isLoading
                    && (
                      <div className="attention-message alert">
                        This organization has no users. Please select another organization.
                      </div>
                    )
                  }
                </div>
              </section>

              <section style={SECTION_STYLES}>
                <h2 className="page-section-title no-margin">Product</h2>

                <div className="wrapper-xxs">
                  <FormInput
                    name="title"
                    label="AUCTION TITLE"
                    value={OFF_SPEC.title}
                    tooltip={tooltips.offer_title}
                    rules={validationRules.required}
                  />
                </div>

                <div className="wrapper-sm">
                  <FormInput
                    name="description"
                    textarea
                    label="DESCRIPTION"
                    value={OFF_SPEC.description}
                    tooltip={tooltips.offer_desc}
                  />
                </div>

                <div className="wrapper-sm">
                  <FormFileInput
                    name="coaDocument"
                    label="PRODUCT COA DOCUMENT"
                    defaultFiles={OFF_SPEC.documents.COA || null}
                    acceptedFileTypes={['application/pdf']}
                  />
                </div>

                <div className="wrapper-sm">
                  <Checkbox
                    label="DOCUMENT CAPABILITIES"
                    name="documentCapabilities"
                    values={dictionaries.DOCUMENT_TYPE}
                    selected={OFF_SPEC.documentCapabilities}
                    className="two-columns"
                  />
                </div>

                <div className="wrapper-xxs">
                  <FormSelect
                    label="PACKAGING"
                    name="packaging"
                    options={dictionaries.PACKAGING_OPTIONS}
                    selected={OFF_SPEC.packaging}
                    defaultValue="25_kg_bags"
                  />
                </div>

                <div className="wrapper-xxs">
                  <RadioGroup
                    label="SALES TERMS"
                    name="salesTerms"
                    defaultValue={OFF_SPEC.params.salesTerms}
                    values={dictionaries.SALES_TERMS}
                  />
                </div>

                <div className="wrapper-sm">
                  <div className="form-input">
                    <div className="label-text" style={{ marginBottom: '8px' }}>SALES TERMS DOCUMENT *</div>

                    {!isOwnTermsChoosed && (
                      <a href={MpcDocLink} target="_blank" rel="noreferrer" className="document-link" style={{ marginTop: '0' }}>
                        <i className="icon-flag" />
                        <span>MPC sales terms</span>
                      </a>
                    )}

                    {isOwnTermsChoosed && (
                    <div>
                      <FormFileInput
                        name="termsDocument"
                        defaultFiles={OFF_SPEC.documents.SALES_TERMS}
                        acceptedFileTypes={['application/pdf']}
                        required={isOwnTermsChoosed}
                      />
                    </div>
                    )}
                  </div>
                </div>
              </section>

              <section style={SECTION_STYLES}>
                <h2 className="page-section-title no-margin">Shipping</h2>

                <div className="wrapper-3xs">
                  <FormSelect
                    label="INCOTERMS"
                    name="incoterms"
                    options={dictionaries.INCOTERM_SELLER}
                    selected={OFF_SPEC.incoterms}
                    defaultValue="FCA"
                  />
                </div>

                <div className="wrapper-sm">
                  <FormSelect
                    label="SELLER INCOTERMS LOCATION"
                    name="logisticLocation"
                    options={groupedLocations[incotermsValue?.locationType] || []}
                    selected={OFF_SPEC.logisticLocation}
                    disabled={!incotermsValue?.locationType}
                  />
                </div>

                <div className="wrapper-xxs">
                  <Checkbox
                    label="LOADING DETAILS"
                    name="loadOptions"
                    values={dictionaries.LOADING_DETAILS}
                    selected={OFF_SPEC.loadOptions}
                  />
                </div>

                <div className="wrapper-sm">
                  <div className="dates-range" style={{ width: '100%' }}>
                    <div className="label-text">PICK-UP DATE RANGE *</div>
                    <div className="dates-range-wrapper" style={{ width: '100%' }}>
                      <ZonedDatePicker
                        inline={isMobile}
                        name="loadDateFrom"
                        minDate={new Date()}
                        value={OFF_SPEC.loadDateFrom}
                        showTimezone={false}
                      />
                      <ZonedDatePicker
                        inline={isMobile}
                        name="loadDateTo"
                        minDate={moment(startDate).toDate()}
                        value={OFF_SPEC.loadDateTo}
                        rules={validationRules.endDate(startDate)}
                        showTimezone={false}
                      />
                    </div>
                  </div>
                </div>
              </section>

              <section style={SECTION_STYLES}>
                <h2 className="page-section-title no-margin">Volume and price</h2>

                <div className="wrapper-xxs">
                  <FormInput
                    name="totalVolume"
                    label="VOLUME"
                    value={OFF_SPEC.totalVolume}
                    className="short"
                    type="number"
                    suffix="MT"
                    rules={validationRules.required}
                  />
                </div>

                <div className="wrapper-sm">
                  <SingleCheckbox
                    title="SPLITTABLE"
                    label="Splittable per container / full truck load"
                    name="splittable"
                    defaultChecked={OFF_SPEC.splittable}
                  />
                </div>

                {splittableValue && (
                  <div className="wrapper-xxs">
                    <FormInput
                      name="minBidVolume"
                      label="MINIMUM BID VOLUME"
                      value={OFF_SPEC.minBidVolume}
                      className="short"
                      type="number"
                      suffix="MT"
                      tooltip={texts.tooltips.auctionMinBidVolume}
                      rules={validationRules.maxBidVolume(totalVolumeValue)}
                    />
                  </div>

                )}

                <div className="wrapper-xxs">
                  <SingleCurrencyInput
                    name="priceEur"
                    label="ASKING PRICE"
                    value={OFF_SPEC.priceEur}
                    tooltip={tooltips.auctionAskPriceEur}
                    currencyName="EUR"
                    currencyDisabledDefault={OFF_SPEC.priceEurDisabled}
                    offerType="OFF_SPEC"
                  />
                </div>

                <div className="wrapper-xxs">
                  <SingleCurrencyInput
                    name="priceUsd"
                    currency="$"
                    label="ASKING PRICE"
                    value={OFF_SPEC.priceUsd}
                    tooltip={tooltips.auctionAskPriceUsd}
                    currencyName="USD"
                    currencyDisabledDefault={OFF_SPEC.priceUsdDisabled}
                    offerType="OFF_SPEC"
                  />
                </div>

                <div
                  className={paymentTermsValue?.hasDayOffset ? 'financing-row' : ''}
                  style={{ marginBottom: 0 }}
                >
                  <FormSelect
                    label="PAYMENT TERMS"
                    name="paymentTerms"
                    options={dictionaries.PAYMENT_TERMS}
                    selected={OFF_SPEC.paymentTerms}
                    disabled={!sellerOrganizationValue?.value}
                  />

                  {paymentTermsValue?.hasDayOffset && (
                    <div className="days-offset">
                      <FormSelect
                        label="NUMBER OF DAYS"
                        name="paymentOffset"
                        options={paymentTermsValue.offsetDays.map((d) => ({ value: `${d}`, label: `${d} days` }))}
                        selected={OFF_SPEC.paymentOffset || null}
                      />
                    </div>
                  )}
                </div>
              </section>

              <section style={SECTION_STYLES}>
                <h2 className="page-section-title no-margin">Buyers</h2>

                <>
                  <div className="form-input"><label htmlFor="">BUYERS WHO SEE THIS OFFER *</label></div>

                  <Radio
                    selected={buyersFetchTypeValue}
                    name="buyersFetchType"
                    value={BuyersFetchTypes.includeAll}
                    label={buyersFetchType[0].label}
                  />

                  <Radio
                    selected={buyersFetchTypeValue}
                    name="buyersFetchType"
                    value={BuyersFetchTypes.exclude}
                    label={buyersFetchType[2].label}
                  />

                  {isExcludeBuyers && (
                    <FormMultiSelect
                      name="buyers"
                      rules={buyersValidation}
                      options={dictionaries.BUYERS_LIST}
                      value={OFF_SPEC.buyers}
                    />
                  )}
                </>
              </section>

              <section style={SECTION_STYLES}>
                <h2 className="page-section-title no-margin">Auction end date</h2>

                <div className="wrapper-sm">
                  <ZonedDatePicker
                    name="endDate"
                    showTimeInput
                    inline={isMobile}
                    minDate={new Date()}
                    value={OFF_SPEC.endDate}
                    dateFormat="dd MMMM yyyy HH:mm"
                  />
                </div>
              </section>

              <div className="buttons-wrapper m-full-width">
                <button
                  type="submit"
                  className="btn-primary"
                >
                  Preview auction
                </button>
                <button
                  type="button"
                  className="btn-secondary"
                  onClick={handleDiscard}
                >
                  Discard
                </button>
              </div>

            </div>

          </form>
        </FormProvider>

      </main>
    </>
  );
};
