import { Divider, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { Form, useForm } from '../../hooks/useForm';
import api from '../../services/api';
import { Dictionary } from '../../services/api/DictionaryService';
import {
  ProductCharacteristicValue,
  ProductSpec,
  ProductSpecItem,
} from '../../services/api/ProductSpecService';
import Controls from '../controls/Controls';
import SpecInput from '../controls/SpecInput';
import TextField from '../controls/TextField';

export interface ProductSpecFormProps {
  category: string
  orgId: number
  item: any
  setOpen(val: boolean): void
  orgType: 'SELLER' | 'BUYER'
}

const PACKAGING_OPTIONS = 'PACKAGING_OPTIONS';
const DOCUMENT_TYPE = 'DOCUMENT_TYPE';

export default function ProductSpecForm(props: ProductSpecFormProps) {
  const {
    orgId, category, item, orgType, setOpen,
  } = props;

  const [prodTypeOptions, setProdTypeOptions] = useState([]);
  const [docTypeOptions, setDocTypeOptions] = useState([]);
  const [packOptions, setPackOptions] = useState([]);
  const [fields, setFields] = useState({});
  const [characteristics, setCharacteristics] = useState<Array<ProductCharacteristicValue>>([]);
  const [globalError, setGlobalError] = useState('');
  const [needValidate, setNeedValidate] = useState(false);
  const disabled = !!item.id;

  const {
    values, errors, setErrors, handleInputChange,
  } = useForm({
    ...item,
    productType: item.productType?.value,
    packaging: orgType === 'BUYER' ? item.packaging?.value : undefined,
    active: item.id ? item.active : 'true',
    requiredDocuments: item.requiredDocuments || [],
  });

  useEffect(() => api.productSpec.getCategories((res: any) => {
    const { productTypes, packagingOptions } = res.find((it: any) => it.value === category);
    setProdTypeOptions(productTypes);
    setPackOptions(packagingOptions);
  }), [category]);

  useEffect(() => setDictionaryValues(), []);

  useEffect(() => {
    values.productType && api.productSpec.getSpecification(values.productType)
    // @ts-ignore
      .then((res) => setFields(_.groupBy(res, (it) => it.displayGroup.label)));
  }, [values.productType]);

  const setDictionaryValues = () => {
    api.dictionary.getDictionaryByCode(
      [PACKAGING_OPTIONS, DOCUMENT_TYPE],
      (res: any) => {
        // TODO: remove comments if needed
        // ? this part is not needed since packaging now comes from the category details
        // setPackOptions(
        //   res[PACKAGING_OPTIONS].map((it: Dictionary) => ({ label: it.label, value: it.value })),
        // );
        setDocTypeOptions(
          res[DOCUMENT_TYPE].map((it: Dictionary) => ({ label: it.label, value: it.value })),
        );
      },
    );
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { needValidate && handleSubmit(); }, [needValidate]);

  const validate = () => {
    const tempErrors = {};
    const errorMessage = 'This field is required';
    const requiredFields = ['title'];
    orgType === 'BUYER' && requiredFields.push('packaging', 'requiredDocuments');
    !disabled && requiredFields.push('productType');

    const isSpecValid = disabled || !characteristics.find((it) => it.hasError);

    requiredFields.forEach((it) => {
      if (!values[it]) {
        // @ts-ignore
        tempErrors[it] = errorMessage;
      }
    });

    setErrors({ ...tempErrors });

    return Object.keys(tempErrors).length === 0 && isSpecValid;
  };

  const handleSpecChange = (charValue: ProductCharacteristicValue) => {
    const itemIndex = characteristics
      .findIndex((it) => it.characteristicSpecId === charValue.characteristicSpecId);
    itemIndex !== -1
      ? characteristics[itemIndex] = { ...charValue }
      : characteristics.push({ ...charValue });
    setCharacteristics(characteristics);
  };

  useEffect(() => {
    const input = document.querySelector('.MuiAlert-root');
    input?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'start',
    });
  }, [globalError]);

  const handleSubmit = () => {
    if (validate() && characteristics.length > 0) {
      const request: ProductSpec = {
        id: item.id,
        organizationId: orgId,
        title: values.title,
        productCategory: { label: category, value: category },
        productType: { label: values.productType, value: values.productType },
        active: values.active === 'true' || values.active === true,
        requiredDocuments: values.requiredDocuments || [],
        // @ts-ignore
        packaging: orgType === 'BUYER'
          ? { label: values.packaging, value: values.packaging }
          : undefined,
        customLabelRequired: false,
        premiumPriceEur: item.premiumPriceEur,
        premiumPriceUsd: item.premiumPriceUsd,
        characteristics: characteristics.map((it) => ({
          characteristicSpecId: it.characteristicSpecId,
          value: it.value,
          valueTo: it.valueTo,
          notTested: it.notTested,
          range: it.range,
        })),
      };

      item.id
        ? api.productSpec.updateProductSpec(item.id, request)
          .then(() => setOpen(false))
          .catch((err) => setGlobalError(err.message))
        : api.productSpec.createProductSpec(request)
          .then(() => setOpen(false))
          .catch((err) => setGlobalError(err.message));
    } else {
      const input = document.querySelector('.Mui-error');
      input?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'start',
      });
    }
    setNeedValidate(false);
  };

  const renderSpecGroup = (groupName: string) => {
    // @ts-ignore
    const items: Array<ProductSpecItem> = fields[groupName];
    return (
      <div key={groupName}>
        <Typography variant="h6">{groupName}</Typography>
        <br />
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          { items
            .sort((it) => it.displayOrder)
            .map((it: ProductSpecItem) => (
              <SpecInput
                key={it.id}
                specTemplate={it}
                orgType={orgType}
                needValidate={needValidate}
                productSpec={item && item.characteristics}
                onChange={handleSpecChange}
                disabled={disabled}
              />
            )) }
        </div>
        <Divider />
        <br />
      </div>
    );
  };

  return (
    <>
      <Typography variant="h5">Product Specifications</Typography>
      <br />
      { globalError && <Alert severity="error">{globalError}</Alert> }
      <TextField label="PRODUCT CATEGORY" value={category} />
      <Form name="create-product-spec">
        <Controls.Input
          name="title"
          label="PRODUCT SPECS TITLE"
          value={values.title}
          error={errors.title}
          style={{ width: '50%' }}
          onChange={handleInputChange}
          required
        />
        <Controls.Select
          name="productType"
          label="PRODUCT TYPE"
          options={prodTypeOptions}
          value={values.productType}
          error={errors.productType}
          onChange={handleInputChange}
          rootStyle={{ width: '50%' }}
          disabled={disabled}
          required
        />
        { fields && (
          Object.keys(fields).map((key) => renderSpecGroup(key)))}

        { (orgType === 'BUYER' && values.productType) && (
        <>
          <Typography variant="h6">Required packaging and documents</Typography>
          <br />
          <Controls.Select
            name="packaging"
            label="PACKAGING"
            options={packOptions}
            value={values.packaging}
            error={errors.packaging}
            onChange={handleInputChange}
            rootStyle={{ width: '50%' }}
            disabled={disabled}
            required
          />
          <br />
          <Controls.CheckboxGroup
            name="requiredDocuments"
            label="REQUIRED DOCUMENTS"
            options={docTypeOptions}
            value={values.requiredDocuments}
            error={errors.requiredDocuments}
            onChange={handleInputChange}
            disabled={disabled}
            groupSize={5}
          />
          <Controls.Checkbox
            name="customLabelRequired"
            label="Custom label required"
            value={values.customLabelRequired}
            onChange={handleInputChange}
            disabled={disabled}
          />
        </>
        ) }
        <Controls.RadioGroup
          name="active"
          label="STATUS"
          value={`${values.active}`}
          items={[{ value: 'true', label: 'Active' }, { value: 'false', label: 'Not active' }]}
          onChange={handleInputChange}
        />

        <div>
          <Controls.Button onClick={() => setNeedValidate(true)}>Submit</Controls.Button>
        </div>
      </Form>
    </>
  );
}
