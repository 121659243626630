import { useQuery } from '@tanstack/react-query';
import { toast } from 'react-hot-toast';
import getDealById from 'api/get/getDealById';

export const useDealQuery = <T>(dealId: string) => {
  const { data, isInitialLoading, isLoading, isFetching, isError, error, refetch } = useQuery({
    queryKey: ['deal', dealId],
    queryFn: () => getDealById<T>(dealId),
    onError: (err) => {
      console.error(err);
      toast.error('Error loading deal');
    },
    enabled: !!dealId,
    staleTime: 0, // refetch on every mount
  // refetchInterval: 1000 * 60 * 5, // 5 minutes
  });

  return {
    data,
    isLoading: isInitialLoading || isLoading || isFetching,
    isError,
    error,
    refetch,
  };
};
