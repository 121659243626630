import axios from 'api/axios';
import { BASE_ENDPOINTS } from 'api/endpoints';
import { AxiosResponse } from 'axios';
import type { FinancingProviderDictionary, Dictionary } from 'types/offerTypes';

type DictionaryTypes = 'FINANCING_PROVIDER' | 'LOGISTIC_PARTNER';

export interface IGetDictionaryParams {
  types: DictionaryTypes[];
}

type IDictionaryResponse = {
  FINANCING_PROVIDER?: FinancingProviderDictionary[];
  LOGISTIC_PARTNER?: Dictionary[];
};

export const getDictionary = async (params: IGetDictionaryParams) => {
  const { types } = params;

  const res: AxiosResponse<IDictionaryResponse> = await axios({
    method: 'get',
    url: BASE_ENDPOINTS.getDictionary,
    params: {
      types: types.join(','),
    },
  });

  if (res.status === 200) {
    return res.data;
  }

  throw new Error(`Cannot get dictionary. Status: ${res.status}`);
};
