import { Dictionary } from 'SHARED/types/offerTypes';

interface IParams {
  paymentTerms?: Dictionary | null;
  paymentOffset?: Dictionary | null;
  isPreview?: boolean;
}

export function readablePaymentTerms({
  paymentTerms,
  paymentOffset,
  isPreview = false,
}: IParams) {
  if (isPreview && paymentTerms && paymentOffset) {
    const label = paymentTerms.label.replace('X', paymentOffset.value);
    return {
      ...paymentTerms,
      label,
    };
  }

  return paymentTerms;
}
