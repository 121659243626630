import { formatMoney, getUserName } from 'services/Utils';
import {
  DestinationLocation, FinancingOption, Organization, OwnerUser,
} from 'types/offerTypes';
import { IOption } from 'validators/common/optionSchema';
import { sortBy } from 'lodash';
import { UseFormResetField } from 'react-hook-form';

export const createAndDownloadFile = (data: Blob, fileName: string) => {
  const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName); // or any other extension

  // Simulate a click event without appending the link to the DOM
  const clickEvent = new MouseEvent('click', {
    view: window,
    bubbles: true,
    cancelable: true,
  });

  // Dispatch the event (trigger the download)
  link.dispatchEvent(clickEvent);

  // Cleanup
  URL.revokeObjectURL(url);
};

export const orgsToOptions = (orgs: Organization[] | undefined): IOption[] => {
  if (!orgs) return [];
  return orgs.map((org) => ({
    label: org.orgName,
    value: `${org.id}`,
  }));
};

export const usersToOptions = (
  users: OwnerUser[] | undefined,
  activeOnly: boolean = false,
): IOption[] => {
  if (!users) return [];
  let usersArr = users;

  if (activeOnly) {
    usersArr = users?.filter((user) => user.status === 'ACTIVE');
  }

  return usersArr.map((user) => ({
    label: getUserName(user),
    value: `${user.id}`,
  }));
};

export const destinationLocationsToOptions = (locations: DestinationLocation[] | undefined): IOption[] => {
  if (!locations) return [];
  return locations.map((location) => ({
    label: `${location.address}, (${location.type === 'PORT' ? 'SEA' : 'LAND'})`,
    value: `${location.id}`,
  }));
};

export const numbersArrayToOptions = (arr: number[] | undefined): IOption[] => {
  if (!arr) return [];

  const newArr = arr.map((item) => ({
    label: `${item}`,
    value: item,
  }));

  return sortBy(newArr, ['value']);
};

export function resetDependentFields(
  fields: string[] = [],
  resetHandler: UseFormResetField<any>,
) {
  for (const field of fields) {
    resetHandler(field);
  }
}

function filterFinancingPrices(
  prices: FinancingOption[] | undefined,
  type: 'SELLER' | 'THIRD_PARTY' | 'ALL' = 'ALL',
) {
  if (!prices) return [];

  switch (type) {
    case 'SELLER':
      return prices.filter((item) => item.partner.label.toLowerCase() === 'seller finance');

    case 'THIRD_PARTY':
      return prices.filter((item) => item.partner.label.toLowerCase() !== 'seller finance');

    default:
      return prices;
  }
}

export function financingPricesToOptions(
  prices: FinancingOption[] | undefined,
  filter: 'ALL' | 'SELLER' | 'THIRD_PARTY' = 'ALL',
  currency: 'USD' | 'EUR' = 'EUR',
) {
  if (!prices) return [];

  const filteredPrices = filterFinancingPrices(prices, filter);

  const newArr = filteredPrices.map((item) => ({
    value: item?.id,
    label: `${item?.partner?.label}. ${formatMoney(item?.price)} ${currency}/MT`,
  }));

  return newArr;
}

export function getSellerFinancePrice(
  prices: FinancingOption[] | undefined,
) {
  if (!prices) return null;

  const filteredPrices = filterFinancingPrices(prices, 'SELLER');

  return filteredPrices[0];
}
