import { useQuery } from '@tanstack/react-query';
import { getOrgUsers } from 'api/get/getOrgUsers';
import { toast } from 'react-hot-toast';
import type { IUnknownId } from 'types/common';

export const useOrgUsersQuery = (orgId: IUnknownId) => useQuery({
  queryKey: ['users', orgId],
  queryFn: () => getOrgUsers(orgId),
  onError: (error) => {
    console.error(error);
    toast.error('Error loading users');
  },
  enabled: !!orgId,
});
