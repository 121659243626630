import React, { useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Preloader from 'SHARED/components/ThePreloader';
import { scrollToTop } from 'SHARED/helpers/common';
import { useTypedSelector } from 'SHARED/redux/hooks/useTypedSelector';
import { useActions } from 'SHARED/redux/hooks/useActions';
import routes from 'SHARED/types/routes';
import { useUserType } from 'SHARED/hooks/useUserType';
import toast from 'react-hot-toast';
import PreventLossUnsavedData from 'SHARED/hooks/usePreventReload';
import { AuctionSummaryAdmin } from '../auction-summary-admin';

export const AuctionCreatePreviewAdminPage: React.FC = () => {
  const { isAdmin } = useUserType();
  const history = useHistory();
  const { OFF_SPEC, loading } = useTypedSelector((state) => state.offer);
  const {
    createOffer,
    resetOffer,
  } = useActions();

  PreventLossUnsavedData(true);

  const handleChangeOffer = () => {
    history.push(routes.admin.auction_create);
  };

  async function handlePlaceOrder() {
    const res: any = await createOffer('OFF_SPEC');
    console.log('res', res);
    console.log('res.payload', res.payload);

    if (res.payload === true) {
      history.push(routes.admin.auction_list_active);
      resetOffer();
    } else {
      toast.error(res.payload);
    }
  }

  useEffect(() => {
    if (OFF_SPEC.title === '') {
      history.push(routes.admin.auction_create);
    }

    scrollToTop();
  }, []);

  if (!isAdmin) {
    return <>You are not authorized to view this page.</>;
  }

  return (
    <>
      <Preloader isLoading={loading} />

      <div className="breadcrumbs">
        <Link to="/offspec/offers/">&lt; All auctions</Link>
      </div>

      <div className="page-body is-logged-in preview-offer-page">

        <h1 className="page-title">Create an auction</h1>

        <div className="attention-message inset">
          Great, you&apos;re almost done! Please review your auction before you continue.
        </div>

        <AuctionSummaryAdmin mode="preview" />

        <div className="attention-message success inset">
          <div className="page-section-title">Place this auction</div>
          <div>
            By placing this offer you agree to the OpenDairy platform terms.
            <br />
            If bids are made at the end of the auction you
            have the legal obligation to sell to the highest bidder.
            <br />
            Once placed, offers can not be changed.
          </div>

          <div className="actions-section">
            <button type="button" className="btn-primary" onClick={handlePlaceOrder}>Place offer</button>

            <button
              onClick={() => { handleChangeOffer(); }}
              type="button"
              className="btn-secondary"
            >
              Change offer
            </button>

            <a href="/" className="btn-secondary">Discard</a>
          </div>
        </div>

      </div>
    </>
  );
};
