import React from 'react';
import { CharacteristicItem } from 'types/specsTypes';

export interface ICharacteristicPreviewProps {
  characteristic: CharacteristicItem,
  className?: string,
  isForBuyer?: boolean,
  styles?: React.CSSProperties,
}

const CharacteristicPreview: React.FC<ICharacteristicPreviewProps> = (props) => {
  const { characteristic, className = '', isForBuyer = false } = props;

  const {
    measurementValueType,
    characteristicType,
    measurementUnit,
    notTested,
    value,
    valueTo,
    range,
  } = characteristic;

  const notTestedText = isForBuyer ? 'Not Required' : 'Not tested';

  const lovercaseSuffixes = ['M_M_PERCENT', 'PERCENT', 'ML'];

  return (
    <div className={`data-preview-row ${className}`} style={props.styles}>
      <div className="label-text">{characteristicType.label}</div>

      {notTested && <div>{notTestedText}</div> }

      {!notTested && (
        <div>
          <span className={`prefix-${measurementValueType.toLowerCase()}`}>
            {measurementValueType === 'RANGE' ? '' : measurementValueType.replace('_', ' ').toLowerCase()}
          </span>

          {range ? `min. ${value} - max. ${valueTo}` : ` ${value}`}

          <span className={lovercaseSuffixes.includes(measurementUnit.value) ? 'normal-suffix' : 'uppercase-suffix'}>
            {` ${measurementUnit.label}`}
          </span>
        </div>
      ) }

    </div>
  );
};

export default CharacteristicPreview;
