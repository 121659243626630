/* eslint-disable react/jsx-props-no-spreading */
import React, { memo }    from 'react';
import { useFormContext } from 'react-hook-form';

interface RadioProps {
  name: string,
  value: string,
  selected: string,
  rules?: any,
  label: string,
  title?: string,
  disabled?: boolean,
}

const Radio: React.FC<RadioProps> = (props) => {
  const { register, formState: { errors } } = useFormContext();

  const {
    name, rules = undefined, label, value, disabled = false, selected,
  } = props;

  return (
    <div className={`${disabled ? 'disabled' : ''} ${errors[name] ? 'invalid' : 'valid'}`}>
      <div className="radio-group-wrapper">
        <div className="custominput" key={value}>
          <input
            defaultChecked={selected === value}
            type="radio"
            id={`${name}-${value}`}
            value={value}
            {...register(name, rules)}
          />
          <label htmlFor={`${name}-${value}`}>{label}</label>
        </div>

      </div>

      {
        errors[name]
        && <div className="error-message">{errors[name]?.message}</div>
      }

    </div>
  );
};

export default memo(Radio, (prev, next) => prev.title === next.title);
