import React, { FC } from 'react';
import { Typography } from '@material-ui/core';
import TextField from 'components/controls/TextField';
import { IOffspecOffer } from 'SHARED/types/offerTypes';
import { getFullName } from 'SHARED/helpers/common';

interface IProps {
  offer: IOffspecOffer
}

const SellerBlock: FC<IProps> = ({ offer }) => (
  <>
    <Typography variant="h5">Seller</Typography>
    <TextField label="SELLER COMPANY" value={offer.ownerOrg?.orgName} />
    <TextField label="COUNTRY" value={offer.ownerOrg?.country} />
    <TextField label="SELLER NAME" value={getFullName(offer.deal?.seller?.profile)} />
    <TextField label="EMAIL" value={offer.deal?.seller?.profile?.email} />
    <TextField label="TELEPHONE" value={offer.deal?.seller?.profile?.phoneNumber} />
  </>
);

export default SellerBlock;
