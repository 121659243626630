import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { FormProvider, useForm } from 'react-hook-form';
import { Link, useHistory, useParams } from 'react-router-dom';
import FormInput from 'SHARED/components/FormInput';
import FormSelect from 'SHARED/components/Select';
import routes, { admin_routes } from 'SHARED/types/routes';
import FormFileInput from 'SHARED/components/FileInput/FormFileInput';
import { DevTool } from '@hookform/devtools';
import { codeValueToOption, getFullName, isDevEnv, optionToCodeValue } from 'SHARED/helpers/common';
import PreventLossUnsavedData from 'SHARED/hooks/usePreventReload';
import { IProvideDocumentForm, provideDocumentFormSchema, provideDocumentFormSchemaAdmin } from 'SHARED/validators/documents/provideDocumentFormSchema';
import { uploadDocumentsFiles } from 'SHARED/api/common/post/uploadFiles';
import { deleteDocumentsFile } from 'SHARED/api/common/delete/deleteFile';
import getDealData from 'SHARED/api/deals/get/getDealData';
import uploadDocument from 'SHARED/api/documents/post/uploadDocument';
import Preloader from 'SHARED/components/ThePreloader';
import notification from 'SHARED/helpers/notifications';
import { useTypedSelector } from 'SHARED/redux/hooks/useTypedSelector';
import { useActions } from 'SHARED/redux/hooks/useActions';
import clsx from 'clsx';
import { IDocumentType } from 'SHARED/validators/documents/documentTypeSchema';
import { Dictionary, InputOption, OnSpecOffer } from 'SHARED/types/offerTypes';
import useQueryItem from 'SHARED/hooks/useQueryItem';
import { IUploadDocumentPayload } from 'SHARED/validators/documents/uploadDocumentSchema';
import validationRules from 'SHARED/helpers/validation';
import { useUserType } from 'SHARED/hooks/useUserType';
import RadioGroup from 'SHARED/components/RadioGroup/RadioGroupV2';
import { getOrgUsers } from 'SHARED/api/common/get/getOrgUsers';

const initialFormValues = {
  documentType: { value: '', label: '', isDisabled: false },
  file: [],
  documentTitle: '',
  comment: '',
} as any;

const orgTypeOptions: InputOption[] = [
  { value: 'SELLER', label: 'Seller' },
  { value: 'BUYER', label: 'Buyer' },
];

interface IRouteParams {
  dealId: string,
}

const OfferProvideDocument = () => {
  const { dealId } = useParams<IRouteParams>();
  const history = useHistory();
  const threadId = useQueryItem('threadId');

  const { isAdmin } = useUserType();

  const methods = useForm<IProvideDocumentForm>({
    defaultValues: initialFormValues,
  });
  const { formState: { isDirty, isSubmitting }, watch, setValue } = methods;

  const { documentTypes, isDocumentsLoading, documents } = useTypedSelector((state) => state.documents);
  const { getDocumentTypes, resetDocuments, getDocuments } = useActions();

  const organizationType = watch('organizationType') as InputOption | undefined;
  const documentType = watch('documentType');
  const isOtherDocumentType = documentType?.value === 'other_document';

  const isSellerOrg = organizationType?.value === 'SELLER';

  const [dealData, setDealData] = useState<OnSpecOffer | null>(null);
  const [isValidDeal, setIsValidDeal] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isThreadUpdate, setIsThreadUpdate] = useState<boolean>(false);
  const [isThreadUpdateError, setIsThreadUpdateError] = useState<boolean>(false);
  const [sellerOrgUsers, setSellerOrgUsers] = useState<Dictionary[]>([]);
  const [buyerOrgUsers, setBuyerOrgUsers] = useState<Dictionary[]>([]);

  const isDisabled = isSubmitting || isLoading || !isValidDeal || isThreadUpdateError;
  const isForRfp = dealData?.parentOffer?.type?.value === 'RFP';
  const getDocumentsTabRoute = () => {
    if (isAdmin) {
      return admin_routes.goto_offerDealPage_documents(dealId);
    }

    if (isForRfp) {
      return routes.rfp.goto_dealDetail_documents(dealId);
    }

    return routes.onspec.goto_dealDetail_documents(dealId);
  };

  // check if document type already got it's own thread
  function isDocumentHasOwnThread(type: IDocumentType) {
    // regular document types, not 'other_document'
    const regularTypeMatch = documents.some((doc) => (
      (type.code !== 'other_document') && (doc.documentType.code === type.code)
    ));

    return regularTypeMatch;
  }

  // if we're updating existing thread
  function handleExistingThread() {
    if (threadId && !!documents.length && !!documentTypes.length) {
      setIsThreadUpdate(true);

      const document = documents.find((doc) => doc.id === Number(threadId));
      const typeMatch = documentTypes.find((type) => type.code === document?.documentType.code);

      if (document && typeMatch) {
        setIsThreadUpdateError(false);

        // other_document type has custom title, so form value should be the one from types list
        if (document.documentType.code === 'other_document') {
          setValue('documentType', codeValueToOption(typeMatch));
          // other_document type requires documentTitle pre-filled
          setValue('documentTitle', document.documentType.title);
        } else {
          setValue('documentType', codeValueToOption(document.documentType));
        }
      } else {
        setIsThreadUpdateError(true);
      }
    } else {
      setIsThreadUpdate(false);
    }
  }

  function onSubmit(data: IProvideDocumentForm) {
    // validate form data, since we don't have schema validation for form
    const validateClient = provideDocumentFormSchema.safeParse(data);
    const validateAdmin = provideDocumentFormSchemaAdmin.safeParse(data);

    const validationResult = isAdmin ? validateAdmin : validateClient;

    if (!validationResult.success) {
      notification({
        type: 'danger',
        message: 'Form data validation failed',
      });

      return;
    }

    const validatedData = validationResult.data;

    const payload: IUploadDocumentPayload = {
      dealId: Number(dealId),
      documentType: optionToCodeValue(data.documentType),
      file: data.file[0],
      comment: validatedData.comment,
    };

    // if we're updating existing thread - add threadId to payload
    if (threadId) {
      payload.threadId = Number(threadId);
    }

    // if documentType is other_document - set documentType.code to 'documentTitle' from form
    if (validatedData.documentType.value === 'other_document' && validatedData.documentTitle) {
      payload.documentType.title = validatedData.documentTitle;
    }

    // remove comment if it's empty
    if (!payload.comment) {
      delete payload.comment;
    }

    // if ADMIN - add fromUserId to payload
    // so backend will know in whose name document is uploaded
    if (isAdmin && validateAdmin.success) {
      payload.fromUserId = validateAdmin.data.user?.value;
    }

    // then pass it to handleUploadDocument
    setIsLoading(true);
    uploadDocument(payload)
      .then((res) => {
        if (res === 'success') {
          // reset(initialFormValues);
          history.push(getDocumentsTabRoute());
        }
      })
      .finally(() => setIsLoading(false));
  }

  // Prevent loss of unsaved data message
  PreventLossUnsavedData(isDirty);

  // get and prepare for form users for orgs
  async function getUsersOptions() {
    if (!dealData || !isAdmin) return;

    const sellerId = dealData?.deal?.sellerOrg?.id;
    const buyerId = dealData?.deal?.buyerOrg?.id;

    if (!sellerId || !buyerId) return;

    setIsLoading(true);
    const sellerUsers = await getOrgUsers(sellerId);
    const buyerUsers = await getOrgUsers(buyerId);

    if (sellerUsers.isError || buyerUsers.isError) return;

    const sellerOptions = sellerUsers?.data?.map((user) => ({
      value: user.externalId,
      label: getFullName(user.profile),
    }));

    const buyerOptions = buyerUsers?.data?.map((user) => ({
      value: user.externalId,
      label: getFullName(user.profile),
    }));

    setSellerOrgUsers(sellerOptions);
    setBuyerOrgUsers(buyerOptions);

    setIsLoading(false);
  }

  // get deal data
  useEffect(() => {
    if (dealId) {
      setIsLoading(true);

      getDocuments(dealId);

      getDealData(dealId)
        .then((dealRes) => {
          if (dealRes) {
            setDealData(dealRes);
            setIsValidDeal(true);
          } else {
            setIsValidDeal(false);
          }
        })
        .catch(() => setIsValidDeal(false))
        .finally(() => setIsLoading(false));
    }
  }, [dealId]);

  useEffect(() => {
    if (isAdmin) { getUsersOptions(); }
  }, [dealData]);

  useEffect(() => {
    if (isAdmin) { setValue('user', null!); }
  }, [organizationType]);

  useEffect(() => {
    getDocumentTypes();

    return () => {
      resetDocuments();
    };
  }, []);

  useEffect(() => {
    handleExistingThread();
  }, [threadId, documents, documentTypes]);

  return (
    <>
      <Helmet>
        <title>Provide a document for the offers deal</title>
      </Helmet>

      <Preloader isLoading={isLoading || isDocumentsLoading} />

      <div className="breadcrumbs">
        <Link to={getDocumentsTabRoute()}>&lt; Deal </Link>
      </div>

      <div className="page-body is-logged-in preview-offer-page">
        {!isValidDeal && (
          <div className="attention-message alert inset info-bar">
            Deal is not valid. You can not upload documents to this deal.
          </div>
        )}
        {isThreadUpdate && !isThreadUpdateError && (
          <div className="attention-message inset info-bar">
            You are updating existing document thread.
          </div>
        )}
        {isThreadUpdateError && (
          <div className="attention-message alert inset info-bar">
            You&apos;re trying to update document thread that doesn&apos;t exist.
          </div>
        )}

        <h1 className="page-title defined">
          Upload document
        </h1>

        {isDevEnv && <DevTool control={methods.control} placement="top-right" />}

        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)} className="pb-3">

            {isAdmin && (
              <>
                <RadioGroup
                  label="Uploading for"
                  name="organizationType"
                  defaultValue={orgTypeOptions[0]}
                  values={orgTypeOptions}
                />

                <FormSelect
                  label="Uploading for the name of the user"
                  name="user"
                  options={isSellerOrg ? sellerOrgUsers : buyerOrgUsers}
                  selected={null}
                  disabled={isDisabled}
                />
              </>
            )}

            <FormSelect
              label="SELECT DOCUMENT TYPE"
              tooltip="If you already uploaded document of some type, you can not upload another one of the same type."
              name="documentType"
              options={
                documentTypes
                  .filter((type) => type.actions.upload)
                  .map((type) => ({
                    ...codeValueToOption(type),
                    isDisabled: isAdmin ? false : isDocumentHasOwnThread(type),
                  }))
              }
              selected={null}
              disabled={isDisabled || isThreadUpdate}
              rules={{
                required: 'Document type is required',
                validate: (value: Dictionary) => (!!value.label && !!value.value) || 'Document type is required',
              }}
            />

            {/* Needed only for the `other_document` type */}
            {isOtherDocumentType && (
              <FormInput
                name="documentTitle"
                label="DOCUMENT TITLE (in english)"
                tooltip="Up to 40 characters. Title should be uniaue for this deal."
                rules={{
                  required: isOtherDocumentType ? '“Other document” type requires title' : false,
                  shouldUnregister: true,
                  deps: ['documentType'],
                  validate: (value: string) => (isThreadUpdate ? true : validationRules.otherDocumentThreadTitle(value, documents, documentTypes)),
                }}
                disabled={isDisabled || isThreadUpdate}
                maxLength={40}
              />
            )}

            <FormFileInput
              name="file"
              label="Document"
              tooltip="Supported file types are PDF, Excel, Word, Images. Maximum file size: 20MB"
              handleUploadFiles={uploadDocumentsFiles}
              handleDeleteFile={deleteDocumentsFile}
              required
              disabled={isDisabled}
            />

            <FormInput
              name="comment"
              label="REMARKS FOR THE RECEIVER"
              textarea
              disabled={isDisabled}
            />

            <div className="btn-wrapper buttons-wrapper m-full-width">
              <button type="submit" className="btn-primary" disabled={isDisabled}>Upload document</button>

              <Link
                to={getDocumentsTabRoute()}
                className={clsx('btn-secondary', { disabled: isDisabled })}
              >
                Discard
              </Link>
            </div>

          </form>
        </FormProvider>

      </div>
    </>
  );
};

export default OfferProvideDocument;
