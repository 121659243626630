import { IDataCollection, BuyersFetchType, Dictionary } from 'SHARED/types/offerTypes';

export const salesTerms:Dictionary[] = [
  { label: 'MPC', value: 'MPC' },
  { label: 'Seller own terms', value: 'OWN' },
];

interface FetchType {
  label: string, value: BuyersFetchType, disabled: boolean
}

export const buyersFetchType: FetchType[] = [
  { label: 'All buyers', value: 'INCLUDE_ALL', disabled: false },
  { label: 'Buyers in my Buyer Groups', value: 'INCLUDE_GROUP', disabled: false },
  { label: 'All buyers, excluding', value: 'EXCLUDE', disabled: false },
];

export const departurePeriod:Dictionary[] = [{ label: 'Month', value: 'MONTH' }, { value: 'PERIOD', label: 'Specific dates' }];

export const volumeUnits:Dictionary = {
  label: 'MT',
  value: 'MT',
};

const OfferData: { [key: string]: IDataCollection[] } = {
  salesTerms,
  buyersFetchType,
  departurePeriod,
};

export default OfferData;
