import React, { FC } from 'react';
import { Typography } from '@material-ui/core';
import { dateTime, prettyDateTimeFormat } from 'services/Utils';
import TextField from 'components/controls/TextField';
import { IOffspecOffer } from 'SHARED/types/offerTypes';

interface IProps {
  offer: IOffspecOffer
}

const OfferBlock: FC<IProps> = ({ offer }) => (
  <>
    <Typography variant="h5">Offer</Typography>
    <TextField label="OFFER ID" value={offer.id} />
    <TextField label="OFFER TYPE" value={offer.type?.label} />
    <TextField label="OFFER PLACED" value={dateTime(offer.created, prettyDateTimeFormat)} />
    <TextField label="STATUS" value={offer.status} />
    { offer.status === 'CANCELLED' && <TextField label="CANCELLATION REASON" value={offer.cancellationReason} /> }
  </>
);

export default OfferBlock;
