import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Paper } from '@material-ui/core';
import useLogisticsPricesStore from 'store/addLogisticsPrices';
import clsx from 'clsx';
import AddNewPriceForm from './addPrice/AddNewPriceForm';
import LanesPreview from './LanesPreview/LanesPreview';
import SelectLaneForm from './SelectLane/SelectLaneForm';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(3),
    maxWidth: '1680px',
    marginBottom: '20px',
  },

  fistBlock: {
    marginBottom: '40px',
  },
}));

const LogisticsPricesAdd = () => {
  const { resetStore, laneData } = useLogisticsPricesStore((state) => state);
  const classes = useStyles();

  useEffect(() => {
    resetStore();

    return () => {
      resetStore();
    };
  }, []);

  return (
    <div className="logistics-prices-page page-container">
      <h1 className="visually-hidden">Add price for logistics lane</h1>

      <Paper className={clsx(classes.container, classes.fistBlock, 'logistics-prices-page__lane-form')}>
        <SelectLaneForm />
      </Paper>

      {!!laneData.length && (
        <>
          <Paper className={classes.container}>
            <LanesPreview />
          </Paper>

          <Paper className={classes.container}>
            <AddNewPriceForm />
          </Paper>
        </>
      )}

    </div>
  );
};

export default LogisticsPricesAdd;
