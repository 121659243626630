import { InputAdornment, Typography } from '@material-ui/core';
import React, { useEffect } from 'react';
import { Form, useForm } from '../../hooks/useForm';
import Controls from '../controls/Controls';

const priceTypeOptions = [
  { label: 'Premium', value: 'PREMIUM' },
  { label: 'Discount', value: 'DISCOUNT' },
  { label: 'None', value: 'NONE' },
];

export interface PackagingDifferentialProps {
  item: any,
  array: Array<any>
  setArray: (val: any) => void
}

export default function PackagingDifferential(props: PackagingDifferentialProps) {
  const { item, array, setArray } = props;
  const {
    values, errors, setErrors, handleInputChange,
  } = useForm(item);

  useEffect(() => {
    validate();
    const temp = array;
    const index = array.findIndex((it: any) => it.packaging.value === values.packaging.value);
    temp[index] = { ...values };
    setArray(temp);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  const validate = () => {
    setErrors({});
    const tmp: any = {};
    if (values.priceType !== 'NONE') {
      ['priceEur', 'priceUsd'].forEach((key) => {
        if (!+values[key]) {
          tmp[key] = 'Price must be set in both currencies';
        }
      });
      setErrors(tmp);
    }

    return Object.keys(tmp).length === 0;
  };

  return (
    <Form name="diff-item">
      <Typography variant="h6">{item.packaging?.label}</Typography>
      <Controls.RadioGroup
        name="priceType"
        value={values.priceType}
        items={priceTypeOptions}
        onChange={handleInputChange}
      />
      {
        values.priceType !== 'NONE' && (
        <div style={{ display: 'flex' }}>
          <Controls.Input
            name="priceEur"
            label="EUR PRICE"
            value={values.priceEur}
            error={errors.priceEur}
            style={{ width: '24%' }}
            onChange={handleInputChange}
            type="number"
            InputProps={{
              endAdornment: <InputAdornment position="end">EUR/MT</InputAdornment>,
              inputProps: { min: 0 },
            }}
            required
          />
          <Controls.Input
            name="priceUsd"
            label="USD PRICE"
            value={values.priceUsd}
            error={errors.priceUsd}
            style={{ width: '25%' }}
            onChange={handleInputChange}
            type="number"
            InputProps={{
              endAdornment: <InputAdornment position="end">USD/MT</InputAdornment>,
              inputProps: { min: 0 },
            }}
            required
          />
        </div>
        )
      }
    </Form>
  );
}
