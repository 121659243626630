import { Typography } from '@material-ui/core';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import api from '../../services/api';
import { emptyProductSpec } from '../../services/api/ProductSpecService';
import { formatMoney, joinOptions } from '../../services/Utils';
import TextField from '../controls/TextField';
import Modal from '../Modal';

export interface ProductDetailsPopupProps {
  productId: number,
  open: boolean,
  setOpen: any
}

export default function ProductDetailsPopup(props: ProductDetailsPopupProps) {
  const { productId, open, setOpen } = props;
  const [product, setProduct] = useState<any>(emptyProductSpec);
  const [fields, setFields] = useState([]);
  const [orgType, setOrgType] = useState('');
  useEffect(
    () => { api.productSpec.getById(productId).then(setProduct).catch(); },
    [productId],
  );
  useEffect(() => {
    if (product.characteristics.length > 0) {
      const groups = _.groupBy(product.characteristics, (it) => it.displayGroup.label);
      // @ts-ignore
      setFields(groups);
    }
    if (product.organizationId) {
      api.organization.getOrganization(product.organizationId, 'SELLER')
        .then(() => setOrgType('SELLER'))
        .catch(() => setOrgType('BUYER')); // check org type by id
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product.id]);

  const getSpecLabel = (item: any) => `${item.characteristicType.label} (${item.minValue} - ${item.maxValue})`;
  const getSpecValue = (item: any) => {
    if (item.notTested) {
      return 'Not required';
    }
    if (item.range) {
      return `MIN ${item.minValue} ${item.measurementUnit.label} - ${item.maxValue} ${item.measurementUnit.label} MAX`;
    }

    return `${item.value} ${item.measurementUnit.label}`;
  };

  const renderSpecItem = (item: any) => (
    <TextField
      label={getSpecLabel(item)}
      value={getSpecValue(item)}
    />
  );

  return (
    <Modal title={`${product.title} (${product.number})`} open={open} setOpen={setOpen}>
      <div style={{ display: 'flex' }}>
        <div style={{ width: '25%' }}>
          <TextField label="PRODUCT CATEGORY" value={product.productCategory?.label} />
          <TextField label="PRODUCT TYPE" value={product.productType?.label} />
          { orgType === 'BUYER' && (
            <>
              <TextField label="PACKAGING" value={product.packaging?.label} />
              <TextField label="REQUIRED DOCUMENTS" value={joinOptions(product.requiredDocuments)} />
            </>
          ) }
          <TextField label="CUSTOM LABEL" value={product.customLabelRequired ? 'Required' : 'Not required'} />
          { orgType === 'SELLER' && (
            <TextField
              label="PREMIUMS"
              value={`EUR ${formatMoney(product.premiumPriceEur)} | USD ${formatMoney(product.premiumPriceUsd)} / mt`}
            />
          ) }
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-around', width: '-webkit-fill-available' }}>
          { Object.keys(fields).map((it: any) => (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Typography variant="h6">{it}</Typography>
              {
                  // @ts-ignore
                  fields[it].map((f: any) => renderSpecItem(f))
                }
            </div>
          ))}
        </div>
      </div>
    </Modal>
  );
}
