import React, { FC } from 'react';
import { amsDateWithTimezone, getUserName, joinOptions } from 'services/Utils';
import ProductSpecsDetails from 'components/partials/ProductSpecsDetails';
import prepareFinancingDetails from 'helpers/prepareFinancingDetails';
import OfferSalesTermsField from 'components/OfferSalesTermsField';
import FormattedPrice from 'SHARED/components/Price';
import { OnSpecOffer } from 'SHARED/types/offerTypes';
import DataItemPreview from 'SHARED/components/DataItemPreview';

interface IProps {
  offer: OnSpecOffer | undefined | null
}

const DealFieldsOffer: FC<IProps> = ({ offer }) => {
  const isSimpleOffer = offer?.type?.value === 'SIMPLE_ALREADY_PRODUCED';

  // encapsulated data
  const dealVolume = offer?.deal?.volume || 0;
  const dealPrice = offer?.deal?.price || 0;
  const dealCurrency = offer?.deal?.currency || 'EUR';
  const dealVolumeUnits = offer?.deal?.volumeUnits || 'MT';

  const sellerOrg = offer?.deal?.sellerOrg?.orgName;
  const sellerCountry = offer?.deal?.sellerOrg?.country;
  const sellerName = getUserName(offer?.deal?.seller);
  const sellerEmail = offer?.deal?.seller?.profile?.email;
  const sellerPhone = offer?.deal?.seller?.profile?.phoneNumber;

  const buyerOrg = offer?.deal?.buyerOrg?.orgName;
  const buyerCountry = offer?.deal?.buyerOrg?.country;
  const buyerName = getUserName(offer?.deal?.buyer);
  const buyerEmail = offer?.deal?.buyer?.profile?.email;
  const buyerPhone = offer?.deal?.buyer?.profile?.phoneNumber;

  const sellerIncoterms = offer?.incoterms?.label;
  const sellerLocation = offer?.deal?.shipping?.departureLocation?.address || offer?.logisticLocationNote;
  const buyerIncoterms = offer?.deal?.shipping?.destinationLocation?.incoterms?.label || sellerIncoterms;
  const buyerLocation = offer?.deal?.shipping?.destinationLocation?.address;
  const requestedDelivery = `${offer?.departureFrom} - ${offer?.departureTo}`;
  const logisticsProviderValue = offer?.deal?.shipping?.logisticHandler?.label || 'N/A';
  const logisticsCost = offer?.deal?.shipping?.logisticPrice;
  const logisticsTotalCost = offer?.deal?.shipping?.logisticAmount;

  const sellerPaymentTerms = offer?.deal?.financing?.sellerPaymentTerm?.label || offer?.paymentTerms?.label;
  const buyerPaymentTerms = offer?.deal?.financing?.buyerPaymentTerm?.label;
  const financingProvider = offer?.deal?.financing?.financingProvider?.label || 'N/A';
  const financingInfo = prepareFinancingDetails({
    currency: offer?.deal?.currency,
    creditInterestRate: offer?.deal?.financing?.creditInterestRate,
    commissionPerUnit: offer?.deal?.financing?.commissionPerUnit,
    commissionPerDeal: offer?.deal?.financing?.commissionPerDeal,
  });
  const financingCost = offer?.deal?.financing?.costOfFinancing;
  const financingTotalCost = offer?.deal?.financing?.totalCostOfFinancing;
  // encapsulated data === END

  if (!offer) {
    return null;
  }

  return (
    <>
      {/* grid 1 */}
      <div className="details-grid">
        <div className="details-col">
          <h2>Deal</h2>
          <DataItemPreview title="Deal ID" item={offer?.deal?.number} />
          <DataItemPreview
            title="Deal done on"
            item={amsDateWithTimezone(offer?.deal?.created)}
          />
          <DataItemPreview
            title="Volume"
            item={dealVolume}
            suffix={dealVolumeUnits}
          />
          <FormattedPrice
            title="Deal product price"
            value={dealPrice}
            prefix={dealCurrency}
            suffix={`/${dealVolumeUnits}`}
          />
          <FormattedPrice
            title="Total product price"
            value={dealPrice * dealVolume}
            prefix={dealCurrency}
          />
        </div>

        <div className="details-col">
          <h2>
            Offer
          </h2>
          <DataItemPreview title="Offer type" item={offer?.type?.label} />
          <DataItemPreview title="ID" item={offer?.id} />
          <DataItemPreview
            title="Offer placed"
            item={amsDateWithTimezone(offer?.created)}
          />
          <DataItemPreview
            title="Offer expiration date"
            item={amsDateWithTimezone(offer?.endDate)}
          />
          <DataItemPreview title="Status" item={offer?.statusLabel} />
        </div>

        <div className="details-col">
          <h2>Product</h2>
          <DataItemPreview
            title="Seller product specification"
          >
            <ProductSpecsDetails
              characteristics={offer?.productCharacteristics}
              coaUsed={offer?.coaUsed}
              linkTitle={offer?.productType?.label}
            />
          </DataItemPreview>
          <DataItemPreview title="Documents included" item={joinOptions(offer?.documentCapabilities)} />
          {!isSimpleOffer && (
            <DataItemPreview
              title="Production location"
              item={offer?.productionLocation?.fullAddress}
            />
          )}
          <DataItemPreview
            title="Custom label"
            item={offer?.customLabelPossible ? 'Custom label possible' : 'Custom label not possible'}
          />

          {/* {renderMPCLink()} */}
          <OfferSalesTermsField offer={offer} />
        </div>
      </div>

      {/* grid 2 */}
      <div className="details-grid">
        <div className="details-col">
          <h2>Seller</h2>
          <DataItemPreview title="Seller company" item={sellerOrg} />
          <DataItemPreview title="Country" item={sellerCountry} />
          <DataItemPreview title="Seller name" item={sellerName} />
          <DataItemPreview title="Seller email" item={sellerEmail} />
          <DataItemPreview title="Seller phone" item={sellerPhone} />
        </div>

        <div className="details-col">
          <h2>Buyer</h2>
          <DataItemPreview title="Buyer company" item={buyerOrg} />
          <DataItemPreview title="Country" item={buyerCountry} />
          <DataItemPreview title="Buyer name" item={buyerName} />
          <DataItemPreview title="Buyer email" item={buyerEmail} />
          <DataItemPreview title="Buyer phone" item={buyerPhone} />
        </div>

        <div className="details-col">
          <h2>Shipping & Logistics</h2>
          <DataItemPreview title="Seller incoterms" item={sellerIncoterms} />
          <DataItemPreview title="Seller logistics location" item={sellerLocation} />
          {!isSimpleOffer && (
            <>
              <DataItemPreview title="Buyer incoterms" item={buyerIncoterms} />
              <DataItemPreview title="Buyer logistics location" item={buyerLocation} />
            </>
          )}
          <DataItemPreview title="Departure" item={requestedDelivery} />
          {!isSimpleOffer && (
            <>
              <DataItemPreview title="Logistics provider" item={logisticsProviderValue} />
              <FormattedPrice
                title="Cost of logistics"
                value={logisticsCost}
                prefix={dealCurrency}
                suffix={`/${dealVolumeUnits}`}
              />
              <FormattedPrice
                title="Total cost of logistics"
                value={logisticsTotalCost}
                prefix={dealCurrency}
              />
            </>
          )}
        </div>

        <div className="details-col">
          <h2>Financing</h2>
          <DataItemPreview title="Seller payment terms" item={sellerPaymentTerms} />
          {!isSimpleOffer && (
            <>
              <DataItemPreview title="Buyer payment terms" item={buyerPaymentTerms} />
              <DataItemPreview title="Financing provider" item={financingProvider} />
              <DataItemPreview title="Financing terms" item={financingInfo} />
              <FormattedPrice
                title="Cost of financing"
                value={financingCost}
                prefix={dealCurrency}
                suffix={`/${dealVolumeUnits}`}
              />
              <FormattedPrice
                title="Total cost of financing"
                value={financingTotalCost}
                prefix={dealCurrency}
              />
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default DealFieldsOffer;
