const prepareDataAttr = (text: string | undefined) => {
  if (!text) return '';
  return text
    .trim()
    .split(' ')
    .map((word) => word.toLowerCase())
    .join('-');
};

export default prepareDataAttr;
