import React from 'react';
import { useDealQuery } from 'api/queries/useDealQuery';
import Preloader from 'components/od-preloader/Preloader';
import {
  Link, Route, Switch, useHistory, useParams,
} from 'react-router-dom';
// import { OnSpecOffer } from 'types/offerTypes';
import pages from 'navigation/pages';
import { Button } from '@mui/material';
import { Typography, Tab, Tabs } from '@material-ui/core';
import { OnSpecOffer } from 'SHARED/types/offerTypes';
import { Helmet } from 'react-helmet';
import clsx from 'clsx';
import DocumentsTable from 'documents-exchange/DocumentsTable';
import TwilioChat from 'components/live-chat/TwilioChat';
import DealFieldsRfp from './DealFieldsRfp';
import DealFieldsOffer from './DealFieldsOffer';

interface OfferDealPageParams {
  dealId: string
}

const OfferDealPage = () => {
  const { dealId } = useParams<OfferDealPageParams>();
  const { location } = useHistory();
  const { isLoading, data: offer } = useDealQuery<OnSpecOffer>(dealId);

  // flags
  const isRfp = offer?.parentOffer?.type?.value === 'RFP';
  const isActiveOffer = offer?.status === 'ACTIVE';
  // flags === END

  const title = `${isRfp ? 'RFP' : 'Offer'} deal: ${offer?.productCategory?.label} ${offer?.productType?.label}, ${offer?.deal?.volume} ${offer?.volumeUnits?.label}`;
  const offerUrl = () => {
    if (!offer?.id) return '/';

    if (isRfp) {
      return pages.goto_rfpProposal(offer?.id);
    }

    return pages.goto_offerPage(offer?.id);
  };

  const rfpUrl = () => {
    if (!offer?.parentOffer) return '/';

    if (isActiveOffer) {
      return pages.goto_rfpOffer(offer?.parentOffer?.id);
    }

    return pages.goto_rfpOffer_archived(offer?.parentOffer?.id);
  };

  const offerTypeValue = isRfp ? 'Proposal' : 'Offer';

  const backUrl = isRfp ? pages.rfp_deals : pages.offers_deals;
  const detailsUrl = isRfp ? pages.goto_rfp_deal(dealId) : pages.goto_offerDealPage(dealId);
  const documentsUrl = isRfp ? pages.goto_rfp_deal_documents(dealId) : pages.goto_offerDealPage_documents(dealId);

  return (
    <>
      <Preloader isLoading={isLoading} />

      <Helmet>
        <title>{offer?.deal ? title : 'Deal details'}</title>
      </Helmet>

      <div className="page-body">
        <div className="page-title">
          <Typography variant="h5" style={{ flexGrow: 1 }}>
            {offer?.deal ? title : 'Deal details...'}
          </Typography>
          <Button
            variant="contained"
            color="secondary"
            to={backUrl}
            component={Link}
          >
            {'< '}Back to deals
          </Button>
        </div>

        <Tabs value={location.pathname} style={{ marginBottom: 20 }}>
          <Tab
            label="Details"
            value={detailsUrl}
            to={detailsUrl}
            component={Link}
          />
          <Tab
            label="Documents"
            value={documentsUrl}
            to={documentsUrl}
            component={Link}
          />
        </Tabs>

        <Switch>
          <Route path={detailsUrl} exact>
            <>
              {!isRfp && <DealFieldsOffer offer={offer} />}
              {isRfp && <DealFieldsRfp offer={offer} />}

              {!isRfp && !!offer && (
              <Link
                className={clsx(!offer?.id && 'disabled')}
                style={{ fontSize: 16, display: 'inline-flex' }}
                to={pages.goto_offerPage(offer.id)}
                title="View RFP details"
              >
                Show offer details
              </Link>
              )}

              {isRfp && (
              <>
                <Link
                  className={clsx(!offer?.id && 'disabled')}
                  style={{ fontSize: 16, display: 'inline-flex' }}
                  to={offerUrl()}
                  title={`View ${offerTypeValue.toLowerCase()} details`}
                >
                  Show {offerTypeValue.toLowerCase()} details
                </Link>

                <br />

                <Link
                  className={clsx(!offer?.id && 'disabled')}
                  style={{ fontSize: 16, display: 'inline-flex' }}
                  to={rfpUrl()}
                  title="View RFP details"
                >
                  Show RFP details
                </Link>
              </>
              )}
            </>
          </Route>

          <Route path={documentsUrl} exact>
            {!!offer?.deal && <DocumentsTable dealId={offer?.deal?.id} />}
          </Route>
        </Switch>

        <br />

        {!!offer?.deal && <TwilioChat dealId={offer?.deal?.id} />}
      </div>
    </>
  );
};

export default OfferDealPage;
