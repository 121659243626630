export const tooltips = {
  simple_offer_location_note: 'Use this field for the location where the products will be delivered and available for pickup.',
  simple_offer_description: 'Use this text field to describe details of the offer such as production location and date, origin or product expiration date.',
  offer_title: 'Enter the product name as you want it to appear to buyers. Keep it short but clear.',
  offer_desc: 'Please describe your product or any additional terms or information that might not be captured in the COA or sales terms.',
  offspecPrice: 'This is the minimum price (excluding any applicable taxes) at which you are legally obligated to sell your products. Buyers will be able to bid below this price, you can decide to accept a lower bid after the auction.',
  price: 'This is the minimum price (excluding any applicable taxes) you want to receive for your products. Buyers will not be able to bid below this amount.',

  askingPriceEur: 'This is your asking price in EUR. EUR buyers will see this and are able to buy instantly at this price. They can also start a negotiation.',
  askingPriceUsd: 'This is your asking price in USD. USD buyers will see this and are able to buy instantly at this price. They can also start a negotiation.',

  auctionAskPriceEur: 'This is the minimum price in EUR (excluding any applicable taxes) at which you are legally obligated to sell your products. Buyers will be able to bid below this price, you can decide to accept a lower bid after the auction.',
  auctionAskPriceUsd: 'This is the minimum price in USD (excluding any applicable taxes) at which you are legally obligated to sell your products. Buyers will be able to bid below this price, you can decide to accept a lower bid after the auction.',

  COA: 'If you enter the exact values from your COA, you will reach more buyers and have a better chance of selling your product.',
  capabilities: 'Please indicate which documents you are able to deliver with this offer.',
  packaging: 'Please indicate which packaging options you are able to offer to the buyer.',
  bid: 'You are bidding on the total price, so the price including logistics and financing but excluding the OpenDairy platform fees.',
  premiumPriceEur: 'Please enter the premium for this specification in EUR',
  premiumPriceUsd: 'Please enter the premium for this specification in USD.',
  rfp: {
    minVolume: 'If you want to request one volume please enter the same volume in both the minimum and maximum field. ',
    datesRange: 'If you need spread delivery within the departure period, please mention this in the instruction text. Please be aware that longer departure windows limit your chances to receive pricing for logistics.',
    documents: 'Please indicate which documents the seller needs to be able to provide',
    uploadDoc: 'You can use this to upload your terms or provide other documentation.',
    price: 'This is the price (excluding any applicable taxes) you want to receive for your products.',
  },
  auctionMinBidVolume: 'Please indicate the minimum volume a buyer can bid on during the auction.',

  buyer_accept_offer_volume: 'With splittable offers this allows you to change the volume you would like to purchase.',
} as const;

export const specsTexts = {
  buyer: {
    title: 'Our product specifications',
    description: "When a supplier offers a product with characteristics that fit in one of your product specifications, OpenDairy will display this offer as a 'matched offer'. \n\n  You will always be able to view offers that don't match your specifications as well.",
  },
  seller: {
    title: 'Our product specifications',
    description: 'Here you can define your product specifications based on which you offer products on OpenDairy',
  },
  common: {
    allSpecs: 'All product Specifications',
    addSpec: 'Add Specification',
    lactoseTooltip: 'As lactose is calculated by the difference with the other parameters the value shown here is a typical value and not used for matching',
  },
} as const;

export const offerTexts = {
  common: {
    noAddressess: 'Unfortunately, you do not have the right loading location type provided for the selected incoterms. Select another incoterms or contact OpenDairy to add another loading location',
    noCategoryProducts: 'You have no Product Specification for this product category. You can create a new specification in your organisational profile',
    noTypeProducts: 'You have no Product Specification for this product type. You can create a new specification in your organisational profile',
    noContainers: 'You have not setup a logistic type for this product category. Please contact OpenDairy to add container type and volume.',
    departueDateLess: 'Departure date can`t be less of auction expiration date',
    placeBid: 'If you want you can enter into negotiations with the seller on both price and volume. Please keep in mind that during the negotiations other buyers are able to buy the offer.',
    placeBidNonSplittable: 'If you want you can enter into negotiations with the seller on price and, in case of a splittable offer, volume. Please keep in mind that during the negotiations other buyers are able to buy the offer.',
  },
  logistics: {
    noPrices: 'Currently none of our partners can offer logistic options for this trade. Please  change your incoterms or contact us for any assistance.',
    noNeed: 'No logistic is needed with this choice of the incoterms. It matches the choice of the seller.',
  },
} as const;

// export const bidTexts = {
//   title: 'You are about to place a bid!',
//   description: 'After accepting this offer the seller will contact you regarding the handling of the deal. If you have any questions please don &apos; t hesitate to contact us!',
//   agree: 'By placing this bid I commit to purchasing this product at the entered price and the binding result of this blind auction. The highest bid will be automatically confirmed with the seller.',
// };

export const originalOfferTexts = {
  disclaimer: 'Excluding any applicable taxes and the',
} as const;

export const buyerNewBid = {
  description: 'If you want you can enter into price negotiations. Please keep in mind that, during the negotiations, other buyers can purchase the offer at any time.',
  volumeHint: {
    apNonSplit: 'You cannot negotiate on volume for this offer.',
    apSplit: 'This allows you to change the volume you would like to bid on.',
    tbpNonSplit: 'You can only bid on more volume than offered for this offer.',
    tbpSplit: 'This allows you to change the volume you would like to bid on.',
  },
} as const;

export const acceptBidTexts = {
  title: 'You are about to accept this offer!',
  description: 'After accepting this offer the seller will contact you regarding the handling of the deal. If you have any questions please don\'t hesitate to contact us!',
  agree: 'By placing this bid I commit to purchasing this product at the entered price and the binding result of this blind auction. The highest bid will be automatically confirmed with the seller.',
} as const;

export const counterBid = {
  title: 'You are about to place a counter bid!',
  description: 'You propose something different than the offer, so the seller will need to review your bid manually.',
  agreeValidation: 'Please agree with the terms before submitting your bid',
  agreeDescription: 'I understand that if the seller accepts my bid I am obligated to purchase this product at the entered price.',
  notValidTitle: 'We are sorry...',
  notValidText: 'Your bid could not be placed as some offer parameters changed in the meantime.',
  notValidExplanation: 'Please review the sellers offer and try to bid again.',
  expiredText: 'This offer is no longer available.',
  expiredExplanation: 'It has either just been sold or the seller cancelled the offer.',
  acceptOffer: 'By accepting this offer I commit to purchasing this product at the entered price *',
  acceptProposal: 'By accepting this proposal I commit to purchasing this product at the entered price *',
} as const;

export const counterOffer = {
  description: 'This counter offer includes the logistic and financial services you selected in your bid and cannot be changed. If you want to change any of these services we ask you to place a new bid instead.',
} as const;

export const offerLabels = {
  disclaimer: 'All prices are excl. VAT, excl. logistics and the',
  toBeProduced: {
    price: 'BASE PRICE',
    documents: 'DOCUMENT CAPABILITIES',
    packaging: 'PACKAGING CAPABILITIES',
  },
  alreadyProduced: {
    price: 'ASKING PRICE',
    documents: 'DOCUMENT INCLUDED',
    packaging: 'PACKAGING',
  },
} as const;

export const onSpecFormLabels = {
  disableCurrency: "Don't publish this offer in",
  disableEUR: "Don't publish this offer in EUR",
  disableUSD: "Don't publish this offer in USD",
} as const;

export const offSpecFormLabels = {
  disableCurrency: "Don't publish this auction in",
  disableEUR: "Don't publish this auction in EUR",
  disableUSD: "Don't publish this auction in USD",
} as const;

export const offerDuplicationTexts = {
  // BASE
  categoriesFail: 'Cannot fetch product categories',
  // ON_SPEC
  alreadyProduced: 'You are creating an offer from your existing Already Produced offer. Please check all extra carefully. We cleared up production location, incoterms, all dates and buyers from the old offer.',
  toBeProduced: 'You are creating an offer from your existing To Be Produced offer. Please check all extra carefully. We cleared up production location, incoterms, all dates and buyers from the old offer.',
  onSpecTypeError: 'You are trying to duplicate an offer with unsupported type.',
  onSpecNotFound: 'You are trying to duplicate a non-existing offer.',
  // OFF SPEC (auctions)
  auction: 'You are creating an auction from your existing one. Please check all extra carefully. We cleared up title, incoterms, loading address, all dates and buyers from the old auction.',
  auctionNotFound: 'You are trying to duplicate a non-existing auction.',
  auctionTypeError: 'You are trying to duplicate an auction with unsupported type.',
} as const;

const texts = {
  tooltips,
  specsTexts,
  offerTexts,
  acceptBidTexts,
  offerLabels,
  onSpecFormLabels,
  offSpecFormLabels,
  offerDuplicationTexts,
  counterOffer,
  originalOfferTexts,
  buyerNewBid,
  counterBid,
};

export default texts;
