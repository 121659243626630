import axios, { AxiosRequestConfig } from 'axios';

const { APP_CONFIG } = window;

const getAuthHeader = () => {
  const token = localStorage.getItem('token');
  if (token) {
    return { Authorization: `Bearer ${token}` };
  }
  return {};
};

type IBaseAxiosUrl = 'core' | 'documents';

const moddedAxios = async (
  config: AxiosRequestConfig,
  baseURL: IBaseAxiosUrl = 'core',
) => {
  const getBaseUrl = () => {
    switch (baseURL) {
      case 'core':
        return APP_CONFIG.api_url;
      case 'documents':
        return APP_CONFIG.documents_api_url;
      default:
        return APP_CONFIG.api_url;
    }
  };

  const authHeader = getAuthHeader();

  const response = await axios({
    ...config,
    baseURL: getBaseUrl(),
    headers: {
      ...config.headers,
      ...authHeader,
    },
  });

  return response;
};

// axios instance with auth headers
export default moddedAxios;
