import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { offersReducer } from './reducers/offers/OffersSlice';
import { offerReducer } from './reducers/offer/OfferSlice';
import { usersReducer } from './reducers/users/UsersSlice';
import { oauthReducer } from './reducers/oauth/OauthSlice';
import { documentsReducer } from './reducers/documents/DocumentsSlice';
import { dictionariesReducer } from './reducers/dictionaries/DictionariesSlice';
import { specsReducer } from './reducers/specs/SpecsSlice';
import { bidsReducer } from './reducers/bids/BidsSlice';

const rootReducer = combineReducers({
  offers: offersReducer,
  offer: offerReducer,
  users: usersReducer,
  oauth: oauthReducer,
  documents: documentsReducer,
  dictionaries: dictionariesReducer,
  specs: specsReducer,
  bids: bidsReducer,
});

export const store = configureStore({
  reducer: rootReducer,
  devTools: true,
  // middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
});

export type RootReducer = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;
