import { AxiosResponse } from 'axios';
import axios from 'api/axios';
import { toast } from 'react-hot-toast';
import documentThreadSchema from 'validators/documents/documentSchema';
import { DOCUMENT_ENDPOINTS } from 'api/endpoints';

async function getDocuments(dealId: number | string) {
  try {
    const response: AxiosResponse<unknown> = await axios({
      method: 'get',
      url: DOCUMENT_ENDPOINTS.getDocuments(dealId),
    }, 'documents');

    const validate = documentThreadSchema.array().safeParse(response.data);

    if (validate.success) {
      return validate.data;
    }

    toast.error('Error\nSomething went wrong with documents validation...');
    throw new Error('Validation error');
  } catch (error) {
    toast.error('Getting documents error\nSomething went wrong...');
    console.error('Getting documents error:', error);

    return 'error';
  }
}

export default getDocuments;
