import { Tab, Tabs } from '@material-ui/core';
import pages from 'navigation/pages';
import React from 'react';
import {
  Route, Switch, useHistory, useLocation,
} from 'react-router-dom';
import CatalogueTable from '../components/tables/CatalogueTable';
import FinancingTable from '../components/tables/FinancingTable';
import LogisticPrices from './LogisticPrices';

export default function CataloguePage() {
  const history = useHistory();
  const location = useLocation();

  const handleChange = (event: React.ChangeEvent<{}>, newValue: any) => {
    event.preventDefault();
    history.push(newValue);
  };

  return (
    <div className="page-body">
      <Tabs value={location.pathname} onChange={handleChange}>
        <Tab label="ORGANIZATION CATALOGUE" value={pages.catalogue} />
        <Tab label="FINANCING" value={pages.catalogue_financing} />
        <Tab label="LOGISTIC PRICES" value={pages.catalogue_logisticPrices} />
      </Tabs>

      <Switch>
        <Route path={pages.catalogue} component={CatalogueTable} exact />
        <Route path={pages.catalogue_financing} component={FinancingTable} exact />
        <Route path={pages.catalogue_logisticPrices} component={LogisticPrices} exact />
      </Switch>
    </div>
  );
}
