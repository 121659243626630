import React, { FC, useEffect, useState } from 'react';
import {
  Table, TableBody, TableCell, TableHead, TableRow,
} from '@material-ui/core';
import { useTableStyles } from 'themes/v4Theme';
import clsx from 'clsx';
import { IDocumentThread } from 'validators/documents/documentSchema';
import { sortBy } from 'lodash';
import getDocuments from 'api/get/getDocuments';
import Preloader from 'components/od-preloader/Preloader';
import { Alert } from '@material-ui/lab';
import { Link } from 'react-router-dom';
import pages from 'navigation/pages';
import DocumentsTableItem from './DocumentsTableItem';

interface IProps {
  dealId: number | string
}

const DocumentsTable: FC<IProps> = ({ dealId }) => {
  const classes = useTableStyles();
  const [documents, setDocuments] = useState<IDocumentThread[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const isDocumentsNotEmpty = documents.length > 0;

  useEffect(() => {
    if (dealId) {
      setIsLoading(true);
      getDocuments(dealId)
        .then((res) => {
          if (res !== 'error') {
            setDocuments(sortBy(res, (item) => item.documentType.title));
          }
        })
        .finally(() => setIsLoading(false));
    }
  }, [dealId]);

  return (
    <>
      <Preloader isLoading={isLoading} />

      <div className="documents-wrapper no-padding">

        <Link
          to={pages.goto_offerDocumentsProvide(dealId)}
          className="btn-primary mb-1"
        >
          Upload
        </Link>

        {!isLoading && !isDocumentsNotEmpty && (
        <Alert severity="warning">
          There are currently no documents related to this deal.
        </Alert>
        )}

        {!isLoading && isDocumentsNotEmpty && (
        <Table aria-label="Documents table" className={clsx(classes.table, 'documents-table')}>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Remarks</TableCell>
              <TableCell>File</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {documents.map((doc) => (
              <DocumentsTableItem key={doc.id} document={doc} dealId={dealId} />
            ))}
          </TableBody>
        </Table>
        )}

      </div>
    </>
  );
};

export default DocumentsTable;
