import { z } from 'zod';

const fileSchema = z.object({
  // newly created file fields
  id: z.number(),
  fileName: z.string(),
  originalFileName: z.string(),
  url: z.string().optional(),
  // optional fields that are present only when file is uploaded
  contentLength: z.number().optional(),
  contentType: z.string().optional(),

  // file bound to existing entity
  documentType: z.string().optional().nullable(),
  documentId: z.number().optional().nullable(),
  orgId: z.number().optional().nullable(),
  offerId: z.number().optional().nullable(),

  // already existing file used in duplication
  duplicate: z.boolean().optional(),
}, {
  invalid_type_error: 'File is not a valid type',
  required_error: 'File is required',
})
  // let unknown fields pass through
  .passthrough();

export const fileInputSchema = fileSchema.array();

export type IFile = z.infer<typeof fileSchema>;

export type IFileInputValue = z.infer<typeof fileInputSchema>;

export default fileSchema;
