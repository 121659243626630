import { z } from 'zod';
import fileSchema from '../common/fileSchema';
import optionSchema from '../common/optionSchema';

export const provideDocumentFormSchema = z.object({
  documentType: optionSchema,
  file: fileSchema.array(),
  documentTitle: z.string().optional(),
  comment: z.string().optional(),
});

export const provideDocumentFormSchemaAdmin = provideDocumentFormSchema.extend({
  organizationType: optionSchema,
  user: optionSchema.nullable(),
});

export type IProvideDocumentFormClient = z.infer<typeof provideDocumentFormSchema>;
export type IProvideDocumentFormAdmin = z.infer<typeof provideDocumentFormSchemaAdmin>;

export type IProvideDocumentForm = IProvideDocumentFormClient | IProvideDocumentFormAdmin;
