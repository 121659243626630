import { useState } from 'react';

export default function useToggle() {
  const [toggle, setToggle] = useState(false);

  const saveToggle = () => {
    setToggle(!toggle);
  };

  return {
    setToggle: saveToggle,
    toggle,
  };
}
