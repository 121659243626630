import { createTheme } from '@mui/material';
import colors from 'components/StyleConfig';

const v5Theme = createTheme({
  palette: {
    primary: {
      main: colors.primaryBlack,
    },
    secondary: {
      main: colors.primaryGreen,
    },
  },
  typography: {
    fontFamily: 'averta',
    fontWeightRegular: 600,
  },

  components: {
    MuiSelect: {
      styleOverrides: {
        select: {
          minWidth: 200,
        },
      },
    },

    // MuiTextField: {
    //   styleOverrides: {
    //     root: {
    //       '& .MuiAutocomplete-inputRoot': {
    //         padding: '10px 32px 10px 14px',
    //       },
    //     },
    //   },
    // },

    MuiAutocomplete: {
      styleOverrides: {
        root: {
          minWidth: 120,

          '& .MuiInputBase-root': {
            padding: '2px 10px',
          },
        },
      },
    },

    // radio button
    MuiRadio: {
      styleOverrides: {
        root: {
          padding: '2px 4px 4px 0px',
        },
      },
    },

    // form group
    MuiFormGroup: {
      styleOverrides: {
        root: {
          gap: '4px 12px',

          '& .MuiFormControlLabel-root': {
            marginRight: 0,
            margin: 0,
          },
        },
      },
    },

    // button, if secondary color white
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: 600,
          textTransform: 'none',

          '&.MuiButton-containedSecondary': {
            color: colors.white,
          },
        },
      },
    },

    // Mui form control margin bottom reset
    MuiFormControl: {
      styleOverrides: {
        root: {
          marginBottom: 0,
        },
      },
    },

    // dialog popup
    // const useStyles = makeStyles((theme) => ({
    //   dialogWrapper: {
    //     padding: theme.spacing(2),
    //     position: 'absolute',
    //     top: theme.spacing(5),
    //     minWidth: '95vw',
    //   },
    //   dialogTitle: {
    //     paddingRight: '0px',
    //   },
    // }));
    MuiDialog: {
      styleOverrides: {
        paper: {
          padding: '16px',

          position: 'absolute',
          // width: '80vw',
          // maxWidth: '1200px',
          // minWidth: '320px',
        },
      },
    },

    MuiDialogTitle: {
      styleOverrides: {
        root: {
          marginBottom: '20px',
        },
      },
    },
  },
});

export default v5Theme;
