import clsx from 'clsx';
import React, { FC } from 'react';

interface IProps {
  isLoading: boolean,
  options?: string,
}

const Preloader: FC<IProps> = ({ isLoading, options }) => {
  return (
    <div className={clsx('preloader-overlay', !isLoading && 'hidden', options)}>
      <div className="logo-wrapper">
        <div className="logo-animated-part">
          <img width="62" className="logo-top-part" src="/images/logo-top.svg" alt="" />
        </div>
      </div>
      <img width="62" className="logo-bottom-part" src="/images/logo-bottom.svg" alt="" />
    </div>
  );
};

export default Preloader;
