const endpoints = {
  containerTypes: '/v1/dictionary/container-type',
  logisticLocations: '/v1/organizations/locations',
  logisticLanePrices: '/v1/logistic/logistic-lanes/prices',
  offers: '/v1/offers',
  deals: '/v1/deals',
} as const;

export const BASE_ENDPOINTS = {
  // get
  getOrganizations: (type: 'seller' | 'buyer') => `/v1/organizations/${type}`,
  getOrgUsers: (orgId: number | string | undefined) => `/v1/organizations/${orgId}/users`,
  getDictionary: '/v1/dictionary/group',
} as const;

export const OFFER_ENDPOINTS = {
  // get
  getOfferById: (offerId: number | string) => `/v1/offers/${offerId}`,
  getLogisticsPrices: '/v1/logistic/offer-prices',
  getFinancingPrices: (offerId: number | string) => `/v1/financing/${offerId}`,
  getOfferHistory: (offerId: number | string) => `/v1/offers/${offerId}/history`,

  // post
  postBid: '/v1/offer/accept',
} as const;

// ***** document exchange process *****
export const DOCUMENT_ENDPOINTS = {
  getDocumentTypes: '/v1/document-types',
  fileUpload: '/v1/file-upload',

  // get
  getDocuments: (dealId: number | string) => `/v1/documents/${dealId}`,
  getDownloadDocumentFile: (dealId: number | string, threadId: number | string) => `/v1/documents/${dealId}/download?threadId=${threadId}`,

  // post
  postDocumentUpload: (dealId: number | string) => `/v1/documents/${dealId}/upload`,

  // put
  putDocumentsApprove: (dealId: number | string) => `/v1/documents/${dealId}/approve`,
  putDocumentsReject: (dealId: number | string) => `/v1/documents/${dealId}/reject`,

  // delete
  deleteFileFromDocument: (dealId: number | string, threadId: number | string) => `/v1/documents/${dealId}/file?threadId=${threadId}`,
} as const;

export default endpoints;
