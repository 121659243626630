import { Button } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import React, { useState } from 'react';
import { emptyOrganizationTemplate } from '../../services/api/OrganizationService';
import { SortOrder } from '../../services/api/RestUtil';
import DataTable from '../DataTable';
import api from '../../services/api';
import OrganizationTemplateForm from '../forms/OrganizationTemplateForm';
import Modal from '../Modal';
import colors from '../StyleConfig';

export default function CatalogueTable() {
  const [recordForEdit, setRecordForEdit] = useState(emptyOrganizationTemplate);
  const [open, setOpen] = useState(false);
  const columns = [
    { field: 'orgName', label: 'Trading name', render: (row: any) => row.orgName },
    { field: 'orgType', label: 'Trading role', render: (row: any) => row.orgType },
    { field: 'country', label: 'Country', render: (row: any) => row.countryName },
    { field: 'status', label: 'Status', render: (row: any) => (row.status === 'ACTIVE' ? 'Active' : 'Not active') },
  ];

  const openInPopup = (item: any) => {
    setRecordForEdit(item);
    setOpen(true);
  };

  const renderAddButton = () => (
    <Button
      variant="contained"
      color="primary"
      style={{ background: colors.primaryGreen }}
      startIcon={<Add />}
      onClick={() => { openInPopup({}); }}
    >
      Add New
    </Button>
  );

  return (
    <>
      <DataTable
        name="Organization catalogue"
        addButton={renderAddButton()}
        columns={columns}
        defaultSort={{ sort: 'orgName', order: SortOrder.Asc }}
        onRowClick={(row) => { setRecordForEdit(row); setOpen(true); }}
        fetchRows={(pageRequest) => api.organization.getOrganizationTemplates(pageRequest)}
      />
      <Modal title="Catalogue organisation" open={open} setOpen={setOpen}>
        <OrganizationTemplateForm orgTemplate={recordForEdit} setOpen={setOpen} />
      </Modal>
    </>
  );
}
