import moment from 'moment';
import { fileInputSchema } from 'SHARED/validators/common/fileSchema';
import { IDocumentThread } from 'SHARED/validators/documents/documentSchema';
import { IDocumentType } from 'SHARED/validators/documents/documentTypeSchema';

export interface IValidation {
  required: boolean | string,
  pattern?: {
    value: string | RegExp,
    message: string
  }
}

const defaultRule: { required: boolean | string } = {
  required: 'This field is mandatory',
};

const checkbox: { required: boolean | string } = {
  required: 'Please select at least one option',
};

const radio: { required: boolean | string } = {
  required: 'Please select an option',
};

const emailRules: IValidation = {
  ...defaultRule,
  pattern: {
    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
    message: 'The email address you entered is not valid',
  },
};

const min = {
  min: {
    value: 1,
    message: 'This field is mandatory',
  },
};

const maxLenght = {
  maxLength: {
    value: 75,
    message: 'This field is too long',
  },
};

const price = {
  ...defaultRule,
  validate: (value:string | number | undefined) => (value !== undefined && value !== 0 && value !== '0,00') || 'The price you entered is not valid',
};

const notRequired = {
  required: false,
};

const endDate = (startDate: Date, message: string = 'The end date must be later than the start date') => ({
  validate: (value:Date) => (moment(value).valueOf() > moment(startDate).valueOf()) || message, type: 'required',
});

// const startDate = (date: Date, message: string = 'Check dates') => ({
//   validate: (value:Date) => (value < date) || message,
// });
const startDate = (date: Date, message: string = 'Check dates') => ({
  validate: (value:Date) => (moment(value).valueOf() <= moment(date).valueOf()) || message,
});
// const startDate = (date: Date, message: string = 'Check dates') => {
//   console.log('date: ', date);
//   console.log(typeof date);

//   return ({
//     validate: (value:Date) => (moment(value).unix() < moment(date).unix()) || message,
//   });
// };

const minPrice = (minValue: any, message: string = 'The amount must be higher than the Offer minimum price') => {
  // prepare string to float
  const preparedValue = (val:string | number) => ((typeof val === 'number') ? val : (parseFloat(val.replace(',', '.'))));
  return {
    validate: (value:string | number) => {
      // console.log(preparedValue(minValue), (preparedValue(value)));
      if (minValue === 0 || minValue === 'undefined') {
        return true;
      }
      return (minValue && value && (preparedValue(value) >= preparedValue(minValue))) || message;
    },
  };
};

const notAllSelected = (optionsLength: any) => ({
  validate: (value:object[]) => (value.length < optionsLength) || 'You can not exclude all buyers',
});

const cancellation = {
  ...defaultRule,
  minLength: {
    value: 3,
    message: 'Cancellation reason is to short',
  },
};

const maxBidVolume = (totalVolume: number) => ({
  ...defaultRule,
  validate: (value: number) => (Number(value) <= Number(totalVolume)) || 'The minimum bid volume cannot exceed the total offer volume',
});

const minBidVolume = (minVolume: number) => ({
  ...defaultRule,
  validate: (value: number) => (Number(value) >= Number(minVolume)) || `The minimum bid volume cannot be lower than the ${minVolume} mt for this auction`,
});

const formFileInput = (files: unknown) => {
  // fallback value for files is null
  const isFilesExist = files !== null;
  const validation = fileInputSchema.safeParse(files);

  // in case if fileInput is empty and not required
  if (!isFilesExist) { return true; }

  return validation.success || 'File validation error';
};

const otherDocumentThreadTitle = (
  threadTitle: string,
  documents: IDocumentThread[],
  documentTypes: IDocumentType[],
) => {
  const documentsMatch = documents.find((doc) => doc.documentType.title.toLowerCase() === threadTitle.toLowerCase());
  const typesMatch = documentTypes.find((type) => type.title.toLowerCase() === threadTitle.toLowerCase());

  return (!documentsMatch && !typesMatch) || 'Document with this title already exists';
};

const withinRange = (
  minValue: number | undefined,
  maxValue: number | undefined,
  message?: string,
) => ({
  ...defaultRule,
  validate: (value: number) => {
    const isMinValueExist = typeof minValue === 'number';
    const isMaxValueExist = typeof maxValue === 'number';
    const errorMessage = message || `Value must be between ${minValue} and ${maxValue}`;

    if (isMinValueExist && isMaxValueExist) {
      return ((value >= minValue) && (value <= maxValue)) || errorMessage;
    }

    return true;
  },
});

interface IDateRangeParams {
  start?: Date | undefined,
  end: Date | undefined,
  message?: string,
  startMessage?: string,
  endMessage?: string,
}
const dateRange = (params: IDateRangeParams) => ({
  ...defaultRule,
  validate: (value: Date) => {
    const {
      start = new Date(),
      end,
      message,
      startMessage,
      endMessage,
    } = params;

    const isStartExist = moment(start).isValid();
    const isEndExist = moment(end).isValid();

    const fieldValue = moment(value).valueOf();
    const startValue = moment(start).valueOf();
    const endValue = moment(end).valueOf();

    const errorMessage = message || 'Value must be between within range';

    if (!start || !end || !isStartExist || !isEndExist) {
      return 'Start and end date must be defined';
    }

    if (fieldValue < startValue) {
      return startMessage || 'Date must be later than start date';
    }

    if (fieldValue > endValue) {
      return endMessage || 'Date must be earlier than end date';
    }

    if (isStartExist && isEndExist) {
      return ((fieldValue >= startValue) && (fieldValue <= endValue)) || errorMessage;
    }

    return true;
  },
});

interface IProposalEndDateParams {
  rfpEndDate: Date | number | string | undefined | null,

  agreedOnDelivery: boolean,
  departurePeriodType: string | undefined | null,
  departureFromMonth: string | undefined | null,
  departureTo: string | undefined | null,

  endDateMessage?: string,
  departureMessage?: string,
}
export const rfpProposalEndDate = (params: IProposalEndDateParams) => ({
  ...defaultRule,
  validate: (value: Date) => {
    const {
      rfpEndDate,

      agreedOnDelivery,
      departurePeriodType,
      departureFromMonth,
      departureTo,

      endDateMessage = 'Proposal end date must be earlier than RFP end date',
      departureMessage = 'Proposal end date must be earlier than departure end date',
    } = params;

    // flags
    const isRfpEndDateExist = moment(rfpEndDate).isValid();
    const isDepartureMonth = departurePeriodType === 'MONTH';
    const isDeparturePeriod = departurePeriodType === 'PERIOD';
    // flags === END

    // values
    const fieldValue = moment(value).valueOf();
    const rfpEndDateValue = moment(rfpEndDate).valueOf();
    // with month more steps required
    // need to get first day of month and then compare
    const departureMonthValue = moment(departureFromMonth).valueOf();
    const departureValue = moment(departureTo).valueOf();
    // values === END

    if (!isRfpEndDateExist) {
      return 'RFP end date must be defined';
    }

    // end date check
    if (fieldValue > rfpEndDateValue) {
      return endDateMessage;
    }

    // if agreed on delivery not additional checks needed
    if (agreedOnDelivery) {
      return true;
    }

    // departure MONTH check
    if (isDepartureMonth) {
      if (!departureFromMonth) {
        return 'Departure month must be defined';
      }

      if (fieldValue > departureMonthValue) {
        return departureMessage;
      }

      return true;
    }

    // departure PERIOD check
    if (isDeparturePeriod) {
      if (!departureTo) {
        return 'Departure end date must be defined';
      }

      if (fieldValue > departureValue) {
        return departureMessage;
      }

      return true;
    }

    return true;
  },
});

const validationRules = {
  email: emailRules,
  password: defaultRule,
  required: defaultRule,
  checkbox,
  radio,
  min,
  price,
  minPrice,
  maxLenght,
  endDate,
  startDate,
  notAllSelected,
  notRequired,
  cancellation,
  maxBidVolume,
  minBidVolume,
  formFileInput,
  otherDocumentThreadTitle,
  withinRange,
  dateRange,
};

export default validationRules;
