/* eslint-disable react/jsx-props-no-spreading */
import React                          from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import MultiSelect                    from 'react-multi-select-component';
import { Dictionary }                 from 'SHARED/types/offerTypes';
import Tooltip from '../Tooltip';

interface IMultiSelect {
  name: `${string}` | `${string}.${string}` | `${string}.${number}`,
  type?: string,
  rules?: any,
  value: Dictionary[] | null | undefined,
  label?: string,
  tooltip?: string,
  disabled?: boolean,
  className?: string,
  options: Dictionary[],
}

// TODO: add variant for buyer groups
const translations: { [key: string]: string } = {
  allItemsAreSelected: 'All buyers are selected',
  clearSearch: 'Clear Search',
  noOptions: 'No options',
  search: 'Search',
  selectAll: 'Select all buyers',
  selectSomeItems: 'Select buyers',
};

const buyerGroupTranslations = {
  allItemsAreSelected: 'All buyer groups are selected',
  clearSearch: 'Clear Search',
  noOptions: 'No options',
  search: 'Search',
  selectAll: 'Select all buyer groups',
  selectSomeItems: 'Select buyer groups',
};

const FormMultiSelect: React.FC<IMultiSelect> = (props) => {
  const { formState: { errors }, control } = useFormContext();

  const {
    name,
    rules = undefined,
    label,
    tooltip,
    disabled = false,
    value,
    className = '',
    options,
  } = props;

  const correctTranslations = name === 'groups' ? buyerGroupTranslations : translations;

  return (
    <div className={`form-input ${className} ${errors[name] ? 'invalid' : 'valid'}`}>
      { label && (
        <label htmlFor={name}>
          {rules && rules.required ? `${label} *` : label}

          {
            tooltip && tooltip.length > 0 && <Tooltip text={tooltip} />
          }
        </label>
      )}

      <Controller
        name={name}
        control={control}
        rules={rules}
        defaultValue={value}
        render={({ field }) => (
          <MultiSelect
            disabled={disabled}
            hasSelectAll={false}
            overrideStrings={correctTranslations}
            options={options}
            labelledBy="Select"
            onChange={field.onChange}
            value={field.value}
          />
        )}
      />

      {
        errors[name]
        && <div className="error-message">{errors[name]?.message}</div>
      }

    </div>
  );
};

export default FormMultiSelect;
