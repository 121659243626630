import { asyncBidsActions } from './bids/AsyncBidsActions';
import { bidsActions } from './bids/BidsSlice';
import { asyncDictionariesActions } from './dictionaries/AsyncDictionariesActions';
import { dictionariesActions } from './dictionaries/DictionariesSlice';
import { asyncDocumentsActions } from './documents/AsyncDocumentsActions';
import { documentsActions } from './documents/DocumentsSlice';
import { asyncOauthActions } from './oauth/AsyncOauthActions';
import { oauthActions } from './oauth/OauthSlice';
import { asyncOfferActions } from './offer/AsyncOfferActions';
import { offerActions } from './offer/OfferSlice';
import { asyncOffersActions } from './offers/AsyncOffersActions';
import { offersActions } from './offers/OffersSlice';
import { asyncSpecsActions } from './specs/AsyncSpecsActions';
import { specsActions } from './specs/SpecsSlice';
import { asyncUsersActions } from './users/AsyncUsersActions';
import { usersActions } from './users/UsersSlice';

export const allActions = {
  // offers actions
  ...offersActions,
  ...asyncOffersActions,

  // offer actions
  ...offerActions,
  ...asyncOfferActions,

  // users actions
  ...usersActions,
  ...asyncUsersActions,

  // oauth actions
  ...oauthActions,
  ...asyncOauthActions,

  // documents actions
  ...documentsActions,
  ...asyncDocumentsActions,

  // dictionaries actions
  ...dictionariesActions,
  ...asyncDictionariesActions,

  // specs actions
  ...specsActions,
  ...asyncSpecsActions,

  // bids actions
  ...bidsActions,
  ...asyncBidsActions,
};
