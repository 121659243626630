import React, { CSSProperties, FC } from 'react';
import { Paper, Typography } from '@material-ui/core';
import { amsDateWithTimezone, formatMoney, getUserName } from 'services/Utils';
import TextField from 'components/controls/TextField';
import colors from 'components/StyleConfig';
import { IOffspecOffer } from 'SHARED/types/offerTypes';

interface IProps {
  offer: IOffspecOffer
}

const WinningBidBlock: FC<IProps> = ({ offer }) => {
  const { deal } = offer;

  const style: CSSProperties = {
    border: 0,
    background: colors.odGreen,
    padding: 24,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  };

  return (
    <>
      <Typography variant="h5">Winning bid</Typography>
      <br />

      <Paper variant="outlined" style={style} key={deal?.id}>
        <TextField label="BID" value={`${formatMoney(deal?.price)} ${deal?.currency} / ${offer.volumeUnits.label}`} />
        <TextField label="BID VOLUME" value={`${deal?.volume} ${offer.volumeUnits.label}`} />
        <TextField label="TOTAL" value={`${formatMoney(deal?.amount)} ${deal?.currency}`} />
        <TextField label="BID TIME" value={amsDateWithTimezone(deal?.created)} />
        <TextField label="USER" value={getUserName(deal?.buyer)} />
        <br />
      </Paper>
    </>
  );
};

export default WinningBidBlock;
