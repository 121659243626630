import { useEffect } from 'react';

export const PreventLossUnsavedData = (isDirty:boolean) => {
  useEffect(() => {
    const onUnload = (e:BeforeUnloadEvent) => {
      if (isDirty) {
        e.preventDefault();
        e.returnValue = '';
      }
    };

    window.addEventListener('beforeunload', onUnload);
    return () => window.removeEventListener('beforeunload', onUnload);
  }, [isDirty]);
};

export default PreventLossUnsavedData;
