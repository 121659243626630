import { createAsyncThunk } from '@reduxjs/toolkit';
import { CORE_ENDPOINTS } from 'SHARED/api/endpoints';
import { axios_Core } from 'SHARED/helpers/axios';
import notification from 'SHARED/helpers/notifications';
import { IGetProductsArgs } from 'SHARED/redux/models/IGetProductsArgs';
import { Dictionary } from 'SHARED/types/offerTypes';
import { CharacteristicItem, ProductCategory, ProductDetails, ProductListItem, SpecificationItem } from 'SHARED/types/specsTypes';

// ===== SPECIFICATIONS LIST =====
const getSpecificationsList = createAsyncThunk(
  'specs/getSpecificationsList',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios_Core.get<SpecificationItem[]>(CORE_ENDPOINTS.specificationsList);

      return response.data;
    } catch (error) {
      console.log('getSpecificationsList error: ', error);

      notification({
        type: 'danger',
        title: 'Error',
        message: 'Could not get specifications list...',
      });

      return rejectWithValue('Could not get specifications list...');
    }
  },
);
// ===== SPECIFICATIONS LIST ===== END

// ===== PRODUCTS LIST =====
const getProducts = createAsyncThunk(
  'specs/getProducts',
  async (args: IGetProductsArgs, { rejectWithValue }) => {
    const { category, type } = args;

    try {
      const response = await axios_Core.get<ProductListItem[]>(CORE_ENDPOINTS.getProducts({ category, type }));

      return response.data;
    } catch (error) {
      console.log('getProducts error: ', error);

      notification({
        type: 'danger',
        title: 'Error',
        message: 'Could not get products list...',
      });

      return rejectWithValue('Could not get products list...');
    }
  },
);
// ===== PRODUCTS LIST ===== END

// ===== PRODUCT CATEGORIES LIST =====
const getProductCategoriesList = createAsyncThunk(
  'specs/getProductCategoriesList',
  async (isProductCreation: boolean, { rejectWithValue }) => {
    try {
      const categories = await axios_Core.get<ProductCategory[]>(CORE_ENDPOINTS.productCategories);

      if (isProductCreation) {
        // CHECK grouped dictionaries
        const documents = await axios_Core.get<Dictionary[]>(CORE_ENDPOINTS.dictionaryDocuments);
        // CHECK grouped dictionaries
        const packaging = await axios_Core.get<Dictionary[]>('v1/dictionary?type=PACKAGING_OPTIONS');

        return {
          categories: categories.data,
          documents: documents.data,
          packaging: packaging.data,
          isProductCreation,
        };
      }

      return {
        categories: categories.data,
        isProductCreation,
      };
    } catch (error) {
      console.log('getProductCategoriesList error: ', error);

      notification({
        type: 'danger',
        title: 'Error',
        message: 'Could not get product categories list...',
      });

      return rejectWithValue('Could not get product categories list...');
    }
  },
);
// ===== PRODUCT CATEGORIES LIST ===== END

// ===== CHARACTERISTICS LIST =====
const getCharacteristicsList = createAsyncThunk(
  'specs/getCharacteristicsList',
  async (type: string, { rejectWithValue }) => {
    try {
      const response = await axios_Core.get<CharacteristicItem[]>(CORE_ENDPOINTS.getCharacteristicsList(type));

      return response.data;
    } catch (error) {
      console.log('getCharacteristicsList error: ', error);

      notification({
        type: 'danger',
        title: 'Error',
        message: 'Could not get characteristics list...',
      });

      return rejectWithValue('Could not get characteristics list...');
    }
  },
);
// ===== CHARACTERISTICS LIST ===== END

// ===== GET PRODUCT =====
const getProduct = createAsyncThunk(
  'specs/getProduct',
  async (productId: number | string, { rejectWithValue }) => {
    try {
      const response = await axios_Core.get<ProductDetails>(CORE_ENDPOINTS.productById(productId));

      return response.data;
    } catch (error) {
      console.log('getProduct error: ', error);

      notification({
        type: 'danger',
        title: 'Error',
        message: 'Could not get product...',
      });

      return rejectWithValue('Could not get product...');
    }
  },
);
// ===== GET PRODUCT ===== END

export const asyncSpecsActions = {
  getSpecificationsList,
  getProducts,
  getProductCategoriesList,
  getCharacteristicsList,
  getProduct,
};
