import {
  Badge, IconButton, List, ListItem, Paper, Popover, Typography,
} from '@material-ui/core';
import { Message } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { Channel, Client, State } from 'twilio-chat';
import api from '../../services/api';
import { TwilioCredentials } from '../../services/api/ChatService';
import Chat from './Chat';

export default function ActiveChannels() {
  const [client, setClient] = useState<Client>();
  const [credentials, setCredentials] = useState<TwilioCredentials>();
  const [channels, setChannels] = useState<Channel[]>([]);
  const [channel, setChannel] = useState<Channel>();
  const [anchorEl, setAnchorEl] = useState(null);
  const [messageCount, setMessageCount] = useState({});
  const [open, setOpen] = useState(true);

  const loadCredentials = () => {
    api.chat.getCredentials()
      .then((res) => {
        setCredentials(res);
        client && client.updateToken(res.token);
      })
      .catch();
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(loadCredentials, []);

  // initialize Twilio client
  useEffect(() => {
    if (credentials) {
      const CLIENT = new Client(credentials.token);
      CLIENT.on(Client.stateChanged, (state: State) => {
        if (state === 'initialized') {
          setClient(CLIENT);
        }
      });
      CLIENT.on(Client.tokenAboutToExpire, loadCredentials);
      CLIENT.on(Client.tokenExpired, loadCredentials);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [credentials]);

  useEffect(() => {
    if (client) {
      client.getSubscribedChannels()
        .then((res) => setChannels(res.items))
        .catch();
    }
  }, [client]);

  useEffect(() => {
    const countMap: any = {};
    channels.forEach(
      (c) => c.getMessagesCount()
        .then((count) => { count && Object.assign(countMap, { [c.sid]: count }); })
        .catch(),
    );
    Object.keys(countMap) && setMessageCount(countMap);
  }, [channels]);

  const renderPopover = () => (
    <Popover
      id={anchorEl ? 'simple-popover' : undefined}
      open={!!anchorEl}
      anchorEl={anchorEl}
      onClose={() => setAnchorEl(null)}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
    >
      {renderChannelList()}
    </Popover>
  );

  const handleChannelClick = (_channel: Channel) => {
    setChannel(_channel); setOpen(true); setAnchorEl(null);
  };

  // @ts-ignore
  const getUnreadCount = (c: Channel) => messageCount[c.sid] || 0;

  const renderChannelList = () => (
    <div style={{ background: '#dcdcdc' }}>
      <List>
        { channels && channels
          .filter((it) => getUnreadCount(it) > 0)
          .map((it, idx) => (
            <ListItem style={{ cursor: 'pointer' }} onClick={() => handleChannelClick(it)} key={`channel-${idx + 1}`}>
              <Paper style={{ padding: 8, display: 'flex', width: '100%' }}>
                <Typography style={{ flexGrow: 1 }}>{ it.friendlyName }</Typography>
                <Badge badgeContent={getUnreadCount(it)} color="primary" />
              </Paper>
            </ListItem>
          )) }
      </List>
    </div>
  );

  return (
    <>
      <IconButton
        style={{
          marginLeft: 'auto', marginRight: 16, padding: 5, color: '#fff',
        }}
        onClick={(e: any) => setAnchorEl(e.target)}
      >
        <Message />
      </IconButton>
      { renderPopover() }
      { (channel && credentials && open) && (
      <Chat channel={channel} identity={credentials.identity} setOpen={setOpen} />
      )}
    </>
  );
}
