/* eslint-disable react/jsx-props-no-spreading */
import React              from 'react';
import { useFormContext } from 'react-hook-form';
import { Dictionary } from 'SHARED/types/offerTypes';
import validationRules     from 'SHARED/helpers/validation';
import Tooltip from '../Tooltip';

interface IRadioGroupProps {
  name: `${string}` | `${string}.${string}` | `${string}.${number}`,
  type?: string,
  rules?: any,
  values: Dictionary[],
  label?: string,
  placeholder?: string,
  tooltip?: string,
  disabled?: boolean,
  defaultValue: Dictionary | null,
}

const RadioGroup: React.FC<IRadioGroupProps> = (props) => {
  const { register, formState: { errors } } = useFormContext();

  const {
    name,
    rules = validationRules.radio,
    label,
    tooltip,
    disabled = false,
    defaultValue,
    values,
  } = props;

  return (
    <div className={`form-input radio-group ${disabled ? 'disabled' : ''} ${errors[name] ? 'invalid' : 'valid'}`}>
      { label && (
        <label>
          {rules && rules.required ? `${label} *` : label}

          {
            tooltip && tooltip.length > 0 && <Tooltip text={tooltip} />
          }
        </label>
      )}

      <div className="radio-group-wrapper">
        { values?.map((item) => (
          <div className="custominput" key={item?.value}>
            <input
              defaultChecked={(defaultValue && defaultValue?.value === item?.value) || false}
              type="radio"
              id={`${name}-${item.value}`}
              value={item?.value}
              {...register(name, rules)}
            />
            <label htmlFor={`${name}-${item.value}`}>{item?.label}</label>
          </div>
        ))}

      </div>

      {
        errors[name]
        && <div className="error-message">{errors[name]?.message}</div>
      }

    </div>
  );
};

export default RadioGroup;
