import {
  InputAdornment, InputLabel, Link, Typography,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import findCategoryPackaging from 'helpers/findCategoryPackaging';
import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import Preloader from 'components/od-preloader/Preloader';
import { useCreateOfferMutation } from 'api/mutations/useCreateOfferMutation';
import { Form, useForm } from '../../hooks/useForm';
import pages from '../../navigation/pages';
import api from '../../services/api';
import { Dictionary } from '../../services/api/DictionaryService';
import { LocationModel } from '../../services/api/LocationService';
import { emptyOffer, OfferTypes } from '../../services/api/OfferService';
import { Organization } from '../../services/api/OrganizationService';
import { IUser } from '../../services/api/UserService';
import {
  date, dateTime, getUserName, isoDateTime, option, unixTimeStamp,
} from '../../services/Utils';
import Controls from '../controls/Controls';
import DocumentInput from '../controls/DocumentInput';
import DownloadField from '../controls/DownloadField';
import ProductPackage from '../controls/ProductPackage';
import FilesIcon from '../icons';
import ProductSpecs from './ProductSpecs';

const DOCUMENT_TYPE = 'DOCUMENT_TYPE';
const PACKAGING_OPTIONS = 'PACKAGING_OPTIONS';
const LOADING_DETAILS = 'LOADING_DETAILS';
const INCOTERM_SELLER = 'INCOTERM_SELLER';
const PAYMENT_TERMS = 'PAYMENT_TERMS';

const ALERT_DEFAULT_STYLE: React.CSSProperties = {
  marginInline: '16px',
  marginBlock: '4px',
};

export default function OfferCreateForm(props: any) {
  const backToOffers = () => props.history.push(pages.offers);

  const [fullIncotermsData, setFullIncotermsData] = useState<Dictionary[]>([]);

  const [sellers, setSellers] = useState([]);
  const [buyers, setBuyers] = useState([]);
  const [users, setUsers] = useState([]);
  const [categories, setCategories] = useState([]);
  // `prodSpecs` - any type used to avoid type error when `prodSpecs[values.productType]` are used
  const [prodSpecs, setProdSpecs] = useState<any>(null);
  const [docTypes, setDocTypes] = useState([]);
  // const [packOptions, setPackOptions] = useState([]);
  const [loadOptions, setLoadOptions] = useState([]);
  const [productionLocations, setProductionLocations] = useState([]);
  const [logisticLocations, setLogisticLocations] = useState([]);
  const [fullLogisticLocations, setFullLogisticLocations] = useState<any[]>([]);
  const [incoterms, setIncoterms] = useState([]);
  const [paymentTerms, setPaymentTerms] = useState([]);
  const [excludedBuyers, setExcludedBuyers] = useState([]);
  const [packages, setPackages] = useState([]);
  const [containers, setContainers] = useState<Record<string, any[]>>({});
  const [file, setFile] = useState(null);
  const [coaFile, setCoaFile] = useState(null);
  const [validateSpec, setValidateSpec] = useState(false);
  const [productSpec, setProductSpec] = useState([]);
  const [offerStatus, setOfferStatus] = useState < 'ACTIVE' | 'OFFLINE'>('ACTIVE');

  const [globalError, setGlobalError] = useState('');
  const {
    values, errors, setValues, setErrors, handleInputChange,
  } = useForm(emptyOffer);

  const isOfflineOffer = offerStatus === 'OFFLINE';

  useEffect(() => {
    console.log({ excludedBuyers });
  }, [excludedBuyers]);

  // load default option
  useEffect(() => {
    setOrganizationOptions('SELLER');
    setOrganizationOptions('BUYER');

    api.productSpec.getCategories((res: any) => {
      setCategories(res.map((it: any) => ({
        category: it.value,
        value: it.value,
        label: it.label,
        productTypes: it.productTypes,
        packagingOptions: it.packagingOptions,
      })));
    });
  }, []);

  // load incoterms location
  useEffect(() => {
    if (values.incoterms) {
      // ? the line below is from the old solution
      // ? in future this whole form should be refactored and remade from scratch
      // ? but for now it will do the job
      // const locationType = ['EXW', 'FCA'].indexOf(values.incoterms) < 0 ? 'PORT' : 'NON_PORT';

      const locationType = fullIncotermsData.find((inc) => inc.value === values.incoterms)?.locationType;

      api.location.getLocations(values.organizationId, 'LOGISTIC', {})
        .then((resp) => resp.rows.filter((it: LocationModel) => it.type === locationType))
        .then((resp) => resp
          .filter((it: LocationModel) => it.active)
          .map((it: any) => ({ label: it.fullAddress, value: it.id, locationType })))
        .then((resp) => setLogisticLocations(resp))
        .catch((err) => setGlobalError(err.message));
    }
    // reset logistic locations if incoterms are not selected
    if (!values.incoterms) {
      setLogisticLocations([]);
    }
  }, [values.organizationId, values.incoterms]);

  // full logistic locations
  useEffect(() => {
    if (values.organizationId) {
      api.location.getLocations(values.organizationId, 'LOGISTIC', {})
        .then((resp) => resp.rows)
        .then((resp: LocationModel[]) => resp.filter((it) => it.active))
        .then((resp) => setFullLogisticLocations(resp))
        .catch((err) => setGlobalError(err.message));
    }
  }, [values.organizationId]);

  // load organization-specific data
  useEffect(() => {
    if (values.organizationId) {
      const { organizationId } = values;
      setValues({ ...emptyOffer, organizationId });
      setGlobalError('');

      api.organization.getOrgUsers(values.organizationId, {})
        .then((res) => setUsers(
          res.rows
            // blocked or inactive users are not allowed to create offers
            .filter((it: IUser) => it?.status === 'ACTIVE')
            .map((it: IUser) => ({ label: getUserName(it), value: it.id })),
        ))
        .catch((err) => setGlobalError(err.message));

      api.location.getLocations(values.organizationId, 'PRODUCTION', {})
        .then((res) => setProductionLocations(
          res.rows
            .filter((it: LocationModel) => it.active)
            .map((it: LocationModel) => ({ label: it.fullAddress, value: it.id })),
        ))
        .catch((err) => setGlobalError(err.message));
      api.transportation.getContainers(values.organizationId, setContainers, setGlobalError);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.organizationId]);

  useEffect(() => {
    if (values.organizationId && values.type) {
      setDictionaryValues();
      setSalesTermsDoc();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.organizationId, values.type]);

  useEffect(() => {

  }, [values.productType, values.productCategory]);

  useEffect(() => {
    if (values.category) {
      api.productSpec.getProductList(values.organizationId, values.category, undefined)
        .then((res) => res.filter((it: any) => it.active))
        .then((res) => {
          const items = res.map((it: any) => ({
            type: it.productType.value,
            label: it.title,
            value: it.id,
            eurPremium: it.premiumPriceEur,
            usdPremium: it.premiumPriceUsd,
            characteristics: it.characteristics,
          }));
          // @ts-ignore
          setProdSpecs(_.groupBy(items, (it) => it.type));
        })
        .catch((err) => setGlobalError(err.message));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.organizationId, values.category]);

  useEffect(() => {
    const input = document.querySelector('.Mui-error');
    input?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'start',
    });
  }, [errors, globalError]);

  useEffect(() => {
    setProductSpec([]);
    setValues({ ...values, coaUsed: false });
    if (prodSpecs && values.productId > 0 && values.productType) {
      // @ts-ignore
      const specs = prodSpecs[values.productType];
      if (specs?.length > 0) {
        const spec = specs.find((it: any) => it.value === values.productId);
        if (spec) {
          setProductSpec(spec.characteristics);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.productId, values.productCategory, values.productType]);

  const setOrganizationOptions = (orgType: 'SELLER' | 'BUYER') => {
    api.organization.getOrganizationPage(orgType, {})
      .then((res) => (orgType === 'SELLER'
        ? setSellers(res.rows)
        : setBuyers(res.rows.map((it: any) => option(it.id, it.orgName)))))
      .catch((err) => setGlobalError(err.message));
  };
  const getSellerOptions = () => (
    sellers
      ? sellers.map((it: any) => option(it.id, it.orgName))
      : []);
  const setDictionaryValues = () => {
    api.dictionary.getDictionaryByCode(
      [PACKAGING_OPTIONS, LOADING_DETAILS, DOCUMENT_TYPE, INCOTERM_SELLER, PAYMENT_TERMS],
      (res: any) => {
        // setPackOptions(
        //   res[PACKAGING_OPTIONS].map((it: Dictionary) => ({ label: it.label, value: it.value })),
        // );
        setLoadOptions(
          res[LOADING_DETAILS].map((it: Dictionary) => ({ label: it.label, value: it.value })),
        );
        setDocTypes(
          res[DOCUMENT_TYPE].map((it: Dictionary) => ({ label: it.label, value: it.value })),
        );
        setIncoterms(
          res[INCOTERM_SELLER].map((it: Dictionary) => ({ label: it.label, value: it.value, locationType: it.locationType })),
        );
        setPaymentTerms(res[PAYMENT_TERMS]);

        // ? full incoterms data needed for the proper locations request (instead of hardcoded ones)
        // ? all because now incoterms include information about the type (PORT \ NON_PORT)
        // ? same information present in the locations, and can be used to filter them as well
        setFullIncotermsData(res[INCOTERM_SELLER]);
      },
      values.organizationId, values.type === 'TO_BE_PRODUCED_BUNDLE' ? 'TO_BE_PRODUCED' : values.type,
    );
  };
  const getMonthOptions = () => {
    const result = [];
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < 12; i++) {
      const month = moment().add(i, 'month').startOf('month');
      result.push({ label: month.format('MMMM YYYY'), value: month.format('YYYY-MM-DD') });
    }
    return result;
  };
  const getProductTypeOptions = () => {
    const category = categories.find((it: any) => it.category === values.category);
    // @ts-ignore
    return category ? category.productTypes : [];
  };
  const getProductSpecOptions = (type: any) => {
    if (prodSpecs && type) {
      // @ts-ignore
      const options = prodSpecs[type];
      return options || [];
    }
    return [];
  };
  const getPaymentTermsOptions = () => (paymentTerms
    ? paymentTerms.map((it: Dictionary) => ({ label: it.label, value: it.value }))
    : []);

  const hasDayOffset = () => {
    if (paymentTerms && values.paymentTerms) {
      // @ts-ignore
      return paymentTerms.find((it: any) => it.value === values.paymentTerms)?.hasDayOffset;
    }
    return false;
  };
  const getOffsetOptions = () => {
    if (paymentTerms && values.paymentTerms) {
      const term = paymentTerms.find((it: any) => it.value === values.paymentTerms);
      // @ts-ignore
      return term && term.offsetDays.map((it: number) => option(it, `${it}`));
    }

    return [];
  };

  const {
    mutate: createOffer,
    isLoading,
  } = useCreateOfferMutation({
    onSuccess: () => {
      backToOffers();
    },
  });

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    if (!validate()) {
      setGlobalError('Validation failed, please check required fields');
      return;
    }

    const request = {
      ...values,
      status: offerStatus,
      endDate: unixTimeStamp(values.endDate),
      expirationDate: values.expirationDate,
      productCharacteristics: (values.coaUsed && productSpec) || null,
      departurePeriod: { label: '', value: values.departurePeriod },
      departureFrom: values.departureFrom && date(values.departureFrom),
      departureTo: values.departureTo && date(values.departureTo),
      packaging: { label: '', value: values.packaging },
      salesTerms: { label: '', value: values.salesTerms },
      paymentTerms: { label: '', value: values.paymentTerms },
      paymentOffset: values.paymentOffset,
      incoterms: { label: '', value: values.incoterms },
      documents: getDocumentsRequest(),
      buyersFetchType: { label: '', value: values.buyersFetchType },
      productType: { label: '', value: values.productType },
      buyers: excludedBuyers,
      productId: values.productId || null,
      productIds: values.type === 'TO_BE_PRODUCED_BUNDLE'
        ? values.productIds.filter((it: number) => it > 0)
        : [],
      packages: values.type === 'TO_BE_PRODUCED_BUNDLE' ? packages : [],
      // additional logic for single currency support
      // priceEur: (values.disablePriceEur && !values.priceUsdDisabled) ? null : values.priceEur,
      priceEur: (values.priceEurDisabled && !values.priceUsdDisabled) ? null : values.priceEur,
      priceUsd: (!values.priceEurDisabled && values.priceUsdDisabled) ? null : values.priceUsd,
    };

    // if "OFFLINE" offer, no exclude buyers needed
    if (offerStatus === 'OFFLINE') {
      request.buyers = [];
    }

    // cleanup before sending data to backend
    delete request.priceEurDisabled;
    delete request.priceUsdDisabled;

    console.log({ request });
    // return;

    if (isLoading) return;

    createOffer(request);

    // api.offer.createOffer(request)
    //   .then(backToOffers);
  };
  const validate = () => {
    setGlobalError('');
    const requiredFields = [
      'ownerUserId',
      'category',
      'type',
      'productionLocationId',
      'productionDate',
      'expirationDate',
      'incoterms',
      'logisticLocationId',
      'departurePeriod',
      'departureFrom',
      'totalVolume',
      ...(values.priceEurDisabled ? [] : ['priceEur']),
      ...(values.priceUsdDisabled ? [] : ['priceUsd']),
      // 'priceEur',
      // 'priceUsd',
      'paymentTerms',
      'endDate',
    ];
    const positiveNumbers = ['totalVolume', 'priceEur', 'priceUsd'];
    const futureDates = isOfflineOffer ? [] : ['endDate', 'expirationDate'];
    const notEmptyArrays = ['documentCapabilities', 'loadOptions'];
    const temp: any = {};

    if (values.type !== 'TO_BE_PRODUCED_BUNDLE') {
      requiredFields.push('productId', 'packaging');
    }

    if (values.coaUsed) {
      if (!productSpec || productSpec.length === 0 || productSpec.find((it: any) => it.hasError)) {
        setGlobalError('Product specification has errors');
        temp.productSpec = 'Product specification has errors';
      }
    }

    requiredFields.forEach((key) => {
      if (!values[key]) {
        temp[key] = 'This field is required.';
      }
    });
    positiveNumbers.forEach((key) => {
      if (values[key] && values[key] <= 0) {
        temp[key] = 'Value must be greater than 0';
      }
    });
    futureDates.forEach((key) => {
      if (moment().isAfter(values[key])) {
        temp[key] = 'Must be in future';
      }
    });

    notEmptyArrays.forEach((key) => {
      if (values[key].length === 0) {
        temp[key] = 'Please select at least one option';
      }
    });

    if (values.type === 'TO_BE_PRODUCED_BUNDLE' && values.productIds.length === 0) {
      temp.productIds = 'Need to choose at least one product package';
    }

    if (hasDayOffset() && !values.paymentOffset) {
      temp.paymentOffset = 'This field is required';
    }

    if (values.salesTerms === 'OWN' && !file) {
      temp.salesTerms = 'This field is required';
    }

    setErrors({ ...temp });

    return Object.keys(temp).length === 0;
  };

  const getDocumentsRequest = () => {
    const res: any = {};
    if (values.salesTerms === 'OWN') {
      res.SALES_TERMS = [file];
    }
    if (values.coaUsed) {
      res.COA = [coaFile];
    }

    return Object.keys(res).length > 0 ? res : null;
  };

  const renderProductPackage = (index: number) => ((values.category && prodSpecs)
    && (
    <ProductPackage
      index={index}
      key={`product-package-${index + 1}`}
      productTypes={getProductTypeOptions()}
      productSpecs={prodSpecs}
      packages={values.productIds}
      setPackages={(val: any) => setValues({ ...values, productIds: val })}
    />
    )
  );

  const renderPackageBundle = () => {
    if (values.productIds.length === 0) {
      return renderProductPackage(0);
    }

    return (
      <div>
        {values.productIds.map((it: any, index: number) => renderProductPackage(index))}
        <br />
        <Controls.Button onClick={() => {
          const ids = values.productIds;
          ids.push(-1);
          setValues({ ...values, productIds: ids });
        }}
        >
          Add another package
        </Controls.Button>
      </div>
    );
  };

  const setSalesTermsDoc = () => {
    if (values.organizationId) {
      const currentSeller = sellers.find((it: Organization) => it.id === values.organizationId);
      if (currentSeller) {
        const { documents } = currentSeller;
        // @ts-ignore
        documents.length > 0 && setFile(documents[0]);
      }
    }

    return null;
  };

  const renderFileField = (label: string, docType: any) => {
    const docs = values.documents;
    const requestedDocs = docs ? docs[docType] : [];
    const files = (requestedDocs || []).map((doc: any) => ({
      fileName: doc.fileName,
      originalFileName: doc.originalFileName,
    }));
    return <DownloadField label={label} files={files} />;
  };

  const renderMPCLink = () => (
    values.documents && values.documents.SALES_TERMS
      ? renderFileField('SALES TERMS', 'SALES_TERMS')
      : (
        <Link
          component="a"
          href="https://issuu.com/nnkc.nl/docs/mpc_-_2018_engels"
          target="_blank"
          rel="noreferrer"
          style={{ display: 'inline-flex' }}
        >
          <FilesIcon />
          <Typography variant="body1" style={{ color: '#009FD1' }}>MPC sales terms</Typography>
        </Link>
      )
  );

  // ? `status` should not be part of the `values` object
  // ? because it will be reset to default value on sellerOrg change
  function handleStatusChange(event: any) {
    setOfferStatus(event.target.value);
  }

  // data
  const matchedContainers = containers[values.category as keyof typeof containers] || [];
  // data === end

  // flags
  const isNoUsers = values.organizationId && users.length === 0;
  const isOrgHasNoLogisticLocations = (
    values.organizationId
    && fullLogisticLocations.length === 0
  );
  const isOrgHasNoProductionLocations = (
    values.organizationId
    && productionLocations.length === 0
  );
  const isMissingContainers = (
    values.category
    && matchedContainers.length < 2
  );
  const isHasNoProductSpecs = (
    values.organizationId
    && values.category
    && Object.keys(prodSpecs || {}).length === 0
  );
  const isHasNoLogisticLocationsForSelectedIncoterms = (
    values.organizationId
    && values.incoterms
    && logisticLocations.length === 0
  );

  const isSubmitDisabled = (
    isNoUsers
    || isOrgHasNoLogisticLocations
    || isMissingContainers
    || isOrgHasNoProductionLocations
    || isHasNoProductSpecs
    || isHasNoLogisticLocationsForSelectedIncoterms
    || isLoading
    || globalError
  );
  // flags === end

  return (
    <div className="page-body">
      <div style={{ display: 'flex' }}>
        <Typography variant="h5" style={{ flexGrow: 1 }}>
          Create new on-spec offer
        </Typography>
        <Controls.Button
          type="button"
          color="secondary"
          onClick={backToOffers}
        >
          <span style={{ fontSize: 16, color: '#FFF' }}>{'< Back to offers'}</span>
        </Controls.Button>
      </div>
      { globalError && <Alert severity="error">{globalError}</Alert> }
      <br />
      <div style={{ display: 'flex' }}>
        <Form name="offer-form" style={{ width: '50%' }}>
          <Controls.RadioGroup
            name="status"
            label="OFFER STATUS"
            value={offerStatus}
            items={[{ value: 'ACTIVE', label: 'Online offer' }, { value: 'OFFLINE', label: 'Offline offer' }]}
            onChange={handleStatusChange}
            required
          />

          <Typography variant="h6">Organization</Typography>
          <br />
          <Controls.Select
            name="organizationId"
            label="SELLER ORGANIZATION *"
            options={getSellerOptions()}
            value={values.organizationId}
            error={errors.organizationId}
            onChange={handleInputChange}
            required
          />
          {isNoUsers && (
            <Alert severity="error" style={ALERT_DEFAULT_STYLE}>
              No active user is created for this organisation
            </Alert>
          )}
          {isOrgHasNoLogisticLocations && (
            <Alert severity="error" style={ALERT_DEFAULT_STYLE}>
              No departure location is defined for this organisation
            </Alert>
          )}
          {isOrgHasNoProductionLocations && (
            <Alert severity="error" style={ALERT_DEFAULT_STYLE}>
              No production location is defined for this organisation
            </Alert>
          )}

          <Controls.Select
            name="ownerUserId"
            label="SELLER USER *"
            options={users}
            value={values.ownerUserId}
            error={errors.ownerUserId}
            onChange={handleInputChange}
            disabled={!values.organizationId || users.length <= 0}
            required
          />
          <Controls.Select
            name="type"
            label="OFFER TYPE *"
            options={[{ label: 'Already produced', value: 'ALREADY_PRODUCED' }, { label: 'To be produced', value: 'TO_BE_PRODUCED_BUNDLE' }]}
            value={values.type}
            error={errors.type}
            onChange={handleInputChange}
            required
          />

          {
              (values.type && values.ownerUserId) && (
              <>
                <Typography variant="h6">Product</Typography>
                <Controls.Select
                  name="category"
                  label="PRODUCT CATEGORY *"
                  options={
                    categories.map((it: any) => ({ label: it.label, value: it.value }))
                  }
                  value={values.category}
                  error={errors.category}
                  onChange={(e: any) => {
                    setValues({
                      ...values,
                      category: e.target.value,
                      productType: undefined,
                      productIds: [],
                      productId: undefined,
                    });
                    setErrors({ ...errors, category: undefined });
                  }}
                  required
                />

                {isMissingContainers && (
                  <Alert severity="error" style={ALERT_DEFAULT_STYLE}>
                    Volume of a container or a truck is not defined in organisation profile
                  </Alert>
                )}
                {isHasNoProductSpecs && (
                  <Alert severity="error" style={ALERT_DEFAULT_STYLE}>
                    No product specification is defined for this product type
                  </Alert>
                )}

                {
                  values.category
                  && !isMissingContainers // check if container exists for this category
                  && !isHasNoProductSpecs // check if product specs exists for this category
                  && (
                    values.type === OfferTypes.ALREADY_PRODUCED
                      ? (
                        <>
                          <Controls.Select
                            name="productType"
                            label="PRODUCT TYPE *"
                            options={getProductTypeOptions()}
                            value={values.productType}
                            error={errors.productType}
                            onChange={handleInputChange}
                            required
                            disabled={isHasNoProductSpecs}
                          />
                          <Controls.Select
                            name="productId"
                            label="PRODUCT SPECS *"
                            options={getProductSpecOptions(values.productType)}
                            value={values.productId}
                            error={errors.productId}
                            onChange={handleInputChange}
                            required
                            disabled={isHasNoProductSpecs}
                          />
                        </>
                      )
                      : renderPackageBundle()
                  )
                }

                <br />

                <Controls.Input
                  name="description"
                  label="REMARKS FOR THE BUYER (500 characters max)"
                  value={values.description}
                  error={errors.description}
                  onChange={handleInputChange}
                  multiline
                />

                { values.type === OfferTypes.ALREADY_PRODUCED && (
                <Controls.Checkbox
                  name="coaUsed"
                  label="Sell based on the Certificate of Analysis"
                  value={values.coaUsed}
                  onChange={handleInputChange}
                  disabled={!values.productType}
                />
                )}
                { values.coaUsed && (
                <>
                  { errors.coaFile && <Alert severity="error">{errors.coaFile}</Alert> }
                  <DocumentInput
                    label="CERTIFICATE OF ANALYSIS *"
                    variant="outlined"
                    file={coaFile}
                    onSave={(it) => {
                      setErrors({ ...errors, coaFile: undefined });
                      setCoaFile(it);
                    }}
                    onDelete={() => {
                      setErrors({ ...errors, coaFile: 'This field is required' });
                      setCoaFile(null);
                    }}
                  />
                </>
                ) }
                { docTypes && (
                  <Controls.CheckboxGroup
                    name="documentCapabilities"
                    label="DOCUMENT CAPABILITIES *"
                    options={docTypes}
                    value={values.documentCapabilities}
                    error={errors.documentCapabilities}
                    onChange={handleInputChange}
                    groupSize={7}
                  />
                )}
                {
                      productionLocations && (
                      <Controls.Select
                        name="productionLocationId"
                        label="PRODUCTION LOCATION *"
                        options={productionLocations}
                        value={values.productionLocationId}
                        error={errors.productionLocationId}
                        onChange={handleInputChange}
                      />
                      )
                }

                { values.type === 'ALREADY_PRODUCED'
                  ? (
                    <Controls.Select
                      name="packaging"
                      label="PACKAGING *"
                      // options={packOptions}
                      options={findCategoryPackaging(categories, values.category)}
                      value={values.packaging}
                      error={errors.packaging}
                      onChange={handleInputChange}
                      required
                    />
                  )
                  : (
                    <Controls.MultiSelect
                      label="PACKAGING *"
                      // options={packOptions}
                      options={findCategoryPackaging(categories, values.category)}
                      valueArray={packages}
                      onChange={setPackages}
                    />
                  )}

                <Controls.Checkbox
                  name="customLabelPossible"
                  label="Custom label possible"
                  value={values.customLabelPossible}
                  onChange={handleInputChange}
                />
                { values.type === 'ALREADY_PRODUCED' && (
                <div style={{ display: 'flex', width: '80%' }}>
                  <div style={{ marginRight: 15 }}>
                    <InputLabel>PRODUCTION DATE *</InputLabel>
                    <Controls.Input
                      name="productionDate"
                      value={date(values.productionDate)}
                      error={errors.productionDate}
                      onChange={handleInputChange}
                      InputProps={{ inputProps: { max: moment().subtract(1, 'day').format('YYYY-MM-DD') } }}
                      type="date"
                      required
                    />
                  </div>
                  <div>
                    <InputLabel>EXPIRATION DATE *</InputLabel>
                    <Controls.Input
                      name="expirationDate"
                      value={date(values.expirationDate)}
                      error={errors.expirationDate}
                      onChange={handleInputChange}
                      InputProps={{ inputProps: isOfflineOffer ? undefined : { min: moment().add(1, 'day').format('YYYY-MM-DD') } }}
                      type="date"
                      required
                    />
                  </div>
                </div>
                ) }
                <Controls.RadioGroup
                  name="salesTerms"
                  label="SALES TERMS"
                  value={values.salesTerms}
                  items={[{ value: 'MPC', label: 'MPC' }, { value: 'OWN', label: 'Seller own terms' }]}
                  onChange={handleInputChange}
                  required
                />
                { errors.salesTerms && <Alert severity="error">{errors.salesTerms}</Alert> }
                {
                      values.salesTerms === 'MPC'
                        ? (
                          <>
                            <InputLabel>SALES TERMS DOCUMENT *</InputLabel>
                            <br />
                            {renderMPCLink()}
                          </>
                        )
                        : (
                          <DocumentInput
                            label="SALES TERMS DOCUMENT *"
                            variant="outlined"
                            file={file}
                            onSave={(it) => {
                              setErrors({ ...errors, salesTerms: undefined });
                              setFile(it);
                            }}
                            onDelete={() => {
                              setErrors({ ...errors, salesTerms: 'This field is required' });
                              setFile(null);
                            }}
                          />
                        )
                    }
                <Typography variant="h6">Shipping</Typography>
                <br />
                <Controls.Select
                  name="incoterms"
                  label="SELLER INCOTERMS *"
                  options={incoterms}
                  value={values.incoterms}
                  error={errors.incoterms}
                  onChange={handleInputChange}
                  required
                />
                {isHasNoLogisticLocationsForSelectedIncoterms && (
                  <Alert severity="error" style={ALERT_DEFAULT_STYLE}>
                    No departure location uses this incoterms
                  </Alert>
                )}
                { logisticLocations && (
                <Controls.Select
                  name="logisticLocationId"
                  label="SELLER INCOTERMS LOCATION *"
                  options={logisticLocations}
                  value={values.logisticLocationId}
                  error={errors.logisticLocationId}
                  onChange={handleInputChange}
                  required
                  disabled={logisticLocations.length === 0}
                />
                )}
                <Controls.CheckboxGroup
                  name="loadOptions"
                  label="LOADING DETAILS *"
                  options={loadOptions}
                  value={values.loadOptions}
                  error={errors.loadOptions}
                  onChange={handleInputChange}
                  groupSize={1}
                />
                <Controls.RadioGroup
                  name="departurePeriod"
                  label="DEPARTURE"
                  value={values.departurePeriod}
                  items={[{ value: 'MONTH', label: 'Month' }, { value: 'PERIOD', label: 'Specific dates' }]}
                  onChange={handleInputChange}
                  required
                />
                { values.departurePeriod === 'MONTH'
                  ? (
                    <Controls.Select
                      label="DEPARTURE *"
                      name="departureFrom"
                      options={getMonthOptions()}
                      // value={date(values.departureFrom)}
                      value={values.departureFrom}
                      error={errors.departureFrom}
                      onChange={handleInputChange}
                      required
                    />
                  )
                  : (
                    <div style={{ display: 'flex' }}>
                      <div style={{ marginRight: 15 }}>
                        <InputLabel>DEPARTURE FROM *</InputLabel>
                        <Controls.Input
                          name="departureFrom"
                          value={date(values.departureFrom)}
                          error={errors.departureFrom}
                          onChange={handleInputChange}
                          InputProps={{ inputProps: { min: date() } }}
                          type="date"
                          required
                        />
                      </div>
                      <div>
                        <InputLabel>DEPARTURE TO *</InputLabel>
                        <Controls.Input
                          name="departureTo"
                          value={date(values.departureTo)}
                          error={errors.departureTo}
                          onChange={handleInputChange}
                          InputProps={{ inputProps: { min: date() } }}
                          type="date"
                          required
                        />
                      </div>
                    </div>
                  )}

                <Typography variant="h6">Volume and price</Typography>
                <Controls.Input
                  name="totalVolume"
                  label="VOLUME (MT)"
                  value={values.totalVolume}
                  error={errors.totalVolume}
                  style={{ width: '50%' }}
                  onChange={handleInputChange}
                  InputProps={{ inputProps: { min: 0 } }}
                  type="number"
                  required
                />
                <Controls.Checkbox
                  name="splittable"
                  label="Splittable per container / full truck load"
                  value={values.splittable}
                  onChange={handleInputChange}
                />
                <div className="create-offer-prices">
                  <div className="disable-currency">
                    <Controls.Checkbox
                      name="priceEurDisabled"
                      label="Disable EUR price"
                      value={values.priceEurDisabled}
                      onChange={handleInputChange}
                      disabled={values.priceUsdDisabled}
                    />
                    <Controls.Checkbox
                      name="priceUsdDisabled"
                      label="Disable USD price"
                      value={values.priceUsdDisabled}
                      onChange={handleInputChange}
                      disabled={values.priceEurDisabled}
                    />
                  </div>
                  <Controls.Input
                    name="priceEur"
                    label="ASKING PRICE"
                    value={values.priceEur}
                    error={!values.priceEurDisabled && errors.priceEur}
                    className="offer-price"
                    onChange={handleInputChange}
                    type="number"
                    InputProps={{
                      endAdornment: <InputAdornment position="end">EUR/MT</InputAdornment>,
                      inputProps: { min: 0 },
                    }}
                    disabled={values.priceEurDisabled}
                    required={!values.priceEurDisabled}
                  />
                  <Controls.Input
                    name="priceUsd"
                    label="ASKING PRICE"
                    value={values.priceUsd}
                    error={!values.priceUsdDisabled && errors.priceUsd}
                    className="offer-price"
                    onChange={handleInputChange}
                    type="number"
                    InputProps={{
                      endAdornment: <InputAdornment position="end">USD/MT</InputAdornment>,
                      inputProps: { min: 0 },
                    }}
                    disabled={values.priceUsdDisabled}
                    required={!values.priceUsdDisabled}
                  />
                </div>

                <Controls.Select
                  name="paymentTerms"
                  label="PAYMENT TERMS *"
                  options={getPaymentTermsOptions()}
                  value={values.paymentTerms}
                  error={errors.paymentTerms}
                  onChange={handleInputChange}
                  required
                />
                { hasDayOffset() && (
                <Controls.Select
                  name="paymentOffset"
                  label="X DAYS *"
                  value={values.paymentOffset}
                  error={errors.paymentOffset}
                            // @ts-ignore
                  options={getOffsetOptions()}
                  onChange={handleInputChange}
                  required
                />
                )}

                {!isOfflineOffer && (
                  <>
                    <Typography variant="h6">Buyers</Typography>
                    <br />
                    <Controls.RadioGroup
                      name="buyersFetchType"
                      label="BUYERS WHO SEE THIS OFFER "
                      value={values.buyersFetchType}
                      items={[{ value: 'INCLUDE_ALL', label: 'All' }, { value: 'EXCLUDE', label: 'All buyers, excluding' }]}
                      onChange={handleInputChange}
                    />
                  </>
                )}

                { values.buyersFetchType === 'EXCLUDE' && (
                <Controls.MultiSelect
                  label="BUYERS THAT DO NOT SEE THIS OFFER"
                  options={buyers}
                  valueArray={excludedBuyers}
                  onChange={setExcludedBuyers}
                />
                )}

                <Typography variant="h6">Offer expiration</Typography>
                <br />
                <div>
                  <InputLabel>OFFER END DATE</InputLabel>
                  <Controls.Input
                    name="endDate"
                    value={dateTime(values.endDate, isoDateTime)}
                    error={errors.endDate}
                    onChange={handleInputChange}
                    type="datetime-local"
                    style={{ width: '50%' }}
                    InputProps={{ inputProps: isOfflineOffer ? undefined : { min: dateTime(null, isoDateTime) } }}
                    required
                  />
                </div>

                <div>
                  <Controls.Button
                    type="submit"
                    disabled={isSubmitDisabled}
                    onClick={handleSubmit}
                  >
                    Submit
                  </Controls.Button>
                </div>

                <Preloader isLoading={isLoading} />
              </>
              )
            }
        </Form>
        <div style={{ width: '50%', paddingLeft: '5%' }}>
          { (values.productType && values.coaUsed) && (
            <>
              <Typography variant="h5">Product spec</Typography>
              <ProductSpecs
                productSpec={productSpec}
                setProductSpec={(spec) => {
                  setProductSpec(spec);
                  setValidateSpec(false);
                }}
                needValidate={validateSpec}
                productType={values.productType}
                orgType="SELLER"
              />
              <div>
                <Controls.Button
                  type="submit"
                  onClick={(e: any) => {
                    e.preventDefault();
                    setValidateSpec(true);
                  }}
                >
                  Submit
                </Controls.Button>
              </div>
            </>
          ) }
        </div>
      </div>
    </div>
  );
}
