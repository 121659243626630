import React, { useEffect, useState } from 'react';
import { InputAdornment, InputLabel, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import moment from 'moment';
import pages from 'navigation/pages';
import { Form, useForm } from '../../hooks/useForm';
import api from '../../services/api';
import { emptyOffer } from '../../services/api/OfferService';
import { dateTime, isoDateTime, unixTimeStamp } from '../../services/Utils';
import Controls from '../controls/Controls';

export default function OfferEditForm(props: any) {
  const { history, match: { params: { offerId: id } } } = props;
  const [globalError, setGlobalError] = useState('');
  const [isSplittable, setIsSplittable] = useState(false);
  const [offerData, setOfferData] = useState(emptyOffer);
  const {
    values, errors, setValues, setErrors, handleInputChange,
  } = useForm(emptyOffer);

  useEffect(() => api.offer.getOfferById(id, setOffer, setGlobalError), []);

  const setOffer = (res: any) => {
    setIsSplittable(res.splittable);
    setValues({ ...res });
    setOfferData(res);
  };

  const validate = () => {
    setGlobalError('');
    const temp: any = {};
    const requiredFields = [
      'remainingVolume',
      ...(offerData.priceEur ? ['priceEur'] : []),
      ...(offerData.priceUsd ? ['priceUsd'] : []),
      // 'priceEur',
      // 'priceUsd',
      'endDate',
    ];
    requiredFields.forEach((key) => {
      if (!values[key]) {
        temp[key] = 'This field is required.';
      }
    });

    if (values.remainingVolume <= 0) {
      temp.remainingVolume = 'Must be greater than 0';
    }

    if (moment().isAfter(values.endDate)) {
      temp.endDate = 'Must be in future';
    }

    setErrors({ ...temp });

    return Object.keys(temp).length === 0;
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (validate()) {
      const request: any = {
        id,
        type: values.type.value,
        remainingVolume: values.remainingVolume,
        splittable: values.splittable,
        // priceEur: values.priceEur,
        // priceUsd: values.priceUsd,
        endDate: unixTimeStamp(values.endDate),
      };

      if (offerData.priceEur) {
        request.priceEur = values.priceEur;
      }
      if (offerData.priceUsd) {
        request.priceUsd = values.priceUsd;
      }

      api.offer.updateOffer(request)
        .then(backToDetails)
        .catch(setGlobalError);
    }
  };

  const backToDetails = () => history.push(pages.goto_offerPage(id));

  return (
    <div className="page-body">
      <Form name="offer-edit">
        <Typography variant="h4">Edit offer</Typography>
        { globalError && <Alert severity="error">{globalError}</Alert> }
        <br />
        <Typography variant="body1">If you need to change any other specifics, we advise you to cancel this offer and create a new one.</Typography>
        <Typography variant="body1">Changing volume, the splittable option and/or pricing does not affect existing deals made within this offer.</Typography>
        <br />
        <Typography variant="h6">Volume and price</Typography>
        <Controls.Input
          name="remainingVolume"
          label="REMAINING VOLUME"
          value={values.remainingVolume}
          error={errors.remainingVolume}
          style={{ width: '50%' }}
          onChange={handleInputChange}
          InputProps={{
            endAdornment: <InputAdornment position="end">MT</InputAdornment>,
            inputProps: { min: 0 },
          }}
          type="number"
          required
        />
        <br />
        <div style={{ display: 'flex' }} className="offer-edit-prices">
          {offerData.priceEur && (
          <Controls.Input
            name="priceEur"
            label={values.type.value === 'ALREADY_PRODUCED' ? 'ASKING PRICE' : 'BASE PRICE'}
            value={values.priceEur}
            error={errors.priceEur}
            style={{ width: '24%', marginRight: 8 }}
            onChange={handleInputChange}
            type="number"
            InputProps={{
              endAdornment: <InputAdornment position="end">EUR/MT</InputAdornment>,
              inputProps: { min: 0 },
            }}
            required
          />
          )}
          {offerData.priceUsd && (
          <Controls.Input
            name="priceUsd"
            label={values.type.value === 'ALREADY_PRODUCED' ? 'ASKING PRICE' : 'BASE PRICE'}
            value={values.priceUsd}
            error={errors.priceUsd}
            style={{ width: '25%' }}
            onChange={handleInputChange}
            type="number"
            InputProps={{
              endAdornment: <InputAdornment position="end">USD/MT</InputAdornment>,
              inputProps: { min: 0 },
            }}
            required
          />
          )}
        </div>
        <br />
        {
        !isSplittable && (
        <div>
          <InputLabel>SPLITTABLE</InputLabel>
          <Controls.Checkbox
            name="splittable"
            label="Splittable per container / full truck load"
            value={values.splittable}
            onChange={handleInputChange}
          />
          <br />
        </div>
        )
      }
        <div>
          <Typography variant="h6">Offer expiration</Typography>
          <br />
          <InputLabel>OFFER END DATE</InputLabel>
          <Controls.Input
            name="endDate"
            value={dateTime(values.endDate, isoDateTime)}
            error={errors.endDate}
            onChange={handleInputChange}
            type="datetime-local"
            style={{ width: '50%' }}
            InputProps={{ inputProps: { min: dateTime(null, isoDateTime) } }}
            required
          />
        </div>
        <br />
        <div>
          <Controls.Button type="submit" onClick={handleSubmit}>Update</Controls.Button>
          <Controls.Button color="default" onClick={backToDetails}>Discard</Controls.Button>
        </div>
      </Form>
    </div>
  );
}
