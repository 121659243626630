import React, { FC, useState } from 'react';
import Popup from 'components/Popup';
import type { CharacteristicItem } from 'types/specsTypes';
import { sortBy, groupBy } from 'lodash';
import CharacteristicPreview from './ProductSpecificationPreview';

interface IProps {
  characteristics: CharacteristicItem[] | undefined | null,
  coaUsed?: boolean,
  linkTitle?: string,
}

const ProductSpecsDetails: FC<IProps> = ({
  characteristics,
  coaUsed,
  linkTitle = 'Specifications details',
}) => {
  const [submitModalOpen, setSubmitModalOpen] = useState(false);

  const title = coaUsed ? 'Certificate of Analysis' : 'Specifications details';

  const sortedCharacteristics = sortBy(characteristics, 'displayGroup.order');
  const groupedCharacteristics = groupBy(sortedCharacteristics, 'displayGroup.value');

  function handleModalOpen() {
    setSubmitModalOpen(true);
  }

  function handleModalClose() {
    setSubmitModalOpen(false);
  }

  if (!characteristics) {
    return <strong>No specifications</strong>;
  }

  return (
    <>
      <button
        type="button"
        className="link-button"
        onClick={handleModalOpen}
      >
        {linkTitle}
      </button>

      <Popup
        title={title}
        open={submitModalOpen}
        handleClose={handleModalClose}
        maxWidth="sm"
      >
        {Object.keys(groupedCharacteristics).map((key) => (
          <div key={key} className="group">
            <strong className="group-title">
              {groupedCharacteristics[key][0].displayGroup.label}
            </strong>

            {groupedCharacteristics[key].map((ch) => (
              (
                <CharacteristicPreview
                  key={ch.id}
                  characteristic={ch}
                />
              )
            ))}
          </div>
        ))}
      </Popup>
    </>
  );
};

export default ProductSpecsDetails;
