import React from 'react';
import prepareDataAttr from 'SHARED/helpers/prepareDataAttr';
import FormattedPrice from './Price';

interface Props {
  values: (number | null)[],
  title?: string,
  suffixes?: string[],
  disableSuffix?: boolean,
  prefixes?: string[],
  className?: string,
}

const FormattedPrices: React.FC<Props> = ({
  values,
  title,
  prefixes = ['EUR', 'USD'],
  suffixes = ['/mt', '/mt'],
  disableSuffix = false,
  className = '',
}) => {
  const priceOne = !!(values[0] && (values[0] > 0));
  const priceTwo = !!(values[1] && (values[1] > 0));
  const twoPrices = !!(priceOne && priceTwo);
  const atLeastOnePrice = !!(priceOne || priceTwo);

  return (
    <>
      {atLeastOnePrice && (
        <div className={className} data-name={prepareDataAttr(title)}>
          {title && <div className="label-text">{title}</div>}

          <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: '10px' }}>
            {priceOne && (
            <FormattedPrice
              value={values[0]?.toString()}
              prefix={prefixes[0]}
              suffix={disableSuffix ? undefined : suffixes[0]}
              testingName={`${title}-${prefixes[0]}`}
            />
            )}

            {twoPrices && <span>|</span>}

            {priceTwo && (
            <FormattedPrice
              value={values[1]?.toString()}
              prefix={prefixes[1]}
              suffix={disableSuffix ? undefined : suffixes[1]}
              testingName={`${title}-${prefixes[1]}`}
            />
            )}
          </div>
        </div>
      ) }
    </>
  );
};

export default FormattedPrices;
