import axios from 'api/axios';
import { OFFER_ENDPOINTS } from 'api/endpoints';
import { AxiosResponse } from 'axios';
import { OnSpecOffer } from 'types/offerTypes';

const getOfferById = async (offerId: number | string) => {
  const res: AxiosResponse<OnSpecOffer> = await axios({
    method: 'get',
    url: OFFER_ENDPOINTS.getOfferById(offerId),
  });

  if (res.status === 200) {
    return res.data;
  }

  throw new Error(`Cannot get deal data. Status: ${res.status}`);
};

export default getOfferById;
