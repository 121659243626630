import { useParams } from 'react-router-dom';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import LinkButton from 'components/hook-form/LinkButton';
import OfferDetails from 'components/offer-details/OfferDetails';
import Preloader from 'components/od-preloader/Preloader';
import { useOfferQuery } from 'api/queries/useOfferQuery';
import CreateDealForm from './CreateDealForm';

interface IRouteParams {
  offerId: string;
}

const OfferNewDealPage = () => {
  const { offerId } = useParams<IRouteParams>();

  const offerQuery = useOfferQuery(offerId);
  const {
    data: offerData,
    refetch: refetchOffer,
    remove: removeOfferQuery,
    isFetching,
  } = offerQuery;

  useEffect(() => {
    if (offerId) {
      refetchOffer();
    }

    return () => {
      removeOfferQuery();
    };
  }, [offerId]);

  return (
    <div className="page-body">
      <Helmet>
        <title>Create a deal</title>
      </Helmet>

      <Preloader isLoading={isFetching} />

      <header className="page-header">
        <h1>Create a deal</h1>

        <div className="button-group">
          <LinkButton to={`/offers/${offerId}`} label="< Back to offer" disabled={!offerId} />
        </div>
      </header>

      <section className="page-content">
        <OfferDetails offer={offerData} viewMode="base" />

        {offerData && <CreateDealForm offer={offerData} />}
      </section>
    </div>
  );
};

export default OfferNewDealPage;
