import axios from 'api/axios';
import { OFFER_ENDPOINTS } from 'api/endpoints';
import { AxiosResponse } from 'axios';
import { VasLogistic } from 'types/offerTypes';

interface IParams {
  [key: string]: string | number | undefined;
}

export const getLogisticsPrices = async (params?: IParams) => {
  const res: AxiosResponse<VasLogistic> = await axios({
    method: 'get',
    url: OFFER_ENDPOINTS.getLogisticsPrices,
    params,
  });

  if (res.status === 200) {
    return res.data;
  }

  throw new Error(`Cannot get logistic prices. Status: ${res.status}`);
};
