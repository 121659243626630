import React, { FC } from 'react';
import {
  Table, TableBody, TableCell, TableHead, TableRow,
} from '@material-ui/core';
import pages from 'navigation/pages';
import { Deal, OfferOffSpec } from 'services/api/OfferService';
import { amsDateWithTimezone, formatMoney, getUserName } from 'services/Utils';
import { useTableStyles } from 'themes/v4Theme';

interface IProps {
  offer: OfferOffSpec
  deals: Deal[]
}

const DealsTable: FC<IProps> = ({ offer, deals }) => {
  const classes = useTableStyles();

  return (
    <Table aria-label="deals table" className={classes.table}>

      <TableHead>
        <TableRow>
          <TableCell>DATE</TableCell>
          <TableCell align="center">BUYER</TableCell>
          <TableCell align="center">USER</TableCell>
          <TableCell align="center">COUNTRY</TableCell>
          <TableCell align="center">BID</TableCell>
          <TableCell align="center">BID VOLUME</TableCell>
          <TableCell align="center">TOTAL</TableCell>
          <TableCell align="center">DEAL ID</TableCell>
        </TableRow>
      </TableHead>

      <TableBody>
        {deals.map((deal) => (
          <TableRow
            key={deal.id}
            title={`${deal.number} (page ID: ${deal.id})`}
            onClick={() => window.open(pages.offSpecOffers_dealPage(deal.id), '_blank', 'noreferrer')}
          >

            <TableCell>{amsDateWithTimezone(deal.created)}</TableCell>
            <TableCell align="center">{deal.buyerOrg?.orgName}</TableCell>
            <TableCell align="center">{getUserName(deal?.buyer)}</TableCell>
            <TableCell align="center">{deal.buyerOrg?.country}</TableCell>
            <TableCell align="center">{`${formatMoney(deal.price)} ${deal.currency} / ${offer.volumeUnits.label}`}</TableCell>
            <TableCell align="center">{`${deal.volume} ${offer.volumeUnits.label}`}</TableCell>
            <TableCell align="center">{`${formatMoney(deal.amount)} ${deal.currency}`}</TableCell>
            <TableCell align="center">{deal.number}</TableCell>

          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default DealsTable;
