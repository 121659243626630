import { Option } from '../../components/controls/Select';
import {
  get, PageRequest, post, put, SortOrder,
} from './RestUtil';
import { IProfile } from './UserService';

export interface Organization {
  id: number,
  orgName: string,
  orgType: 'SELLER' | 'BUYER',
  country: string,
  status: string,
  // offSpec: boolean,
  registrationDate?: string,
  approvalDate?: string,
  coc?: string,
  vat?: string,
  paymentTerm?: Option | undefined,
  paymentOffset?: number | undefined,
  isFinancingProvider: false
  isTotalPriceCredited: false
  currency?: 'EUR' | 'USD'
  location?: string,
  feesDescription?: string,
  notes?: string,
  legalName?: string,
  contactPerson?: IProfile,
  documents?: Array<any>
  offSpecLocation1?: string,
  offSpecLocation2?: string,
  offSpecLocation3?: string,
}

export interface OrganizationTemplate {
  id?: number,
  orgType: 'SELLER' | 'BUYER',
  orgName: string,
  countryId?: number,
  countryName?: string,
  status?: 'ACTIVE' | 'NOT_ACTIVE',
}

export const emptyOrganizationTemplate: OrganizationTemplate = {
  id: undefined,
  orgType: 'SELLER',
  orgName: '',
  countryId: undefined,
  countryName: '',
  status: 'NOT_ACTIVE',
};

export const emptyOrganization: Organization = {
  id: 0,
  orgName: '',
  orgType: 'SELLER',
  status: 'NEW',
  currency: 'EUR',
  coc: '',
  vat: '',
  // offSpec: true,
  notes: '',
  location: '',
  country: '',
  legalName: '',
  documents: [],
  isFinancingProvider: false,
  isTotalPriceCredited: false,
  contactPerson: {
    id: null,
    salutation: 'MR',
    firstName: '',
    middleName: '',
    lastName: '',
    email: '',
    jobTitle: '',
    phoneNumber: '',
  },
  approvalDate: '',
  registrationDate: '',
};

export class OrganizationService {
  getOrganization = (id: number, orgType: string) => get(`/v1/organizations/${orgType.toLowerCase()}/${id}`);

  getOrgUsers = (id: number, pageRequest?: PageRequest) => get(`/v1/organizations/${id}/users`, pageRequest);

  getOrganizationPage = (orgType: string, pageRequest?: PageRequest) => get(`/v1/organizations/${orgType.toLowerCase()}`, pageRequest);

  createOrganization = (org: Organization) => post(`/v1/organizations/${org.orgType.toLowerCase()}`, org);

  updateOrganization = (id: number, org: Organization) => put(`/v1/organizations/${org.orgType.toLowerCase()}/${id}`, org);

  getOrganizationTemplates = (pageRequest?: PageRequest) => get('/v1/dictionary/organization', pageRequest);

  getOrganizationTemplatesList = (setTemplates: any, setError: any) => {
    get('/v1/dictionary/organization/list', { sort: 'orgName', order: SortOrder.Asc })
      .then((resp) => setTemplates(resp))
      .catch((err) => setError(err));
  };

  createOrganizationTemplate = (orgTemplate: any) => post('/v1/dictionary/organization', orgTemplate);

  updateOrganizationTemplate = (id: number, orgTemplate: any) => put(`/v1/dictionary/organization/${id}`, orgTemplate);

  getApprovedSellers = (buyerId: number) => get('/v1/organizations/approved-sellers', { buyerId });

  setApprovedSellers = (buyerId: number, sellers: Array<Option>) => put(`/v1/organizations/approved-sellers?buyerId=${buyerId}`, sellers);
}
