import React, { FC } from 'react';
import { Tooltip } from '@material-ui/core';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

interface IProps {
  text: string;
}

const Hint: FC<IProps> = ({ text }) => {
  return (
    <Tooltip title={text} className="form-item-hint" arrow style={{ fontSize: 20 }} placement="top">
      {/* <span>?</span> */}
      <HelpOutlineIcon className="hint-icon" color="secondary" />
    </Tooltip>
  );
};

export default Hint;
