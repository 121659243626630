import React from 'react';
import {
  Route, Switch,
} from 'react-router-dom';
import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import { MuiThemeProvider } from '@material-ui/core';
import { ThemeProvider } from '@mui/material/styles';
import { Toaster } from 'react-hot-toast';
import ReactNotificationsComponent from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import v4Theme from 'themes/v4Theme';
import v5Theme from 'themes/v5Theme';
import { useTypedSelector } from 'SHARED/redux/hooks/useTypedSelector';
import Preloader from 'SHARED/components/ThePreloader';
import AuthHandler from 'pages/AuthHandler';
import Dashboard from '../pages/Dashboard';
import pages from '../navigation/pages';

// Tanstack Query Client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
      // refetchOnMount: true,
      // staleTime: Infinity,
    },
  },
});

const App: React.FC = () => {
  const { loading } = useTypedSelector((state) => state.users);
  const { isLoading } = useTypedSelector((state) => state.oauth);

  return (
    <>
      <Toaster
        position="bottom-right"
        toastOptions={{
          duration: 5000,
        }}
      />

      <ReactNotificationsComponent />

      <Preloader isLoading={isLoading || loading} />

      <MuiThemeProvider theme={v4Theme}>
        <ThemeProvider theme={v5Theme}>

          <QueryClientProvider client={queryClient}>

            <Switch>
              <Route exact path={pages.authHandler} component={AuthHandler} />
              <Route path={pages.dashboard} component={Dashboard} />
            </Switch>

          </QueryClientProvider>

        </ThemeProvider>
      </MuiThemeProvider>
    </>
  );
};

export default App;
