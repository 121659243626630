import lodash from 'lodash';
import moment from 'moment-timezone';
import { dateFormat } from 'services/Utils';
import { ISelectedLocations } from 'store/addLogisticsPrices';

export type ILocationType = 'PORT' | 'NON-PORT';

export const locationTypeOptions = [
  { value: 'PORT', label: 'port' },
  { value: 'NON-PORT', label: 'non-port' },
];

export type FormContainer = {
  typeId: number | null | undefined | string;
  priceEur: number | null;
  priceUsd: number | null;
};

export type LaneByLocationsParams = {
  countryIdFrom: number | string | null | undefined;
  locationTypeFrom: ILocationType | undefined;
  seaPortIdFrom: number | string | null | undefined;
  zipFrom: string | null | undefined;

  countryIdTo: number | string | null | undefined;
  locationTypeTo: ILocationType | undefined;
  seaPortIdTo: number | string | null | undefined;
  zipTo: string | null | undefined;
};

type FormDate = string | null;

export interface IFormLanePrices {
  provider: string;

  validFrom: FormDate;
  validTo: FormDate;

  departureFrom: FormDate;
  departureTo: FormDate;

  containers: FormContainer[]
}

export const prepareSellerOptions = (sellers: any[] | undefined | null) => {
  if (!sellers) return [];

  return sellers.map((seller) => ({
    value: seller.id,
    label: seller.name,
  }));
};

export const prepareCountryOptions = (countries: any[] | undefined | null) => {
  if (!countries) return [];

  return countries.map((country) => ({
    value: country.id,
    label: country.name,
  }));
};

export const preparePortLocationOptions = (
  countries: any[] | undefined | null,
  countryId: number | string | undefined | null,
) => {
  if (!countries || !countryId) return [];

  const country = countries.find((c) => c.id === countryId);
  if (!country) return [];

  const locations = country.portLocations;
  if (!locations || locations.length === 0) return [];

  return locations.map((location: any) => ({
    value: location.seaPort.id,
    label: `${location.seaPort.name} (${location.seaPort.code})`,
  }));
};

export const prepareCityOptions = (
  countries: any[] | undefined | null,
  countryId: number | string | undefined | null,
) => {
  if (!countries || !countryId) return [];

  const country = countries.find((c) => c.id === countryId);
  if (!country) return [];

  const locations = country.nonPortLocations;
  if (!locations || locations.length === 0) return [];

  const cities: string[] = [];
  for (const [key] of Object.entries(locations)) {
    cities.push(key);
  }

  return lodash.uniq(cities).map((city) => ({
    value: city,
    label: city,
  }));
};

export const prepareZipCodeOptions = (
  countries: any[] | undefined | null,
  countryId: number | string | undefined | null,
  city: string | undefined | null,
) => {
  if (!countries || !countryId || !city) return [];

  const country = countries.find((c) => c.id === countryId);
  if (!country) return [];

  const locations = country.nonPortLocations;
  if (!locations || locations.length === 0) return [];

  const cityLocations = locations[city];
  if (!cityLocations || cityLocations.length === 0) return [];

  const zipCodes: string[] = [];
  cityLocations.forEach((loc: any) => zipCodes.push(loc.zip));

  return lodash.uniq(zipCodes).map((zipCode) => ({
    value: zipCode,
    label: zipCode,
  }));
};

export const prepareLaneRequestParams = (params: LaneByLocationsParams) => {
  const {
    countryIdFrom,
    locationTypeFrom,
    seaPortIdFrom,
    zipFrom,

    countryIdTo,
    locationTypeTo,
    seaPortIdTo,
    zipTo,
  } = params;

  const laneRequestParams: any = {
    countryIdFrom,
    countryIdTo,
  };

  if (locationTypeFrom === 'PORT') {
    laneRequestParams.seaPortIdFrom = seaPortIdFrom;
  } else {
    laneRequestParams.zipFrom = zipFrom;
  }

  if (locationTypeTo === 'PORT') {
    laneRequestParams.seaPortIdTo = seaPortIdTo;
  } else {
    laneRequestParams.zipTo = zipTo;
  }

  const allParamsTrueCheck = Object.values(laneRequestParams).every((item) => !!item);

  return {
    laneRequestParams,
    allParamsTrueCheck,
  };
};

export const prepareLanePreviewData = (
  sellers: any[] | undefined | null,
  destinationCountries: any[] | undefined | null,
  selectedLocations: ISelectedLocations | null,
) => {
  if (!sellers || !destinationCountries || !selectedLocations) return null;

  // from
  let countryFrom: string = '';
  let locationFrom: string = '';
  // to
  let countryTo: string = '';
  let locationTo: string = '';

  // orgs
  const sellerOrgs: string[] = [];
  const buyerOrgs: string[] = [];

  // from
  sellers.find((s) => s.id === selectedLocations.seller)
    ?.countries.find((c: any) => {
      if (c.id === selectedLocations.countryIdFrom) {
        // country text
        countryFrom = c.name;

        if (selectedLocations.locationTypeFrom === 'PORT' && selectedLocations.seaPortIdFrom) {
          const port = c.portLocations.find(
            (l: any) => l.seaPort.id === selectedLocations.seaPortIdFrom,
          );
          // location text
          locationFrom = `${port.seaPort.name} (${port.seaPort.code})`;
          port.organizations.forEach((org: any) => sellerOrgs.push(org.name));
        }
        if (selectedLocations.locationTypeFrom === 'NON-PORT' && selectedLocations.cityFrom) {
          const city = c.nonPortLocations[selectedLocations.cityFrom];
          const cityLocation = city.find((l: any) => l.zip === selectedLocations.zipFrom);
          // location text
          locationFrom = `${cityLocation.city}, ${cityLocation.zip} `;
          cityLocation.organizations.forEach((org: any) => sellerOrgs.push(org.name));
        }

        return true;
      }

      return false;
    });

  // to
  destinationCountries.find((c: any) => {
    if (c.id === selectedLocations.countryIdTo) {
      // country text
      countryTo = c.name;

      if (selectedLocations.locationTypeTo === 'PORT' && selectedLocations.seaPortIdTo) {
        const port = c.portLocations.find(
          (l: any) => l.seaPort.id === selectedLocations.seaPortIdTo,
        );
        // location text
        locationTo = `${port.seaPort.name} (${port.seaPort.code}) `;
        port.organizations.forEach((org: any) => buyerOrgs.push(org.name));
      }
      if (selectedLocations.locationTypeTo === 'NON-PORT' && selectedLocations.cityTo && selectedLocations.zipTo) {
        const city = c.nonPortLocations[selectedLocations.cityTo];
        const cityLocation = city.find((l: any) => l.zip === selectedLocations.zipTo);
        // location text
        locationTo = `${cityLocation.city}, ${cityLocation.zip} `;
        cityLocation.organizations.forEach((org: any) => buyerOrgs.push(org.name));
      }

      return true;
    }

    return false;
  });

  return {
    from: {
      country: countryFrom,
      location: locationFrom,
    },
    to: {
      country: countryTo,
      location: locationTo,
    },
    sellers: sellerOrgs,
    buyers: buyerOrgs,
  };
};

export const prepareProviderOptions = (laneData: any[] | undefined | null) => {
  if (!laneData) return [];

  return laneData.map((lane) => ({
    value: lane.id,
    label: `${lane.logisticPartner.value} (${lane.logisticPartner.label})`,
  }));
};

export const prepareContainersData = (
  laneData: any[] | undefined | null,
  selectedProvider: string | undefined | null,
  containerTypes: any[] | undefined | null,
) => {
  if (!containerTypes) return [];

  const containersWithMockPrices = containerTypes?.map((container) => ({
    label: container.label,
    value: container.id,

    code: container.code,

    id: container.id,

    priceUsd: 0,
    priceEur: 0,
  }));

  if (!laneData || !selectedProvider) return containersWithMockPrices;

  const providerLane = laneData?.find((l) => l.id === selectedProvider);

  if (!providerLane) return containersWithMockPrices;

  const containersWithPrices = containersWithMockPrices.map((container) => {
    const containerWithPrice = providerLane.containers.find((c: any) => c.typeId === container.id);
    // return containerWithPrice;
    // return container;
    if (!containerWithPrice) return container;

    return {
      ...container,

      priceUsd: containerWithPrice.priceUsd,
      priceEur: containerWithPrice.priceEur,
    };
  });

  return containersWithPrices;
};

export const prepareLanePricesPayload = (data: IFormLanePrices) => {
  const formattedData = {
    id: data.provider,

    departureFrom: moment(data.departureFrom).format(dateFormat),
    departureTo: moment(data.departureTo).format(dateFormat),

    // should be sure that dates are in future and if not add couple of hours
    validFrom: moment(data.validFrom).tz('Europe/Amsterdam').set({
      hour: 0,
      minute: 0,
      second: 0,
    }).valueOf(),
    validTo: moment(data.validTo).tz('Europe/Amsterdam').set({
      hour: 23,
      minute: 59,
      second: 59,
    }).valueOf(),

    containers: data.containers.map((c) => ({
      ...c,
      priceEur: Number(c.priceEur),
      priceUsd: Number(c.priceUsd),
    })),
  };

  return formattedData;
};
