import clsx from 'clsx';
import React from 'react';
import { formatValue } from 'react-currency-input-field';
import prepareDataAttr from 'SHARED/helpers/prepareDataAttr';

interface Props {
  value: string | number | null | undefined,
  title?: string,
  label?: string, // TODO: refactor SELLER and BUYER to use label instead of title
  suffix?: string | null,
  prefix?: string | null,
  className?: string,
  priceClassName?: string,
  boldPrice?: boolean,
  testingName?: string,
}

const FormattedPrice: React.FC<Props> = ({
  value,
  title,
  prefix,
  suffix,
  className = '',
  boldPrice = false,
  priceClassName = '',
  testingName,
  label,
}) => {
  const price = formatValue({
    value: value ? value.toString() : undefined,
    decimalScale: 2,
    decimalSeparator: ',',
    groupSeparator: '.',
    prefix: '',
  });
  const val = price.substring(0, price.length - 2);
  const cents = price.split(',')[1];

  return (
    <div
      className={`data-preview-row ${className}`}
      data-name={prepareDataAttr(title || testingName)}
    >
      {(title || label) && <div className="label-text">{title || label}</div>}

      {!!value && (
        <div className={clsx('price', boldPrice && 'bold', priceClassName)}>
          {prefix && (
          <span className="prefix-nbsp">{prefix}</span>
          )}
          <span className="val">{val}</span>
          <span className="cents">{cents}</span>

          {suffix && (
          <span>
            {suffix}
          </span>
          )}
        </div>
      )}

      {!value && (
        <span className="no-value">-</span>
      )}
    </div>
  );
};

export default FormattedPrice;
