import currency from 'currency.js';
import moment from 'moment';
import momentTz from 'moment-timezone';
import { sortBy } from 'lodash';
import { OfferTypeDictionary } from 'types/offerTypes';
import { Option } from '../components/controls/Select';

export const prettyDateFormat = 'DD MMM YYYY';
export const prettyDateTimeFormat = 'DD MMM YYYY HH:mm';
export const dateFormat = 'YYYY-MM-DD';
export const dateTimeFormat = 'YYYY-MM-DD HH:mm';
export const isoDateTime = 'YYYY-MM-DDTHH:mm';
export const dateFormatTimezone = 'D MMM YYYY HH:mm z';
export const dateFormatWithSecondsTimezone = 'D MMM YYYY HH:mm:ss z';

export const formatMoney = (value: number | string | undefined | null) => (
  value
    ? currency(value, { separator: '.', decimal: ',', symbol: '' }).format()
    : '0,00'
);

export const unixTimeStamp = (value?:any) => moment(value).valueOf();

export const prettyDate = (value?: any) => amsDateWithTimezone(value, prettyDateFormat);
export const prettyDateTime = (value?: any) => amsDateWithTimezone(value, prettyDateTimeFormat);
export const date = (value?: any, format?:string) => amsDateWithTimezone(value, format || dateFormat);
export const dateTime = (value?: any, format?: string) => {
  const fmt = format || dateTimeFormat;
  return value && moment(value).format(fmt);
};
export const amsDateWithTimezone = (
  value: any,
  format: string = dateFormatTimezone,
) => {
  if (!value) {
    return 'Invalid date';
  }

  const regularDate = momentTz(value);
  const tzDate = regularDate.tz('Europe/Amsterdam').format(format);
  return tzDate;
};

export const timezonesList = sortBy(momentTz.tz.names().map((zone) => {
  // const utcAbbr = momentTz.tz(zone).format('z');
  // ? label with long dash
  // const label = `UTC${momentTz.tz(zone).format('Z')} \u2014 ${zone.replace('/', ', ').replace('_', ' ')}`;
  const label = `(UTC${momentTz.tz(zone).format('Z')}) ${zone.replaceAll('/', ', ').replaceAll('_', ' ')}`;
  const utcOffset = momentTz.tz(zone).format('Z');
  const offset = Number(utcOffset.split(':')[0].replace('+0', '').replace('-0', '-'));
  return {
    label,
    value: zone,
    offset,
  };
}), 'offset');

export const option = (value: any, label?: string | undefined): Option => ({ label: label || '', value });

export const renderLocationAddress = (loc: any) => [
  loc?.name,
  loc?.address1,
  loc?.address2,
  loc?.zip,
  loc?.city,
  loc?.country,
].filter((it) => !!it).join(', ');

export const joinOptions = (
  options: Option[] | OfferTypeDictionary[] | null,
) => options && options.map((it) => it.label).join(', ');

export const getUserName = (user: any) => {
  if (user && user.profile) {
    const { firstName, middleName, lastName } = user.profile;
    return [firstName, middleName, lastName].filter((it) => !!it).map((it) => it.trim()).join(' ');
  }
  return '';
};
