import { makeStyles, Paper } from '@material-ui/core';
import React from 'react';
import { Message } from 'twilio-chat';
import { prettyDateTime } from '../../services/Utils';

const useStyles = makeStyles((theme) => ({
  messageLeft: {
    display: 'flex',
    flexDirection: 'column',
    padding: 15,
    marginBottom: 15,
    borderRadius: 15,
    width: '80%',
    marginRight: 'auto',
    background: '#66bb6a',
    color: '#fff',
  },
  messageRight: {
    display: 'flex',
    flexDirection: 'column',
    padding: 15,
    marginBottom: 15,
    borderRadius: 15,
    width: '80%',
    marginLeft: 'auto',
    background: '#0288d1',
    color: '#fff',
  },
  messageHeader: {
    display: 'flex',
    flexGrow: 1,
    fontSize: 14,
    fontWeight: 600,
  },
  messageBody: {
    padding: '10px 0',
    overflowWrap: 'break-word',
    fontWeight: 500,
    whiteSpace: 'pre-wrap',
  },
  messageFooter: {
    display: 'flex',
    justifyContent: 'flex-end',
    fontSize: 10,
    color: '#fff',
  },
  actionButton: {
    padding: 0,
    color: '#fff',
  },
}));

export interface MessageProps {
  message: Message,
  isIncome: boolean,
  userName: string,
  onEdit?: (message: Message) => void,
}

export default function MessageItem(props: MessageProps) {
  const { message, isIncome, userName } = props;
  const classes = useStyles();
  return (
    <Paper
      elevation={5}
      className={isIncome ? classes.messageLeft : classes.messageRight}
    >
      <div className={classes.messageHeader}>
        {userName}
        {/* { !isIncome && ( */}
        {/* <div style={{ marginLeft: 'auto' }}> */}
        {/*  <IconButton className={classes.actionButton} onClick={() => onEdit(message)}> */}
        {/*    <Edit fontSize="small" /> */}
        {/*  </IconButton> */}
        {/*  <IconButton className={classes.actionButton} onClick={() => message.remove()}> */}
        {/*    <Delete fontSize="small" /> */}
        {/*  </IconButton> */}
        {/* </div> */}
        {/* ) } */}
      </div>
      <div className={classes.messageBody}>{message.body}</div>
      <div className={classes.messageFooter}>{prettyDateTime(message.dateCreated)}</div>
    </Paper>
  );
}
