import { createAsyncThunk } from '@reduxjs/toolkit';
import { axios_Core } from 'SHARED/helpers/axios';
import notification from 'SHARED/helpers/notifications';
import { OfferBid } from 'SHARED/types/bidTypes';
import { OfferEndpoints } from 'SHARED/types/routes';

// ===== BID HISTORY =====
interface IBidHistoryArgs {
  offerId: number | string
  buyerId: number
}
const bidHistory = createAsyncThunk(
  'bids/bidHistory',
  async (args: IBidHistoryArgs, { rejectWithValue }) => {
    const { offerId, buyerId } = args;

    try {
      const response = await axios_Core.get<OfferBid[]>(OfferEndpoints.bidHistory, { params: { offerId, buyerId } });

      return response.data;
    } catch (error) {
      console.log('bidHistory error', error);

      notification({
        type: 'danger',
        title: 'Error',
        message: 'Could not get bid history...',
      });

      return rejectWithValue('Could not get bid history...');
    }
  },
);
// ===== BID HISTORY ===== END

export const asyncBidsActions = {
  bidHistory,
};
