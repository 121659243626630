import { Button, Typography } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { CreditLineRow, CreditLineRowCondition } from '../../services/api/CreditService';
import { Page } from '../../services/api/RestUtil';
import { formatMoney, prettyDate } from '../../services/Utils';
import DataTable from '../DataTable';
import api from '../../services/api';
import Modal from '../Modal';
import colors from '../StyleConfig';
import CreditLineView from '../forms/CreditLineView';

export default function FinancingTable() {
  const [lineId, setLineId] = useState<number>(0);
  const [groups, setGroups] = useState<Array<CreditLineRow>>([]);
  const [globalError, setGlobalError] = useState<string>('');
  const [open, setOpen] = useState<boolean>(false);

  const columns = [
    { field: 'buyer', label: 'BUYER', render: (row: CreditLineRowCondition) => row.buyer.label },
    { field: 'providerType', label: 'FINANCING PARTNER TYPE', render: (row: CreditLineRowCondition) => row.providerType.label },
    { field: 'provider', label: 'FINANCING ORGANISATION', render: (row: CreditLineRowCondition) => row.provider.label },
    { field: 'seller', label: 'FOR SELLER', render: (row: CreditLineRowCondition) => row.seller.label },
    { field: 'validTo', label: 'CREDIT VALID TILL', render: (row: CreditLineRowCondition) => prettyDate(row.validTo) },
    { field: 'totalLimit', label: 'CREDIT AMOUNT', render: (row: CreditLineRowCondition) => `${row.currency} ${formatMoney(row.totalLimit)}` },
    { field: 'remainingLimit', label: 'REMAINING CREDIT', render: (row: CreditLineRowCondition) => `${row.currency} ${formatMoney(row.remainingLimit)}` },
    { field: 'status', label: 'STATUS', render: (row: CreditLineRowCondition) => row.status.label },
  ];

  useEffect(() => {
    !open && api.credit.getLines().then(setGroups).catch(setGlobalError);
  }, [open]);

  const openInPopup = (item: any) => {
    setLineId(item);
    setOpen(true);
  };

  const getRows = (conditions: Array<CreditLineRowCondition>) => {
    const page: Page<CreditLineRowCondition> = {
      rows: _.sortBy(conditions, (it) => it.status.value),
      totalPageCount: 1,
      totalRowCount: conditions.length,
    };
    return new Promise<Page<CreditLineRowCondition>>((resolve) => resolve(page));
  };

  const renderGroup = (group: CreditLineRow) => (
    <DataTable
      name={group.buyer.label}
      columns={columns}
      onRowClick={(row: CreditLineRowCondition) => { setLineId(row.lineId); setOpen(true); }}
      fetchRows={() => getRows(group.conditions)}
    />
  );

  return (
    <>
      <div style={{ display: 'flex' }}>
        <Typography style={{ flexGrow: 1 }} variant="h5">Financing</Typography>
        <Button
          variant="contained"
          color="primary"
          style={{ background: colors.primaryGreen }}
          startIcon={<Add />}
          onClick={() => { openInPopup(0); }}
        >
          Add credit line
        </Button>
      </div>
      { globalError && <Alert severity="error">{ globalError }</Alert> }
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
        { groups && groups.map(renderGroup) }
      </div>
      <Modal title="Credit line" open={open} setOpen={setOpen}>
        { open && <CreditLineView lineId={lineId} setOpen={setOpen} /> }
      </Modal>
    </>
  );
}
