import { AxiosInstance, AxiosResponse } from 'axios';
import { CORE_ENDPOINTS, DOCUMENT_ENDPOINTS } from 'SHARED/api/endpoints';
import { axios_Core, axios_Documents } from 'SHARED/helpers/axios';
import notification from 'SHARED/helpers/notifications';
import { ApiType } from 'SHARED/types/common';
import { FilesResponce } from 'SHARED/types/offerTypes';
import fileSchema from 'SHARED/validators/common/fileSchema';

const uploadFiles = (type: ApiType) => async (formData: FormData) => {
  const isCore = type === 'CORE';
  const isDocuments = type === 'DOCUMENTS';

  let axiosInstance: AxiosInstance = axios_Core;
  let endpoint: string = CORE_ENDPOINTS.fileUpload;

  if (isCore) {
    axiosInstance = axios_Core;
    endpoint = CORE_ENDPOINTS.fileUpload;
  }

  if (isDocuments) {
    axiosInstance = axios_Documents;
    endpoint = DOCUMENT_ENDPOINTS.fileUpload;
  }

  const headers = { 'Content-Type': 'multipart/form-data' };

  try {
    const response: AxiosResponse<FilesResponce> = await axiosInstance.post(
      endpoint,
      formData,
      { headers },
    );

    const validateFilesResponse = fileSchema.array().safeParse(response.data.data);

    if (validateFilesResponse.success) {
      return response.data.data;
    }
    notification({
      type: 'danger',
      title: 'Validation error',
      message: 'Files response is not valid',
    });

    throw new Error('Files response is not valid');
  } catch (error) {
    console.error('Upload documents files error:', error);

    return [];
  }
};

export const uploadCoreFiles = uploadFiles('CORE');
export const uploadDocumentsFiles = uploadFiles('DOCUMENTS');
