import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import toast from 'react-hot-toast';
import {
  Button as MuiButton,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import FormSelect from 'components/hook-form/FormSelect';
import validationRules from 'components/hook-form/validationRules';
import getLogisticLocations from 'api/get/logisticLocations';
import useLogisticsPricesStore from 'store/addLogisticsPrices';
import {
  ILocationType,
  prepareCityOptions, prepareCountryOptions, prepareLaneRequestParams, preparePortLocationOptions, prepareSellerOptions, prepareZipCodeOptions,
} from 'helpers/logisticsLocations';
import Button from 'components/hook-form/Button';
import getLogisticLanePrices from 'api/get/logisticLanePrices';
import { Link } from 'react-router-dom';
import pages from 'navigation/pages';
import colors from 'components/StyleConfig';
import { Alert } from '@mui/material';
import LocationSelector from './LocationSelector';

interface ILaneFormValues {
  seller: string | number;

  // ? DEPARTURE
  countryIdFrom: string | number;
  locationTypeFrom: ILocationType;
  // if port
  seaPortIdFrom?: string | number;
  // if non-port
  cityFrom?: string;
  zipFrom?: string;

  // ? DESTINATION
  countryIdTo: string | number;
  locationTypeTo: ILocationType;
  // if port
  seaPortIdTo?: string | number;
  // if non-port
  cityTo?: string;
  zipTo?: string;
}

const SelectLaneForm = () => {
  const [fetchLocationsError, setFetchLocationsError] = useState(false);

  const {
    sellers,
    destinationCountries,

    setSellers,
    setDestinationCountries,
    setSelectedLocations,
    setLaneData,
  } = useLogisticsPricesStore((state) => state);

  const methods = useForm<ILaneFormValues>({
    defaultValues: {
      locationTypeFrom: 'PORT',
      locationTypeTo: 'PORT',
    },
  });
  const { handleSubmit, watch, setValue } = methods;

  // departure
  const seller = watch('seller');
  const countryIdFrom = watch('countryIdFrom');
  const locationTypeFrom = watch('locationTypeFrom');
  const seaPortIdFrom = watch('seaPortIdFrom');
  const cityFrom = watch('cityFrom');
  const zipFrom = watch('zipFrom');

  // destination
  const countryIdTo = watch('countryIdTo');
  const locationTypeTo = watch('locationTypeTo');
  const seaPortIdTo = watch('seaPortIdTo');
  const cityTo = watch('cityTo');
  const zipTo = watch('zipTo');

  const countriesFromFilteredBySeller = sellers.find((item: any) => item.id === seller)?.countries || [];

  // === conditions ===
  const isSubmitDisabled = () => {
    if (!countryIdFrom) return true;
    if (!countryIdTo) return true;
    if (locationTypeFrom === 'PORT' && !seaPortIdFrom) return true;
    if (locationTypeFrom === 'NON-PORT' && !zipFrom) return true;
    if (locationTypeTo === 'PORT' && !seaPortIdTo) return true;
    if (locationTypeTo === 'NON-PORT' && !zipTo) return true;

    return false;
  };
  // === conditions ===

  // === OPTIONS ===
  // from
  const sellerOptions = prepareSellerOptions(sellers);
  const countryFromOptions = prepareCountryOptions(countriesFromFilteredBySeller);
  const portFromOptions = preparePortLocationOptions(countriesFromFilteredBySeller, countryIdFrom);
  const cityFromOptions = prepareCityOptions(countriesFromFilteredBySeller, countryIdFrom);
  const zipFromOptions = prepareZipCodeOptions(countriesFromFilteredBySeller, countryIdFrom, cityFrom);
  // to
  const countryToOptions = prepareCountryOptions(destinationCountries);
  const portToOptions = preparePortLocationOptions(destinationCountries, countryIdTo);
  const cityToOptions = prepareCityOptions(destinationCountries, countryIdTo);
  const zipToOptions = prepareZipCodeOptions(destinationCountries, countryIdTo, cityTo);
  // === OPTIONS END ===

  const resetFieldsOnSellerChange = () => {
    setValue('countryIdFrom', '');
    setValue('seaPortIdFrom', '');
    setValue('cityFrom', '');
    setValue('zipFrom', '');
  };

  const locations = useQuery(
    ['logisticsLocations'],
    () => getLogisticLocations(),
    {
      onError: (err: AxiosError) => {
        setFetchLocationsError(true);
        toast.error(err.message);
      },
      onSuccess: (data) => {
        toast.success('Locations fetched');
        setSellers(data.sellers);
        setDestinationCountries(data.destinationCountries);
      },
    },
  );

  const lanePricesMutation = useMutation(getLogisticLanePrices, {
    onError: (err: AxiosError) => toast.error(err.message),
    onSuccess: (data) => {
      setFetchLocationsError(false);
      toast.success('Lane prices fetched');
      setSelectedLocations({
        seller,
        countryIdFrom,
        locationTypeFrom,
        seaPortIdFrom,
        cityFrom,
        zipFrom,

        countryIdTo,
        locationTypeTo,
        seaPortIdTo,
        cityTo,
        zipTo,
      });
      setLaneData(data);
    },
  });

  const onSubmit = (data: any) => {
    // console.log(data);

    const { laneRequestParams, allParamsTrueCheck } = prepareLaneRequestParams(data);

    if (allParamsTrueCheck) {
      lanePricesMutation.mutate(laneRequestParams);
    } else {
      toast.error('Some fields are empty');
    }
  };

  useEffect(() => {
    // ? reset on seller change
    resetFieldsOnSellerChange();
  }, [seller]);

  return (
    <section>
      <div>
        <MuiButton
          variant="contained"
          color="primary"
          style={{ background: colors.primaryGreen }}
          component={Link}
          to={pages.catalogue_logisticPrices}
          startIcon={<ArrowBackIcon />}
        >
          Back
        </MuiButton>

        <br />
        <br />
      </div>

      <h2>1. Select a lane {locations.isFetching && '(Loading...)'}</h2>

      {fetchLocationsError && (
        <Alert severity="error" style={{ marginBottom: 20 }}>
          Could not fetch locations. Please try to refresh the page.
        </Alert>
      )}

      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          <div className="two-col-grid two-col-grid--l">
            <FormSelect
              name="seller"
              label="From seller*"
              options={sellerOptions}
              disabled={!sellerOptions.length}
              rules={validationRules.notRequired}
            />

            <span />
          </div>

          <div className="two-col-grid two-col-grid--l">
            <LocationSelector
              title="Departure"
              type="From"
              countryOptions={countryFromOptions}
              portOptions={portFromOptions}
              cityOptions={cityFromOptions}
              zipOptions={zipFromOptions}
            />

            <LocationSelector
              title="Destination"
              type="To"
              countryOptions={countryToOptions}
              portOptions={portToOptions}
              cityOptions={cityToOptions}
              zipOptions={zipToOptions}
            />
          </div>

          <br />

          <Button
            type="submit"
            label="Find lane"
            loading={lanePricesMutation.isLoading}
            disabled={isSubmitDisabled()}
          />
        </form>
      </FormProvider>

    </section>
  );
};

export default SelectLaneForm;
