import moment from 'moment';
import { Option } from '../../components/controls/Select';
import { Organization } from './OrganizationService';
import { get, post, put } from './RestUtil';
import { IUser } from './UserService';

export const OfferTypes = {
  OFF_SPEC: 'OFF_SPEC',
  ALREADY_PRODUCED: 'ALREADY_PRODUCED',
  SIMPLE_ALREADY_PRODUCED: 'SIMPLE_ALREADY_PRODUCED',
  RFP: 'RFP',
  RFP_PROPOSAL: 'RFP_PROPOSAL',
  TO_BE_PRODUCED: 'TO_BE_PRODUCED',
  TO_BE_PRODUCED_BUNDLE: 'TO_BE_PRODUCED_BUNDLE',
};

interface Dictionary {
  value: string
  label: string
}

export interface OfferOffSpecSimple {
  id: number
  type: string
  status: string
  title: string
  totalVolume: number
  remainingVolume: number
  volumeUnits: string
  price: number
  currency: string
  created: number
  endDate?: number
  loadDateFrom: number
  loadDateTo: number
  auctionEndDate: number
  seller: string
  country: string
  buyer: string
  bidders: number
  matches: number
  ourLastBid: number
  deal: Deal
  statusLabel: string
  splittable: boolean
  minBidVolume?: number | null
}

export interface OfferOffSpec {
  id?: number
  // type: string
  type: Dictionary
  status: string
  title: string
  description: string
  totalVolume: number
  volumeUnits: Option
  documentCapabilities: Option[]
  packaging: Option
  salesTerms: Option
  incoterms: Option
  paymentTerms: Option
  loadAddress: Option
  loadOptions: Option[]
  documents: object,
  price: number
  currency: string
  created: number
  loadDateFrom: number
  loadDateTo: number
  endDate?: number
  ownerOrg: Organization
  ownerUser: IUser
  country: string
  buyer: string
  buyers: Option[]
  bidders: number
  ourLastBid: number
  deal: Deal
  splittable: boolean
  minBidVolume?: number | null
  cancellationReason?: string
}

export interface IAnalyticsVisit{
  user: {
    label: string
    value: number // id
  }
  company: {
    label: string
    value: number // id
  }
  userType: string
  events: {
    // event: {
    //   label: string
    //   value: string
    // }
    eventTime: number
  }[]
}

export interface IOfferAnalytics {
  offerId: number,
  visits: IAnalyticsVisit[]
}

export interface OrganizationBids {
  buyer:Organization
  latestBid: Bid
  deletedBids: Bid[]
  previousBids: Bid[]
}

export interface Bid {
  id: number
  action: string
  actionTime: number
  active: boolean
  orgName: string
  userName: string
  value: number
  bidVolume: number
  offerTotalPrice: number
  currency: string
}

export interface Deal {
  amount: number
  buyer: IUser
  buyerOrg: Organization
  created: string
  currency: string
  number: string
  price: number
  seller: IUser
  sellerOrg: Organization
  totalVolume: number
  volumeUnits: string
  volume: number
  id: number
}

export const emptyOffer: any = {
  type: '',
  organizationId: '',
  ownerUserId: '',
  buyersFetchType: 'INCLUDE_ALL',
  endDate: moment(),
  expirationDate: moment().add(1, 'day'),
  productionDate: moment().subtract(1, 'day'),
  priceEurDisabled: false,
  priceEur: 0,
  priceUsdDisabled: false,
  priceUsd: 0,
  coaUsed: false,
  splittable: false,
  customLabelPossible: false,
  productCharacteristics: [],
  departurePeriod: 'MONTH',
  departureFrom: moment().startOf('month'),
  departureTo: '',
  logisticLocationId: '',
  productionLocationId: '',
  productId: '',
  productIds: [],
  totalVolume: 0,
  remainingVolume: null,
  volumeUnits: {
    label: 'MT',
    value: 'MT',
  },
  loadOptions: [],
  packaging: '',
  salesTerms: 'MPC',
  paymentTerms: '',
  paymentOffset: null,
  incoterms: '',
  documentCapabilities: [],
  documents: {},
  buyers: [],
  category: '',
  productType: '',
};

export class OfferService {
  getOffersOffSpec = (pageRequest: any) => this.getOffers({ ...pageRequest, types: 'OFF_SPEC' });

  getOffers = (pageRequest: any) => get('/v1/offers', pageRequest);

  getDeals = (request: any) => get('/v1/deals', request);

  getDealById = (id: number | string) => get(`/v1/deals/${id}`);

  getOfferById = (id: number, setOffer: (resp: OfferOffSpec) => void, setError: any) => {
    get(`/v1/offers/${id}`).then(setOffer).catch(setError);
  };

  getOfferAnalyticsById = (
    id: number,
    setAnalytics: (resp: IOfferAnalytics) => void,
    setError: any,
  ) => {
    get(`/v1/offers/${id}/analytics`)
      .then(setAnalytics)
      .catch(setError);
  };

  createOffer = (request: object) => post('/v1/offers', request);

  updateOffer = (request: object) => put('/v1/offers', request);

  cancelOffer = (id: number, reason: string) => put(`/v1/offers/off-spec/${id}`, { action: 'CANCEL', cancellationReason: reason });

  getBidHistory = (id: number,
    setHistory: (resp: any) => void,
    setError: (resp: any) => void) => {
    get(`/v1/offers/off-spec/${id}/bids/all`).then(setHistory).catch(setError);
  };

  getNegotiationHistory = (
    offerId: number,
    buyerId: number,
    setHistory: (resp: any) => void,
    setError: (resp: any) => void,
  ) => {
    get('/v1/negotiation/bid/history', { offerId, buyerId }).then(setHistory).catch(setError);
  };

  getOfferHistory = (offerId: number) => get(`/v1/offers/${offerId}/history`);
}
