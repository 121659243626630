import React, { ReactNode } from 'react';
import {
  Dialog, DialogTitle, DialogContent, makeStyles, Typography,
} from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import Controls from './controls/Controls';

const useStyles = makeStyles((theme) => ({
  dialogWrapper: {
    padding: theme.spacing(2),
    position: 'fixed',
    // top: theme.spacing(4),
    top: '30px',
    // width: '95vw',
    width: '100%',
    // maxWidth: '1600px',
    maxHeight: 'calc(95vh - 80px)',
  },
  dialogTitle: {
    paddingRight: '0px',
  },
}));

export interface ModalProps {
  title: string | ReactNode,
  children: any,
  open: boolean,
  setOpen(val: boolean): void
}

export default function Modal(props: ModalProps) {
  const {
    title, children, open, setOpen,
  } = props;
  const classes = useStyles();

  return (
    <Dialog open={open} maxWidth="lg" classes={{ paper: classes.dialogWrapper }}>
      <DialogTitle className={classes.dialogTitle}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="h5" style={{ flexGrow: 1 }}>
            {title}
          </Typography>
          <Controls.Button variant="text" onClick={() => setOpen(false)}>
            <HighlightOffIcon fontSize="large" />
          </Controls.Button>
        </div>
      </DialogTitle>
      <DialogContent dividers>
        {children}
      </DialogContent>
    </Dialog>
  );
}
