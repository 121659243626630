import { get } from './RestUtil';

export interface TwilioCredentials {
  token: string,
  channelSid: string,
  identity: string,
}

export default class ChatService {
  getCredentials = (dealId?: number): Promise<TwilioCredentials> => get('/v1/chat/credentials', { dealId });
}
