import React, { useState } from 'react';
import { Button } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { emptyLocation, LocationModel } from '../../services/api/LocationService';
import DataTable from '../DataTable';
import LocationForm from '../forms/LocationForm';
import Modal from '../Modal';
import api from '../../services/api';
import colors from '../StyleConfig';

const initialState = emptyLocation;

export interface LocationTableProps {
  orgType: 'SELLER' | 'BUYER',
  orgId: number
}

export default function LocationTable(props: LocationTableProps) {
  const [open, setOpen] = useState(false);
  const [recordForEdit, setRecordForEdit] = useState(initialState);
  const { orgType, orgId } = props;
  const logisticColumns = [
    { field: 'name', label: 'LOCATION NAME', render: (row: LocationModel) => row.name || '-' },
    { field: 'address1', label: 'ADDRESS LINE 1', render: (row: LocationModel) => row.address1 },
    { field: 'city', label: 'CITY', render: (row: LocationModel) => row.city },
    { field: 'seaPortId', label: 'SEA PORT', render: (row: LocationModel) => row.seaPortName },
    { field: 'isMain', label: 'MAIN', render: (row: LocationModel) => row.main && 'Main' },
    { field: 'isActive', label: 'STATUS', render: (row: LocationModel) => (row.active ? 'Active' : 'Inactive') },
  ];
  const productionColumns = [
    { field: 'name', label: 'LOCATION NAME', render: (row: LocationModel) => row.name || '-' },
    { field: 'address1', label: 'ADDRESS LINE 1', render: (row: LocationModel) => row.address1 },
    { field: 'city', label: 'CITY', render: (row: LocationModel) => row.city },
    { field: 'isMain', label: 'MAIN', render: (row: LocationModel) => row.main && 'Main' },
    { field: 'isActive', label: 'STATUS', render: (row: LocationModel) => (row.active ? 'Active' : 'Inactive') },
  ];

  const openInPopup = (item: any) => {
    setRecordForEdit(item);
    setOpen(true);
  };

  const renderAddButton = (locCategory: 'LOGISTIC' | 'PRODUCTION') => (
    <Button
      variant="contained"
      color="primary"
      style={{ background: colors.primaryGreen }}
      startIcon={<Add />}
      onClick={() => { openInPopup({ ...emptyLocation, category: locCategory !== 'LOGISTIC' ? 'PRODUCTION' : 'LOGISTIC' }); }}
    >
      { `Add ${locCategory.toLowerCase()} location` }
    </Button>
  );

  const getModalTitle = () => (
    recordForEdit.id && recordForEdit.id > 0
      ? `Update ${recordForEdit.category.toLowerCase()} location`
      : `Create ${recordForEdit.category.toLowerCase()} location`
  );

  return (
    <>
      <DataTable
        name={orgType === 'SELLER' ? 'Loading locations' : 'Delivery locations'}
        columns={logisticColumns}
        onRowClick={openInPopup}
        addButton={renderAddButton('LOGISTIC')}
        reload={open}
        fetchRows={(pageRequest) => api.location.getLocations(orgId, 'LOGISTIC', pageRequest)}
      />
      {
        orgType === 'SELLER'
        && (
        <DataTable
          name="Production locations"
          columns={productionColumns}
          onRowClick={openInPopup}
          addButton={renderAddButton('PRODUCTION')}
          reload={open}
          fetchRows={(pageRequest) => api.location.getLocations(orgId, 'PRODUCTION', pageRequest)}
        />
        )
      }
      <Modal title={getModalTitle()} open={open} setOpen={setOpen}>
        <LocationForm
          orgId={orgId}
          orgType={orgType}
          location={recordForEdit}
          setOpen={setOpen}
        />
      </Modal>
    </>
  );
}
