import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { timezonesList } from 'services/Utils';
import { emailRegExp, IUser } from '../../services/api/UserService';
import api from '../../services/api';
import { Form, useForm } from '../../hooks/useForm';
import Controls from '../controls/Controls';
import { Option } from '../controls/Select';

let initialState: IUser = {
  id: null,
  type: '',
  status: 'ACTIVE',
  login: '',
  password: '',
  roleId: '',
  profile: {
    id: null,
    salutation: 'MR',
    firstName: '',
    middleName: '',
    lastName: '',
    email: '',
    jobTitle: '',
    phoneNumber: '',
    language: 'English',
    timeZone: 'UTC+1/CET',
    smsEnabled: false,
  },
};

export interface UserFormProps {
  user: IUser
  userType: 'ADMIN' | 'SELLER' | 'BUYER'
  roles: Array<Option>
  defaultRole: Option
  orgId?: number
  setOpen(val: boolean): void
}

const salutations = [{ value: 'MR', label: 'Mr.' }, { value: 'MRS', label: 'Mrs.' }];
const statuses = [{ value: 'ACTIVE', label: 'Active' }, { value: 'BLOCKED', label: 'Blocked' }];

export default function UserForm(props: UserFormProps) {
  const {
    user, userType, defaultRole, roles, setOpen, orgId,
  } = props;

  if (user) {
    initialState = { ...user.profile, ...user, type: user.type };
  }

  const {
    values, setValues, errors, setErrors, handleInputChange, resetForm,
  } = useForm({
    ...initialState.profile,
    ...initialState,
    type: userType,
    phoneNumber: initialState?.profile?.phoneNumber || '',
    roleId: initialState.roleId?.toString() || defaultRole.value.toString(),
  });

  const [globalError, setGlobalError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (!validate()) {
      setGlobalError('Validation failed, please fill required fields');
      return;
    }
    setGlobalError('');
    const userTypeValue = !values.type ? userType : values.type;
    const userModel: IUser = {
      id: values.id,
      type: userTypeValue,
      status: values.status || 'ACTIVE',
      roleId: +values.roleId || +defaultRole.value,
      login: values.email,
      profile: {
        id: user?.profile?.id,
        salutation: values.salutation || 'MR',
        firstName: values.firstName,
        middleName: values.middleName,
        lastName: values.lastName,
        jobTitle: values.jobTitle,
        email: values.email,
        phoneNumber: values.phoneNumber || null,
        language: values.language,
        timeZone: values.timeZone?.value || 'Europe/Amsterdam',
        smsEnabled: (values.phoneNumber && values.smsEnabled),
      },
    };

    if (orgId) {
      userModel.orgId = orgId;
    }

    let error = false;
    setIsLoading(true);
    if (values.id) {
      await api.user.updateUser(values.id, userModel)
        .catch((err) => { error = true; setGlobalError(err.message); });
    } else {
      userModel.password = values.password;
      await api.user.createUser(userModel)
        .catch((err) => { error = true; setGlobalError(err.message); });
    }
    if (!error) {
      setOpen(false);
    }
    setIsLoading(false);
  };

  function validate() {
    setGlobalError('');
    const temp: any = {};
    const requiredFields = ['type', 'roleId', 'email', 'firstName', 'lastName'];
    userType !== 'ADMIN' && requiredFields.push('language', 'timeZone');

    requiredFields.forEach((key) => {
      if (!values[key]) {
        temp[key] = 'This field is required.';
      }
    });

    (values.email && !emailRegExp.test(String(values.email).toLowerCase())) && (temp.email = 'Invalid email format');

    setErrors({ ...temp });

    return Object.keys(temp).length === 0;
  }

  const handlePhoneNumberChange = (e: any) => {
    const val: string = e.target.value;
    const allowedSymbols = ['+', '-', '(', ')', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
    let correctValue = true;
    val?.split('').forEach((it) => {
      if (!allowedSymbols.includes(it)) {
        correctValue = false;
      }
    });
    if (correctValue) {
      handleInputChange(e);
    }
    if (!val) {
      setValues({ ...values, phoneNumber: '', smsEnabled: false });
    }
  };

  return (
    <Form name="create-admin">
      { globalError && <Alert severity="error">{globalError}</Alert> }
      <Grid container>
        <Grid item>
          <Controls.RadioGroup
            name="salutation"
            label="TITLE"
            value={values.salutation || 'MR'}
            items={salutations}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item>
          <Controls.RadioGroup
            name="roleId"
            label="ROLE"
            value={values.roleId || defaultRole.value}
            items={roles}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item>
          <Controls.RadioGroup
            name="status"
            label="STATUS"
            value={values.status || 'ACTIVE'}
            items={statuses}
            onChange={handleInputChange}
          />
        </Grid>
      </Grid>
      <Controls.Input
        name="firstName"
        label="First name"
        value={values.firstName}
        error={errors.firstName}
        onChange={handleInputChange}
        required
      />
      <Controls.Input
        name="middleName"
        label="Middle name"
        value={values.middleName}
        error={errors.middleName}
        onChange={handleInputChange}
      />
      <Controls.Input
        name="lastName"
        label="Last name"
        value={values.lastName}
        error={errors.lastName}
        onChange={handleInputChange}
        required
      />
      <Controls.Input
        name="email"
        label="Email(login)"
        type="email"
        autoComplete="off"
        value={values.email}
        error={errors.email}
        onChange={handleInputChange}
        required
      />
      {/* { */}
      {/*  (!values.id) */}
      {/*  && ( */}
      {/*    <Controls.Input */}
      {/*      name="password" */}
      {/*      type="password" */}
      {/*      label="Password" */}
      {/*      autoComplete="new-password" */}
      {/*      value={values.password} */}
      {/*      error={errors.password} */}
      {/*      onChange={handleInputChange} */}
      {/*      required */}
      {/*    /> */}
      {/*  ) */}
      {/* } */}
      {
        userType !== 'ADMIN' && (
          <>
            <Controls.Input
              name="jobTitle"
              label="Job title"
              value={values.jobTitle}
              error={errors.jobTitle}
              onChange={handleInputChange}
            />
            <Controls.Input
              name="phoneNumber"
              label="Phone number"
              value={values.phoneNumber}
              error={errors.phoneNumber}
              onChange={handlePhoneNumberChange}
            />
            <Controls.Select
              name="language"
              label="Preferred language"
              value={values.language}
              error={errors.language}
              onChange={handleInputChange}
              options={[{ label: 'English', value: 'English' }]}
              required
            />

            <Controls.AutocompleteSelect
              name="timeZone"
              label="Preferred time zone *"
              value={
                values.timeZone?.label
                  ? values.timeZone
                  : timezonesList.find((it) => it.value === values.timeZone)
              }
              error={errors.timeZone}
              onChange={handleInputChange}
              options={timezonesList}
              required
            />

            <Controls.Checkbox
              name="smsEnabled"
              label={`SMS notification enabled ${!values.phoneNumber ? '(need phone number)' : ''}`}
              value={values.smsEnabled}
              onChange={handleInputChange}
              disabled={!values.phoneNumber}
            />
          </>
        )
      }
      <div>
        <Controls.Button type="submit" onClick={handleSubmit} disabled={isLoading}>Submit</Controls.Button>
        <Controls.Button color="default" onClick={resetForm}>Reset</Controls.Button>
      </div>
    </Form>
  );
}
