import { Tab, Tabs } from '@material-ui/core';
import React, { useState } from 'react';
import { Link, useHistory, withRouter } from 'react-router-dom';
import exactLinkCheck from 'helpers/exactLinkCheck';
import pages from 'navigation/pages';
import api from '../../services/api';
import { OfferTypes } from '../../services/api/OfferService';
import { SortOrder } from '../../services/api/RestUtil';
import DataTable from '../DataTable';
import { formatMoney, prettyDate } from '../../services/Utils';

const activeColumns = [
  {
    field: 'created',
    label: 'RFP FROM',
    render: (row: any) => prettyDate(row.created),
  },
  {
    field: 'endDate',
    label: 'RFP END',
    render: (row: any) => prettyDate(row.endDate),
  },
  {
    field: 'buyer',
    label: 'BUYER',
    render: (row: any) => row.buyerName,
  },
  {
    field: 'product',
    label: 'PRODUCT ',
    render: (row: any) => `${row.productTitle} (${row.productCategory} ${row.productType})`,
  },
  {
    field: 'volume',
    label: 'VOLUME',
    render: (row: any) => `Min: ${row.minVolume} Max: ${row.maxVolume} ${row.volumeUnits.label}`,
  },
  {
    field: 'departure',
    label: 'DELIVERY',
    render: (row: any) => `${prettyDate(row.departureFrom)} - ${prettyDate(row.departureTo)}`,
  },
  {
    field: 'proposals',
    label: 'PROPOSALS',
    render: (row: any) => row.proposalsCount,
  },
  {
    field: 'status',
    label: 'STATUS',
    render: (row: any) => row.statusLabel,
  },
];
const archivedColumns = [
  {
    field: 'endDate',
    label: 'ENDED ON',
    render: (row: any) => prettyDate(row.endDate),
  },
  {
    field: 'buyer',
    label: 'BUYER',
    render: (row: any) => row.buyerName,
  },
  {
    field: 'product',
    label: 'PRODUCT ',
    render: (row: any) => `${row.productTitle} (${row.productCategory} ${row.productType})`,
  },
  {
    field: 'volume',
    label: 'VOLUME',
    render: (row: any) => `Min: ${row.minVolume} Max: ${row.maxVolume} ${row.volumeUnits.label}`,
  },
  {
    field: 'departure',
    label: 'DEPARTURE',
    render: (row: any) => `${prettyDate(row.departureFrom)} - ${prettyDate(row.departureTo)}`,
  },
  {
    field: 'deals',
    label: 'DEALS',
    render: (row: any) => row.dealsCount || 0,
  },
  {
    field: 'status',
    label: 'STATUS',
    render: (row: any) => row.statusLabel,
  },
];

const dealsColumns = [
  {
    field: 'created',
    label: 'DEAL ON',
    render: (row: any) => prettyDate(row?.created),
  },
  {
    field: 'product',
    label: 'PRODUCT ',
    render: (row: any) => `${row.productCategory} (${row.productType})`,
  },
  {
    field: 'price',
    label: 'PRICE',
    render: (row: any) => `${formatMoney(row?.deal?.price)} ${row?.deal?.currency}`,
  },
  {
    field: 'volume',
    label: 'VOLUME',
    render: (row: any) => `${row?.deal?.volume} ${row?.deal?.volumeUnits}`,
  },
  {
    field: 'buyer',
    label: 'BUYER',
    render: (row: any) => row?.deal?.buyerOrg?.orgName,
  },
  {
    field: 'seller',
    label: 'SELLER',
    render: (row: any) => row?.deal?.sellerOrg?.orgName,
  },
  {
    field: 'delivery',
    label: 'DELIVERY',
    render: (row: any) => `${prettyDate(row?.departureFrom)} - ${prettyDate(row?.departureTo)}`,
  },

];

function RFPTable(props: any) {
  const { location } = useHistory();

  const [reload, setReload] = useState(false);

  const isActiveTab = exactLinkCheck(pages.rfp);
  const isArchivedTab = exactLinkCheck(pages.rfp_archived);
  const isDealsTab = exactLinkCheck(pages.rfp_deals);

  const tableName = () => {
    switch (location.pathname) {
      case pages.rfp_archived:
        return 'Archived RFPs';

      case pages.rfp_deals:
        return 'Deals';

      default: // pages.rfp
        return 'Active RFPs';
    }
  };

  const tableColumns = () => {
    switch (location.pathname) {
      case pages.rfp_archived:
        return archivedColumns;

      case pages.rfp_deals:
        return dealsColumns;

      default: // pages.rfp
        return activeColumns;
    }
  };

  const tableSort = {
    sort: 'created',
    order: SortOrder.Desc,
  };

  return (
    <div className="page-body">
      <Tabs
        value={location.pathname}
        onChange={() => { setReload(!reload); }}
      >
        <Tab
          label="Active"
          value={pages.rfp}
          to={pages.rfp}
          component={Link}
        />
        <Tab
          label="Archived"
          value={pages.rfp_archived}
          to={pages.rfp_archived}
          component={Link}
        />
        <Tab
          label="Deals"
          value={pages.rfp_deals}
          to={pages.rfp_deals}
          component={Link}
        />
      </Tabs>
      <DataTable
        name={tableName()}
        columns={tableColumns()}
        onRowClick={(offer) => {
          if (isDealsTab) {
            return props.history.push(`${pages.rfp_deals}/${offer?.deal?.id}`);
          }

          if (isArchivedTab) {
            return props.history.push(`${pages.rfp_archived}/${offer?.id}`);
          }

          return props.history.push(`${pages.rfp}/${offer?.id}`);
        }}
        reload={reload}
        defaultSort={tableSort}
        fetchRows={
          (pageRequest) => {
            if (isDealsTab) {
              return api.offer.getDeals({
                ...pageRequest,
                active: false,
                rfpProposal: true,
                statuses: ['DEAL'],
              });
            }

            return api.offer.getOffers({
              ...pageRequest,
              types: [OfferTypes.RFP],
              active: isActiveTab,
            });
          }
        }
      />
    </div>
  );
}

export default withRouter(RFPTable);
