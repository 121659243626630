import React, { FC, ReactNode, useEffect } from 'react';
import {
  Dialog, DialogContent, DialogTitle, IconButton,
} from '@mui/material';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { MUIBreakpoint } from 'types/common';

interface IProps {
  title: string
  children: ReactNode
  open: boolean
  handleClose: () => void
  isLoading?: boolean
  maxWidth?: MUIBreakpoint
}

const Popup: FC<IProps> = ({
  title,
  children,
  open,
  handleClose,
  isLoading = false,
  maxWidth = 'md',
}) => {
  const modalRef = React.createRef<HTMLDivElement>();

  // ? for some reason keydown event is not working with Esc key
  function escKeyUpHandler(e: KeyboardEvent) {
    if (isLoading) return;

    if (e.key === 'Escape' || e.key === 'Esc') {
      handleClose();
    }
  }

  useEffect(() => {
    document.addEventListener('keyup', escKeyUpHandler);

    return () => {
      document.removeEventListener('keyup', escKeyUpHandler);
    };
  }, []);

  return (
    <Dialog
      open={open}
      maxWidth={maxWidth}
      fullWidth
      className="popup-dialog"
      ref={modalRef}
    >

      <DialogTitle className="popup-dialog-title">

        <div style={{ display: 'flex', alignItems: 'center', gap: 20 }}>
          <h1 style={{ flexGrow: 1, margin: 0 }}>
            {title}
          </h1>

          <IconButton onClick={() => handleClose()}>
            <HighlightOffIcon fontSize="large" />
          </IconButton>
        </div>

      </DialogTitle>

      <DialogContent>
        {children}
      </DialogContent>

    </Dialog>
  );
};

export default Popup;
