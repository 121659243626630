import { Tab, Tabs } from '@material-ui/core';
import React, { useState } from 'react';
import { Link, useHistory, withRouter } from 'react-router-dom';
import CallSplitIcon from '@material-ui/icons/CallSplit';
import pages from 'navigation/pages';
import exactLinkCheck from 'helpers/exactLinkCheck';
import getDeals from 'api/get/getDealst';
import getOffers from 'api/get/getOffers';
import routes from 'SHARED/types/routes';
import { Add } from '@material-ui/icons';
import { Stack, Typography } from '@mui/material';
import { OfferOffSpecSimple } from '../../services/api/OfferService';
import { SortOrder } from '../../services/api/RestUtil';
import DataTable from '../DataTable';
import { formatMoney, prettyDate, prettyDateTime } from '../../services/Utils';

function OfferOffSpecTable(props: any) {
  const [reload, setReload] = useState(false);

  const { location } = useHistory();

  const getCurrency = (offer: OfferOffSpecSimple) => offer.deal?.currency || offer.currency;
  const getPrice = (offer: OfferOffSpecSimple) => offer.deal?.price || offer.price;
  const getUnits = (offer: OfferOffSpecSimple) => offer.deal?.volumeUnits || offer.volumeUnits;
  const getAmount = (offer: OfferOffSpecSimple) => offer.deal?.volume || offer.totalVolume;

  const offerColumns = [
    {
      field: 'created',
      label: 'PLACED',
      render: (row: OfferOffSpecSimple) => prettyDate(row.created),
    },
    {
      field: 'seller',
      label: 'SELLER',
      render: (row: OfferOffSpecSimple) => row.seller,
    },
    // {
    //   field: 'type',
    //   label: 'TYPE',
    //   render: (row: OfferOffSpecSimple) => row.type,
    // },
    {
      field: 'title',
      label: 'OFFER TITLE',
      render: (row: OfferOffSpecSimple) => row.title,
    },
    {
      field: 'volume',
      label: 'VOLUME',
      render: (row: OfferOffSpecSimple) => (
        <>
          {row.splittable && <CallSplitIcon style={{ marginRight: 4 }} />}
          {`${row.totalVolume} ${row.volumeUnits?.toLowerCase()}`}
        </>
      ),
    },
    {
      field: 'price',
      label: 'ASK PRICE',
      render: (row: OfferOffSpecSimple) => `${formatMoney(row.price)} ${row.currency} / ${row.volumeUnits}`,
    },
    {
      field: 'bidders',
      label: 'BIDDERS',
      render: (row: OfferOffSpecSimple) => row.bidders,
    },
    {
      field: 'buyer',
      label: 'BUYER',
      // hidden: !exactLinkCheck(pages.offSpecOffers_archived),
      hidden: true,
      render: (row: OfferOffSpecSimple) => row.buyer,
    },
    {
      field: 'status',
      label: 'STATUS',
      render: (row: OfferOffSpecSimple) => row.statusLabel,
    },
  ];
  const dealColumns = [
    {
      field: 'created',
      label: 'DEAL DONE',
      render: (row: OfferOffSpecSimple) => prettyDateTime(row.deal?.created),
    },
    {
      field: 'title',
      label: 'PRODUCT',
      render: (row: OfferOffSpecSimple) => row.title,
    },
    {
      field: 'price',
      label: 'DEAL NET PRICE',
      render: (row: OfferOffSpecSimple) => `${formatMoney(getPrice(row))} ${getCurrency(row)}  / ${getUnits(row)?.toLowerCase()}`,
    },
    {
      field: 'totalVolume',
      label: 'VOLUME',
      render: (row: OfferOffSpecSimple) => `${getAmount(row)} ${getUnits(row)?.toLowerCase()}`,
    },
    {
      field: 'seller',
      label: 'SELLER',
      render: (row: OfferOffSpecSimple) => row.deal?.sellerOrg?.orgName || row.seller,
    },
    {
      field: 'buyer',
      label: 'BUYER',
      render: (row: OfferOffSpecSimple) => row.deal?.buyerOrg?.orgName,
    },
  ];

  // DataTable props
  const tableName = () => {
    switch (location.pathname) {
      case pages.offSpecOffers_archived:
        return 'Archived auctions';

      case pages.offSpecOffers_deals:
        return 'Deals';

      default: // pages.offSpecOffers
        return 'Active auctions';
    }
  };
  const tableColumns = () => {
    switch (location.pathname) {
      case pages.offSpecOffers_deals:
        return dealColumns;

      default: // pages.offSpecOffers OR pages.offSpecOffers_archived
        return offerColumns;
    }
  };
  const tableSort = () => {
    switch (location.pathname) {
      case pages.offSpecOffers:
        return { sort: 'created', order: SortOrder.Desc };

      default:
        // return { sort: 'updated', order: SortOrder.Desc };
        return { sort: 'created', order: SortOrder.Desc };
    }
  };

  const isDealTab = exactLinkCheck(pages.offSpecOffers_deals);

  return (
    <div className="page-body">
      <Tabs
        value={location.pathname}
        onChange={() => { setReload(!reload); }}
      >
        <Tab
          label="Active"
          value={pages.offSpecOffers}
          to={pages.offSpecOffers}
          component={Link}
        />
        <Tab
          label="Archived"
          value={pages.offSpecOffers_archived}
          to={pages.offSpecOffers_archived}
          component={Link}
        />
        <Tab
          label="Deals"
          value={pages.offSpecOffers_deals}
          to={pages.offSpecOffers_deals}
          component={Link}
        />
      </Tabs>

      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        alignItems={{ xs: 'flex-start', sm: 'center' }}
        justifyContent={{ xs: 'flex-start', sm: 'space-between' }}
        spacing={2}
        style={{ marginBlock: 24 }}
      >
        <Typography fontSize={22}>{tableName()}</Typography>

        {!isDealTab && (
          <div className="buttons-wrapper">
            <Link
              to={routes.admin.auction_create}
              className="btn btn--icon-start btn--compact btn-primary"
            >
              <Add /> Create Auction
            </Link>
          </div>
        )}

      </Stack>

      <DataTable
        // name={tableName()}
        name=""
        columns={tableColumns()}
        onRowClick={
          isDealTab
            ? (offer) => props.history.push(`/offspec/deals/${offer?.deal?.id}`)
            : (offer) => props.history.push(`/offspec/offer/${offer.id}`)
        }
        reload={reload}
        defaultSort={tableSort}
        fetchRows={
          isDealTab
            ? (params) => getDeals({
              ...params,
              types: 'OFF_SPEC',
            })
            : (params) => getOffers({
              ...params,
              statuses: exactLinkCheck(pages.offSpecOffers) ? 'ACTIVE' : null,
              active: exactLinkCheck(pages.offSpecOffers),
              types: 'OFF_SPEC',
            })
        }
      />
    </div>
  );
}

export default withRouter(OfferOffSpecTable);
