import axios from 'axios';
import notification, { showNotification } from 'SHARED/helpers/notifications';
import { store } from 'react-notifications-component';
import routes from 'SHARED/types/routes';

// env variables
declare global {
  interface Window {
    APP_CONFIG: {
      api_url: string;
      documents_api_url: string;
      oauth_url: string;
    }
  }
}

const { APP_CONFIG } = window;
axios.defaults.baseURL = APP_CONFIG.api_url;
// axios.defaults.baseURL = 'http://195.60.230.205:18080/api'; // EVHENIY loal IP

const axiosDocuments = axios.create({ ...axios.defaults, baseURL: APP_CONFIG.documents_api_url });

// ****************  setting/removing Authorization header for all next requests
export const setAxiosDefaults = (token: string | null): void => {
  const value = token ? `Bearer ${token}` : null;
  axios.defaults.headers.common.Authorization = value;
  axiosDocuments.defaults.headers.common.Authorization = value;
};

// **************** setTokens to local storage
export const setTokens = (token: string, refreshToken: string) => {
  setAxiosDefaults(token);
  localStorage.setItem('token', token);
  localStorage.setItem('refresh-token', refreshToken);
};
// ****************  remove tokens from local storage
export const removeTokens = (): void => {
  setAxiosDefaults(null);
  localStorage.removeItem('token');
  localStorage.removeItem('refresh-token');
};

// **************** creating and submitting custom form
// **************** for correct logout on oauth server
export const oauthLogoutForm = () => {
  const accessToken = localStorage.getItem('token');
  const ACTION_URL = `${routes.auth.oauth}/oauth/logout?token=${accessToken || ''}`;
  // custom form creation & configuring
  const form = document.createElement('form');
  form.method = 'POST';
  form.action = ACTION_URL;
  document.body.appendChild(form);
  removeTokens();
  console.log(ACTION_URL);
  form.submit();
};

// ****************  default state during first app load
const accessToken = localStorage.getItem('token');

if (accessToken) {
  setAxiosDefaults(accessToken);
}

const goToLogin = () => {
  removeTokens();
  setTimeout(() => {
    window.location.replace(routes.auth.oauth);
  }, 2000);
};

const customOnRejectedHandler = (error: any) => {
  console.log(error.response);

  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  if (!error.response) {
    // @ts-expect-error:
    store.removeAllNotifications();

    showNotification('CONNECTION_ERROR');

    // eslint-disable-next-line prefer-promise-reject-errors
    return Promise.reject({ response: { status: undefined, message: undefined, error: undefined } });
  }

  let errorMessage = ''; // 404 page
  let { status } = error.response;
  // const url = window.location.href;

  if (error.response && status === 500) {
    status = 'Something went wrong';
    errorMessage = 'Internal server error';
  }

  if (error.response && status === 400) {
    status = 'Something went wrong';
    const detailedMessage = error.response.data.errors ? error.response.data.errors[0].message : null;
    errorMessage = detailedMessage || error.response.data.message;
  }

  if (error.response && status === 401) {
    errorMessage = 'Unauthorized';
    localStorage.setItem('route', window.location.pathname);
    if (window.location.hostname !== 'localhost') {
      goToLogin();
    }
  }

  if (error.response && status === 403) {
    errorMessage = 'You are not allowed to see this content';
    // goToLogin();
  }

  if (error.response && status === 404) {
    errorMessage = 'Page not found';
  }

  if (error.response) {
    notification({
      type: 'danger',
      title: status.toString(),
      message: errorMessage,
      dismiss: {
        duration: 10000,
      },
    });
  }

  return Promise.reject(error.response);
};

axios.interceptors.response.use((response) => response, customOnRejectedHandler);
axiosDocuments.interceptors.response.use((response) => response, customOnRejectedHandler);

export { axiosDocuments as axios_Documents, axios as axios_Core };
// axios.defaults.baseURL = BASE_URL;

export default axios;
