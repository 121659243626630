import axios from 'api/axios';
import endpoints from 'api/endpoints';

interface Params {
  countryIdFrom: number
  seaPortIdFrom?: number
  zipFrom?: string

  countryIdTo: number
  seaPortIdTo?: number
  zipTo?: string
}

const getLogisticLanePrices = async (params: Params) => {
  const res = await axios({
    method: 'get',
    url: endpoints.logisticLanePrices,
    params,
  });

  if (res.status === 200) {
    return res.data;
  }
  throw new Error(`Cannot get logistic lane prices. Status: ${res.status}`);
};

export default getLogisticLanePrices;
