import { Typography } from '@material-ui/core';
import { IOffspecOffer } from 'SHARED/types/offerTypes';
import Controls from 'components/controls/Controls';
import pages from 'navigation/pages';
import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';

interface IProps {
  offer: IOffspecOffer | undefined
}

const AuctionDealHeader: FC<IProps> = ({ offer }) => {
  const history = useHistory();

  const makeTitle = () => {
    if (offer) {
      return `Auction deal: ${offer.title}, ${offer.deal?.volume} ${offer.volumeUnits?.label}`;
    }
    return 'Auction deal...';
  };

  return (
    <div style={{ display: 'flex' }}>
      <Typography variant="h5" style={{ flexGrow: 1 }}>
        {makeTitle()}
      </Typography>
      <Controls.Button
        type="button"
        color="secondary"
        onClick={() => history.push(pages.offSpecOffers_deals)}
      >
        <span style={{ fontSize: 16, color: '#FFF' }}>
          {'< Back to deals'}
        </span>
      </Controls.Button>
    </div>
  );
};

export default AuctionDealHeader;
