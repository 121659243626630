import axios from 'api/axios';

const getDealById = async <T>(dealId: number | string): Promise<T> => {
  const res = await axios({
    method: 'get',
    url: `/v1/deals/${dealId}`,
  });

  if (res.status === 200) {
    return res.data;
  }
  throw new Error(`Cannot get deal data. Status: ${res.status}`);
};

export default getDealById;
