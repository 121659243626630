import { z } from 'zod';

const optionSchema = z.object({
  label: z.string().nonempty(),
  value: z.string(),
  isDisabled: z.boolean().optional(),
});

export type IOption = z.infer<typeof optionSchema>;

export default optionSchema;
