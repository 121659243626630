import React from 'react';
import { Alert, AlertTitle } from '@material-ui/lab';
import { IOfferAnalytics } from 'services/api/OfferService';
import { amsDateWithTimezone } from 'services/Utils';
import OfferVisitor from './OfferVisitor';

import './offer-analytics.scss';

interface IProps {
  offerCreationDate: number;
  analytics: IOfferAnalytics | null;
  error: any;
}

const OfferAnalytics: React.FC<IProps> = ({
  offerCreationDate,
  analytics,
  error,
}) => {
  if (error || !analytics) {
    return (
      <Alert severity="error">
        <AlertTitle>Fetch analytics error</AlertTitle>
      </Alert>
    );
  }

  const offerVisitorsCount = (
    <>
      <span>Offer details page visited by: </span>
      <b>
        {analytics?.visits?.length}
        <span> unique users.</span>
      </b>
    </>
  );
  const noVisitorsText = 'No users visited this offer.';

  return (
    <section className="offer-analytics">

      <p>
        <span>Offer created: </span>
        <b>
          {!offerCreationDate && "can't find creation date..."}
          {amsDateWithTimezone(offerCreationDate)}
        </b>
      </p>

      <div className="visitors-amount">
        {analytics?.visits?.length
          ? offerVisitorsCount
          : noVisitorsText}
      </div>

      <div className="analytics-visitors">
        {analytics?.visits?.map((visitor) => (
          <OfferVisitor key={visitor.user.value} visit={visitor} />
        ))}
      </div>

    </section>
  );
};

export default OfferAnalytics;
