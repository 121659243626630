import { axios_Core } from 'SHARED/helpers/axios';
import toast from 'react-hot-toast';

export const getOfferDetails = async <T>(offerId: string) => {
  try {
    const res = await axios_Core.get<T>(`/v1/offers/${offerId}`);

    return res.data;
  } catch (error) {
    toast.error('Error loading offer');

    throw new Error('Error loading offer');
  }
};
