import axios from 'api/axios';
import endpoints from '../endpoints';

const getLogisticLocations = async () => {
  const params = {
    isActiveOrganization: true,
    isActive: true,
    category: 'LOGISTIC',
  };

  // const rest = await axios.get(endpoints.logisticLocations, { params });
  const res = await axios({
    method: 'get',
    url: endpoints.logisticLocations,
    params,
  });

  if (res.status === 200) {
    return res.data;
  }
  throw new Error(`Cannot get logistic locations. Status: ${res.status}`);
};

export default getLogisticLocations;
