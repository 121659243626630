import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { salesTerms } from 'SHARED/helpers/OfferData';
import { DictionariesList, Dictionary, DictionaryEntry, Location } from 'SHARED/types/offerTypes';
import { IContainer } from 'SHARED/redux/models/IContainers';
import { asyncDictionariesActions } from './AsyncDictionariesActions';

interface IDictionariesState {
  dictionaries: DictionariesList,
  locations: {
    LOGISTIC: Location[],
    PRODUCTION: Location[],
    BOTH: Location[],
  },
  containers: { [key: string]: IContainer[] },
  loading: boolean,
  error: string | null,
}

const initialState: IDictionariesState = {
  dictionaries: {
    INCOTERM_SELLER: [],
    INCOTERM_BUYER: [],
    LOADING_DETAILS: [],
    PACKAGING_OPTIONS: [],
    PAYMENT_TERMS: [],
    DOCUMENT_TYPE: [],
    SALES_TERMS: salesTerms,
    PRODUCT_LOCATION: [],
    LOADING_ADDRESS: [],
    BUYERS_LIST: [],
    BUYERS_GROUPS_LIST: [], // ???
  },
  locations: {
    LOGISTIC: [],
    PRODUCTION: [],
    BOTH: [],
  },
  containers: {},
  loading: false,
  error: null,
};

const dictionariesSlice = createSlice({
  name: 'dictionaries',
  initialState,
  reducers: {
    resetDictionariesState: () => initialState,

    resetDictionaries: (state) => {
      state.dictionaries = initialState.dictionaries;
    },

    resetLocations: (state) => {
      state.locations = initialState.locations;
    },

    setDictionary: (state, action: PayloadAction<{ key: DictionaryEntry, value: Dictionary[] }>) => {
      const { key, value } = action.payload;

      state.dictionaries[key] = value;
    },
  },
  extraReducers: (builder) => {
    // getDictionaries
    builder.addCase(
      asyncDictionariesActions.getDictionaries.pending,
      (state) => {
        state.loading = true;
      },
    );

    builder.addCase(
      asyncDictionariesActions.getDictionaries.fulfilled,
      (state, action) => {
        const { dictionaries, buyers, buyersGroup } = action.payload;

        state.dictionaries = {
          ...state.dictionaries,
          ...dictionaries,
          BUYERS_LIST: buyers,
          BUYERS_GROUPS_LIST: buyersGroup,
        };
        state.loading = false;
      },
    );

    builder.addCase(
      asyncDictionariesActions.getDictionaries.rejected.type,
      (state, payload: PayloadAction<string>) => {
        state.loading = false;
        state.error = payload.payload;
      },
    );
    // getDictionaries === END

    // getBuyers
    builder.addCase(
      asyncDictionariesActions.getBuyers.pending,
      (state) => {
        state.loading = true;
      },
    );

    builder.addCase(
      asyncDictionariesActions.getBuyers.fulfilled,
      (state, action) => {
        const buyers = action.payload;

        state.dictionaries.BUYERS_LIST = buyers;
        state.loading = false;
      },
    );

    builder.addCase(
      asyncDictionariesActions.getBuyers.rejected.type,
      (state, payload: PayloadAction<string>) => {
        state.loading = false;
        state.error = payload.payload;
      },
    );
    // getBuyers === END

    // getLocations
    builder.addCase(
      asyncDictionariesActions.getLocations.pending,
      (state) => {
        state.loading = true;
      },
    );

    builder.addCase(
      asyncDictionariesActions.getLocations.fulfilled,
      (state, action) => {
        const { payload: locationsList } = action;
        const logistic = locationsList.filter((l) => l.category === 'LOGISTIC');
        const production = locationsList.filter((l) => l.category === 'PRODUCTION');

        state.loading = false;
        state.locations = {
          LOGISTIC: logistic,
          PRODUCTION: production,
          BOTH: locationsList,
        };
      },
    );
    // getLocations === END

    // getContainers
    builder.addCase(
      asyncDictionariesActions.getContainers.pending,
      (state) => {
        state.loading = true;
      },
    );

    builder.addCase(
      asyncDictionariesActions.getContainers.fulfilled,
      (state, action) => {
        const containers = action.payload;

        state.loading = false;
        state.containers = containers;
      },
    );

    builder.addCase(
      asyncDictionariesActions.getContainers.rejected.type,
      (state, payload: PayloadAction<string>) => {
        state.loading = false;
        state.error = payload.payload;
      },
    );
    // getContainers === END
  },
});

export const dictionariesReducer = dictionariesSlice.reducer;
export const dictionariesActions = dictionariesSlice.actions;
