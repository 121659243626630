import React from 'react';
import {
  Checkbox as MuiCheckbox,
  FormControl,
  FormControlLabel, FormHelperText,
  FormLabel, Grid,
} from '@material-ui/core';
import { Option } from './Select';

export interface CheckBoxGroupProps {
  label: string
  name: string
  options: Array<Option>
  value: Array<Option>
  onChange: any
  disabled?: boolean
  groupSize?: number
  error?: string
}

export default function CheckboxGroup(props: CheckBoxGroupProps) {
  const {
    label, name, options, value, onChange, disabled, error, groupSize = 1,
  } = props;

  const renderGroup = (items: Array<Option>, idx: number) => (
    <Grid
      container
      key={idx}
      style={{ display: 'flex', flexDirection: 'column', width: '33%' }}
    >
      { items.map((opt) => (
        <Grid item>
          <FormControlLabel
            label={opt.label}
            key={opt.value}
            // style={{ whiteSpace: 'nowrap' }}
            // style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
            control={(
              <MuiCheckbox
                name={opt.value}
                color="secondary"
                checked={value.some((it) => it.value === opt.value)}
                onChange={(e, val) => handleChange(opt, val)}
              />
                )}
            disabled={disabled}
          />
        </Grid>
      )) }
      <br />
    </Grid>
  );

  const splitArray = () => {
    if (!groupSize) {
      return [options];
    }
    const result: Array<Array<Option>> = [];
    options.forEach((it, idx) => {
      if (!(idx % groupSize)) {
        result.push(options.slice(idx, idx + groupSize));
      }
    });
    return result;
  };

  const handleChange = (val: any, checked: boolean) => {
    const tempArray = value.slice();
    if (checked) {
      tempArray.push(val);
      onChange({ target: { name, value: tempArray } });
    } else {
      onChange({ target: { name, value: tempArray.filter((it: any) => it.value !== val.value) } });
    }
  };

  return (
    <FormControl
      {...(error && { error: true, helperText: error })}
      className="form-control222"
      style={{ width: '100%' }}
    >
      <FormLabel style={{ marginBottom: 15 }}>{label}</FormLabel>
      { options.length && (
      <Grid container spacing={3} style={{ display: 'flex', paddingLeft: 15 }}>
        {splitArray().map((it, idx) => renderGroup(it, idx))}
      </Grid>
      )}
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
}
