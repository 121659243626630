/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import clsx from 'clsx';
import { useFormContext, Controller } from 'react-hook-form';
import { Dictionary } from 'SHARED/types/offerTypes';
import validationRules from 'SHARED/helpers/validation';
import Select from 'react-select';
import Tooltip from '../Tooltip';

interface ISelectOption extends Dictionary {
  isDisabled?: boolean,
}
interface FormSelectProps {
  name: `${string}` | `${string}.${string}` | `${string}.${number}`,
  rules?: any,
  options: ISelectOption[],
  label?: string,
  selected: Dictionary | null,
  defaultValue?: string,
  tooltip?: string,
  placeholder?: string,
  disabled?: boolean,
  onChange?: (value?: any) => void
  className?: string,
}

const FormSelect: React.FC<FormSelectProps> = (props) => {
  const { formState: { errors }, control } = useFormContext();

  const {
    name,
    rules = validationRules.required,
    label,
    tooltip,
    disabled = false,
    options,
    selected,
    placeholder = '',
    defaultValue = null,
    onChange,
    className = '',
  } = props;

  let val; // TODO: refactor

  if (selected) {
    // if in state present correct value use it
    val = selected;
  } else if (defaultValue) {
    // if not check is there is a default option if yes find it in options
    val = options.find((option) => option.value === defaultValue);
  }

  // else if (options.length > 0) {
  //   val = options[0];
  // }

  // const onChangeHandle = (e:React.ChangeEvent<HTMLSelectElement>) => {
  //   const found = options.find((el) => el.value === e.target.value);

  //   setValue(name, found);
  // };

  return (
    <div className={clsx('form-input', errors[name] && 'invalid', className)} data-testing={name || ''}>
      {label && (
        <label htmlFor={name}>
          {rules && rules.required ? `${label} *` : label}

          {tooltip && tooltip.length > 0 && <Tooltip text={tooltip} />}
        </label>
      )}

      <Controller
        name={name}
        control={control}
        rules={rules}
        defaultValue={val}
        render={({ field, fieldState }) => (
          <>
            <Select
              className="form-select"
              classNamePrefix="form-select"
              isDisabled={disabled}
              options={options}
              onChange={(value) => { field.onChange(value); onChange?.(value); }}
              value={field.value}
              isSearchable={false}
              ref={field.ref}
              placeholder={placeholder}
              // menuIsOpen
            />
            <div className="error-message">{fieldState.error?.message}</div>
          </>
        )}

      />

    </div>
  );
};

export default FormSelect;
