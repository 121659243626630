import { CircularProgress } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React, { useEffect, useState } from 'react';
import { Channel, Client, State } from 'twilio-chat';
import api from '../../services/api';
import { TwilioCredentials } from '../../services/api/ChatService';
import Chat from './Chat';

export interface ChatProps {
  dealId: any
}

export default function TwilioChat(props: ChatProps) {
  const { dealId } = props;
  const [credentials, setCredentials] = useState<TwilioCredentials>();
  const [client, setClient] = useState<Client>();
  const [channel, setChannel] = useState<Channel>();
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(true);

  const loadCredentials = () => {
    dealId && api.chat.getCredentials(dealId).then((res) => {
      setCredentials(res);
      client && client.updateToken(res.token);
    }).catch(setError);
  };

  // fetch Twilio token and chat identifier for current user
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(loadCredentials, [dealId]);

  useEffect(() => () => { // cleanup client listeners on unmount
    client?.removeAllListeners();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // initialize Twilio client
  useEffect(() => {
    if (credentials) {
      const CLIENT = new Client(credentials.token);
      CLIENT.on(Client.stateChanged, (state: State) => {
        if (state === 'initialized') {
          setClient(CLIENT);
          setLoading(false);
        }
      });
      CLIENT.on(Client.tokenAboutToExpire, loadCredentials);
      CLIENT.on(Client.tokenExpired, loadCredentials);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [credentials]);

  // join to requested channel
  useEffect(() => {
    if (client && credentials?.channelSid) {
      client.getChannelBySid(credentials.channelSid)
        .then(setChannel)
        .catch(() => {
          setLoading(false);
          setError('Failed to connect to request channel');
        });
    }
  }, [credentials, client]);

  return (
    <>
      {error && <Alert severity="error">{error}</Alert> }
      { loading && <CircularProgress color="secondary" /> }
      { (credentials && channel && open)
      && <Chat channel={channel} identity={credentials.identity} setOpen={setOpen} />}
    </>
  );
}
