import { Alert, Button, Typography } from '@mui/material';
import { useOfferQuery } from 'api/queries/useOfferQuery';
import clsx from 'clsx';
import OfferSalesTermsField from 'components/OfferSalesTermsField';
import Preloader from 'components/od-preloader/Preloader';
import DataItemPreview from 'components/partials/DataItemPreview';
import renderMultiline from 'helpers/renderMultiline';
import pages from 'navigation/pages';
import React, { FC } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useParams } from 'react-router-dom';
import { amsDateWithTimezone, formatMoney } from 'services/Utils';

interface ProposalPageParams {
  proposalId: string
}

const ProposalDetailsPage: FC = () => {
  const { proposalId } = useParams<ProposalPageParams>();
  const {
    data: proposal, isLoading, isFetching, isError,
  } = useOfferQuery(proposalId);

  const {
    productCategory,
    productType,
    totalVolume,
    volumeUnits,
    feedback,
    buyers,
  } = proposal || {};

  const title = `Proposal: ${productCategory?.label} ${productType?.label}, ${totalVolume} ${volumeUnits?.label}`;

  // encapsulated data
  const sellerOrg = proposal?.ownerOrg?.orgName;
  const sellerCountry = proposal?.ownerOrg?.country;

  const sellerIncoterms = proposal?.incoterms?.label;
  const sellerLocation = proposal?.logisticLocation?.fullAddress;
  const requestedDelivery = `${proposal?.departureFrom} - ${proposal?.departureTo}`;

  const sellerPaymentTerms = proposal?.paymentTerms?.label;

  const currency = proposal?.priceEur ? 'EUR' : 'USD';
  // encapsulated data === END

  const isActiveRfp = proposal?.parentOffer?.status?.label === 'ACTIVE';

  const backUrl = () => {
    if (!proposal?.parentOffer?.id) { return pages.rfp; }

    if (isActiveRfp) {
      return pages.goto_rfpOfferProposals(proposal?.parentOffer?.id);
    }

    return pages.goto_rfpOfferProposals_archived(proposal?.parentOffer?.id);
  };

  return (
    <>
      <Preloader isLoading={isLoading || isFetching} />

      <Helmet>
        <title>{proposal ? title : 'Proposal details'}</title>
      </Helmet>

      <div className="page-body">
        {isError && (
        <Alert severity="error" style={{ marginBottom: 20 }}>
          Could not load proposal details
        </Alert>
        )}

        <div style={{ display: 'flex', alignItems: 'flex-start', marginBottom: 40 }}>
          <Typography variant="h5" style={{ flexGrow: 1 }}>
            {proposal ? title : 'Proposal details...'}
          </Typography>
          <Button
            variant="contained"
            color="secondary"
            to={backUrl()}
            component={Link}
          >
            {'< '}{proposal?.parentOffer ? 'Back to proposals' : 'Back to RFPs'}
          </Button>
        </div>

        {/* proposal details */}
        <div className="details-grid">
          <div className="details-col">
            <h2>Seller</h2>
            <DataItemPreview label="Seller company" value={sellerOrg} />
            <DataItemPreview label="Country" value={sellerCountry} />
            <DataItemPreview label="Production location" value={proposal?.productionLocation?.fullAddress} />
            <DataItemPreview label="Sales terms" value={proposal?.salesTerms?.label} />
            <OfferSalesTermsField offer={proposal} />
          </div>

          <div className="details-col">
            <h2>Proposal</h2>
            {/* <DataItemPreview label="" value={} /> */}
            <DataItemPreview label="Status" value={proposal?.statusLabel} />
            <DataItemPreview label="Id" value={proposal?.id} />
            <DataItemPreview label="Placed" value={amsDateWithTimezone(proposal?.created)} />
            <DataItemPreview label="Proposed packaging" value={proposal?.packaging?.label} />
            <DataItemPreview label="Custom label" value={proposal?.customLabelPossible ? 'Custom label possible' : 'Custom label not possible'} />
            <DataItemPreview label="Proposed volume" value={proposal?.totalVolume} suffix={proposal?.volumeUnits?.label} />
            <DataItemPreview
              label="Proposed price"
              value={formatMoney(proposal?.priceEur || proposal?.priceUsd)}
              prefix={currency}
              suffix={`/${proposal?.volumeUnits?.label}`}
            />
            <DataItemPreview label="Proposal valid until" value={amsDateWithTimezone(proposal?.endDate)} />
            <DataItemPreview label="Message" value={renderMultiline(proposal?.description)} />
          </div>

          <div className="details-col">
            <h2>Shipping & Logistics</h2>
            {proposal?.agreedOnDelivery && (
            <Alert severity="info" style={{ marginBottom: 20 }}>
              This offer complies with requested delivery and timeline
            </Alert>
            )}
            <DataItemPreview label="Proposed incoterms" value={sellerIncoterms} />
            <DataItemPreview label="Proposed logistics location" value={sellerLocation} />
            <DataItemPreview label="Proposed delivery" value={requestedDelivery} />
          </div>

          <div className="details-col">
            <h2>Financing</h2>
            {proposal?.agreedOnPayment && (
            <Alert severity="info" style={{ marginBottom: 20 }}>
              This offer complies with requested payment terms
            </Alert>
            )}
            <DataItemPreview label="Proposed payment terms" value={sellerPaymentTerms} />
          </div>
        </div>

        {proposal?.hasDeals && (
          proposal?.deals?.map((deal) => (
            <Link
              key={deal?.id}
              className={clsx(!proposal?.id && 'disabled')}
              style={{ fontSize: 16, display: 'inline-flex' }}
              to={pages.goto_rfp_deal(deal?.id)}
              title="View deal details"
            >
              Show deal {`(${deal?.number})`} details
            </Link>
          ))
        )}

        {!!feedback && (
        <Alert severity="info" style={{ marginTop: 20 }}>
          <h3>Feedback from {buyers?.[0]?.label} on {amsDateWithTimezone(feedback?.created)}</h3>

          {renderMultiline(feedback?.message)}
        </Alert>
        )}
      </div>

    </>
  );
};

export default ProposalDetailsPage;
