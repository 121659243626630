import { get } from './RestUtil';

export interface Country {
  id: number,
  name: string,
  iso2: string,
}

export type Dictionary = {
  id: number,
  label: string,
  value: string,
  displayOrder: number,

  // incoterms only
  locationType?: 'PORT' | 'NON_PORT',
};

export class DictionaryService {
  getCountries = (setCountries: any, setError: any) => {
    get('/v1/dictionary/country?page=0&size=1000&sort=name,ASC')
      .then((resp) => setCountries(resp))
      .catch((err) => setError(err));
  };

  getDictionaryByCode = (
    codes: Array<string>,
    setDictionary: any,
    orgId?: number,
    offerType?: string,
  ) => {
    get('/v1/dictionary/group', { types: codes, orgId, offerTypes: [offerType] })
      .then(setDictionary)
      .catch(() => {});
  };
}
