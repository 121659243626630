import { useMemo } from 'react';
import { bindActionCreators } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../store';
import { allActions } from '../reducers/all-actions';

export const useAppDispatch = () => useDispatch<AppDispatch>();

// useMemo is used to avoid memory leak
// when action are put into the dependency array of useEffect hook
export const useActions = () => {
  const dispatch = useAppDispatch();

  return useMemo(() => {
    return bindActionCreators(allActions, dispatch);
  }, [dispatch]);
};
