import React from 'react';
import { Link } from '@material-ui/core';
import { createFileDownloadUrl, download } from '../../services/api/RestUtil';

export interface DownloadProps {
  fileName: string;
  originalFileName?: string;
  children?: any;
}

export default function DownloadButton(props: DownloadProps) {
  const {
    fileName,
    originalFileName,
    children,
  } = props;
  const downloadFn = React.useCallback(
    () => download(createFileDownloadUrl(fileName), null, originalFileName),
    [fileName, originalFileName],
  );
  return (
    <Link
      component="button"
      variant="body2"
      onClick={(e: any) => {
        e.preventDefault();
        downloadFn();
      }}
      color="inherit"
    >
      {children}
    </Link>
  );
}
