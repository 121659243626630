import React, { useEffect, useState } from 'react';
import {
  BrowserRouter as Router, Route, Switch, Redirect,
} from 'react-router-dom';
import deepLinkCheck from 'helpers/deepLinkCheck';
import isDevEnv from 'helpers/isDevEnv';
import { useActions } from 'SHARED/redux/hooks/useActions';
import { useTypedSelector } from 'SHARED/redux/hooks/useTypedSelector';
import OfferProvideDocument from 'SHARED/pages/Documents/OfferProvideDocument';
import { SimpleOfferCreateAdmin } from 'SHARED/pages/simple-offer-admin/create';
import routes from 'SHARED/types/routes';
import { SimpleOfferPreviewAdmin } from 'SHARED/pages/simple-offer-admin/preview';
import { AuctionCreateAdminPage } from 'SHARED/pages/auction-admin/create';
import { AuctionCreatePreviewAdminPage } from 'SHARED/pages/auction-admin/preview';
import OfferDetailsForm from '../components/forms/OfferDetailsForm';
import OfferEditForm from '../components/forms/OfferEditForm';
import OfferOffSpecForm from '../components/forms/OfferOffSpecForm';
import RFPDetails from '../components/forms/RFPDetails';
import OfferOffSpecTable from '../components/tables/OfferOffSpecTable';
import OfferTable from '../components/tables/OfferTable';
import RFPTable from '../components/tables/RFPTable';
import pages from '../navigation/pages';
import { authUrl } from '../services/api/RestUtil';
import SellerPage from './SellerPage';
import AdminPage from './AdminPage';
import BuyerPage from './BuyerPage';
import Header from '../components/Header';
import CataloguePage from './CataloguePage';
import OfferCreateForm from '../components/forms/OfferCreateForm';
import LogisticsPricesAdd from './logistics/LogisticsPricesAdd';
import AuctionDealPage from './auctionDeal/AuctionDealPage';
import OfferNewDealPage from './OfferNewDeal/OfferNewDealPage';
import OfferDealPage from './offer-deal/OfferDealPage';
import ProposalDetailsPage from './ProposalDetails/ProposalDetailsPage';

const getTabCode = () => {
  switch (true) {
    case deepLinkCheck(pages.seller):
      return 1;
    case deepLinkCheck(pages.buyer):
      return 2;
    case deepLinkCheck(pages.catalogue):
      return 3;
    case deepLinkCheck(pages.admin):
      return 4;
    case deepLinkCheck(pages.offSpecBase):
      return 6;
    case deepLinkCheck(pages.rfp):
      return 7;
    default:
      return 0;
  }
};

const Dashboard: React.FC = () => {
  const [tab, setTab] = useState(getTabCode());

  const { isLoggedIn } = useTypedSelector((state) => state.oauth);
  const { getUser, setIsLoggedIn } = useActions();

  useEffect(() => {
    // get user info if user has logged in
    if (isLoggedIn) {
      getUser();
    }
  }, [isLoggedIn]);

  // const userName = `${me.profile?.firstName} ${me.profile?.middleName || ''} ${me.profile?.lastName}`;

  useEffect(() => {
    setIsLoggedIn(true);
    if (isDevEnv) return; // ? disable annoying redirect in Dev mode

    !localStorage.getItem('token') && window.location.replace(authUrl);
  }, []);

  useEffect(() => {
    setTab(getTabCode);
  }, [tab]);

  return (
    <Router>

      <Header tab={tab} setTab={setTab} />

      <div className="container page-container is-logged-in">

        <Switch>
          <Redirect exact from={pages.dashboard} to={pages.offers} />

          {/* OFFERS */}
          <Route
            exact
            path={[
              pages.offers,
              pages.offers_archived,
              pages.offers_deals,
            ]}
            component={OfferTable}
          />

          <Route
            exact
            path={routes.admin.offers_createSimpleOffer}
            component={SimpleOfferCreateAdmin}
          />
          <Route
            exact
            path={routes.admin.offers_previewSimpleOffer}
            component={SimpleOfferPreviewAdmin}
          />

          <Route exact path={pages.newOffer} component={OfferCreateForm} />

          <Route
            exact
            path={[
              pages.offerPage,
              pages.offerPage_bids,
              pages.offerPage_history,
              pages.offerPage_matches,
              pages.offerPage_analytics,
            ]}
            component={OfferDetailsForm}
          />

          <Route path={pages.offerEdit} component={OfferEditForm} />

          <Route
            path={[
              pages.offerDeal,
              pages.offerDeal_documents,
            ]}
            component={OfferDealPage}
            exact
          />
          {/* OFFERS === END */}

          {/* AUCTIONS */}
          <Route
                    // path={pages.offSpecOffers}
            path={[
              pages.offSpecOffers,
              pages.offSpecOffers_archived,
              pages.offSpecOffers_deals,
            ]}
            component={OfferOffSpecTable}
            exact
          />

          <Route path={pages.offSpecOffer} component={OfferOffSpecForm} />

          <Route path={pages.auctionDeal} component={AuctionDealPage} />

          <Route
            exact
            path={routes.admin.auction_create}
            component={AuctionCreateAdminPage}
          />
          <Route
            exact
            path={routes.admin.auction_preview}
            component={AuctionCreatePreviewAdminPage}
          />
          {/* AUCTIONS === END */}

          {/* RFPs */}
          <Route
            path={[
              pages.rfp,
              pages.rfp_archived,
              pages.rfp_deals,
            ]}
            component={RFPTable}
            exact
          />
          <Route
            path={[
              pages.rfpOffer,
              pages.rfpOffer_archived,

              pages.rfpOfferProposals,
              pages.rfpOfferProposals_archived,

              pages.rfpOfferAnalytics,
              pages.rfpOfferAnalytics_archived,
            ]}
            component={RFPDetails}
            exact
          />

          <Route
            path={[
              pages.rfp_deal,
              pages.rfp_deal_documents,
            ]}
            component={OfferDealPage}
            exact
          />

          <Route path={pages.rfpProposal} component={ProposalDetailsPage} exact />
          {/* RFPs === END */}

          {/* Documents exchange */}
          <Route
            path={pages.offerDocumentsProvide}
            component={OfferProvideDocument}
                // component={DocsTest}
            exact
          />
          {/* Documents exchange === END */}

          <Route path={pages.seller} component={SellerPage} />
          <Route path={pages.buyer} component={BuyerPage} />

          <Route
            path={[
              pages.catalogue,
              pages.catalogue_financing,
              pages.catalogue_logisticPrices,
            ]}
            component={CataloguePage}
            exact
          />

          <Route path={pages.admin} component={AdminPage} />

          <Route exact path={pages.offer_dealCreateUrl} component={OfferNewDealPage} />

          <Route path={pages.deal} component={OfferOffSpecForm} />

          <Route path={pages.catalogue_logisticPrices_add} component={LogisticsPricesAdd} exact />
        </Switch>
      </div>

    </Router>
  );
};

export default Dashboard;
