import { InputLabel } from '@material-ui/core';
import { Close, FilterList } from '@material-ui/icons';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Form, useForm } from '../../hooks/useForm';
import api from '../../services/api';
import { Organization } from '../../services/api/OrganizationService';
import { date, option } from '../../services/Utils';
import Controls from '../controls/Controls';
import Modal from '../Modal';

const emptyFilter = {
  endFrom: null,
  endTo: null,
  sellerId: undefined,
  buyerId: undefined,
  productType: undefined,
  matched: undefined,
};

export interface OfferFilterProps {
  onSave: (filter: any) => void
  isDeal: boolean
}

export default function OfferFilter(props: OfferFilterProps) {
  const { values, setValues, handleInputChange } = useForm(emptyFilter);
  const [sellers, setSellers] = useState([]);
  const [buyers, setBuyers] = useState([]);
  const [productTypes, setProductTypes] = useState<any>([]);
  const [open, setOpen] = useState(false);

  const { onSave, isDeal } = props;

  useEffect(() => {
    setOrganizationOptions('SELLER');
    setOrganizationOptions('BUYER');
    setProductTypeOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setOrganizationOptions = (orgType: 'SELLER' | 'BUYER') => {
    api.organization.getOrganizationPage(orgType, {})
      .then((res) => res.rows.map((it: Organization) => ({ label: it.orgName, value: it.id })))
      .then((res) => (orgType === 'SELLER' ? setSellers(res) : setBuyers(res)))
      .catch(() => {});
  };

  const setProductTypeOptions = () => {
    api.productSpec.getCategories((res: any) => setProductTypes(mapProductTypes(res)));
  };

  const mapProductTypes = (res: any) => {
    const options: any = [];
    res?.forEach((group: any) => {
      group.productTypes
        ?.forEach((type: any) => options.push(option(type.value, `${group.label} ${type.label}`)));
    });

    return options;
  };

  const handleApplyFilter = (e: any) => {
    e.preventDefault();
    const filter: any = {
      ...values,
      productTypes: values.productType && [values.productType],
      sellerIds: values.sellerId && [values.sellerId],
      buyerIds: values.buyerId && [values.buyerId],
      endFrom: undefined,
      endTo: undefined,
    };

    if (isDeal) {
      filter.createdFrom = date(values.endFrom);
      filter.createdTo = date(values.endTo);
    } else {
      filter.endFrom = moment(values.endFrom).startOf('day').toISOString();
      filter.endTo = moment(values.endTo).endOf('day').toISOString();
    }

    onSave(filter);
    setOpen(false);
  };

  return (
    <>
      <button
        type="button"
        className="btn btn--icon-start btn--compact btn-primary"
        onClick={() => setOpen(true)}
      >
        <FilterList /> Open filter
      </button>
      <Modal title="Filter" open={open} setOpen={setOpen}>
        <Form style={{ display: 'flex', flexDirection: 'row' }}>
          <div style={{ display: 'flex' }}>
            <div style={{ width: '50%' }}>
              <InputLabel style={{ paddingLeft: 15 }}>Date from</InputLabel>
              <Controls.Input
                name="endFrom"
                value={moment(values.endFrom).format('yyyy-MM-DD')}
                onChange={handleInputChange}
                InputProps={{
                  endAdornment: values.endFrom && <Close style={{ cursor: 'pointer' }} onClick={() => setValues({ ...values, endFrom: null })} />,
                  inputProps: { max: values.endTo },
                }}
                type="date"
              />
            </div>
            <div style={{ width: '50%' }}>
              <InputLabel style={{ paddingLeft: 15 }}>Date to</InputLabel>
              <Controls.Input
                name="endTo"
                value={moment(values.endTo).format('yyyy-MM-DD')}
                onChange={handleInputChange}
                error={values.endFrom > values.endTo}
                InputProps={{
                  endAdornment: values.endTo && <Close style={{ cursor: 'pointer' }} onClick={() => setValues({ ...values, endTo: null })} />,
                  inputProps: { min: values.endFrom },
                }}
                type="date"
              />
            </div>
          </div>
          <div style={{ display: 'flex' }}>
            <Controls.Select
              label="Seller"
              name="sellerId"
              options={[option(undefined, 'All sellers'), ...sellers]}
              value={values.sellerId}
              onChange={handleInputChange}
            />
            <Controls.Select
              label="Buyer"
              name="buyerId"
              options={[option(undefined, 'All buyers'), ...buyers]}
              value={values.buyerId}
              onChange={handleInputChange}
            />
          </div>
          <div style={{ display: 'flex' }}>
            <Controls.Select
              label="Product"
              name="productType"
              options={[option(undefined, 'All products'), ...productTypes]}
              value={values.productType}
              onChange={handleInputChange}
            />
            <Controls.Checkbox
              label="Only matched"
              name="matched"
              value={values.matched}
              onChange={handleInputChange}
            />
          </div>
          <div style={{ display: 'flex', justifyContent: 'end' }}>
            <Controls.Button
              type="submit"
              onClick={handleApplyFilter}
              disabled={values.endFrom > values.endTo}
            >
              Apply filter
            </Controls.Button>
            <Controls.Button
              type="reset"
              onClick={(e: any) => {
                e.preventDefault();
                setValues(emptyFilter);
              }}
            >
              Clear filter
            </Controls.Button>
          </div>
        </Form>
      </Modal>
    </>
  );
}
