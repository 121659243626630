import moment from 'moment';
import OfferData from 'SHARED/helpers/OfferData';
import { Dictionaries, DictionariesList } from 'SHARED/types/offerTypes';

const toObject:string[] = [
  'salesTerms',
  'buyersFetchType',
  'departurePeriod',
];

const toFloat:string[] = [
  'totalVolume',
  'price',
  'priceEur',
  'priceUsd',
];

const notDictionary:string[] = [
  'buyersFetchType',
  'departurePeriod',
];

const toArray:string[] = [
  'documentCapabilities',
  'loadOptions',
  'packages',
];

const toTimestamp:string[] = [
  'endDate',
  'loadDateFrom',
  'loadDateTo',
  'endDate',
];

// const toDateString:string[] = [
//   'departureFrom',
//   'departureTo',
// ];

export const preparePreview = (data: any, dictionaries:DictionariesList) => { // TODO: refactor any type
  const OfferPreview = data;
  type DictionariesTypes = keyof typeof Dictionaries;

  // find value in dictinary
  // by it's value
  const findData = (key:DictionariesTypes) => { // TODO: need to check properly
    let options;
    const k = Dictionaries[key];
    options = dictionaries[k];
    // some keys (fields) comes not not from dictionaries
    if (notDictionary.includes(key)) {
      options = OfferData[key];
    }
    const choosedOptions = data[key];

    if (!options || !choosedOptions) {
      console.log('cant find KEY ⚠️, please check lists');
    }

    return { options, choosedOptions };
  };

  // iterate each field in form data to find
  // fileds need to be coverted to proper type before submit
  Object.keys(data).forEach((key: any) => {
    // find OBJECT in dictionary by value
    if (toTimestamp.includes(key)) {
      OfferPreview[key] = parseInt(moment(OfferPreview[key]).format('x'), 10);
    }
    // if (toDateString.includes(key)) {
    //   OfferPreview[key] = moment(OfferPreview[key], 'YYYY-MM-DD');
    // }

    if (toObject.includes(key)) {
      const { options, choosedOptions } = findData(key);
      // find object in dictionary
      const result = options.find((el) => choosedOptions === el.value);
      OfferPreview[key] = result;
    }

    // find object's ARRAY in dictionary by string's array
    if (toArray.includes(key)) {
      const { options, choosedOptions } = findData(key);

      if (choosedOptions.length > 0) {
        const result = options.filter((el) => choosedOptions.includes(el.value));
        OfferPreview[key] = result;
      }
    }

    // convert filed to FLOAT
    if (toFloat.includes(key)) {
      const val = data[key];

      if (typeof val === 'string') {
        OfferPreview[key] = parseFloat(data[key].replace(',', '.'));
      } else {
        OfferPreview[key] = val;
      }
    }
  });
  return OfferPreview;
};

export default preparePreview;
