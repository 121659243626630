import axios from 'api/axios';
import { IRequestParams } from 'api/types';
import { Deal } from 'services/api/OfferService';
import { Page } from 'services/api/RestUtil';
import endpoints from '../endpoints';

const getDeals = async (params: IRequestParams): Promise<Page<Deal>> => {
  const requestParams: IRequestParams = {
    statuses: 'DEAL',
    sort: 'created,DESC',
    ...params,
  };

  const res = await axios({
    method: 'get',
    url: endpoints.deals,
    params: requestParams,
  });

  if (res.status === 200) {
    return res.data;
  }
  throw new Error(`Cannot get deals list. Status: ${res.status}`);
};

export default getDeals;
