import React, { FC } from 'react';
import DataItemPreview from 'SHARED/components/DataItemPreview';
import OfferDisclaimer from 'SHARED/components/OfferDisclaimer';
import FormattedPrices from 'SHARED/components/Prices';
import { MpcDocLink } from 'SHARED/helpers/common';
import { dateRangeWithTimezone, dateWithTimezone } from 'SHARED/helpers/dates';
import { readablePaymentTerms } from 'SHARED/helpers/readablePaymentTerms';
import useUserTimezone from 'SHARED/hooks/useUserTimezone';
import { OnSpecOffer } from 'SHARED/types/offerTypes';

interface IProps {
  offer: OnSpecOffer | null | undefined
}

export const SimpleOfferSummaryAdmin: FC<IProps> = ({ offer }) => {
  const { userTimezone } = useUserTimezone();

  if (!offer) {
    return null;
  }

  // flags
  const isMPC = offer?.salesTerms?.value === 'MPC';
  // flags === end

  // product
  // product === END

  // shipping
  const departureValue = (
    offer.departurePeriod.value === 'MONTH'
      ? dateWithTimezone(offer.departureFrom, userTimezone, 'MMMM YYYY')
      : dateRangeWithTimezone({ from: offer.departureFrom, to: offer.departureTo, timezone: userTimezone })
  );
  // shipping === END

  // volume and price
  const totalUsd = offer.priceUsd ? offer.priceUsd * (offer.totalVolume || 0) : 0;
  const totalEur = offer.priceEur ? offer.priceEur * (offer.totalVolume || 0) : 0;
  // volume and price === END

  // buyers
  // buyers === END

  return (
    <>
      {/* grid 1 */}
      <div className="details-grid col-3">
        <div className="details-col">
          <h3 className="page-section-title">Seller</h3>
          <DataItemPreview title="Organization" item={offer.sellerOrganization} />
          <DataItemPreview title="User" item={offer.sellerUser} />

          <h3 className="page-section-title">Product</h3>

          <DataItemPreview
            title="Remarks for the buyer"
            multilineText={offer.description}
            multiline
          />
          <DataItemPreview title="Product category" item={offer.productCategory} />
          <DataItemPreview title="Product type" item={offer.productType} />
          <DataItemPreview title="Documents included" item={offer.documentCapabilities} />
          <DataItemPreview title="Packaging" item={offer.packages} />

          {isMPC && (
            <DataItemPreview
              title="SALES TERMS DOCUMENT"
              link={{ url: MpcDocLink, displayName: 'MPC sales terms' }}
            />
          )}
          {!isMPC && offer?.documents?.SALES_TERMS && (
            <DataItemPreview
              title="SALES TERMS DOCUMENT"
              file={offer.documents.SALES_TERMS[0]}
            />
          )}
        </div>

        <div className="details-col">
          <h3 className="page-section-title">Shipping</h3>

          <DataItemPreview title="Seller incoterms" item={offer.incoterms} />
          <DataItemPreview title="Seller incoterms location" multilineText={offer.logisticLocationNote} multiline />
          <DataItemPreview title="Loading details" item={offer.loadOptions} />
          <DataItemPreview title="Departure" item={departureValue} />

          <DataItemPreview
            title="Trade anonymously"
          >
            {offer.anonymous
              ? (
                <>
                  <i className="icon-eye-slash inline-icon" />
                  Your identity as well as that of bidders will not be disclosed until a deal is made
                </>
              )
              : 'No'}
          </DataItemPreview>

          <h3 className="page-section-title">Offer expiration</h3>
          <DataItemPreview title="Offer end date" item={offer.endDate} isDate />
        </div>

        <div className="details-col">
          <h3 className="page-section-title">Volume and price</h3>

          <DataItemPreview
            title="Volume"
            item={offer.totalVolume}
            suffix={offer.volumeUnits.label}
          />
          <DataItemPreview
            title="SPLITTABLE"
            icon={offer.splittable ? 'icon-splittable' : ''}
            item={offer.splittable ? 'Yes' : 'no'}
          />
          <FormattedPrices title="Asking price" values={[offer.priceEur, offer.priceUsd]} />
          <DataItemPreview
            title="PAYMENT TERMS"
            item={readablePaymentTerms({
              paymentTerms: offer.paymentTerms,
              paymentOffset: offer.paymentOffset,
              isPreview: true,
            })}
          />
          <FormattedPrices title="Total price" disableSuffix values={[totalEur, totalUsd]} />
        </div>
      </div>
      {/* grid 1 === END */}

      {/* grid 2 */}
      <div className="details-grid">
        <div className="details-col">
          <h3 className="page-section-title">Buyers</h3>

          {offer.buyersFetchType?.value === 'INCLUDE_ALL' && (
            <DataItemPreview
              title="Buyers who can see this offer"
              item="All buyers have been included"
            />
          )}
          {offer.buyersFetchType?.value === 'EXCLUDE' && (
            <DataItemPreview
              title="EXCLUDED BUYERS"
              item={offer.params.excludedBuyers || offer.buyers}
            />
          )}
          {offer.buyersFetchType?.value === 'INCLUDE_GROUP' && (
            <DataItemPreview
              title="INCLUDED BUYERS"
              item={offer.buyers}
            />
          )}
        </div>
      </div>
      {/* grid 2 === END */}

      <OfferDisclaimer userType="seller" newTab />
    </>
  );
};
