import React from 'react';

const renderMultiline = (text: string | undefined | null, keyPrefix = ''): React.ReactNode => {
  if (!text) {
    return (
      <div>
        -
      </div>
    );
  }

  const splitText = text.split('\n\n');

  return splitText.map((paragraph, paragraphIndex) => {
    const lines = paragraph.split('\n');
    const renderedLines = lines.map((line, lineIndex) => {
      const key = `${keyPrefix}-line-${lineIndex}`;
      return <div key={key}>{line}</div>;
    });

    if (paragraph.length === 0) {
      return renderedLines;
    }

    const key = `${keyPrefix}-paragraph-${paragraphIndex}`;
    return <div key={key} className="paragraph">{renderedLines}</div>;
  });
};

export default renderMultiline;
