/* eslint-disable react/jsx-props-no-spreading */
import clsx from 'clsx';
import React, { memo } from 'react';
import { useFormContext } from 'react-hook-form';
import Tooltip from '../Tooltip';

interface SingleCheckboxProps {
  name: string,
  rules?: any,
  label?: string,
  title?: string,
  tooltip?: string,
  disabled?: boolean,
  defaultChecked?: boolean,
  className?: string,
  caption?: string | null,
}

export const SingleCheckbox: React.FC<SingleCheckboxProps> = (props) => {
  const {
    register, formState: { errors },
  } = useFormContext();

  const {
    name,
    rules = undefined,
    label,
    title,
    tooltip,
    disabled = false,
    defaultChecked = false,
    className = '',
    caption,
  } = props;

  // console.log('checkbox');

  return (
    <div className={clsx('form-input checkbox-input', errors[name] && 'invalid', className)}>
      {title && (
        <label htmlFor={name}>
          {rules && rules.required ? `${title} *` : title}

          {
            tooltip && tooltip.length > 0 && <Tooltip text={tooltip} />
          }
        </label>
      )}

      <div className={clsx(disabled && 'disabled', 'custominput')}>
        <input
          type="checkbox"
          defaultChecked={defaultChecked}
          id={name}
          {...register(name, rules)}
        />

        <label htmlFor={name}>{label}</label>
      </div>

      {caption && <div className="field-caption">{caption}</div>}

      {
        errors[name]
        && <div className="error-message">{errors[name]?.message}</div>
      }

    </div>
  );
};

export default memo(SingleCheckbox, (prev, next) => prev.title === next.title);
