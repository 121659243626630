/* eslint-disable react/jsx-props-no-spreading */
import React               from 'react';
import { useFormContext }  from 'react-hook-form';
import { IDataCollection } from 'SHARED/types/offerTypes';
import validationRules     from 'SHARED/helpers/validation';
import Tooltip             from 'SHARED/components/Tooltip';
import './checkboxes.scoped.scss';
import prepareDataAttr from 'SHARED/helpers/prepareDataAttr';

interface CheckboxesPoolProps {
  name: string,
  rules?: any,
  selected?: IDataCollection[] | null, // TODO: refactor
  values: IDataCollection[],
  label?: string,
  tooltip?: string,
  disabled?: boolean,
  className?: 'two-columns' | 'three-columns',
  testingName?: string,
}

const Checkboxes: React.FC<CheckboxesPoolProps> = (props) => {
  const {
    register, formState: { errors },
  } = useFormContext();

  const {
    name,
    rules = validationRules.checkbox,
    label,
    tooltip,
    disabled = false,
    values,
    className = '',
    selected = [{ value: '1', label: 'Please Define' }],
    testingName,
  } = props;

  const val = selected ? selected.map((v) => v.value) : []; // TODO: refactor

  return (
    <div
      className={`form-input ${className} ${errors[name] ? 'invalid' : 'valid'}`}
      data-testing={prepareDataAttr(testingName || name)}
    >
      { label && (
        <label htmlFor={name}>
          {rules && rules.required ? `${label} *` : label}
          {
            tooltip && tooltip.length > 0 && <Tooltip text={tooltip} />
          }
        </label>
      )}

      {!values && <div>** check dictionary name **</div>}
      {!values.length && <div className="attention-message">No options</div>}

      <div className="input-wrapper">
        { values && values?.map((c) => (
          <div className="custominput" key={c?.value}>
            <input
              disabled={disabled}
              type="checkbox"
              defaultChecked={val.includes(c?.value)}
              id={`${name}-${c.value}`}
              value={c.value}
              {...register(name, rules)}
            />
            <label htmlFor={`${name}-${c.value}`}>{c?.label}</label>
          </div>
        ))}
      </div>

      {
        errors[name]
        && <div className="error-message">{errors[name]?.message}</div>
      }

    </div>
  );
};

export default Checkboxes;
