import { Alert } from '@material-ui/lab';
import React, { useEffect, useState } from 'react';
import { Form, useForm } from '../../hooks/useForm';
import api from '../../services/api';
import { Country } from '../../services/api/DictionaryService';
import {
  emptyOrganizationTemplate,
  OrganizationTemplate,
} from '../../services/api/OrganizationService';
import Controls from '../controls/Controls';

export interface OgTemplateFormProps {
  orgTemplate: OrganizationTemplate,
  setOpen(val: boolean): void,
}

const orgTypes = [{ value: 'SELLER', label: 'Seller' }, { value: 'BUYER', label: 'Buyer' }];

export default function OrganizationTemplateForm(props: OgTemplateFormProps) {
  const { orgTemplate, setOpen } = props;
  const [globalError, setGlobalError] = useState('');
  const [countries, setCountries] = useState([]);
  const initialState = orgTemplate.id ? orgTemplate : emptyOrganizationTemplate;
  const {
    values, setValues, errors, setErrors, handleInputChange, resetForm,
  } = useForm(initialState);

  useEffect(() => api.dictionary.getCountries(setCountries, setGlobalError), []);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    setGlobalError('');
    if (validate()) {
      const request: OrganizationTemplate = {
        orgName: values.orgName,
        orgType: values.orgType,
        countryId: values.countryId,
        countryName: values.countryName,
      };
      if (values.id) {
        api.organization.updateOrganizationTemplate(values.id, request)
          .then(() => setOpen(false))
          .catch((err) => setGlobalError(err.message));
      } else {
        api.organization.createOrganizationTemplate(request)
          .then(() => setOpen(false))
          .catch((err) => setGlobalError(err.message));
      }
    }
  };

  const validate = () => {
    setGlobalError('');
    const temp: any = {};

    ['orgName', 'orgType', 'countryName'].forEach((key) => {
      if (!values[key]) {
        temp[key] = 'This field is required.';
      }
    });

    setErrors({ ...temp });

    return Object.keys(temp).length === 0;
  };

  const getCountryOptions = () => (countries
    ? countries.map((it: Country) => ({ label: it.name, value: it.name }))
    : []);

  const setCountryData = (e: any) => {
    const countryName = e.target.value;
    // @ts-ignore
    const countryId = countries.find((it) => it.name === countryName)?.id;
    if (countryId) {
      setValues({ ...values, countryId, countryName });
    }
  };

  return (
    <Form name="create-organization-template">
      { globalError && <Alert severity="error">{globalError}</Alert> }
      { !values.id && (
      <Controls.RadioGroup
        name="orgType"
        label="TRADER ROLE"
        value={values.orgType}
        items={orgTypes}
        onChange={handleInputChange}
      />
      )}
      <Controls.Input
        name="orgName"
        label="TRADING NAME"
        value={values.orgName}
        error={errors.orgName}
        onChange={handleInputChange}
        required
      />
      <Controls.Select
        name="countryName"
        label="COUNTRY"
        value={values.countryName}
        error={errors.countryName}
        onChange={setCountryData}
        options={getCountryOptions()}
        required
      />
      <br />
      <div>
        <Controls.Button type="submit" onClick={handleSubmit}>Submit</Controls.Button>
        <Controls.Button color="default" onClick={resetForm}>Reset</Controls.Button>
      </div>
    </Form>
  );
}
