import { Add } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import {
  Button, CircularProgress, Paper, TableRow, Toolbar, Typography,
} from '@material-ui/core';
import useToggle from '../../hooks/useToggle';
import api from '../../services/api';
import Modal from '../Modal';
import colors from '../StyleConfig';
import ProductSpecForm from './ProductSpecForm';

export interface ProductSpecProps {
  orgType: 'SELLER' | 'BUYER',
  orgId: number
}

export default function ProductSpecPage(props: ProductSpecProps) {
  const { orgId, orgType } = props;
  const [open, setOpen] = useState(false);
  const [productCategory, setProductCategory] = useState('');
  const [recordForEdit, setRecordForEdit] = useState<any>({});
  const [products, setProducts] = useState([]);
  const { toggle, setToggle } = useToggle();
  const [load, setLoad] = useState(true);

  useEffect(() => {
    setLoad(true);
    api.productSpec.getProducts(orgId)
      .then(setProducts)
      .then(() => setLoad(false))
      .catch(() => setLoad(false));
  }, [orgId, toggle]);

  const openInPopup = (item: any, category: string) => {
    setRecordForEdit(item);
    setProductCategory(category);
    setOpen(true);
  };

  const handleModalClose = (val: boolean) => {
    setToggle();
    setOpen(val);
  };

  const getProductsByCategory = (category: string) => {
    const item: any = products.find((it: any) => it.productCategory.value === category);
    return item ? item.products : [];
  };

  const renderCategoryBlock = (category: string) => {
    const matchedCategory: any = products.find((item: any) => item.productCategory.value === category);
    const productGroup = getProductsByCategory(category);
    return (
      <Paper style={{ margin: '20px 10px', padding: '0 20px' }} elevation={3}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="h6">{ matchedCategory?.productCategory?.label }</Typography>
          <Toolbar>
            <Button
              variant="contained"
              color="primary"
              style={{ background: colors.primaryGreen }}
              startIcon={<Add />}
              onClick={() => openInPopup({}, category)}
            >
              Add specification
            </Button>
          </Toolbar>
        </div>
        { productGroup.length > 0 && renderTable(productGroup, category)}
      </Paper>
    );
  };

  const renderTable = (items: Array<any>, category: string) => (
    <div style={{ padding: '10px' }}>
      <div style={{ display: 'flex', marginBottom: 10 }}>
        <Typography variant="body1" color="textSecondary" style={{ width: '33%' }}>TYPE</Typography>
        <Typography variant="body1" color="textSecondary" style={{ width: '33%' }}>TITLE</Typography>
        <Typography variant="body1" color="textSecondary" style={{ width: '33%' }}>STATUS</Typography>
      </div>
      {
        items.map((it) => (
          <TableRow onClick={() => openInPopup(it, category)} style={{ display: 'flex', cursor: 'pointer', marginBottom: 5 }}>
            <Typography style={{ width: '33%' }}>{it.productType.label}</Typography>
            <Typography style={{ width: '33%' }}>{it.title}</Typography>
            <Typography style={{ width: '33%' }}>{it.active ? 'Active' : 'Not active'}</Typography>
          </TableRow>
        ))
      }
    </div>
  );

  return (
    <>
      <Modal title={`${recordForEdit.id ? 'Update' : 'Create'} product specification`} open={open} setOpen={setOpen}>
        <ProductSpecForm
          category={productCategory}
          orgId={orgId}
          item={recordForEdit}
          setOpen={handleModalClose}
          orgType={orgType}
        />
      </Modal>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Typography variant="h5" style={{ marginRight: 15 }}>Product specification</Typography>
        { load && <CircularProgress color="secondary" /> }
      </div>
      {
        !load && products && products?.length > 0 && (
        <>
            {products?.map((product: any) => (
              renderCategoryBlock(product.productCategory.value)
            ))}
        </>
        )
      }
    </>
  );
}
