import React from 'react';
import ReactDOM from 'react-dom';
import smoothscroll from 'smoothscroll-polyfill';
import { store } from 'SHARED/redux/store';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import App from './components/App';

// ? old styles, need to be revaluated and removed if not needed
import 'assets/styles/index.scss';
// ? new styles, may conflict with old styles
import 'SHARED/assets/styles/index.scss';

// enable smooth scrolling for Safari/Edge/IE
smoothscroll.polyfill();

const jsx = (
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>
);

ReactDOM.render(jsx, document.querySelector('#root'));
