import React, { FC } from 'react';
import { Typography } from '@material-ui/core';
import { getUserName } from 'services/Utils';
import TextField from 'components/controls/TextField';
import { Deal } from 'SHARED/types/offerTypes';

interface IProps {
  deal: Deal | undefined | null
}

const BuyerBlock: FC<IProps> = ({ deal }) => (
  <>
    <Typography variant="h5">Buyer</Typography>
    <TextField label="BUYER COMPANY" value={deal?.buyerOrg?.orgName} />
    <TextField label="DESTINATION COUNTRY" value={deal?.buyerOrg?.country} />
    <TextField label="BUYER NAME" value={getUserName(deal?.buyer)} />
    <TextField label="EMAIL" value={deal?.buyer?.profile?.email} />
    <TextField label="TELEPHONE" value={deal?.buyer?.profile?.phoneNumber} />
  </>
);

export default BuyerBlock;
