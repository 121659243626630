import React, { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import moment from 'moment';

import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

import { IFormFieldBase } from './FormSelect';
import validationRules from './validationRules';

interface IFormDatePickerProps extends IFormFieldBase {
  dateFormat?: string;
}

const FormDatePicker: FC<IFormDatePickerProps> = ({
  name,
  label,
  rules = validationRules.required,
  disabled,
  dateFormat = 'DD MMM YYYY',
  required,
  placeholder = moment().format(dateFormat),
}) => {
  const {
    formState, control,
  } = useFormContext();
  const { errors } = formState;
  const isRequired = (rules && rules.required) || required;

  return (
    <div className="form-field">
      {label && <div className="label-text">{isRequired ? `${label} *` : label}</div>}

      <LocalizationProvider dateAdapter={AdapterMoment}>

        <Controller
          name={name}
          control={control}
          rules={{
            ...validationRules.formDatePickerValue,
            ...rules,
          }}
          defaultValue={moment().toISOString()}
          render={({ field: { onChange, value } }) => (
            <DatePicker
              value={value}
              onChange={(newValue) => (
                // if newValue === null or it's Moment object with invalid date
                (!newValue || !newValue?.isValid())
                  ? onChange(newValue)
                  : onChange(newValue?.toISOString())
              )}
              inputFormat={dateFormat}
              disabled={disabled}
              views={['year', 'month', 'day']}
              disableMaskedInput
              renderInput={(params) => (
                <TextField
                  {...params}
                  id={`${name}-date-input`}
                  size="small"
                  error={!!errors[name]}
                  inputProps={{
                    ...params.inputProps,
                    placeholder,
                  }}
                />
              )}
            />
          )}
        />

      </LocalizationProvider>

      {errors[name]?.message && <div className="form-field__error">{errors[name]?.message}</div>}
    </div>
  );
};

export default FormDatePicker;
