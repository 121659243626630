const deepLinkCheck = (path: string) => {
  const location = window.location.pathname;

  // console.log({ location, path });

  if (
    location === path
    || location === `${path}/`
    || location.includes(`${path}/`)
  ) return true;

  return false;
};

export default deepLinkCheck;
