import moment, { Moment } from 'moment-timezone';
import { zonedTimeToUtc } from 'date-fns-tz';
import { Dictionary } from 'SHARED/types/offerTypes';

export type IDate = Date | string | number;

export type IDatesRangeTimezone = (payload: {
  from: Date | number | string | null,
  to: Date | number | string | null,
  format?: string,
  timezone: string,
}) => string;

export const dateFormatTimezone = 'D MMM YYYY HH:mm z';
export const dateFormat = 'D MMM YYYY HH:mm';
export const fallbackTimezone = 'Europe/Amsterdam';
export const timezonesList = moment.tz.names();

export const deviceTimezone = () => moment.tz.guess();

const removeZoneFromDateFormat = (format: string) => (
  format.replaceAll(' z', '')
    .replaceAll(' Z', '')
    .replaceAll('z', '')
    .replaceAll('Z', '')
);

export const dateWithTimezone = (
  time: number | string | null | Date | undefined,
  zone: string | null | undefined,
  format?: string | undefined,
  abbr?: string,
) => {
  const formatOption = removeZoneFromDateFormat(format || dateFormatTimezone);
  const timezone = (zone && timezonesList.includes(zone)) ? zone : fallbackTimezone;

  const timeZoneAbbr = abbr ? ` ${abbr}` : '';

  return time ? moment.tz(time, timezone).format(`${formatOption}${timeZoneAbbr}`) : 'Invalid date';
};
export const dateRangeWithTimezone: IDatesRangeTimezone = (
  { from, to, format = 'DD MMM', timezone },
) => `${moment(from).tz(timezone).format(format)} - ${moment(to).tz(timezone).format(format)} ${moment(to).tz(timezone).format('YYYY')}`;

export const dateWithoutTimezone = (timestamp: number | string) => moment(timestamp).format(dateFormat);

export const createTimestamp = (date: Date | null) => (date ? moment(date).utcOffset(0, true).format('x') : null);

export const zonedMonthsDictionary = (userTimezone: string): Dictionary[] => {
  const getLastDayOfMonth = (momentDate: Moment): Moment => {
    const lastDay = momentDate.format(`YYYY-MM-${momentDate.daysInMonth()}`);
    // ? subtract 1 day to get valid day for any timezone
    const dayBefore = moment(lastDay).subtract(1, 'days').toISOString();
    return moment(dayBefore);
  };

  const nextMonths: Moment[] = [];
  nextMonths.push(getLastDayOfMonth(moment()));

  for (let i = 0; i < 11; i += 1) {
    nextMonths.push(getLastDayOfMonth(moment(nextMonths[i]).add(1, 'months')));
  }
  return nextMonths.map((m) => {
    const convertedDate = moment(zonedTimeToUtc(m.toDate(), userTimezone));

    return ({
      label: moment.tz(convertedDate, userTimezone).format('MMMM YYYY'),
      value: convertedDate.toISOString(),
    });
  });
};
