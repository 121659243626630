import React, { useEffect } from 'react';
import moment from 'moment-timezone';
import { zonedTimeToUtc, utcToZonedTime } from 'date-fns-tz';
import { useFormContext, Controller } from 'react-hook-form';
import DatePicker from 'react-datepicker';
import validationRules from 'SHARED/helpers/validation';
import useUserTimezone from 'SHARED/hooks/useUserTimezone';
import { useTypedSelector } from 'SHARED/redux/hooks/useTypedSelector';
import Tooltip from '../Tooltip';
import 'react-datepicker/dist/react-datepicker.css';

interface IZonedDatePickerProps {
  name: `${string}` | `${string}.${string}` | `${string}.${number}`,
  rules?: any,
  value?: any,
  label?: string,
  showTimezone?: boolean, // true by default
  defaultValue?: Date | string | null | undefined,
  tooltip?: string,
  placeholder?: string,
  dateFormat?: string,
  disabled?: boolean,
  showTimeInput?: boolean,
  minDate?: Date | string | null | undefined,
  maxDate?: Date | string | null | undefined,
  inline?: boolean,
  caption?: string,
}

const ZonedDatePicker: React.FC<IZonedDatePickerProps> = ({
  name,
  rules = validationRules.required,
  value,
  label,
  showTimezone = true,
  defaultValue = null,
  tooltip,
  placeholder = 'Select a date',
  dateFormat = 'dd MMMM yyyy',
  disabled = false,
  showTimeInput = false,
  minDate = new Date(),
  maxDate,
  inline = false,
  caption,
}) => {
  const { formState: { errors }, control, watch, setValue } = useFormContext();
  const proxyName = `${name}_proxy`;
  const proxyValue = watch(proxyName);

  const { userTimezone: timezone } = useUserTimezone();
  const { me } = useTypedSelector((state) => state.users);
  const timeZoneAbbr = me?.profile?.timeZoneAbbr || 'CET';

  const formattedValue = value ? utcToZonedTime(new Date(value), timezone) : undefined;

  // defining min hours, minutes for preventing choosing time before current time
  const val = watch(name);
  const isSelectedDateToday = moment(zonedTimeToUtc(moment(new Date()).toDate(), timezone)).isSame(val, 'day');
  let minTimeHour = moment.tz(new Date(), timezone).hours();
  let minTimeMinutes = moment.tz(new Date(), timezone).minutes();
  if (!isSelectedDateToday) {
    minTimeHour = 0;
    minTimeMinutes = 0;
  }
  const minTime = new Date(new Date().setHours(minTimeHour, minTimeMinutes, 0, 0));
  const maxTime = new Date(new Date().setHours(23, 59, 0, 0));

  useEffect(() => {
    if (!val) {
      setValue(proxyName, formattedValue);
    }
  }, []);

  useEffect(() => {
    if (!proxyValue) {
      setValue(name, '');
    } else {
      setValue(name, zonedTimeToUtc(moment(proxyValue).toDate(), timezone).toISOString());
    }
  }, [proxyValue]);

  return (
    <div className={`form-input ${errors[name] ? 'invalid' : 'valid'}`}>
      {label && (
        <label htmlFor={name}>
          {rules && rules.required ? `${label} *` : label}

          {
            tooltip && tooltip.length > 0 && <Tooltip text={tooltip} />
          }
        </label>
      )}

      <div className="react-datepicker__input-wrapper">
        {/* ReactDatepicker - component used to pick Year\Month\Day\Time but're we not gonna use it's timezone */}
        <Controller
          name={proxyName}
          rules={undefined}
          control={control}
          defaultValue={undefined}
          render={({ field }) => (
            <>
              <DatePicker
                inline={inline}
                placeholderText={placeholder}
                disabled={disabled}
                {...field}
                minDate={minDate ? moment(minDate).toDate() : undefined}
                maxDate={maxDate ? moment(maxDate).toDate() : undefined}
                showTimeSelect={showTimeInput}
                minTime={minTime}
                maxTime={maxTime}
                selected={field.value === null ? defaultValue : field.value}
                dateFormat={dateFormat}
                timeFormat="HH:mm"
                timeIntervals={15}
                ref={(elem) => {
                // @ts-expect-error
                  elem && field.ref(elem.input);
                }}
                autoComplete="off"
              />
            </>
          )}
        />

        {showTimezone && <div className="suffix timezone">{timeZoneAbbr}</div>}

      </div>

      {/* Hidden input, used to store date in any chosen format, this value goes to ReactHookForm state */}
      <Controller
        name={name}
        rules={rules}
        control={control}
        defaultValue={value}
        render={({ field, fieldState }) => (
          <>
            <input
              {...field}
              type="text"
              value={field.value}
              disabled={disabled}
              className="visually-hidden"
            />

            <div className="error-message">{fieldState.error?.message}</div>
          </>
        )}
      />

      {caption && <div className="field-caption">{caption}</div>}
    </div>
  );
};

export default ZonedDatePicker;
