import {
  IOffspecOffer, OnSpecOffer, Organisation, OfferListItem, RFP,
} from 'SHARED/types/offerTypes';

import {
  buyersFetchType,
  volumeUnits,
  departurePeriod,
} from 'SHARED/helpers/OfferData';
import moment from 'moment-timezone';

export interface IOfferState {
  OFF_SPEC: IOffspecOffer,
  ON_SPEC: OnSpecOffer,
  RFP: RFP,
  offersList: OfferListItem[],
  loading: boolean,
  pricesLoading: boolean,
  isFilesUploading: boolean,
  totalPageCount: number,
  totalRowCount: number,
  offerDuplicateLoading: boolean,
  error: string | null,
}

//  ************************* END bid info *************************

export const ONE_DAY = 3600 * 1000 * 24;
export const TWO_YEARS = ONE_DAY * 365 * 2;

const defaultDates: { [date: string]: number } = {
  loadFrom: Date.now() + (ONE_DAY * 2),
  loadTo: Date.now() + (ONE_DAY * 7),
  auctionEnd: Date.now() + (ONE_DAY),
};

const defaultOrg:Organisation = {
  approvalDate: '',
  coc: '',
  contactPerson: null,
  country: '',
  id: 0,
  notes: '',
  offSpecLocations: null,
  orgName: '',
  legalName: '',
  registrationDate: '',
  status: '',
  tradingName: '',
  type: '',
  vat: '',
  currency: 'EUR',
};

export const defaultOffspecOffer:IOffspecOffer = {
  id: '',
  type: null,
  created: null,
  title: '',
  status: 'ACTIVE',
  statusLabel: '',
  description: '',
  totalVolume: undefined,
  documentCapabilities: [],
  productLocation: null,
  packaging: null,
  salesTerms: { label: 'MPC', value: 'MPC' },
  documents: {
    COA: null,
    SALES_TERMS: null,
  },
  incoterms: null,
  loadOptions: null,
  loadDateFrom: defaultDates.loadFrom,
  loadDateTo: defaultDates.loadTo,
  price: null,
  priceEur: null,
  priceUsd: null,
  endDate: defaultDates.auctionEnd,
  buyersFetchType: buyersFetchType[0],
  buyers: [],
  paymentTerms: null,
  bidders: 0,
  volumeUnits,
  currency: 'EUR',
  buyer: '',
  country: '',
  cancellationReason: '',
  cancellationInfo: {
    date: '',
    fullName: '',
  },
  latestBid: null,
  winningBid: null,
  seller: '',
  ourLastBid: null,
  params: {
    excludedBuyers: [],
    buyersFetchType: null,
    salesTerms: { label: 'MPC', value: 'MPC' },
  },
  ownerOrg: defaultOrg,
  deal: null,
  groups: [],
  splittable: false,
  logisticLocation: null,
};

export const defaultOnspecOffer:OnSpecOffer = {
  id: '',
  type: null,
  created: null,
  title: '',
  status: 'ACTIVE',
  description: '',
  productCategory: null,
  productType: null,
  product: null,
  productId: 0,
  products: [],
  productIds: [],
  coaUsed: false,
  documents: {
    COA: null,
    SALES_TERMS: null,
  },
  multiDeal: false,
  totalVolume: undefined,
  documentCapabilities: [],
  productionLocation: null,
  logisticLocation: null,
  logisticLocationId: null,
  customLabelPossible: false,
  splittable: false,
  productionDate: Date.now() - (ONE_DAY),
  expirationDate: Date.now() + (TWO_YEARS), // add 2 years from now by default
  endDate: Date.now() + (ONE_DAY * 1),
  departurePeriod: departurePeriod[0],
  departureFrom: moment().toISOString(),
  departureFromMonth: null,
  departureTo: moment().toISOString(),
  packaging: null,
  packages: null,
  salesTerms: { label: 'MPC', value: 'MPC' },
  incoterms: null,
  loadOptions: null,
  price: null,
  priceEur: null,
  priceUsd: null,
  buyersFetchType: buyersFetchType[0],
  buyers: [],
  groups: [],
  paymentTerms: null,
  bidders: 0,
  volumeUnits,
  currency: 'EUR',
  buyer: '',
  country: '',
  cancellationReason: '',
  cancellationInfo: {
    date: '',
    fullName: '',
  },
  latestBid: null,
  winningBid: null,
  seller: '',
  ourLastBid: null,
  productCharacteristics: null,
  params: {
    excludedBuyers: [],
    buyersFetchType: null,
    salesTerms: { label: 'MPC', value: 'MPC' },
  },
  ownerOrg: defaultOrg,
  deal: null,
  hasDeals: false,
  version: '',
  priceDetails: null,
  vasFinancing: null,
};

const defaultRFP:RFP = {
  id: undefined,
  productLocation: null,
  loadAddress: null,
  packages: null,
  documentCapabilities: null,
  productCategory: null,
  productType: null,
  product: null,
  deliveryLocation: null,
  volumeUnits,
  documents: {
    COA: null,
    SALES_TERMS: null,
  },
  loadOptions: null,
  endDate: moment().add(2, 'day').valueOf(),
  departureFrom: moment().toISOString(),
  departureTo: moment().add(1, 'month').toISOString(),
  version: '',
  minVolume: undefined,
  maxVolume: undefined,
  ownerOrg: null,
  proposals: [],
  isCanAddProposal: false,
  status: null,
  statusLabel: '',
  incoterms: null,
  paymentTerms: null,
  currency: null,
};

export const initialOfferState: IOfferState = {
  OFF_SPEC: defaultOffspecOffer,
  ON_SPEC: defaultOnspecOffer,
  RFP: defaultRFP,
  offersList: [],
  loading: false,
  pricesLoading: false,
  isFilesUploading: false,
  totalPageCount: 0,
  totalRowCount: 0,
  offerDuplicateLoading: false,
  error: null,
};

export default initialOfferState;
