import { useQuery } from '@tanstack/react-query';
import { getLogisticsPrices } from 'api/get/getLogisticsPrices';
import { toast } from 'react-hot-toast';

interface IParams {
  offerId?: string | number;
  orgId?: string | number;
  buyerIncoterms?: string | number;
}

export const useLogisticsPricesQuery = (params: IParams) => {
  const { offerId, orgId, buyerIncoterms } = params;

  return useQuery({
    queryKey: ['logisticsPrices', offerId, orgId, buyerIncoterms],
    queryFn: () => getLogisticsPrices({ ...params }),
    onError: (error) => {
      console.error(error);
      toast.error('Error loading logistics prices');
    },
    enabled: !!offerId && !!orgId,
  });
};
