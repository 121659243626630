type IFinancingDetails = {
  currency?: string,
  creditInterestRate?: number,
  commissionPerUnit?: number,
  commissionPerDeal?: number,
};

const prepareFinancingDetails = (params: IFinancingDetails) => {
  const {
    creditInterestRate, commissionPerUnit, commissionPerDeal, currency = '-',
  } = params;

  if (!creditInterestRate && !commissionPerUnit && !commissionPerDeal) {
    return '-';
  }

  const interestRate = creditInterestRate ? `${creditInterestRate}% per year` : '';
  const fixed = commissionPerUnit ? `, ${currency} ${commissionPerUnit}/mt` : '';
  const perDeal = commissionPerDeal ? `, ${currency} ${commissionPerDeal} per deal` : '';

  return `${interestRate}${fixed}${perDeal}`;
};

export default prepareFinancingDetails;
