import {
  Grid, Link as MuiLink, Tab, Tabs, Typography,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React, { useEffect, useState } from 'react';
import useOfferAnalytics from 'hooks/useOfferAnalytics';
import OfferAnalytics from 'components/analytics/OfferAnalytics';
import clsx from 'clsx';
import {
  Link, NavLink, Route, Switch, useHistory,
} from 'react-router-dom';
import pages from '../../navigation/pages';
import api from '../../services/api';
import { CreditLineRowCondition } from '../../services/api/CreditService';
import { emptyOffer } from '../../services/api/OfferService';
import { Page } from '../../services/api/RestUtil';
import Controls from '../controls/Controls';
import TextField from '../controls/TextField';
import DownloadField from '../controls/DownloadField';
import {
  formatMoney,
  joinOptions,
  prettyDate,
  prettyDateTime,
} from '../../services/Utils';
import DataTable from '../DataTable';
import ProductDetailsPopup from './ProductDetailsPopup';

export interface OfferOffSpecFormProps {
  id: number
  setOpen(val: boolean): void
}

enum OfferDocType {
  SALES_TERMS = 'SALES_TERMS',
  COA = 'COA',
}

const proposalColumns = [
  // NEW
  {
    field: 'price',
    label: 'PRICE',
    render: (row: any) => `${row?.currency} ${formatMoney(row?.price)}  /${row?.volumeUnits}`,
  },
  {
    field: 'volume',
    label: 'VOLUME',
    render: (row: any) => `${row?.totalVolume} ${row?.volumeUnits}`,
  },
  {
    field: 'seller',
    label: 'SELLER',
    render: (row: any) => row?.sellerOrg,
  },
  {
    field: 'country',
    label: 'COUNTRY',
    render: (row: any) => row?.sellerOrgCountry,
  },
  {
    field: 'status',
    label: 'STATUS',
    render: (row: any) => row?.statusLabel,
  },
];

const containerStyle = { justifyContent: 'space-between' };
const itemStyle = { width: '33%', padding: '15px' };

export default function RFPDetails(props: any) {
  const { history, match: { params: { rfpId } } } = props;
  const [rfp, setRfp] = useState(emptyOffer);
  const [error, setError] = useState('');
  const [productId, setProductId] = useState(-1);
  const [open, setOpen] = useState(false);

  const { location } = useHistory();

  // ANALYTICS
  const { analytics, analyticsError } = useOfferAnalytics(rfpId);

  useEffect(() => api.offer.getOfferById(rfpId, setRfp, setError), [rfpId]);

  const renderFileField = (source: any, label: string, docType: OfferDocType) => {
    const docs = source?.documents;
    const requestedDocs = docs ? docs[docType] : [];
    const files = (requestedDocs || []).map((doc: any) => ({
      fileName: doc.fileName,
      originalFileName: doc.originalFileName,
    }));
    return (
      <div style={{ margin: '15px 10px' }}>
        <DownloadField label={label} files={files} />
      </div>
    );
  };

  const RFPBlock = () => (
    <>
      <Typography variant="h5">RFP</Typography>
      <TextField label="RFP ID" value={rfp?.id} />
      <TextField label="CREATED" value={prettyDateTime(rfp?.created)} />
      <TextField label="VALID UNTIL" value={prettyDateTime(rfp?.endDate)} />
      <TextField label="PROPOSAL VOLUME" value={`${rfp?.minVolume} - ${rfp?.maxVolume} ${rfp?.volumeUnits?.label}`} />
      <TextField label="STATUS" value={rfp?.statusLabel} />
    </>
  );

  const renderProductLink = (name: string, prodId: number) => (
    <MuiLink
      style={{ cursor: 'pointer', fontSize: 16, color: '#009FD1' }}
      onClick={() => { setProductId(prodId); setOpen(true); }}
    >
      {name}
    </MuiLink>
  );

  const renderRFP = () => (rfp?.id && (
    <>
      <Grid container style={containerStyle}>
        <Grid item style={itemStyle}>{RFPBlock()}</Grid>
        <Grid item style={itemStyle}>
          <Typography variant="h5">Shipping</Typography>
          <TextField label="DELIVERY ADDRESS" value={rfp?.deliveryLocation?.fullAddress} />
          <TextField label="DEPARTURE" value={`${prettyDate(rfp?.departureFrom)} - ${prettyDate(rfp?.departureTo)}`} />
        </Grid>
        <Grid item style={itemStyle}>
          <Typography variant="h5">Product</Typography>
          <TextField label="PRODUCT CATEGORY" value={rfp?.productCategory?.label} />
          <TextField label="PRODUCT TYPE" value={rfp?.productType?.label} />
          <TextField label="PRODUCT SPECIFICATION" value={renderProductLink(rfp?.productTitle, rfp?.productId)} />
          <TextField label="INSTRUCTION FOR THE SELLERS" value={rfp?.description} />
          {renderFileField(rfp, 'ATTACHED DOCUMENT', OfferDocType.SALES_TERMS)}
          <TextField label="PACKAGING ALLOWED" value={joinOptions(rfp?.packages)} />
          <TextField label="DOCUMENTS REQUIRED" value={joinOptions(rfp?.documentCapabilities)} />
        </Grid>
      </Grid>

      {!!rfp?.deals && (
        rfp?.deals?.map((deal: any) => (
          <Link
            key={deal?.id}
            className={clsx(!deal?.id && 'disabled')}
            style={{ fontSize: 16, display: 'inline-flex' }}
            to={pages.goto_rfp_deal(deal?.id)}
            title="View deal details"
          >
            Show deal {`(${deal?.number})`} details
          </Link>
        ))
      )}
    </>
  ));

  const renderProposals = () => (
    <DataTable
      name="Received proposals"
      columns={proposalColumns}
      fetchRows={fetchProposals}
      onRowClick={(row) => history.push(pages.goto_rfpProposal(row?.id))}
    />
  );

  const fetchProposals = () => {
    const page: Page<any> = {
      rows: rfp?.proposals,
      totalPageCount: 1,
      totalRowCount: rfp?.proposals?.length,
    };

    return new Promise<Page<CreditLineRowCondition>>((resolve) => resolve(page));
  };

  const isActive = rfp?.status === 'ACTIVE';

  const detailsUrl = isActive ? pages.goto_rfpOffer(rfpId) : pages.goto_rfpOffer_archived(rfpId);
  const proposalsUrl = isActive ? pages.goto_rfpOfferProposals(rfpId) : pages.goto_rfpOfferProposals_archived(rfpId);
  const analyticsUrl = isActive ? pages.goto_rfpOfferAnalytics(rfpId) : pages.goto_rfpOfferAnalytics_archived(rfpId);

  return (
    <div className="page-body">
      { error && <Alert severity="error">{error}</Alert> }
      <div style={{ display: 'flex' }}>
        <Typography variant="h5" style={{ flexGrow: 1 }}>RFP</Typography>
        <Controls.Button
          type="button"
          color="secondary"
          onClick={() => props.history.push(isActive ? pages.rfp : pages.rfp_archived)}
        >
          <span style={{ fontSize: 16, color: '#FFF' }}>{'< Back to RFPs'}</span>
        </Controls.Button>
      </div>

      <Tabs value={location.pathname} className="nav-tabs">
        <Tab
          label="Details"
          to={detailsUrl}
          value={detailsUrl}
          component={NavLink}
          exact
        />
        <Tab
          label="Proposals"
          to={proposalsUrl}
          value={proposalsUrl}
          component={NavLink}
          exact
        />
        <Tab
          label="Analytics"
          to={analyticsUrl}
          value={analyticsUrl}
          component={NavLink}
          exact
        />
      </Tabs>

      <br />

      <Switch>
        <Route path={[pages.rfpOffer, pages.rfpOffer_archived]} exact>{renderRFP()}</Route>
        <Route path={[pages.rfpOfferProposals, pages.rfpOfferProposals_archived]} exact>{renderProposals()}</Route>
        <Route path={[pages.rfpOfferAnalytics, pages.rfpOfferAnalytics_archived]} exact>
          <OfferAnalytics
            offerCreationDate={rfp?.created}
            analytics={analytics}
            error={analyticsError}
          />
        </Route>
      </Switch>

      {productId > 0 && <ProductDetailsPopup productId={productId} open={open} setOpen={setOpen} />}
    </div>
  );
}
