import axios from 'api/axios';
import { OFFER_ENDPOINTS } from 'api/endpoints';
import { AxiosResponse } from 'axios';
import { PlaceBidResponse } from 'types/bidTypes';

export interface IPlaceBidPayload {
  offerId: number | string;
  offerVersion: string;

  buyerOrgId: number | string;
  buyerUserId: number | string;

  currency: 'USD' | 'EUR';
  volume: number | string;
  price: number | string;
  creationDate: number;
  logisticLocationId: number | string;
  paymentTerm: string;

  packaging?: string;

  logisticPrice?: number | string | null;
  containerTypeId?: number | string | null;
  logisticPartnerId?: number | string | null;

  paymentOffset?: number | string | null;
  creditConditionId?: number | string | null;
}

export interface IPostOfferBidParams {
  commit?: boolean;
  payload: IPlaceBidPayload;
}

export const postOfferBid = async (params: IPostOfferBidParams) => {
  const { commit = false, payload } = params;
  const isCommit = commit ? 'true' : 'false';

  const res: AxiosResponse<PlaceBidResponse> = await axios({
    method: 'post',
    url: `${OFFER_ENDPOINTS.postBid}?commit=${isCommit}`,
    data: payload,
  });

  if (res.status === 200) {
    return res.data;
  }

  throw new Error(`Cannot post a bid. Status: ${res.status}`);
};
