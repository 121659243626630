import React, { FC } from 'react';
import { Typography } from '@material-ui/core';
import {
  date, joinOptions,
} from 'services/Utils';
import TextField from 'components/controls/TextField';
import { IOffspecOffer } from 'SHARED/types/offerTypes';

interface IProps {
  offer: IOffspecOffer
}

const ShippingBlock: FC<IProps> = ({ offer }) => {
  const {
    incoterms,
    loadAddress,
    logisticLocation,
    loadOptions,
    loadDateFrom,
    loadDateTo,
  } = offer;

  return (
    <>
      <Typography variant="h5">Shipping</Typography>
      <TextField label="INCOTERMS" value={incoterms?.label} />
      {!!loadAddress?.value && <TextField label="LOADING ADDRESS" value={loadAddress?.label} />}
      {!!logisticLocation?.fullAddress && <TextField label="SELLER INCOTERMS LOCATION" value={logisticLocation?.fullAddress} />}
      <TextField label="LOADING DETAILS" value={joinOptions(loadOptions)} />
      <TextField label="PICK-UP DATE RANGE" value={`${date(loadDateFrom)} - ${date(loadDateTo)}`} />
    </>
  );
};

export default ShippingBlock;
