import axios from 'api/axios';
import endpoints from 'api/endpoints';

const updateLaneProviderPrices = async (data: any) => {
  const res = await axios({
    method: 'PATCH',
    url: endpoints.logisticLanePrices,
    data,
  });

  if (res.status === 200) {
    return res.data;
  }
  throw new Error(`Cannot get logistic locations. Status: ${res.status}`);
};

export default updateLaneProviderPrices;
