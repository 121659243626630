import axios from 'api/axios';
import { BASE_ENDPOINTS } from 'api/endpoints';
import type { IPageResponse } from 'api/types';
import type { Organization } from 'types/offerTypes';

type OrgsRes = IPageResponse<Organization>;

export const getOrgsByType = async (orgType: 'seller' | 'buyer'): Promise<OrgsRes> => {
  const res = await axios({
    method: 'get',
    url: BASE_ENDPOINTS.getOrganizations(orgType),
  });

  if (res.status === 200) {
    return res.data;
  }
  throw new Error(`Cannot get orgs data. Status: ${res.status}`);
};
