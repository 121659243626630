import React, { FC } from 'react';
import { Typography } from '@material-ui/core';
import { IOffspecOffer } from 'SHARED/types/offerTypes';
import DataItemPreview from 'SHARED/components/DataItemPreview';
import TextField from 'components/controls/TextField';
import { joinOptions } from 'services/Utils';

interface IProps {
  offer: IOffspecOffer
}

const ProductBlock: FC<IProps> = ({ offer }) => {
  const { documents } = offer;

  return (
    <>
      <Typography variant="h5">Product</Typography>
      <TextField label="OFFER TITLE" value={offer.title} />
      <TextField label="DESCRIPTION" value={offer.description} />

      {documents && documents.COA && <DataItemPreview file={documents.COA[0]} title="PRODUCT COA" />}

      <TextField label="DOCUMENTS INCLUDED" value={joinOptions(offer.documentCapabilities)} />
      <TextField label="PRODUCTION LOCATION" value="-" />
      <TextField label="PACKAGING" value={offer.packaging?.label} />

      {documents && documents.SALES_TERMS && <DataItemPreview file={documents.SALES_TERMS[0]} title="SALES TERMS DOCUMENT" />}
    </>
  );
};

export default ProductBlock;
