import ChatService from './ChatService';
import { CreditService } from './CreditService';
import { DictionaryService } from './DictionaryService';
import { FileService } from './FileService';
import { OfferService } from './OfferService';
import { TransportationService } from './TransportationServie';
import { ProductSpecService } from './ProductSpecService';
import { UserService } from './UserService';
import { OrganizationService } from './OrganizationService';
import { LocationService } from './LocationService';

const api = {
  user: new UserService(),
  organization: new OrganizationService(),
  dictionary: new DictionaryService(),
  offer: new OfferService(),
  location: new LocationService(),
  transportation: new TransportationService(),
  productSpec: new ProductSpecService(),
  file: new FileService(),
  chat: new ChatService(),
  credit: new CreditService(),
};

export default api;
