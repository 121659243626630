import React from 'react';
import { Grid } from '@material-ui/core';
import TwilioChat from 'components/live-chat/TwilioChat';
import { useParams } from 'react-router-dom';
import { useDealQuery } from 'api/queries/useDealQuery';
import Preloader from 'components/od-preloader/Preloader';
import { IOffspecOffer } from 'SHARED/types/offerTypes';
import AuctionBuyerBlock from './blocks/BuyerBlock';
import AuctionDealBlock from './blocks/DealBlock';
import OfferBlock from './blocks/OfferBlock';
import ProductBlock from './blocks/ProductBlock';
import AuctionSellerBlock from './blocks/SellerBlock';
import ShippingBlock from './blocks/ShippingBlock';
import VolumeBlock from './blocks/VolumeBlock';
import WinningBidBlock from './blocks/WinningBidBlock';
import AuctionDealHeader from './partials/AuctionDealHeader';

interface AuctionDealPageParams {
  dealId: string
}

const containerStyle = { justifyContent: 'space-between' };
const itemStyle = { width: '33%', padding: '15px' };

const AuctionDealPage = () => {
  const { dealId } = useParams<AuctionDealPageParams>();
  const { isLoading, data: offer } = useDealQuery<IOffspecOffer>(dealId);

  return (
    <div className="page-body">
      <Preloader isLoading={isLoading} />

      <AuctionDealHeader offer={offer} />

      {!!offer && (
        <div style={{ display: 'flex' }}>

          <Grid container>
            <Grid container style={containerStyle}>
              <Grid item style={itemStyle}><AuctionDealBlock deal={offer?.deal} /></Grid>
              <Grid item style={itemStyle}><AuctionSellerBlock offer={offer} /></Grid>
              <Grid item style={itemStyle}><AuctionBuyerBlock deal={offer?.deal} /></Grid>
            </Grid>

            <br />

            <Grid container style={containerStyle}>
              <Grid item style={itemStyle}><OfferBlock offer={offer} /></Grid>
              <Grid item style={itemStyle}><ProductBlock offer={offer} /></Grid>
              <Grid item style={itemStyle}><ShippingBlock offer={offer} /></Grid>
            </Grid>

            <Grid container style={containerStyle}>
              <Grid item style={itemStyle}><VolumeBlock offer={offer} /></Grid>
              <Grid item style={itemStyle}><WinningBidBlock offer={offer} /></Grid>
            </Grid>

            <TwilioChat dealId={offer?.deal?.id} />
          </Grid>
        </div>
      )}
    </div>
  );
};

export default AuctionDealPage;
