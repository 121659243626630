import { z } from 'zod';

const documentNodeStatusSchema = z.union([
  z.literal('REQUESTED'),
  z.literal('ON_REVIEW'),
  z.literal('APPROVED'),
  z.literal('REJECTED'),
  z.literal('FILE_DELETED'),
  z.literal('DELETED'),
]);

const documentNodeSchema = z.object({
  comment: z.string().optional().nullable(),
  createdAt: z.number(),
  createdBy: z.string(),
  originalFileName: z.string(),
  ownedBy: z.string(),
  type: z.string(),
});

// TODO: for now ok, but not sure if it's actually like this
const documentActionSchema = z.object({
  request: z.boolean(),
  upload: z.boolean(),
}).nullable();

const documentThreadSchema = z.object({
  createdBy: z.string(),
  documentType: z.object({
    actions: documentActionSchema,
    code: z.string(),
    title: z.string(),
  }),
  history: documentNodeSchema.array(),
  id: z.number(),
  latestNode: documentNodeSchema,
  ownedBy: z.string(),
  requested: z.boolean(),
  status: documentNodeStatusSchema,
  userUploader: z.boolean(),
});

export type IDocumentThread = z.infer<typeof documentThreadSchema>;

export default documentThreadSchema;
