import React, { FC } from 'react';
import { Alert } from '@mui/material';
import DataItemPreview from 'components/partials/DataItemPreview';
import {
  amsDateWithTimezone, formatMoney, getUserName, joinOptions,
} from 'services/Utils';
import ProductSpecsDetails from 'components/partials/ProductSpecsDetails';
import prepareFinancingDetails from 'helpers/prepareFinancingDetails';
import OfferSalesTermsField from 'components/OfferSalesTermsField';
import { OnSpecOffer } from 'SHARED/types/offerTypes';

interface IProps {
  offer: OnSpecOffer | undefined | null
}

const DealFieldsRfp: FC<IProps> = ({ offer }) => {
  // encapsulated data
  const dealVolume = offer?.deal?.volume || 0;
  const dealPrice = offer?.deal?.price || 0;
  const dealCurrency = offer?.deal?.currency || 'EUR';
  const dealVolumeUnits = offer?.deal?.volumeUnits || 'MT';

  const sellerOrg = offer?.deal?.sellerOrg?.orgName;
  const sellerCountry = offer?.deal?.sellerOrg?.country;
  const sellerName = getUserName(offer?.deal?.seller);
  const sellerEmail = offer?.deal?.seller?.profile?.email;
  const sellerPhone = offer?.deal?.seller?.profile?.phoneNumber;

  const buyerOrg = offer?.deal?.buyerOrg?.orgName;
  const buyerCountry = offer?.deal?.buyerOrg?.country;
  const buyerName = getUserName(offer?.deal?.buyer);
  const buyerEmail = offer?.deal?.buyer?.profile?.email;
  const buyerPhone = offer?.deal?.buyer?.profile?.phoneNumber;

  const sellerIncoterms = offer?.incoterms?.label;
  const sellerLocation = offer?.deal?.shipping?.departureLocation?.address;
  const buyerIncoterms = offer?.deal?.shipping?.destinationLocation?.incoterms?.label || sellerIncoterms;
  const buyerLocation = offer?.deal?.shipping?.destinationLocation?.address;
  const requestedDeliveryLabel = offer?.agreedOnDelivery ? 'Requested delivery' : 'Departure';
  const requestedDelivery = `${offer?.departureFrom} - ${offer?.departureTo}`;
  const logisticsProviderValue = offer?.deal?.shipping?.logisticHandler?.label || 'N/A';
  const logisticsPrice = offer?.deal?.shipping?.logisticPrice;

  const sellerPaymentTerms = offer?.deal?.financing?.sellerPaymentTerm?.label;
  const buyerPaymentTerms = offer?.deal?.financing?.buyerPaymentTerm?.label;
  const financingProvider = offer?.deal?.financing?.financingProvider?.label || 'N/A';
  const financingInfo = prepareFinancingDetails({
    currency: offer?.deal?.currency,
    creditInterestRate: offer?.deal?.financing?.creditInterestRate,
    commissionPerUnit: offer?.deal?.financing?.commissionPerUnit,
    commissionPerDeal: offer?.deal?.financing?.commissionPerDeal,
  });
  // encapsulated data === END

  if (!offer) {
    return null;
  }

  return (
    <>
      {/* grid 1 */}
      <div className="details-grid">
        <div className="details-col">
          <h2>Deal</h2>
          <DataItemPreview label="Deal ID" value={offer?.deal?.number} />
          <DataItemPreview
            label="Deal done on"
            value={amsDateWithTimezone(offer?.deal?.created)}
          />
          <DataItemPreview
            label="Volume"
            value={dealVolume}
            suffix={dealVolumeUnits}
          />
          <DataItemPreview
            label="Deal product price"
            value={formatMoney(dealPrice)}
            prefix={dealCurrency}
            suffix={`/${dealVolumeUnits}`}
          />
          <DataItemPreview
            label="Total product price"
            value={formatMoney(dealPrice * dealVolume)}
            prefix={dealCurrency}
          />
        </div>

        <div className="details-col">
          <h2>
            Offer
          </h2>
          <DataItemPreview label="Offer type" value="Proposal for RFP" />
          <DataItemPreview label="ID" value={offer?.id} />
          <DataItemPreview
            label="Offer placed"
            value={amsDateWithTimezone(offer?.created)}
          />
          <DataItemPreview
            label="Offer expiration date"
            value={amsDateWithTimezone(offer?.endDate)}
          />
          <DataItemPreview label="Status" value={offer?.statusLabel} />
        </div>

        <div className="details-col">
          <h2>Product</h2>
          <DataItemPreview
            label="Buyer product specification"
            value={(
              <ProductSpecsDetails
                characteristics={offer?.productCharacteristics}
                coaUsed={offer?.coaUsed}
                linkTitle={offer?.productType?.label}
              />
                )}
          />
          <DataItemPreview label="Documents included" value={joinOptions(offer?.documentCapabilities)} />
          <DataItemPreview label="Production location" value={offer?.productionLocation?.fullAddress} />
          <DataItemPreview
            label="Custom label"
            value={offer?.customLabelPossible ? 'Custom label possible' : 'Custom label not possible'}
          />

          {/* {renderMPCLink()} */}
          <OfferSalesTermsField offer={offer} />
        </div>
      </div>

      {/* grid 2 */}
      <div className="details-grid">
        <div className="details-col">
          <h2>Seller</h2>
          <DataItemPreview label="Seller company" value={sellerOrg} />
          <DataItemPreview label="Country" value={sellerCountry} />
          <DataItemPreview label="Seller name" value={sellerName} />
          <DataItemPreview label="Seller email" value={sellerEmail} />
          <DataItemPreview label="Seller phone" value={sellerPhone} />
        </div>

        <div className="details-col">
          <h2>Buyer</h2>
          <DataItemPreview label="Buyer company" value={buyerOrg} />
          <DataItemPreview label="Country" value={buyerCountry} />
          <DataItemPreview label="Buyer name" value={buyerName} />
          <DataItemPreview label="Buyer email" value={buyerEmail} />
          <DataItemPreview label="Buyer phone" value={buyerPhone} />
        </div>

        <div className="details-col">
          <h2>Shipping & Logistics</h2>
          {offer?.agreedOnDelivery && (
            <Alert severity="info" style={{ marginBottom: 20 }}>
              This offer complies with requested delivery and timeline
            </Alert>
          )}
          <DataItemPreview label="Seller incoterms" value={sellerIncoterms} />
          <DataItemPreview label="Seller logistics location" value={sellerLocation} />
          <DataItemPreview label="Buyer incoterms" value={buyerIncoterms} />
          <DataItemPreview label="Buyer logistics location" value={buyerLocation} />
          <DataItemPreview label={requestedDeliveryLabel} value={requestedDelivery} />
          <DataItemPreview label="Logistics provider" value={logisticsProviderValue} />
          <DataItemPreview
            label="Pricing"
            value={logisticsPrice ? formatMoney(logisticsPrice) : null}
            suffix={dealCurrency}
          />
        </div>

        <div className="details-col">
          <h2>Financing</h2>
          {offer?.agreedOnPayment && (
            <Alert severity="info" style={{ marginBottom: 20 }}>
              This offer complies with requested payment terms
            </Alert>
          )}
          <DataItemPreview label="Seller payment terms" value={sellerPaymentTerms} />
          <DataItemPreview label="Buyer payment terms" value={buyerPaymentTerms} />
          <DataItemPreview label="Financing provider" value={financingProvider} />
          <DataItemPreview label="Pricing" value={financingInfo} />
        </div>
      </div>
    </>
  );
};

export default DealFieldsRfp;
