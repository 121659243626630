import { createMuiTheme, makeStyles } from '@material-ui/core';
import colors from 'components/StyleConfig';

// TABLE STYLES
export const useTableStyles = makeStyles((theme) => ({
  table: {
    minWidth: 200,
    width: 'auto',
    marginRight: 'auto',
    '& thead th': {
      fontWeight: '700',
      fontSize: 14,
      padding: theme.spacing(1),
      paddingRight: 40,
      background: '#252422',
      color: '#f6f5f3',
    },
    '& tbody td': {
      fontWeight: '500',
      fontSize: 14,
      border: 'unset',
      borderBottom: '1px solid lightgray',
      paddingRight: 40,
      maxWidth: 600,

      '&:last-child': {
        paddingRight: 16,
      },
    },
    '& tbody tr:hover': {
      backgroundColor: '#ebffc1',
      cursor: 'pointer',
    },
  },
}));
// TABLE STYLES === END

const v4Theme = createMuiTheme({
  palette: {
    primary: {
      main: colors.primaryBlack,
    },
    secondary: {
      main: colors.primaryGreen,
      contrastText: colors.white,
    },
  },
  typography: {
    fontFamily: 'averta',
    fontWeightRegular: 600,
  },
  overrides: {
    MuiTable: {
      root: {
        border: 'unset',
      },
    },
    MuiTableCell: {
      root: {
        padding: 8,
      },
    },

    // === TABS ===
    MuiTabs: {
      root: {
        '&.nav-tabs': {
          '& .MuiTabs-indicator': {
            backgroundColor: 'transparent',
            display: 'none !important',
          },
        },
      },
    },
    MuiTab: {
      root: {
        minWidth: 'initial !important',
        marginRight: 8,

        borderBottom: '2px solid transparent',

        '&.active': {
          borderBottom: `2px solid ${colors.primaryGreen}`,
        },
      },
    },

    // === SELECT ===
    MuiSelect: {
      select: {
        // backgroundColor: 'salmon !important',
        // width: '200px !important',
        // padding: '0 8px !important',
        minWidth: '150px',
        boxSizing: 'border-box',
      },
    },

    // === BUTTON ===
    MuiButton: {
      root: {
        fontWeight: 600,
        textTransform: 'none',

        '&.button': {
          // backgroundColor: colors.primaryBlack,
          // color: 'white',
          // border: 'none',
        },
      },
    },

    // === RADIO ===
    MuiRadio: {
      root: {
        paddingTop: '0px',
        paddingBottom: '0px',
      },
    },

    // === ALERT ===
    MuiPaper: {
      root: {
        '&.MuiAlert-root': {
          padding: '4px 8px',
        },
      },
    },

    // === TOOLTIP ===
    MuiTooltip: {
      tooltip: {
        fontSize: '16px',
      },
    },

  },
});

export default v4Theme;
