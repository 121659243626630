import { Option } from '../../components/controls/Select';
import { get, post, put } from './RestUtil';

export interface ProductCharacteristicValue {
  characteristicSpecId: number
  value: number | string | null
  valueTo: number | null,
  range?: boolean,
  notTested: boolean
  hasError?: false
}

export interface ProductSpecItem {
  id: number,
  characteristicType: Option,
  measurementUnit: Option,
  measurementValueType: 'MAX' | 'MIN' | 'TYPICAL' | 'RANGE' | 'ND_IN',
  range: boolean,
  prefill: boolean,
  prefillValues: [],
  comparisonType: 'N_A' | 'LESS_BETTER' | 'MORE_BETTER' | 'TYPICAL' | 'MATCH_RANGE',
  displayOrder: 1,
  displayGroup: Option,
  minValue: any,
  typicalValue: number | string,
  maxValue: number | string,
  forMatching: boolean
}

export interface ProductSpec {
  id: number
  organizationId: number
  title: string
  active: boolean | 'true' | 'false'
  customLabelRequired: boolean
  productCategory: Option
  productType: Option
  requiredDocuments: Array<Option>
  loadingOption?: Option
  premiumPriceEur: number,
  premiumPriceUsd: number,
  characteristics: Array<ProductCharacteristicValue>
}

export const emptyProductSpec = {
  id: null,
  organizationId: null,
  title: '',
  active: false,
  customLabelRequired: false,
  productCategory: { label: '', value: '' },
  productType: { label: '', value: '' },
  requiredDocuments: [],
  premiumPriceEur: 0,
  premiumPriceUsd: 0,
  characteristics: [],
};

export class ProductSpecService {
  getProducts = (organizationId: number) => get('/v1/product/grouped', { organizationId });

  getById = (id: number) => get(`/v1/product/${id}`);

  getProductList = (organizationId: number, category: string, type: string | undefined) => get('/v1/product', { organizationId, category, type });

  getCategories = (setTypes: any) => { get('/v1/product/categories').then(setTypes); };

  getSpecification = (productType: any) => get('/v1/product/specifications', { productType });

  createProductSpec = (spec: ProductSpec) => post('/v1/product', spec);

  updateProductSpec = (id: number, spec: ProductSpec) => put('/v1/product', { ...spec, id });
}
