import {
  Button, Snackbar, Typography,
} from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import React, { useState } from 'react';
import colors from 'components/StyleConfig';
import { Link } from 'react-router-dom';
import pages from 'navigation/pages';
// import api from '../services/api';
import { download } from '../services/api/RestUtil';
import { prettyDate } from '../services/Utils';
import Controls from '../components/controls/Controls';

export default function LogisticPrices() {
  // const [error, setError] = useState('');
  const [open, setOpen] = useState(false);
  const [processing, setProcessing] = useState(false);

  // const handleFileImport = (e: any) => {
  //   setProcessing(true);
  //   setError('');
  //   const { files } = e.target;

  //   if (files.length > 0) {
  //     const formData = new FormData();
  //     formData.append('file', files[0], files[0].name);
  //     api.location.importLogisticPrices(formData)
  //       .then(() => setOpen(true))
  //       .catch((err) => setError(err.message))
  //       .finally(() => setProcessing(false));
  //   }
  // };

  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '20px',
        }}
      >
        <h2>Logistic prices</h2>

        <Button
          variant="contained"
          color="primary"
          style={{ background: colors.primaryGreen }}
          component={Link}
          to={pages.catalogue_logisticPrices_add}
          startIcon={<Add />}
        >
          Add price
        </Button>
      </div>

      {/* { error && <Alert severity="error">{error}</Alert> } */}
      <Typography variant="body1" style={{ marginBottom: 10 }}>
        Download the latest export with all possible lanes
      </Typography>
      <Controls.Button
        disabled={processing}
        style={{ marginBottom: 10, width: 100 }}
        onClick={() => Promise
          .resolve()
          .then(() => setProcessing(true))
          .then(() => download('/v1/logistic/lane-prices', undefined, `Logistic lanes ${prettyDate(new Date())}.xlsx`))
          .finally(() => setProcessing(false))}
      >
        DOWNLOAD
      </Controls.Button>
      {/* <Typography variant="body1" style={{ marginBottom: 10 }}>
        Step 2: Edit the prices and validity dates
      </Typography>
      <Typography variant="body1" style={{ marginBottom: 10 }}>
        Step 3: Upload your Excel
      </Typography>
      <input
        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        id="contained-button-file"
        type="file"
        hidden
        onChange={handleFileImport}
      /> */}
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      {/* <label htmlFor="contained-button-file">
        <Button style={{ width: 100 }} variant="contained" color="primary" component="span" disabled={processing}>
          Upload
        </Button>
      </label> */}

      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={() => setOpen(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert onClose={() => setOpen(false)} severity="success" variant="filled">
          Success!
        </Alert>
      </Snackbar>
    </div>
  );
}
