import React, { useState } from 'react';
import { Grid, makeStyles, Typography } from '@material-ui/core';

export function useForm(initialValues: object): any {
  const [values, setValues] = useState(initialValues);
  const [errors, setErrors] = useState({});

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
    setErrors({ ...errors, [name]: undefined });
  };

  const resetForm = () => {
    setValues(initialValues);
    setErrors({});
  };

  return {
    values,
    setValues,
    errors,
    setErrors,
    handleInputChange,
    resetForm,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiFormControl-root': {
      width: '-webkit-fill-available',
      margin: theme.spacing(2),
    },
  },
  paper: {
    padding: theme.spacing(3),
  },
}));

export function Form(props: any) {
  const classes = useStyles();
  const { label, children, ...other } = props;
  return (
    <form className={classes.root} autoComplete="off" {...other}>
      <Grid container>
        <Grid item style={{ minWidth: '100%' }}>
          <Typography style={{ marginLeft: 8 }} variant="h6">{ label }</Typography>
          <br />
          {children}
        </Grid>
      </Grid>
    </form>
  );
}
