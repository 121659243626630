import { useQuery } from '@tanstack/react-query';
import { toast } from 'react-hot-toast';
import getOfferById from 'api/get/getOfferById';

export const useOfferQuery = (offerId: string) => useQuery({
  queryKey: ['offer', offerId],
  queryFn: () => getOfferById(offerId),
  onError: (error) => {
    console.error(error);
    toast.error('Error loading offer');
  },
  enabled: !!offerId,
  staleTime: 0, // refetch every time
  // refetchInterval: 1000 * 60 * 5, // 5 minutes
});
