import { useMutation } from '@tanstack/react-query';
import { IPostOfferBidParams, postOfferBid } from 'api/post/postOfferBid';
import { toast } from 'react-hot-toast';

export const useCreateDealMutation = () => {
  return useMutation({
    mutationKey: ['createDeal'],
    mutationFn: (params: IPostOfferBidParams) => {
      const {
        offerId,
        offerVersion,
        buyerOrgId,
        buyerUserId,
        volume,
        price,
        logisticLocationId,
        paymentTerm,
        creationDate,
      } = params.payload;

      const requiredParams = [
        offerId,
        offerVersion,
        buyerOrgId,
        logisticLocationId,
        buyerUserId,
        volume,
        price,
        paymentTerm,
        creationDate,
      ];

      const isEnabled = requiredParams.every((param) => !!param);

      if (isEnabled) {
        return postOfferBid(params);
      }

      toast.error('Cannot create a deal. Some required params are missing');
      throw new Error('Cannot place bid. Some required params are missing');
    },
    onError: (error: unknown) => {
      console.error(error);
      toast.error('Something went wrong with sending deal data');
    },

  });
};
