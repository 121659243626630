import { CORE_ENDPOINTS } from 'SHARED/api/endpoints';
import { axios_Core } from 'SHARED/helpers/axios';
import notification from 'SHARED/helpers/notifications';
import { IUserResponseType } from 'SHARED/redux/models/IUser';
import { IPageResponse } from 'SHARED/types/models/IPageResponseType';

export async function getOrgUsers(orgId: string | number) {
  try {
    const response = await axios_Core.get<IPageResponse<IUserResponseType>>(CORE_ENDPOINTS.getOrganizationUsers(orgId));

    if (response.status === 200) {
      return {
        isError: false,
        data: response.data?.rows || [],
      } as const;
    }

    throw new Error(`Error while getting organization users: ${response.status}`);
  } catch (error) {
    console.error('getOrgUsers', error);

    notification({
      type: 'danger',
      title: 'Error',
      message: 'Error while getting organization users',
    });

    return {
      isError: true,
      message: 'Error while getting organization users',
    } as const;
  }
}
