import moment from 'moment-timezone';

const isValidMomentDate = (date: any) => {
  if (
    (typeof date === 'number' || typeof date === 'string' || date instanceof Date)
    && moment(date).isValid()
  ) return true;

  return false;
};

export default isValidMomentDate;
