import React, {
  ReactNode, useEffect, useMemo, useState,
} from 'react';
import {
  CircularProgress,
  makeStyles, Paper, TableFooter, TablePagination,
  Toolbar,
  Typography,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Preloader from 'SHARED/components/ThePreloader';
import { Page, SortOrder } from '../services/api/RestUtil';

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
    '& thead th': {
      fontWeight: '700',
      fontSize: 14,
      padding: theme.spacing(1),
      background: '#252422',
      color: '#f6f5f3',
    },
    '& tbody td': {
      fontWeight: '500',
      fontSize: 14,
      border: 'unset',
      borderBottom: '1px solid lightgray',
    },
    '& tbody tr:hover': {
      backgroundColor: '#ebffc1',
      cursor: 'pointer',
    },
  },
}));

export interface DataColumn<T> {
  field: string,
  label: string,
  width?: number,
  hidden?: boolean,
  render(row: T): React.ReactNode;
}

export interface DataTableProps {
  name: string,
  columns: Array<DataColumn<any>>,
  defaultSort?: any,
  addButton?: ReactNode,
  fetchRows(pageRequest: any): Promise<Page<any>>,
  onRowClick?: (row: any) => void,
  reload?: boolean
}

function DataTable(props: DataTableProps) {
  const classes = useStyles();
  const [pageConf, setPageConf] = useState({ page: 0, size: 20 });
  const [count, setCount] = useState(0);
  const [rows, setRows] = useState([]);
  const [globalError, setGlobalError] = useState('');
  const [load, setLoad] = useState(false);

  const {
    name, columns, addButton, fetchRows, reload, defaultSort, onRowClick,
  } = props;

  const sort = useMemo(() => defaultSort || { sort: 'id', order: SortOrder.Desc }, [defaultSort]);

  const loadData = () => {
    setLoad(true);
    setRows([]);
    fetchRows({ ...pageConf, ...sort })
      .then((resp) => {
        if (resp.rows) {
          // @ts-ignore
          setRows(resp.rows);
          setCount(resp.totalRowCount);
          if (resp.totalPageCount < pageConf.page) {
            setPageConf({ ...pageConf, page: 0 });
          }
        }
        setLoad(false);
      })
      .catch((err) => { setGlobalError(err.message); setLoad(false); });
  };

  useEffect(() => {
    loadData();

    return () => {
      setRows([]);
    };
  }, [fetchRows, sort, reload, pageConf]);

  const handleChangePage = (event: any, page: any) => {
    setPageConf({ ...pageConf, page });
    slideUp();
  };

  const handleChangeSize = (event: any) => {
    const size = parseInt(event.target.value, 10);
    setPageConf({ page: 0, size });
    slideUp();
  };

  const slideUp = () => {
    const input = document.querySelector('th');
    input?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'start',
    });
  };

  let rowIdx = 0;
  const getRowIndex = () => {
    rowIdx += 1;
    return rowIdx;
  };

  let cellIdx = 0;
  const getCellIndex = () => {
    cellIdx += 1;
    return cellIdx;
  };

  return (
    <>
      <Preloader isLoading={load} />

      {(!!name || !!addButton) && (
        <div style={{ display: 'flex', alignItems: 'center', margin: '12px 0' }}>
          {!!name && (
            <div style={{ display: 'flex', flexGrow: 1, alignItems: 'center' }}>
              <Typography variant="h6" style={{ marginRight: 15 }}>{name}</Typography>
            </div>
          )}

          { !!addButton && (<Toolbar>{addButton}</Toolbar>) }
        </div>
      )}

      {!!globalError && <Alert severity="error">{globalError}</Alert>}

      <TableContainer component={Paper} style={{ marginBottom: 16 }}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              {
                columns.filter((it) => !it.hidden).map((col) => (
                  <TableCell width={col.width} key={col.field}>
                    <Typography variant="body1" style={{ whiteSpace: 'nowrap' }}>{col.label}</Typography>
                  </TableCell>
                ))
              }
            </TableRow>
          </TableHead>

          <TableBody>
            {
              rows.map((row) => (
                <TableRow key={getRowIndex()} onClick={() => onRowClick && onRowClick(row)}>
                  {columns.filter((it) => !it.hidden).map((col) => (
                    <TableCell width={col.width} key={getCellIndex()}>
                      <Typography
                        variant="body1"
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          whiteSpace: 'nowrap',
                          padding: '0 12px 0 0',
                        }}
                      >
                        {col.render(row)}
                      </Typography>
                    </TableCell>
                  ))}
                </TableRow>
              ))
            }
          </TableBody>
          <TableFooter>
            <TableRow style={{ height: 30 }}>
              <TablePagination
                page={pageConf.page}
                style={{ border: 'unset' }}
                rowsPerPageOptions={[20, 50, 100]}
                rowsPerPage={pageConf.size}
                count={count}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeSize}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </>
  );
}

export default DataTable;
