import React, { FC, useState } from 'react';
import {
  Link,
  TableCell, TableRow,
} from '@material-ui/core';
import { IDocumentThread } from 'validators/documents/documentSchema';
import renderMultiline from 'helpers/renderMultiline';
import getDownloadDocumentFile from 'api/get/getDownloadDocumentFile';
import clsx from 'clsx';

interface IProps {
  document: IDocumentThread
  dealId: number | string
}

const DocumentsTableItem: FC<IProps> = ({ document, dealId }) => {
  const { documentType, latestNode, status } = document;

  const [isLoading, setIsLoading] = useState<boolean>(false);

  function prepareStatus() {
    switch (status) {
      case 'REQUESTED':
        return 'Requested';

      case 'ON_REVIEW':
        return 'Uploaded';

      case 'REJECTED':
        return 'Update requested';

      case 'APPROVED':
        return 'Accepted';

      case 'FILE_DELETED':
        return 'File deleted';

      case 'DELETED':
        return 'Deleted';

      default:
        return status;
    }
  }

  // DOWNLOAD DOCUMENT
  function handleDownload() {
    setIsLoading(true);
    getDownloadDocumentFile({ dealId, threadId: document.id, originalFileName: latestNode.originalFileName })
      .finally(() => setIsLoading(false));
  }

  return (
    <TableRow>
      <TableCell>{documentType.title}</TableCell>
      <TableCell>{prepareStatus()}</TableCell>
      <TableCell className="document-comment">
        {latestNode.comment && latestNode.comment.length > 0 && (
          renderMultiline(latestNode.comment)
        )}
      </TableCell>
      <TableCell>
        {status !== 'DELETED' && (
          <Link
            style={{ cursor: 'pointer', fontSize: 16, color: '#009FD1' }}
            className={clsx(isLoading && 'disabled')}
            onClick={handleDownload}
          >
            Download
          </Link>
        )}
      </TableCell>
    </TableRow>
  );
};

export default DocumentsTableItem;
