import React, { FC } from 'react';
import { Controller, RegisterOptions, useFormContext } from 'react-hook-form';
import {
  FormControl, MenuItem, Select,
} from '@mui/material';
import validationRules from './validationRules';
// import {
//   FormControl, MenuItem, Select,
// } from '@material-ui/core';
// import { Alert } from '@material-ui/lab';

export interface IOption {
  value: string | number;
  label: string;
  disabled?: boolean;
}

export interface IFormFieldBase {
  name: string;
  label?: string;
  rules?: RegisterOptions;
  disabled?: boolean;
  placeholder?: string;
  className?: string;
  error?: boolean;
  required?: boolean;
  suffix?: string;

  // fields that should be reset when this field changes
  dependentFields?: string[];
}

interface IFormSelectProps extends IFormFieldBase {
  options: IOption[];
  defaultValue?: string | number;
}

const FormSelect: FC<IFormSelectProps> = ({
  name,
  placeholder,
  label,
  options,
  rules = validationRules.required,
  disabled,
  defaultValue,
  error,
  required = false,
}) => {
  const { formState, control } = useFormContext();
  const { errors } = formState;

  return (
    <div className="form-field form-select">

      {label && <div className="label-text">{label}{required && ' *'}</div>}

      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field: { onChange, value } }) => (
          <FormControl
            variant="outlined"
            size="small"
          >
            <Select
              id={`${name}-select`}
              value={value || ''}
              defaultValue={defaultValue}
              onChange={onChange}
              disabled={disabled}
              error={!!errors[name] || error}
            >
              <MenuItem value=""><em>{`- ${placeholder || 'none'} -`}</em></MenuItem>

              {options.map((opt) => (
                <MenuItem
                  key={opt.value}
                  value={opt.value}
                  disabled={opt.disabled}
                >
                  {opt.label}
                </MenuItem>
              ))}

            </Select>
          </FormControl>
        )}
      />

      {errors[name]?.message && <div className="form-field__error">{errors[name]?.message}</div>}
    </div>
  );
};

export default FormSelect;
