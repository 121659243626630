import {
  del, download, post,
} from './RestUtil';

export interface FileData {
  id: number,
  fileName: string,
  originalFileName: string,
}

export class FileService {
  upload = (request: any) => post('/v1/file-upload', request);

  download = (file: any) => download(`/v1/file-download/${file.fileName}`, null, file.originalFileName);

  deleteFile = (id: number) => del('/v1/file-upload', { id });
}
