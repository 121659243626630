import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IOffersListData } from 'SHARED/redux/models/IOffersListData';
import { OfferHistoryItem } from 'SHARED/types/offerTypes';
import { asyncOffersActions } from './AsyncOffersActions';

interface OffersState {
  offerHistory: OfferHistoryItem[]
  offers: IOffersListData,
  auctions: IOffersListData,
  rfps: IOffersListData,

  isLoading: boolean,
  error: string | null,
}

const defaultOffersData: IOffersListData = {
  list: [],
  totalRowCount: 0,
  totalPageCount: 0,
};

const initialState: OffersState = {
  offerHistory: [],

  offers: defaultOffersData,
  auctions: defaultOffersData,
  rfps: defaultOffersData,

  isLoading: false,
  error: null,
};

const offersSlice = createSlice({
  name: 'offers',
  initialState,

  reducers: {
    resetOffersState: () => initialState,

    resetOfferHistory: (state) => {
      state.offerHistory = [];
    },

    resetOffersLists: (state) => {
      state.offers = defaultOffersData;
      state.auctions = defaultOffersData;
      state.rfps = defaultOffersData;
    },
  },

  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    // getOfferHistory
    builder.addCase(
      asyncOffersActions.getOfferHistory.pending,
      (state) => {
        state.isLoading = true;
      },
    );
    builder.addCase(
      asyncOffersActions.getOfferHistory.fulfilled,
      (state, action) => {
        state.offerHistory = action.payload.reverse();
        state.isLoading = false;
      },
    );
    builder.addCase(
      asyncOffersActions.getOfferHistory.rejected.type,
      (state, action: PayloadAction<string>) => {
        state.error = action.payload;
        state.isLoading = false;
      },
    );
    // getOfferHistory === END

    // getOffersListData
    builder.addCase(
      asyncOffersActions.getOffersListData.pending,
      (state) => {
        state.isLoading = true;
      },
    );
    builder.addCase(
      asyncOffersActions.getOffersListData.fulfilled,
      (state, action) => {
        const { data, type } = action.payload;

        let correctList: keyof OffersState = 'offers';

        switch (type) {
          case 'onspec':
            correctList = 'offers';
            break;
          case 'offspec':
            correctList = 'auctions';
            break;
          case 'rfp':
            correctList = 'rfps';
            break;
          default:
            correctList = 'offers';
        }

        state[correctList] = {
          list: data.rows,
          totalRowCount: data.totalRowCount,
          totalPageCount: data.totalPageCount,
        };
        state.isLoading = false;
      },
    );
    builder.addCase(
      asyncOffersActions.getOffersListData.rejected.type,
      (state, action: PayloadAction<string>) => {
        state.error = action.payload;
        state.isLoading = false;
      },
    );
    // getOffersListData === END
  },
});

export const offersReducer = offersSlice.reducer;
export const offersActions = offersSlice.actions;
