import React from 'react';
import './preloader-styles.scss';

interface IProps {
  isLoading: boolean,
  options?: string,
}

const Preloader: React.FC<IProps> = ({ isLoading, options = '' }) => (
  <div className={isLoading ? `preloader-overlay ${options}` : 'preloader-overlay hidden'}>
    <div className="logo-wrapper">
      <div className="logo-animated-part">
        <img width="62" className="logo-top-part" src="/images/logo-top.svg" alt="" />
      </div>
    </div>
    <img width="62" className="logo-bottom-part" src="/images/logo-bottom.svg" alt="" />
  </div>
);

export default Preloader;
