import { Divider, Typography } from '@material-ui/core';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { Form } from '../../hooks/useForm';
import api from '../../services/api';
import { ProductCharacteristicValue, ProductSpecItem } from '../../services/api/ProductSpecService';
import SpecInput from '../controls/SpecInput';

export interface ProductSpecFormProps {
  productSpec: any
  setProductSpec: (spec: any) => void
  productType: string
  needValidate: boolean
  orgType: 'SELLER' | 'BUYER'
}

export default function ProductSpecs(props: ProductSpecFormProps) {
  const {
    productSpec, setProductSpec, productType, orgType, needValidate,
  } = props;

  const [fields, setFields] = useState({});
  const [
    characteristics,
    setCharacteristics,
  ] = useState<Array<ProductCharacteristicValue>>(productSpec);
  const disabled = !!productSpec.id;

  useEffect(() => {
    productType && api.productSpec.getSpecification(productType)
      .then((res) => setFields(_.groupBy(res, (it) => it.displayGroup.label)));
  }, [productType]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { needValidate && handleSubmit(); }, [needValidate]);

  const validate = () => disabled || !characteristics.find((it) => it.hasError);

  const handleSpecChange = (charValue: ProductCharacteristicValue) => {
    const itemIndex = characteristics
      .findIndex((it: any) => it.characteristicSpecId === charValue.characteristicSpecId);
    itemIndex !== -1
      ? characteristics[itemIndex] = { ...charValue }
      : characteristics.push({ ...charValue });
    setCharacteristics(characteristics);
  };

  const handleSubmit = () => {
    if (validate()) {
      setProductSpec(characteristics);
    } else {
      const input = document.querySelector('.Mui-error');
      input?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'start',
      });
    }
  };

  const renderSpecGroup = (groupName: string) => {
    // @ts-ignore
    const items: Array<ProductSpecItem> = fields[groupName];
    return (
      <div key={groupName}>
        <Typography variant="h6">{groupName}</Typography>
        <br />
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          { items
            .sort((it) => it.displayOrder)
            .map((it: ProductSpecItem) => (
              <SpecInput
                key={it.id}
                specTemplate={it}
                orgType={orgType}
                needValidate={needValidate}
                productSpec={productSpec}
                onChange={handleSpecChange}
                disabled={disabled}
              />
            ))}
        </div>
        <Divider />
        <br />
      </div>
    );
  };

  return (
    <Form name="create-product-spec">
      { fields && (Object.keys(fields).map((key) => renderSpecGroup(key)))}
    </Form>
  );
}
