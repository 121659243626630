import React, { CSSProperties, ReactNode } from 'react';
import { Typography } from '@material-ui/core';

export interface TextFieldProps {
  label: string
  value: any
  style?: CSSProperties | undefined
  prefix?: ReactNode | string | null
  suffix?: ReactNode | string | null
}
export default function TextField(props: TextFieldProps & CSSProperties) {
  const {
    label, value, style, prefix, suffix,
  } = props;
  return (
    <div style={{ ...style, margin: '15px 10px' }}>
      <Typography variant="body1" color="textSecondary">{label}</Typography>
      <Typography
        style={{ wordBreak: 'break-all', display: 'flex', alignItems: 'center' }}
        variant="body1"
      >
        {!!prefix && prefix}
        <span
          style={{
            marginLeft: prefix ? 4 : 0,
            marginRight: suffix ? 4 : 0,
          }}
        >
          {value || '-'}
        </span>
        {!!suffix && suffix}
      </Typography>
    </div>
  );
}
