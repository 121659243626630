import axios from 'api/axios';
import { DOCUMENT_ENDPOINTS } from 'api/endpoints';
import { AxiosResponse } from 'axios';
import { createAndDownloadFile } from 'helpers/common';
import { toast } from 'react-hot-toast';

interface IParams {
  dealId: string | number;
  threadId: string | number;
  originalFileName: string;
}

async function getDownloadDocumentFile(params: IParams) {
  const { dealId, threadId, originalFileName } = params;

  try {
    const response: AxiosResponse<Blob> = await axios(
      {
        method: 'get',
        url: DOCUMENT_ENDPOINTS.getDownloadDocumentFile(dealId, threadId),
        responseType: 'blob',
      },
      'documents',
    );

    console.log(response);

    createAndDownloadFile(response.data, originalFileName);

    return response.data;
  } catch (error) {
    toast.error('Error getting document file\nSomething went wrong...');

    console.error('Error getting document file:', error);

    return null;
  }
}

export default getDownloadDocumentFile;
