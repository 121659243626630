import { AxiosInstance } from 'axios';
import { CORE_ENDPOINTS } from 'SHARED/api/endpoints';
import { axios_Core, axios_Documents } from 'SHARED/helpers/axios';
import { createAndDownloadFile } from 'SHARED/helpers/common';
import { ApiType } from 'SHARED/types/common';

interface IDownloadFileArgs {
  name: string;
  originalFileName: string;
}

const downloadFile = (type: ApiType) => async (args: IDownloadFileArgs) => {
  const { name, originalFileName } = args;

  const isCore = type === 'CORE';
  const isDocuments = type === 'DOCUMENTS';

  let axiosInstance: AxiosInstance = axios_Core;
  const endpoint = CORE_ENDPOINTS.fileDownload;

  if (isCore) {
    axiosInstance = axios_Core;
  }

  if (isDocuments) {
    axiosInstance = axios_Documents;
  }

  try {
    const response = await axiosInstance.get<Blob>(endpoint(name), { responseType: 'blob' });

    createAndDownloadFile(response.data, originalFileName);

    return response.data;
  } catch (error) {
    return false;
  }
};

export const downloadCoreFile = downloadFile('CORE');
export const downloadDocumentsFile = downloadFile('DOCUMENTS');
