import React from 'react';
import { Link } from 'react-router-dom';
import texts from 'SHARED/helpers/texts';
import './offer-disclaimer.scss';
import routes from 'SHARED/types/routes';

interface IProps {
  userType: 'seller' | 'buyer';
  newTab?: boolean;
}

const OfferDisclaimer: React.FC<IProps> = ({
  userType,
  newTab = false,
}) => (
  <div className="offer-disclaimer">
    {userType === 'seller' ? texts.offerLabels.disclaimer : texts.originalOfferTexts.disclaimer}
    {' '}
    <Link
      to={routes.common.profile}
      target={newTab ? '_blank' : '_self'}
      rel="noopener noreferrer"
    >
      OpenDairy Fees
    </Link>
  </div>
);

export default OfferDisclaimer;
