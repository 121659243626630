import { Typography, Link as MuiLink } from '@mui/material';
import FileField from 'pages/auctionDeal/partials/FileField';
import React, { FC } from 'react';
import { OnSpecOffer } from 'types/offerTypes';
import FilesIcon from './icons';

interface IProps {
  offer: OnSpecOffer | any
}

const OfferSalesTermsField: FC<IProps> = ({ offer }) => {
  if (!offer || !offer?.documents || !offer?.documents.SALES_TERMS) {
    return (
      <div style={{ margin: '15px 10px' }} className="file-field">
        <Typography variant="body1" color="textSecondary">SALES TERMS DOCUMENT</Typography>
        <MuiLink
          component="a"
          href="https://issuu.com/nnkc.nl/docs/mpc_-_2018_engels"
          target="_blank"
          rel="noreferrer"
          style={{ display: 'inline-flex' }}
        >
          <FilesIcon />
          <Typography variant="body1" style={{ color: '#009FD1' }}>MPC sales terms</Typography>
        </MuiLink>
      </div>
    );
  }

  return (
    <>
      {offer?.documents && !!offer?.documents.SALES_TERMS && (
      <FileField label="SALES TERMS DOCUMENT" docType="SALES_TERMS" offer={offer as any} />
      )}
    </>
  );
};

export default OfferSalesTermsField;
