import clsx from 'clsx';
import React, { FC } from 'react';

export interface IDataItemPreviewProps {
  label?: string;
  value: React.ReactNode | string | number | null | undefined;
  prefix?: string;
  suffix?: string;
  annotation?: string;
  labelClass?: string;
  valueClass?: string;
}

const DataItemPreview: FC<IDataItemPreviewProps> = ({
  label,
  value = '-',
  prefix,
  suffix,
  annotation,
  labelClass,
  valueClass,
}) => {
  const isNode = typeof value === 'object';

  const prefixText = prefix ? `${prefix} ` : '';
  const suffixText = suffix ? ` ${suffix}` : '';

  // React.ReactNode is an object and should be rendered outside of string
  const text = isNode ? value : `${prefixText}${value}${suffixText}`;

  return (
    <div className="data-item-preview">
      {label && <div className={clsx('label-text', labelClass)}>{label}</div>}
      <div className={clsx('value', valueClass)}>{value ? text : '-'}</div>
      {annotation && <div className="annotation-text">{annotation}</div>}
    </div>
  );
};

export default DataItemPreview;
