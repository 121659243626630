import {
  Badge,
  Chip, Collapse, IconButton, List, ListItem, ListItemText,
  makeStyles, Paper, Popover, TextField, Typography,
} from '@material-ui/core';
import {
  Close, Person, Send, UnfoldLess, UnfoldMore,
} from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import React, { useEffect, useState } from 'react';
import { Channel, Member, Message } from 'twilio-chat';
import Controls from '../controls/Controls';
import MessageItem from './MessageItem';

const useStyles = makeStyles((theme) => ({
  chatContainer: {
    width: 500,
    borderRadius: 15,
    position: 'fixed',
    bottom: 5,
    right: 5,
    zIndex: 99,
  },
  chatHeader: {
    display: 'flex',
    padding: 10,
    background: '#252422',
    color: '#fff',
    alignItems: 'center',
  },
  chatContent: {
    padding: '10px 20px',
    overflowY: 'scroll',
    maxHeight: 600,
    minHeight: 50,
    background: '#ceffd0',
  },
  chatFooter: {
    padding: 10,
    borderTop: '1px solid',
    background: '#ceffd0',
  },
  typingIndicator: {
    display: 'flex',
    justifyContent: 'center',
    padding: 5,
  },
}));

export interface ChatProps {
  channel: Channel,
  identity: string,
  setOpen: (val: boolean) => void
}
export default function Chat(props: ChatProps) {
  const classes = useStyles();
  const { channel, identity, setOpen } = props;

  const [members, setMembers] = useState<any>([]);
  const [descriptors, setDescriptors] = useState<any>([]);
  const [users, setUsers] = useState<any>([]);
  const [messages, setMessages] = useState<Message[]>();
  const [message, setMessage] = useState('');
  const [messageEdit, setMessageEdit] = useState<Message>();
  const [typingMember, setTypingMember] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [compact, setCompact] = useState<boolean>(false);

  const [error, setError] = useState('');

  // cleanup client listeners on unmount
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => () => { channel?.removeAllListeners(); }, []);

  // load messages and channel members
  useEffect(() => {
    if (channel) {
      loadMessages(channel);
      channel.getMembers()
        .then((res) => res.map(mapMember))
        .then(setMembers)
        .catch(setError);
      channel.getUserDescriptors()
        .then((res) => res.items.map((it) => ({ identity: it.identity, online: it.online })))
        .then((res) => setDescriptors(res))
        .catch(setError);

      channel.on(Channel.messageAdded, () => loadMessages(channel));
      channel.on(Channel.messageUpdated, () => loadMessages(channel));
      channel.on(Channel.messageRemoved, () => loadMessages(channel));

      channel.on(Channel.typingStarted, (member) => updateTypingMember(member, true));
      channel.on(Channel.typingEnded, (member) => updateTypingMember(member, false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channel]);

  useEffect(() => {
    if (messages && messages.length > 0) {
      const end = document.getElementById('message-list-end');
      end?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'start',
      });
    }
  }, [messages]);

  // check member status by linked user descriptor
  useEffect(() => {
    if (members.length > 0 && descriptors.length > 0) {
      const result = members.map(
        (m: any) => ({
          ...m,
          online: descriptors.find((d: any) => m.identity === d.identity)?.online,
        }),
      );
      setUsers(result);
    }
  }, [members, descriptors]);

  const loadMessages = (_channel: Channel) => {
    _channel.getMessages()
      .then((res) => setMessages(res.items))
      .then(() => channel.setAllMessagesConsumed())
      .catch(setError);
  };

  const mapMessage = (_message: Message) => (
    <MessageItem
      message={_message}
      isIncome={isIncomeMessage(_message.author)}
      userName={getUserName(_message.author)}
      onEdit={handleEdit}
    />
  );

  const getUserName = (_identity: string) => {
    const member = members.find((it: any) => it.identity === _identity);
    return member?.name || identity;
  };

  const isIncomeMessage = (author: string) => author !== identity;

  const getOnlineUserCount = () => (
    users.length > 0 && users.filter((it: any) => it.online).length
  );

  // @ts-ignore
  const mapMember = (item: Member) => ({ identity: item.identity, name: item.attributes.name });

  const handleSend = () => {
    const text = message?.trim();
    if (channel && text) {
      if (messageEdit) {
        messageEdit.updateBody(text)
          .then(() => {
            setMessage('');
            setMessageEdit(undefined);
          });
      } else {
        channel.sendMessage(message)
          .then(() => setMessage(''))
          .catch(setError);
      }
    }
  };

  const handleEdit = (_message: Message) => {
    setMessageEdit(_message);
    setMessage(_message.body);
  };

  const sendButton = () => (
    <Controls.Button
      endIcon={<Send />}
      onClick={handleSend}
      disabled={!message.trim()}
    >
      Send
    </Controls.Button>
  );

  const updateTypingMember = (member: Member, isTyping: boolean) => {
    if (isTyping) {
      const item = members.find((it: any) => it.identity === member.identity);
      item && setTypingMember(item.name);
    } else {
      setTypingMember('');
    }
  };

  const renderPopover = () => (
    <Popover
      id={anchorEl ? 'simple-popover' : undefined}
      open={!!anchorEl}
      anchorEl={anchorEl}
      onClose={() => setAnchorEl(null)}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      {renderUserList()}
    </Popover>
  );

  const renderUserList = () => (
    <List>
      {users.map((it: any) => (
        <ListItem key={it.name}>
          <ListItemText primary={`${it.name} ${(it.online && '- Online') || ''}`} />
        </ListItem>
      ))}
    </List>
  );

  return (
    error
      ? <Alert severity="error">{`Failed to initialize chat: ${error}`}</Alert>
      : (
        <Paper elevation={3} className={classes.chatContainer}>
          <Paper className={classes.chatHeader}>
            <Typography>
              {channel?.friendlyName || 'Conversation loading...'}
            </Typography>
            <IconButton
              style={{ marginLeft: 'auto', padding: 5, color: '#fff' }}
              onClick={(e: any) => setAnchorEl(e.target)}
            >
              <Badge badgeContent={getOnlineUserCount()} color="error">
                <Person />
              </Badge>
            </IconButton>
            <IconButton
              style={{ marginLeft: 'auto', padding: 5, color: '#fff' }}
              onClick={() => setCompact(!compact)}
            >
              { compact ? <UnfoldMore /> : <UnfoldLess /> }
            </IconButton>
            <IconButton
              style={{ marginLeft: 'auto', padding: 5, color: '#fff' }}
              onClick={() => setOpen(false)}
            >
              <Close />
            </IconButton>
            { renderPopover() }
          </Paper>
          <Collapse in={!compact}>
            <div className={classes.chatContent}>
              {
                messages && messages.length > 0
                  ? messages.map(mapMessage)
                  : <Typography color="textSecondary">No messages available</Typography>
              }
              <span id="message-list-end" />
              { typingMember && (
              <div className={classes.typingIndicator}>
                <Chip label={`${typingMember} is typing...`} />
              </div>
              )}
            </div>
            <div className={classes.chatFooter}>
              <TextField
                label="Message"
                style={{ width: '100%', background: '#ffffffa6' }}
                value={message}
                onChange={(e) => {
                  channel.typing();
                  setMessage(e.target.value);
                }}
                placeholder="Enter your message"
                multiline
                variant="outlined"
                InputProps={{ endAdornment: sendButton() }}
              />
            </div>
          </Collapse>
        </Paper>
      )
  );
}
