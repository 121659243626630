import React, { useEffect } from 'react';
import { InputAdornment, Typography } from '@material-ui/core';
import { useForm } from '../../hooks/useForm';
import { ProductCharacteristicValue, ProductSpecItem } from '../../services/api/ProductSpecService';
import Controls from './Controls';

export interface SpecInputProps {
  specTemplate: ProductSpecItem
  onChange: (it: ProductCharacteristicValue) => void
  needValidate: boolean
  productSpec?: Array<any>
  orgType: 'SELLER' | 'BUYER'
  disabled: boolean
}

export default function SpecInput(props: SpecInputProps) {
  const {
    specTemplate, onChange, productSpec, orgType, disabled, needValidate,
  } = props;
  const {
    id,
    characteristicType,
    measurementUnit,
    measurementValueType,
    minValue,
    typicalValue,
    maxValue,
    range,
    prefillValues,
    prefill,
  } = specTemplate;

  const getInitialState = () => {
    if (productSpec && productSpec.length > 0) {
      const specItem = productSpec.find((it) => it.characteristicSpecId === id);
      return { ...specItem, range };
    }

    return {
      characteristicSpecId: id,
      notTested: false,
      value: prefill ? typicalValue : null,
      valueTo: null,
      hasError: false,
      range,
    };
  };

  const {
    values, errors, setErrors, handleInputChange,
  } = useForm(getInitialState());

  useEffect(
    () => { needValidate && onChange({ ...values, hasError: !validate() }); },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onChange, values],
  );

  const validate = () => {
    if (disabled) {
      return true;
    }
    setErrors({});
    if (!values.notTested) {
      if (!values.value) {
        setErrors({ value: 'This field is required' });
        return false;
      }

      if (!isNaN(minValue)) {
        if (+minValue > +values.value) {
          setErrors({ value: `Value should be in range ${minValue} - ${maxValue}` });
          return false;
        }

        if (+values.value > +maxValue) {
          setErrors({ value: `Value should be in range ${minValue} - ${maxValue}` });
          return false;
        }
      }

      if (range) {
        if (!values.valueTo) {
          setErrors({ valueTo: 'This field is required' });
          return false;
        }

        if (+minValue > +values.valueTo) {
          setErrors({ valueTo: `Value should be in range ${minValue} - ${maxValue}` });
          return false;
        }

        if (+values.valueTo > +maxValue) {
          setErrors({ valueTo: `Value should be in range ${minValue} - ${maxValue}` });
          return false;
        }

        if (+values.value > +values.valueTo) {
          setErrors({ valueTo: 'Max value should be greater than min value' });
          return false;
        }
      }
    }

    return true;
  };

  const getSpecLabel = () => `${characteristicType.label} (${minValue} - ${maxValue})`;

  const renderInputElement = () => {
    if (isNaN(minValue)) {
      return (
        <Controls.Select
          name="value"
          label={characteristicType.label}
          value={values.value}
          error={errors.value}
          options={prefillValues || []}
          disabled={disabled || values.notTested}
          onChange={handleInputChange}
        />
      );
    }

    if (range) {
      return (
        <div style={{ display: 'flex', alignItems: 'center', paddingLeft: '1%' }}>
          <Typography variant="body1">MIN</Typography>
          <Controls.Input
            name="value"
            type="number"
            label={getSpecLabel()}
            value={values.value}
            error={errors.value}
            disabled={disabled || values.notTested}
            InputProps={{ inputProps: { min: minValue, max: maxValue } }}
            onChange={handleInputChange}
          />
          <Typography variant="body1">MAX</Typography>
          <Controls.Input
            name="valueTo"
            type="number"
            label={getSpecLabel()}
            value={values.valueTo}
            error={errors.valueTo}
            disabled={disabled || values.notTested}
            InputProps={{ inputProps: { min: minValue, max: maxValue } }}
            onChange={handleInputChange}
          />
        </div>
      );
    }

    return (
      <Controls.Input
        name="value"
        type="number"
        label={getSpecLabel()}
        value={values.value}
        error={errors.value}
        disabled={disabled || values.notTested}
        onChange={handleInputChange}
        InputProps={{
          endAdornment: <InputAdornment position="end">{measurementUnit.label}</InputAdornment>,
          inputProps: { min: minValue, max: maxValue },
        }}
      />
    );
  };

  return (
    <div style={{ display: 'inline-flex', alignItems: 'center' }}>
      <Typography variant="body1" style={{ width: '10%' }}>{measurementValueType}</Typography>
      <div style={{ width: '65%' }}>
        {renderInputElement()}
      </div>
      <div style={{ width: '25%' }}>
        <Controls.Checkbox
          name="notTested"
          label={orgType === 'SELLER' ? 'Not tested' : 'Not required'}
          value={values.notTested}
          onChange={handleInputChange}
          disabled={disabled}
        />
      </div>
    </div>
  );
}
