type ParamId = number | string;

const pages = {
  dashboard: '/',
  login: '/login',
  seller: '/seller',
  buyer: '/buyer',
  admin: '/admin',

  catalogue: '/catalogue',
  catalogue_financing: '/catalogue/financing',
  catalogue_logisticPrices: '/catalogue/logistic-prices',
  catalogue_logisticPrices_add: '/catalogue/logistic-prices/add',

  // OFFERS (ON_SPEC)
  // ? URl for offer page -> `/offers/:offerId` (active and archived), same way auctions and rfp should be refactored/remade since those pages basically are the same
  // ? URl for deal page -> `/offers/deals/:dealId` (separate page)
  offers: '/offers',
  offerPage: '/offers/:offerId',
  offerEdit: '/offers/:offerId/edit',
  // tabs
  offerPage_bids: '/offers/:offerId/bids',
  offerPage_history: '/offers/:offerId/history',
  offerPage_matches: '/offers/:offerId/matches',
  offerPage_analytics: '/offers/:offerId/analytics',

  offers_archived: '/offers/archived',

  offers_deals: '/offers/deals',
  offerDeal: '/offers/deals/:dealId',
  offerDeal_documents: '/offers/deals/:dealId/documents',

  offerDocumentsProvide: '/offers/documents/provide/:dealId',
  // goto
  goto_offerPage: (offerId: ParamId) => `/offers/${offerId}`,
  goto_offerPage_bids: (offerId: ParamId) => `/offers/${offerId}/bids`,
  goto_offerPage_history: (offerId: ParamId) => `/offers/${offerId}/history`,
  goto_offerPage_matches: (offerId: ParamId) => `/offers/${offerId}/matches`,
  goto_offerPage_analytics: (offerId: ParamId) => `/offers/${offerId}/analytics`,

  goto_offerEdit: (offerId: ParamId) => `/offers/${offerId}/edit`,

  goto_offerDealPage: (dealId: ParamId) => `/offers/deals/${dealId}`,
  goto_offerDealPage_documents: (dealId: ParamId) => `/offers/deals/${dealId}/documents`,
  goto_offerDealCreate: (offerId: ParamId) => `/offers/deal/create/${offerId}`,
  offer_dealCreateUrl: '/offers/deal/create/:offerId',

  goto_offerDocumentsProvide: (dealId: ParamId) => `/offers/documents/provide/${dealId}`,
  // OFFERS (ON_SPEC) === END

  // AUCTIONS
  offSpecOffers: '/offspec/offers',
  offSpecOffers_offerPage: (offerId: number | string) => `/offspec/offers/${offerId}`,

  offSpecOffers_archived: '/offspec/offers/archived',

  offSpecOffers_deals: '/offspec/deals',
  offSpecOffers_dealPage: (dealId: number | string) => `/offspec/deals/${dealId}`,
  // AUCTIONS === END

  // RFP
  rfp: '/rfp', // for rfp/:rfpId
  rfp_archived: '/rfp/archived',
  rfp_deals: '/rfp/deals',

  rfpOffer: '/rfp/:rfpId',
  goto_rfpOffer: (rfpId: ParamId) => `/rfp/${rfpId}`,
  rfpOfferProposals: '/rfp/:rfpId/proposals',
  goto_rfpOfferProposals: (rfpId: ParamId) => `/rfp/${rfpId}/proposals`,
  rfpOfferAnalytics: '/rfp/:rfpId/analytics',
  goto_rfpOfferAnalytics: (rfpId: ParamId) => `/rfp/${rfpId}/analytics`,

  rfpOffer_archived: '/rfp/archived/:rfpId',
  goto_rfpOffer_archived: (rfpId: ParamId) => `/rfp/archived/${rfpId}`,
  rfpOfferProposals_archived: '/rfp/archived/:rfpId/proposals',
  goto_rfpOfferProposals_archived: (rfpId: ParamId) => `/rfp/archived/${rfpId}/proposals`,
  rfpOfferAnalytics_archived: '/rfp/archived/:rfpId/analytics',
  goto_rfpOfferAnalytics_archived: (rfpId: ParamId) => `/rfp/archived/${rfpId}/analytics`,

  rfp_deal: '/rfp/deals/:dealId',
  rfp_deal_documents: '/rfp/deals/:dealId/documents',
  goto_rfp_deal: (dealId: ParamId) => `/rfp/deals/${dealId}`,
  goto_rfp_deal_documents: (dealId: ParamId) => `/rfp/deals/${dealId}/documents`,

  rfpProposal: '/rfp/proposals/:proposalId',
  goto_rfpProposal: (proposalId: ParamId) => `/rfp/proposals/${proposalId}`,
  // RFP === END

  offSpecOffer: '/offspec/offer/:id',
  offSpecBase: '/offspec',
  auctionDeal: '/offspec/deals/:dealId',

  newOffer: '/offer',

  deals: '/offspec/deals',
  deal: '/offspec/deal/:id',

  authHandler: '/oauth2/code',
} as const;

export default pages;
