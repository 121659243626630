import React, { FC } from 'react';
import { Button as MuiButton, IconButton } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

export interface CustomButtonProps {
  label: string;
  onClick?: () => any;
  variant?: 'text' | 'outlined' | 'contained';
  color?: 'primary' | 'secondary';
  type?: 'submit' | 'button' | 'reset';
  size?: 'small' | 'medium' | 'large';
  disabled?: boolean;
  loading?: boolean;
  className?: string;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  icon?: React.ReactNode;
  iconOnly?: boolean;
}

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    transition: 'all 0.3s ease',
  },

  iconOnly: {
    borderRadius: '4px',
    padding: '8px',
    boxShadow: '0 1px 3px 0 rgba(33, 36, 41, 0.2)',
  },
}));

const Button: FC<CustomButtonProps> = ({
  label,
  onClick,
  variant = 'contained',
  color = 'primary',
  type = 'button',
  size = 'medium',
  disabled = false,
  loading = false,
  className,

  startIcon,
  endIcon,
  iconOnly,
  icon,
}) => {
  const classes = useStyles();
  const loadingIconSize = size === 'small' ? 16 : size === 'medium' ? 24 : 28;

  if (iconOnly && icon) {
    return (
      <IconButton
        onClick={onClick}
        disabled={disabled || loading}
        color={color}
        className={clsx(classes.root, iconOnly && classes.iconOnly, className)}
        aria-label={label}
        title={label}
      >
        {icon}
      </IconButton>
    );
  }

  return (
    <>
      <MuiButton
        type={type}
        variant={variant}
        color={color}
        size={size}
        onClick={onClick}
        disabled={disabled || loading}
        className={clsx('button', (loading) && 'button--loading')}
        startIcon={startIcon}
        endIcon={endIcon}
      >
        <span className="button__label">{label}</span>
        <CircularProgress
          className="button__icon"
          color="secondary"
          size={loading ? loadingIconSize : 0}
          thickness={4}
          classes={{
            root: classes.root,
          }}
        />
      </MuiButton>
    </>
  );
};

export default Button;
