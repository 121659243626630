import {
  Checkbox,
  FormControl, InputLabel, ListItemText, MenuItem, Select,
} from '@material-ui/core';
// import Autocomplete from '@material-ui/lab/Autocomplete';
import React from 'react';
import { Option } from './Select';

export interface MultiSelectProps {
  label: string
  options: Array<any>
  valueArray: Array<any>
  onChange: any
  disabled?: boolean
}

export default function MultiSelect(props: MultiSelectProps) {
  const {
    label, options, valueArray, onChange, disabled,
  } = props;

  const handleChange = (array: any) => {
    // array.includes(-1) ? onChange([]) : onChange(array);

    switch (true) {
      case array.includes(-2):
        onChange(options);
        break;

      case array.includes(-1):
        onChange([]);
        break;

      default:
        onChange(array);
    }
  };

  return (
    <FormControl
      variant="outlined"
      disabled={disabled}
      // style={{ width: '50%' }}
    >
      <InputLabel>{label}</InputLabel>
      <Select
        value={valueArray}
        variant="outlined"
        {...props}
        renderValue={(selected) => (selected as Option[]).map((it: Option) => it.label).join(', ')}
        onChange={(e) => handleChange(e.target.value)}
        multiple
      >
        <MenuItem
          key={-2}
          value={-2}
          style={{ display: 'inline-flex', width: '50%', textAlign: 'center' }}
        >
          <ListItemText primary="Select all" />
        </MenuItem>

        <MenuItem
          key={-1}
          value={-1}
          style={{ display: 'inline-flex', width: '50%', textAlign: 'center' }}
        >
          <ListItemText primary="Clear" />
        </MenuItem>

        {options.map((option) => (
          <MenuItem key={option.value} value={option}>
            <Checkbox checked={valueArray.indexOf(option) > -1} />
            <ListItemText primary={option.label} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
