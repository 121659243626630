import clsx from 'clsx';
import React, {
  FC, useEffect, useRef, useState,
} from 'react';
import { useFormContext, RegisterOptions } from 'react-hook-form';
import { isFileTooBig } from 'SHARED/helpers/common';
import notification from 'SHARED/helpers/notifications';
import validationRules from 'SHARED/helpers/validation';
import { AcceptedFileTypes } from 'SHARED/types/common';
import { File } from 'SHARED/types/offerTypes';
import { uploadCoreFiles } from 'SHARED/api/common/post/uploadFiles';
import { deleteCoreFile } from 'SHARED/api/common/delete/deleteFile';
import Tooltip from '../Tooltip';

const defaultFileTypes: AcceptedFileTypes[] = [
  'application/pdf',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'image/png',
  'image/jpeg',
  'image/heic',
  'image/avif',
  'image/webp',
];

interface IProps {
  name: string,
  label?: string,
  tooltip?: string,
  className?: string,
  disabled?: boolean,
  required?: boolean,
  multiple?: boolean,
  defaultFiles?: File[] | null,

  maxSize?: number, // in mega bytes
  acceptedFileTypes?: AcceptedFileTypes[],

  rules?: RegisterOptions,

  // upload all files at once
  handleUploadFiles?: (formData: FormData) => Promise<File[]>,
  // handler to delete files one by one
  handleDeleteFile?: (fileId: number | string) => Promise<void>,
}

const FormFileInput: FC<IProps> = ({
  name,
  label,
  tooltip,
  className,
  disabled = false,
  required = false,
  multiple = false,
  defaultFiles,
  maxSize = 20,
  acceptedFileTypes = defaultFileTypes,

  // TODO: add proper validation for file object (describe it in `validationRules`)
  rules = required ? validationRules.required : {},

  // ? by default we use core api
  // ? and only in one place it's documents api
  handleUploadFiles = uploadCoreFiles,
  handleDeleteFile = deleteCoreFile,
}) => {
  const {
    register, formState: { errors }, setValue, clearErrors, watch,
  } = useFormContext();

  const fileInputValue: File[] = watch(name, []);

  const [isLoading, setIsLoading] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const fileTypes = acceptedFileTypes.join(',');
  const fileInputName = `${name}--file-input`;
  const isNoFiles = (!fileInputValue || fileInputValue?.length === 0);
  const isValidInputValue = (
    fileInputValue
    && (typeof fileInputValue === 'object')
    && Array.isArray(fileInputValue)
    && fileInputValue?.length > 0
  );

  function handleFileChange(e: React.ChangeEvent<HTMLInputElement>) {
    const bodyFormData = new FormData();

    if (e.target.files?.length) {
      const { length } = e.target.files;
      const { files: filesList } = e.target;

      let isFileValidationError = false;

      // file validation section
      for (let x = 0; x < length; x += 1) {
        const file = filesList[x];

        if (isFileTooBig(file.size, maxSize)) {
          notification({
            type: 'danger',
            title: 'File is too big',
            message: `File ${file.name} is too big. Maximum file size is ${maxSize}MB`,
            dismiss: {
              duration: 5000,
            },
          });

          isFileValidationError = true;
        }
      }
      if (isFileValidationError) {
        return;
      }

      // appending file to form body
      for (let x = 0; x < length; x += 1) {
        const file = e.target.files[x];
        bodyFormData.append('files', file);
      }

      // before we upload files we need to delete all previous files
      if (!isNoFiles) {
        handleDeleteAllFiles();
      }

      // then we can upload new files
      setIsLoading(true);
      handleUploadFiles(bodyFormData)
        .then((uploadedFiles) => {
          setValue(name, uploadedFiles);
          clearErrors(name);
        })
        .catch((err) => console.log(err))
        .finally(() => setIsLoading(false));
    }
  }

  async function handleDeleteAllFiles() {
    for await (const file of fileInputValue) {
      if (!file.duplicate) {
        await handleDeleteFile(file.id)
          .catch((err) => console.log(err));
      }
    }

    clearFileInputValue();
  }

  function clearFileInputValue() {
    // without this line we can't upload same file twice (intentionally or after deleting)
    // because onChange event won't fire
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
    setValue(name, null);
  }

  // for now it's just remove all files button
  function handleRemoveFiles(e?: React.MouseEvent<HTMLButtonElement>) {
    if (fileInputValue && fileInputValue.length > 0) {
      setIsLoading(true);

      handleDeleteAllFiles()
        .catch((err) => console.log(err))
        .finally(() => {
          clearFileInputValue();
          setIsLoading(false);
        });
    }
  }

  // trigger file input with custom button
  function handleTrigger(e: React.MouseEvent<HTMLButtonElement>) {
    e.preventDefault();
    fileInputRef?.current?.click();
  }

  // handle files prefill
  useEffect(() => {
    if (defaultFiles && defaultFiles.length > 0) {
      setValue(name, defaultFiles);
    } else {
      clearFileInputValue();
    }
  }, [defaultFiles]);

  useEffect(() => {
    clearErrors(name);
  }, [required]);

  return (
    <>

      <div className={clsx('form-input form-file-input__wrapper', errors[name] && 'invalid', className)}>
        {/* label */}
        {label && (
          <label htmlFor={name}>
            {required ? `${label} *` : label}

            {tooltip && tooltip.length > 0 && <Tooltip text={tooltip} />}
          </label>
        )}

        {/* input */}
        <div className="form-file-input">

          {/* file preview */}
          <div className={clsx('body', !isNoFiles && 'changed')}>
            {/* {!isNoFiles && fileInputValue?.map((file) => (
              <div key={file.id} className="file-preview-item">{`- ${file.originalFileName}`}</div>
            ))}

            {isNoFiles && <span>Choose file{multiple ? 's' : ''}</span>} */}

            {isValidInputValue && fileInputValue?.map((file) => (
              <div key={file.id} className="file-preview-item">{`- ${file.originalFileName}`}</div>
            ))}

            {!isValidInputValue && <span>Choose file{multiple ? 's' : ''}</span>}
          </div>

          {/* trigger button */}
          <div className="trigger-button">
            <button
              type="button"
              className="form-file-input__button"
              onClick={handleTrigger}
              title={isNoFiles ? 'Add file' : 'Change file (current files will be removed)'}
              disabled={isLoading || disabled}
            >
              Browse
            </button>
          </div>

          {/* remove all files button */}
          {(fileInputValue && fileInputValue.length > 0) && (
            <button
              type="button"
              className="remove-file-btn"
              onClick={(e) => handleRemoveFiles(e)}
              title="Remove all files"
              disabled={isLoading || disabled}
            >
              <i className="icon-delete" />
            </button>
          )}

          {/* hidden input */}
          <input
            name={fileInputName}
            id={fileInputName}
            className="visually-hidden"
            type="file"
            accept={fileTypes}
            onChange={handleFileChange}
            multiple={multiple}
            disabled={disabled}
            ref={fileInputRef}
          />

        </div>

        {/* the actual data that stored in the form */}
        {/* this way there will be scroll-to on-error */}
        <input
          {...register(name, { ...rules, validate: validationRules.formFileInput })}
          type="text"
          id={name}
          className="form-file-input__form-field"
          onClick={(e) => e.preventDefault()}
          onChange={(e) => e.preventDefault()}
          readOnly
        />

        {/* the actual data that stored in the form */}
        {/* with hidden output will be no scroll to, since it's not focusable */}
        {/* <output
          {...register(
            name,
            { ...rules, validate: validationRules.formFileInput },
          )}
          id={name}
          className="form-file-input__output visually-hidden"
          hidden
        /> */}

        {errors[name] && <div className="error-message">{errors[name]?.message}</div>}

      </div>

    </>
  );
};

export default FormFileInput;
