import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import initialOfferState from 'SHARED/initialState/offer';
import { BaseOfferType, DocType, IOffspecOffer, Location, OnSpecOffer, RFP } from 'SHARED/types/offerTypes';
import { asyncOfferActions } from './AsyncOfferActions';

const offerSlice = createSlice({
  name: 'offer',
  initialState: initialOfferState,
  reducers: {
    resetOffer: () => initialOfferState,

    resetOffersList: (state) => {
      state.offersList = [];
    },

    setOfferError: (state, action: PayloadAction<string | null>) => {
      state.error = action.payload;
      state.offersList = [];
      state.loading = false;
    },

    setOfferDuplicateLoading: (state, action: PayloadAction<boolean>) => {
      state.offerDuplicateLoading = action.payload;
    },

    setOfferLocations: (
      state,
      action: PayloadAction<{
        productionLocation: Location | null,
        logisticLocation: Location | null,
      }>,
    ) => {
      const { productionLocation, logisticLocation } = action.payload;

      state.ON_SPEC.productionLocation = productionLocation;
      state.ON_SPEC.logisticLocation = logisticLocation;
    },

    // manual save offer, without API call
    saveOffer: (
      state,
      action: PayloadAction<{ offer: OnSpecOffer | IOffspecOffer | RFP, offerType: BaseOfferType }>,
    ) => {
      const { offer, offerType } = action.payload;

      const isOffer = offerType === 'ON_SPEC';
      const isAuction = offerType === 'OFF_SPEC';
      const isRFP = offerType === 'RFP';

      if (isOffer) {
        state.ON_SPEC = { ...state.ON_SPEC, ...offer as OnSpecOffer };
      }

      if (isAuction) {
        state.OFF_SPEC = { ...state.OFF_SPEC, ...offer as IOffspecOffer };
      }

      if (isRFP) {
        state.RFP = { ...state.RFP, ...offer as RFP };
      }
    },

    clearFiles: (state, action: PayloadAction<{ docType: DocType, offerType: BaseOfferType }>) => {
      const { docType, offerType } = action.payload;

      state[offerType].documents[docType] = null;
    },
  },
  extraReducers: (builder) => {
    // getOfferDetails
    builder.addCase(
      asyncOfferActions.getOfferDetails.pending,
      (state) => {
        state.loading = true;
        state.error = null;
      },
    );

    builder.addCase(
      asyncOfferActions.getOfferDetails.fulfilled,
      (state, action) => {
        const { offer, offerType } = action.payload;

        const isOffer = offerType === 'ON_SPEC';
        const isAuction = offerType === 'OFF_SPEC';
        const isRFP = offerType === 'RFP';

        if (isOffer) {
          state.ON_SPEC = offer as OnSpecOffer;
        }

        if (isAuction) {
          state.OFF_SPEC = offer as IOffspecOffer;
        }

        if (isRFP) {
          state.RFP = offer as RFP;
        }

        state.loading = false;
      },
    );

    builder.addCase(
      asyncOfferActions.getOfferDetails.rejected.type,
      (state, action: PayloadAction<string>) => {
        state.error = action.payload;
        state.loading = false;
      },
    );
    // getOfferDetails === END

    // getOfferVas
    builder.addCase(
      asyncOfferActions.getOfferVas.pending,
      (state) => {
        state.loading = true;
        state.pricesLoading = true;
        state.error = null;
      },
    );

    builder.addCase(
      asyncOfferActions.getOfferVas.fulfilled,
      (state, action) => {
        const { prices, type } = action.payload;

        const isAll = type === 'all';
        const isLogistics = type === 'logistics';
        const isFinancing = type === 'financing';

        if (isAll) {
          state.ON_SPEC.priceDetails = prices || state.ON_SPEC.priceDetails;
          state.ON_SPEC.vasFinancing = prices?.vasFinancing || state.ON_SPEC.vasFinancing;
        }

        if (isLogistics) {
          state.ON_SPEC.priceDetails = {
            ...(state.ON_SPEC.priceDetails || prices),

            logisticProvider: prices.logisticProvider,
            logisticPriceId: prices.logisticPriceId,
            logisticPrice: prices.logisticPrice,
          };

          state.ON_SPEC.vasLogistics = prices?.vasLogistics || state.ON_SPEC.vasLogistics;
        }

        if (isFinancing) {
          state.ON_SPEC.priceDetails = {
            ...(state.ON_SPEC.priceDetails || prices),

            financingProvider: prices.financingProvider,
            creditAmount: prices.creditAmount,
            creditConditionId: prices.creditConditionId,
            creditPrice: prices.creditPrice,
            creditTermDays: prices.creditTermDays,
            packagingPrice: prices.packagingPrice,
          };

          state.ON_SPEC.vasFinancing = prices?.vasFinancing || state.ON_SPEC.vasFinancing;
        }

        state.loading = false;
        state.pricesLoading = false;
      },
    );

    builder.addCase(
      asyncOfferActions.getOfferVas.rejected.type,
      (state, action: PayloadAction<string>) => {
        state.error = action.payload;
        state.loading = false;
        state.pricesLoading = false;
      },
    );
    // getOfferVas === END

    // removeFile
    builder.addCase(
      asyncOfferActions.removeFile.pending,
      (state) => {
        state.loading = true;
        state.error = null;
      },
    );

    builder.addCase(
      asyncOfferActions.removeFile.fulfilled,
      (state, action) => {
        const { docType, offerType } = action.payload;

        state[offerType].documents[docType] = null;
        state.loading = false;
      },
    );

    builder.addCase(
      asyncOfferActions.removeFile.rejected.type,
      (state, action: PayloadAction<string>) => {
        state.loading = false;
        state.error = action.payload;
      },
    );
    // removeFile === END

    // uploadFiles
    builder.addCase(
      asyncOfferActions.uploadFiles.pending,
      (state) => {
        state.loading = true;
        state.error = null;
      },
    );

    builder.addCase(
      asyncOfferActions.uploadFiles.fulfilled,
      (state, action) => {
        const { documents, docType, offerType } = action.payload;

        state[offerType].documents[docType] = documents;
        state.loading = false;
      },
    );

    builder.addCase(
      asyncOfferActions.uploadFiles.rejected.type,
      (state, action: PayloadAction<string>) => {
        state.error = action.payload;
        state.loading = false;
      },
    );
    // uploadFiles === END

    // create RFP
    builder.addCase(
      asyncOfferActions.createRFP.pending,
      (state) => {
        state.loading = true;
      },
    );

    builder.addCase(
      asyncOfferActions.createRFP.fulfilled,
      () => initialOfferState,
    );

    builder.addCase(
      asyncOfferActions.createRFP.rejected.type,
      (state) => {
        state.RFP = initialOfferState.RFP;
        state.loading = false;
      },
    );
    // create RFP === END

    // createOffer
    builder.addCase(
      asyncOfferActions.createOffer.pending,
      (state) => {
        state.loading = true;
        state.error = null;
      },
    );

    builder.addCase(
      asyncOfferActions.createOffer.fulfilled,
      (state, action) => {
        console.log('this should only run if offer is created', action.payload);

        state.loading = false;

        if (action.payload === true) {
          state = initialOfferState;
        }
      },
    );

    builder.addCase(
      asyncOfferActions.createOffer.rejected.type,
      (state, action: PayloadAction<string>) => {
        console.log('this should only run if offer is not created', action.payload);

        state.error = action.payload;
        state.loading = false;
        // state.error = 'Could not create offer...';
      },
    );
    // createOffer === END
  },
});

export const offerReducer = offerSlice.reducer;
export const offerActions = offerSlice.actions;
