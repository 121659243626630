import { CORE_ENDPOINTS, DOCUMENT_ENDPOINTS } from 'SHARED/api/endpoints';
import { axios_Core, axios_Documents } from 'SHARED/helpers/axios';
import { ApiType } from 'SHARED/types/common';
import { AxiosInstance } from 'axios';

// async function deleteDocumentsFile(fileId: string | number) {
//   try {
//     await axios_Documents.delete(DOCUMENT_ENDPOINTS.fileUpload, { data: { id: fileId } });
//   } catch (error) {
//     console.error('Delete documents file error:', error);
//   }
// }

export const deleteFile = (type: ApiType) => async (fileId: string | number) => {
  const isCore = type === 'CORE';
  const isDocuments = type === 'DOCUMENTS';

  let axiosInstance: AxiosInstance = axios_Core;
  let endpoint: string = CORE_ENDPOINTS.fileUpload;

  if (isCore) {
    axiosInstance = axios_Core;
    endpoint = CORE_ENDPOINTS.fileUpload;
  }

  if (isDocuments) {
    axiosInstance = axios_Documents;
    endpoint = DOCUMENT_ENDPOINTS.fileUpload;
  }

  try {
    await axiosInstance.delete(endpoint, { data: { id: fileId } });
  } catch (error) {
    console.error('Delete file error:', error);
  }
};

export const deleteCoreFile = deleteFile('CORE');
export const deleteDocumentsFile = deleteFile('DOCUMENTS');
