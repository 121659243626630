import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import FilesIcon from 'components/icons';
import DownloadButton from './DownloadButton';

const useStyles = makeStyles((theme) => ({
  fileDownLoadRow: {
    display: 'inline-flex',
    color: '#009FD1',
    alignItems: 'center',
  },
}));

export interface FileData {
  fileName: string;
  originalFileName: string;
}

export interface TextFieldProps {
  label: string;
  files: Array<FileData>;
}
export default function DownloadField(props: TextFieldProps) {
  const { label, files = [] } = props;
  const classes = useStyles();
  return (
    <>
      <Typography variant="body1" color="textSecondary">{label}</Typography>
      {
        files.length
          ? files.map(({ fileName, originalFileName }) => (
            <div>
              <DownloadButton
                key={fileName}
                fileName={fileName}
                originalFileName={originalFileName}
              >
                <div className={`${classes.fileDownLoadRow}`}>
                  <FilesIcon />
                  <Typography variant="body1">{originalFileName}</Typography>
                </div>
              </DownloadButton>
            </div>
          ))
          : <Typography variant="body1">-</Typography>
      }
    </>
  );
}
