import { createAsyncThunk } from '@reduxjs/toolkit';
import { getOffersData } from 'SHARED/api/common/get/getOffersData';
import { CORE_ENDPOINTS } from 'SHARED/api/endpoints';
import { axios_Core } from 'SHARED/helpers/axios';
import { ListParams, OfferHistoryItem, PagesTypes } from 'SHARED/types/offerTypes';

const getOfferHistory = createAsyncThunk(
  'offers/getOfferHistory',
  async (offerId: number | string, { rejectWithValue }) => {
    try {
      const response = await axios_Core.get<OfferHistoryItem[]>(
        CORE_ENDPOINTS.getOfferHistory(offerId),
        { params: { offerId } },
      );

      return response.data;
    } catch (error) {
      console.log('getOfferHistory error', error);
      return rejectWithValue('Could not fetch offer history...');
    }
  },
);

// get offers / auctions / rfps list data
const defaultListParams: ListParams = { params: { page: 0, size: 5 } };
interface IGetOffersListDataArgs {
  params?: ListParams
  offerTypes: string
  isDeals: boolean
  type: PagesTypes
}
const getOffersListData = createAsyncThunk(
  'offers/getOffersListData',
  async (
    args: IGetOffersListDataArgs,
    { rejectWithValue },
  ) => {
    const {
      params = defaultListParams,
      offerTypes,
      isDeals,
      type,
    } = args;

    try {
      const response = await getOffersData({ params, offerTypes, isDeals });
      return {
        data: response,
        type,
      };
    } catch (error) {
      console.log('getOffersListData error', error);
      return rejectWithValue(`Could not fetch ${type} list data...`);
    }
  },
);

// export only this object so auto-imports won't be confused
// otherwise while using useActions hook
// auto - imports might import original actions with no dispatch attached
export const asyncOffersActions = {
  getOfferHistory,
  getOffersListData,
};
