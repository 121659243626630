import React, { FC } from 'react';
import clsx from 'clsx';
import { Link as MuiLink } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import Button, { CustomButtonProps } from './Button';

interface IProps extends CustomButtonProps {
  to: string;
}

const LinkButton: FC<IProps> = ({
  to, disabled = false, color = 'secondary', ...rest
}) => (
  <MuiLink component={RouterLink} to={to} className={clsx(disabled && 'disabled')}>
    <Button disabled={disabled} color={color} {...rest} />
  </MuiLink>
);

export default LinkButton;
