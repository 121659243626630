import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { setTokens } from 'SHARED/helpers/axios';
import pages from '../navigation/pages';
import { exchangeCode, redirectToAuth } from '../services/api/RestUtil';

function AuthHandler(props: RouteComponentProps) {
  const { location: { search }, history } = props;
  const authCode = new URLSearchParams(search).get('code');

  localStorage.removeItem('token');
  localStorage.removeItem('refresh-token');

  exchangeCode(authCode, `${window.location.origin}${window.location.pathname}`)
    .then((resp: any) => {
      localStorage.setItem('token', resp.access_token);
      localStorage.setItem('refresh-token', resp.refresh_token);

      setTokens(resp.access_token, resp.refresh_token);

      const prevLocation = localStorage.getItem('prevLocation');

      if (prevLocation) {
        window.location.replace(prevLocation);
      } else {
        history.replace(pages.dashboard);
      }
    })
    .catch(() => redirectToAuth());

  return (<></>);
}

// @ts-ignore
export default withRouter(AuthHandler);
