import { createAsyncThunk } from '@reduxjs/toolkit';
import { DOCUMENT_ENDPOINTS } from 'SHARED/api/endpoints';
import { axios_Documents } from 'SHARED/helpers/axios';
import notification from 'SHARED/helpers/notifications';
import documentThreadSchema from 'SHARED/validators/documents/documentSchema';
import documentTypeSchema from 'SHARED/validators/documents/documentTypeSchema';

// ===== DOCUMENTS =====
const getDocuments = createAsyncThunk(
  'documents/getDocuments',
  async (dealId: number | string, { rejectWithValue }) => {
    try {
      const response = await axios_Documents.get<unknown>(DOCUMENT_ENDPOINTS.getDocuments(dealId));

      const validation = documentThreadSchema.array().safeParse(response.data);

      if (!validation.success) {
        console.error('getDocuments validation failed', validation);

        throw new Error('Documents validation failed...');
      }

      return validation.data;
    } catch (error) {
      console.log('getDocuments error', error);

      notification({
        type: 'danger',
        title: 'Error',
        message: 'Could not get documents...',
      });

      return rejectWithValue('Could not get documents...');
    }
  },
);
// ===== DOCUMENTS ===== END

// ===== DOCUMENT TYPES =====
const getDocumentTypes = createAsyncThunk(
  'documents/getDocumentTypes',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios_Documents.get<unknown>(DOCUMENT_ENDPOINTS.getDocumentTypes);

      const validation = documentTypeSchema.array().safeParse(response.data);

      if (!validation.success) {
        console.error('getDocumentTypes validation failed', validation);

        throw new Error('Document types validation failed...');
      }

      return validation.data;
    } catch (error) {
      console.log('getDocumentTypes error', error);

      notification({
        type: 'danger',
        title: 'Error',
        message: 'Could not get document types...',
      });

      return rejectWithValue('Could not get document types...');
    }
  },
);
// ===== DOCUMENT TYPES ===== END

export const asyncDocumentsActions = {
  getDocuments,
  getDocumentTypes,
};
