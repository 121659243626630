import moment, { Moment } from 'moment-timezone';

const defaultRule: { required: boolean | string } = {
  required: 'This field is mandatory',
};

const requiredNoMessage: { required: boolean } = {
  required: true,
};

const notRequired = {
  required: false,
};

const logisticPricesSelectLane: { required: boolean | string } = {
  required: 'This field is necessary to find correct lane',
};

const isDateString = (value: string | Moment | null) => typeof value === 'string';

const formDatePickerValue = {
  validate: (value: string | Moment) => isDateString(value) || 'Invalid date format',
};

const formDatePickerEndDate = (
  startDate: string | Moment | null,
  message: string = 'End date should be after start date',
) => ({
  validate: (value: string | Moment) => {
    if (!isDateString(value)) return 'Date is not valid';

    // means that date is valid but start date is not
    if (isDateString(value) && !isDateString(startDate)) return true;

    // return ((moment(value).valueOf() > moment(startDate).valueOf())) || message;
    return moment(value).isAfter(startDate) || message;
  },
});

const formDatePickerAfterDate = (
  value: string | Moment | null,
  startDate: string | number | null,
  message: string = 'Date should be after start date',
) => {
  if (!isDateString(value)) return 'Date is not valid';

  // means that date is valid but start date is not
  if (!startDate) return true;

  return moment(value).isAfter(startDate) || message;
};

const endDate = (startDate: string | Moment | null) => ({
  validate: (value: string | Moment) => moment(value).valueOf() > moment(startDate).valueOf() || 'End date should be after start date',
});

const numberInput = {
  validate: (value: string) => {
    const parsed = parseInt(value, 10);
    if (Number.isNaN(parsed) || (parsed < 0)) return 'Invalid number';
    return true;
  },
};

const validationRules = {
  required: defaultRule,
  requiredNoMessage,
  notRequired,
  logisticPricesSelectLane,
  formDatePickerValue,
  formDatePickerEndDate,
  endDate,
  numberInput,
  formDatePickerAfterDate,
};

export default validationRules;
