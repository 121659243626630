import { useTypedSelector } from 'SHARED/redux/hooks/useTypedSelector';
import { useMemo } from 'react';

export const useUserType = () => {
  const { me } = useTypedSelector((state) => state.users);

  const userTypeStatuses = useMemo(() => {
    const isBuyer = me?.type === 'BUYER';
    const isSeller = me?.type === 'SELLER';
    const isAdmin = me?.type === 'ADMIN';

    return {
      isBuyer,
      isSeller,
      isAdmin,
    };
  }, [me]);

  return userTypeStatuses;
};
