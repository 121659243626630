import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { BidPayload, OfferBid } from 'SHARED/types/bidTypes';
import { asyncBidsActions } from './AsyncBidsActions';

interface IBidsState {
  loading: boolean,
  preparedBid: BidPayload | null
  history: OfferBid[],
  error: string | null,
}

const initialState: IBidsState = {
  loading: false,
  preparedBid: null,
  history: [],
  error: null,
};

const bidsSlice = createSlice({
  name: 'bids',
  initialState,
  reducers: {
    resetBidsState: () => initialState,
    clearHistory: (state) => {
      state.history = [];
    },
  },
  extraReducers: (builder) => {
    // bidHistory
    builder.addCase(
      asyncBidsActions.bidHistory.pending,
      (state) => {
        state.loading = true;
      },
    );

    builder.addCase(
      asyncBidsActions.bidHistory.fulfilled,
      (state, action) => {
        state.loading = false;
        state.history = action.payload;
      },
    );

    builder.addCase(
      asyncBidsActions.bidHistory.rejected.type,
      (state, action: PayloadAction<string>) => {
        state.loading = false;
        state.error = action.payload;
      },
    );
    // bidHistory === END
  },
});

export const bidsReducer = bidsSlice.reducer;
export const bidsActions = bidsSlice.actions;
