import {
  del, get, post, put,
} from './RestUtil';

export interface Container {
  id?: number | null | undefined
  orgId?: number | null | undefined
  productCategory: string
  containerType: string
  containerTypeLabel: string
  containerCategory: 'SEA' | 'LAND'
  volume: number | 0
}

export interface ContainerType {
  code: string
  label: string
  category: 'SEA' | 'LAND'
  displayOrder: number
}

export const buildContainerTemplate = (orgId: number, productCategory: string, containerCategory: 'SEA' | 'LAND', containers: object): Container => {
  // @ts-ignore
  const container = containers && containers[productCategory]?.find(
    (it: Container) => it.containerCategory === containerCategory,
  );
  return container
    ? { ...container }
    : {
      id: null,
      orgId,
      productCategory,
      containerType: '',
      containerTypeLabel: '',
      containerCategory,
      volume: 0,
    };
};

export class TransportationService {
  getContainerTypes = (setTypes: (res: any) => void, setErrors: any) => {
    get('/v1/dictionary/container-type').then(setTypes).catch(setErrors);
  };

  getContainers = (orgId: any, setContainers: (res: any) => void, setErrors: any) => {
    get(`/v1/organization/${orgId}/containers`).then(setContainers).catch(setErrors);
  };

  getPackagePrices = (orgId: any) => get(`/v1/organization/${orgId}/packaging/prices`);

  setPackagePrices = (orgId: any, request: Array<any>) => post(`/v1/organization/${orgId}/packaging/prices`, request);

  createContainer = (orgId: any, container: Container) => post(`/v1/organization/${orgId}/containers`, container);

  updateContainer = (id: number, orgId: any, container: Container) => put(`/v1/organization/${orgId}/containers/${id}`, container);

  deleteContainer = (id: number, orgId: any) => del(`/v1/organization/${orgId}/containers/${id}`);
}
