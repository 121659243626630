import { createSlice } from '@reduxjs/toolkit';
import { UserResponse } from 'SHARED/redux/models/IUserModels';
import { asyncUsersActions } from './AsyncUsersActions';

interface IUsersState {
  me: UserResponse
  loading: boolean
}

const initialUsersState: IUsersState = {
  me: {
    createdAt: null,
    id: null,
    login: null,
    orgId: null,
    organization: null,
    profile: null,
    roleId: null,
    roleName: null,
    status: null,
    type: null,
  },

  loading: false,
};

const usersSlice = createSlice({
  name: 'users',
  initialState: initialUsersState,
  reducers: {
    resetUsersState: () => initialUsersState,
  },
  extraReducers: (builder) => {
    builder.addCase(
      asyncUsersActions.getUser.pending,
      (state) => {
        state.loading = true;
      },
    );

    builder.addCase(
      asyncUsersActions.getUser.fulfilled,
      (state, action) => {
        state.me = action.payload;
        state.loading = false;
      },
    );

    builder.addCase(
      asyncUsersActions.getUser.rejected.type,
      (state) => {
        state.loading = false;
      },
    );
  },
});

export const usersReducer = usersSlice.reducer;
export const usersActions = usersSlice.actions;
