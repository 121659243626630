import { createAsyncThunk } from '@reduxjs/toolkit';
import { CORE_ENDPOINTS } from 'SHARED/api/endpoints';
import { axios_Core } from 'SHARED/helpers/axios';
import notification from 'SHARED/helpers/notifications';
import { store } from 'SHARED/redux/store';
import { PriceParams } from 'SHARED/types/bidTypes';
import {
  BaseOfferType,
  DocType,
  FilesResponce,
  IOffspecOffer,
  OfferType,
  OnSpecOffer,
  PricesResponce,
  RFP,
} from 'SHARED/types/offerTypes';
import { OfferEndpoints } from 'SHARED/types/routes';
import { cloneDeep } from 'lodash';

// ON_SPEC offer
interface IGetOfferDetailsArgs {
  id: number | string
  offerType: BaseOfferType
  isDeal?: boolean
}
const getOfferDetails = createAsyncThunk(
  'offer/getOfferDetails',
  async (args: IGetOfferDetailsArgs, { rejectWithValue }) => {
    const { id, offerType, isDeal = false } = args;

    const url = isDeal ? `/v1/deals/${id}` : `/v1/offers/${id}`;

    try {
      const response = await axios_Core.get<OnSpecOffer | IOffspecOffer | RFP>(url);

      // some fields stores in separate params object which is a backend data store issue
      // in this case buyersFetchType must be set from params to offer data
      const offer = { ...response.data, buyersFetchType: response.data.params?.buyersFetchType || null };

      return { offer, offerType };
    } catch (error) {
      console.log('getOfferDetails error', error);

      notification({
        type: 'danger',
        title: 'Error',
        message: 'Could not get offer details...',
      });

      return rejectWithValue('Could not get offer details...');
    }
  },
);
// ON_SPEC offer === END

// ===== VAS =====
interface IGetOfferVasArgs extends PriceParams {
  type?: 'all' | 'logistics' | 'financing'
}
const getOfferVas = createAsyncThunk(
  'offer/getOfferVas',
  async (args: IGetOfferVasArgs, { rejectWithValue }) => {
    const { type, ...requestParams } = args;

    try {
      const res = await axios_Core.get<PricesResponce>(`${CORE_ENDPOINTS.getOfferPrices}?`, { params: requestParams });

      return { prices: res?.data, type };
    } catch (error) {
      console.log('getOfferVas error', error);

      notification({
        type: 'danger',
        title: 'Error',
        message: 'Could not get offer VAS...',
      });

      return rejectWithValue('Could not get offer VAS...');
    }
  },
);
// ===== VAS ===== END

// ===== REMOVE FILE =====
interface IRemoveFileArgs {
  id: number
  docType: DocType
  offerType: BaseOfferType
}
const removeFile = createAsyncThunk(
  'offer/removeFile',
  async (args: IRemoveFileArgs, { rejectWithValue }) => {
    const { id, docType, offerType } = args;

    try {
      await axios_Core.delete(CORE_ENDPOINTS.fileUpload, { data: { id } });

      return { docType, offerType };
    } catch (error) {
      console.log('removeFile error', error);

      notification({
        type: 'danger',
        title: 'Error',
        message: 'Could not remove file...',
      });

      return rejectWithValue('Could not remove file...');
    }
  },
);
// ===== REMOVE FILE ===== END

// ===== UPLOAD FILES =====
interface IUploadFilesArgs {
  formData: FormData
  docType: DocType
  offerType: BaseOfferType
}
const uploadFiles = createAsyncThunk(
  'offer/uploadFiles',
  async (args: IUploadFilesArgs, { rejectWithValue }) => {
    const { formData, docType, offerType } = args;

    const headers = {
      'Content-Type': 'multipart/form-data',
    };

    try {
      const response = await axios_Core.post<FilesResponce>(
        CORE_ENDPOINTS.fileUpload,
        formData,
        { headers },
      );

      return { documents: response.data.data, docType, offerType };
    } catch (error) {
      console.log('uploadFiles error', error);

      notification({
        type: 'danger',
        title: 'Error',
        message: 'Could not upload files...',
      });

      return rejectWithValue('Could not upload files...');
    }
  },
);
// ===== UPLOAD FILES ===== END

// ===== CREATE RFP =====
const createRFP = createAsyncThunk(
  'offer/createRFP',
  async (_, { rejectWithValue }) => {
    const offer = store.getState().offer.RFP;

    const payload = {
      ...offer,
      deliveryLocationId: offer.deliveryLocation?.id,
      productId: offer.product?.value,
    };

    try {
      const res = await axios_Core.post<RFP>(CORE_ENDPOINTS.offersBase, payload);

      notification({
        title: 'Success',
        message: 'RFP is placed',
      });

      return res?.data?.id;
    } catch (error) {
      console.log('createRFP error', error);

      notification({
        type: 'danger',
        title: 'Error',
        message: 'Could not create RFP...',
      });

      return rejectWithValue('Could not create RFP...');
    }
  },
);
// ===== CREATE RFP ===== END

// ===== CREATE OFFER =====
const createOffer = createAsyncThunk(
  'offer/createOffer',
  async (offerType: OfferType, { rejectWithValue }) => {
    const offerState = offerType === 'OFF_SPEC' ? 'OFF_SPEC' : 'ON_SPEC';

    // const offer = store.getState().offer[offerState];
    const offer = cloneDeep(store.getState().offer[offerState]);

    // remove `ownerOrg` field from offer (it breaks backend validation)
    // this object might be used when offer is updated, but not when it's created
    // @ts-expect-error:
    delete offer.ownerOrg;

    if (offerType === 'TO_BE_PRODUCED_BUNDLE') {
      // @ts-expect-error:
      offer.productIds = cloneDeep(offer.products.map((i) => parseInt(i.product.value, 10)));
    }
    if (offer.paymentOffset) {
      // @ts-expect-error:
      offer.paymentOffset = offer.paymentOffset.value;
    }
    if (offerState === 'ON_SPEC' && !offer.paymentOffset) {
      delete offer.paymentOffset;
    }

    try {
      const response = await axios_Core.post(OfferEndpoints.create, offer);

      if (response.status === 200) {
        return true;
      }

      throw new Error('Could not create an offer...');
    } catch (error) {
      console.log('createOffer error', error);

      notification({
        type: 'danger',
        title: 'Error',
        message: 'Could not create an offer...',
      });

      return rejectWithValue('Could not create an offer...');
    }
  },
);
// ===== CREATE OFFER ===== END

export const asyncOfferActions = {
  getOfferDetails,
  getOfferVas,
  removeFile,
  uploadFiles,
  createRFP,
  createOffer,
};
