import React, { FC } from 'react';
import { OfferOffSpec } from 'services/api/OfferService';
import DownloadField from 'components/controls/DownloadField';

export const SALES_TERMS = 'SALES_TERMS';
export const COA = 'COA';

type OfferDocType = typeof SALES_TERMS | typeof COA;

interface IProps {
  label: string,
  docType: OfferDocType
  offer: OfferOffSpec
}

const FileField: FC<IProps> = ({
  label,
  docType,
  offer,
}) => {
  const docs = offer?.documents;
  const requestedDocs = docs ? (docs as any)[docType] : [];
  const files = (requestedDocs || []).map((doc: any) => ({
    fileName: doc.fileName,
    originalFileName: doc.originalFileName,
  }));
  return (
    <div style={{ margin: '15px 10px' }} key={label} className="file-field">
      <DownloadField label={label} files={files} />
    </div>
  );
};

export default FileField;
