import { Snackbar, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React, { useEffect, useState } from 'react';
import api from '../../services/api';
import { Organization } from '../../services/api/OrganizationService';
import { Page } from '../../services/api/RestUtil';
import Controls from '../controls/Controls';
import { Option } from '../controls/Select';

export default function ApprovedSellersForm(props: any) {
  const { orgId } = props;
  const [sellers, setSellers] = useState<Array<Option>>([]);
  const [approved, setApproved] = useState<Array<Option>>([]);
  const [error, setError] = useState('');
  const [open, setOpen] = useState(false);

  useEffect(() => {
    api.organization.getOrganizationPage('SELLER')
      .then(
        (res: Page<Organization>) => res.rows.map((it) => ({ label: it.orgName, value: it.id })),
      )
      .then((res: Array<Option>) => setSellers(res))
      .catch((err) => setError(err.message));
    api.organization.getApprovedSellers(orgId)
      .then((res: Array<Option>) => setApproved(res))
      .catch((err) => setError(err.message));
  }, [orgId]);

  const handleSubmit = () => {
    api.organization.setApprovedSellers(orgId, approved)
      .then(() => setOpen(true))
      .catch((err) => setError(err.message));
  };

  const updateApproved = ({ target: { value } }: any) => setApproved(value);

  return (
    <>
      <Typography variant="h5">Approved suppliers</Typography>
      { error && <Alert severity="error">{error}</Alert> }
      <br />
      <Controls.CheckboxGroup
        label="SELLERS"
        name="sellers"
        options={sellers}
        value={approved}
        onChange={updateApproved}
        groupSize={1}
      />
      <div>
        <Controls.Button type="submit" onClick={handleSubmit}>Submit</Controls.Button>
      </div>
      <Snackbar open={open} autoHideDuration={3000} onClose={() => setOpen(false)} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
        <Alert onClose={() => setOpen(false)} severity="success" variant="filled">
          Success!
        </Alert>
      </Snackbar>
    </>
  );
}
