import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Dictionary } from 'SHARED/types/offerTypes';
import { CharacteristicItem, ProductCategory, ProductDetails, ProductListItem, SpecificationItem } from 'SHARED/types/specsTypes';
import { asyncSpecsActions } from './AsyncSpecsActions';

interface ISpecsState {
  specifications: SpecificationItem[],
  characteristics: CharacteristicItem[],
  categories: ProductCategory[],
  documents: Dictionary[],
  packaging: Dictionary[],
  product: ProductDetails | null,
  products: ProductListItem[] | null,
  loading: boolean,
  error: string | null,
}

const initialState: ISpecsState = {
  specifications: [],
  characteristics: [],
  categories: [],
  documents: [],
  packaging: [],
  product: null,
  products: null,
  loading: false,
  error: null,
};

const specsSlice = createSlice({
  name: 'specs',
  initialState,
  reducers: {
    resetSpecsState: () => initialState,

    clearProduct: (state) => {
      state.product = null;
    },
  },
  extraReducers: (builder) => {
    // getSpecificationsList
    builder.addCase(
      asyncSpecsActions.getSpecificationsList.pending,
      (state) => {
        state.loading = true;
        state.error = null;
      },
    );

    builder.addCase(
      asyncSpecsActions.getSpecificationsList.fulfilled,
      (state, action) => {
        state.specifications = action.payload;
        state.loading = false;
      },
    );

    builder.addCase(
      asyncSpecsActions.getSpecificationsList.rejected.type,
      (state, action: PayloadAction<string>) => {
        state.loading = false;
        state.error = action.payload;
      },
    );
    // getSpecificationsList === END

    // getProducts
    builder.addCase(
      asyncSpecsActions.getProducts.pending,
      (state) => {
        state.loading = true;
        state.error = null;
      },
    );

    builder.addCase(
      asyncSpecsActions.getProducts.fulfilled,
      (state, action) => {
        state.products = action.payload;
        state.loading = false;
      },
    );

    builder.addCase(
      asyncSpecsActions.getProducts.rejected.type,
      (state, action: PayloadAction<string>) => {
        state.loading = false;
        state.error = action.payload;
      },
    );
    // getProducts === END

    // getProductCategoriesList
    builder.addCase(
      asyncSpecsActions.getProductCategoriesList.pending,
      (state) => {
        state.loading = true;
        state.error = null;
      },
    );

    builder.addCase(
      asyncSpecsActions.getProductCategoriesList.fulfilled,
      (state, action) => {
        const { categories, isProductCreation, documents, packaging } = action.payload;

        state.categories = categories;
        state.loading = false;

        if (isProductCreation) {
          state.documents = documents;
          state.packaging = packaging;
        }
      },
    );

    builder.addCase(
      asyncSpecsActions.getProductCategoriesList.rejected.type,
      (state, action: PayloadAction<string>) => {
        state.loading = false;
        state.error = action.payload;
      },
    );
    // getProductCategoriesList === END

    // getCharacteristicsList
    builder.addCase(
      asyncSpecsActions.getCharacteristicsList.pending,
      (state) => {
        state.loading = true;
        state.characteristics = [];
        state.error = null;
      },
    );

    builder.addCase(
      asyncSpecsActions.getCharacteristicsList.fulfilled,
      (state, action) => {
        state.characteristics = action.payload;
        state.loading = false;
      },
    );

    builder.addCase(
      asyncSpecsActions.getCharacteristicsList.rejected.type,
      (state, action: PayloadAction<string>) => {
        state.error = action.payload;
        state.characteristics = [];
        state.loading = false;
      },
    );
    // getCharacteristicsList === END

    // getProduct
    builder.addCase(
      asyncSpecsActions.getProduct.pending,
      (state) => {
        state.loading = true;
        state.error = null;
      },
    );

    builder.addCase(
      asyncSpecsActions.getProduct.fulfilled,
      (state, action) => {
        state.product = action.payload;
        state.loading = false;
      },
    );

    builder.addCase(
      asyncSpecsActions.getProduct.rejected.type,
      (state, action: PayloadAction<string>) => {
        state.loading = false;
        state.error = action.payload;
      },
    );
    // getProduct === END
  },
});

export const specsReducer = specsSlice.reducer;
export const specsActions = specsSlice.actions;
