import React, { FC } from 'react';
import DataItemPreview from 'components/partials/DataItemPreview';
import ProductSpecsDetails from 'components/partials/ProductSpecsDetails';
import renderMultiline from 'helpers/renderMultiline';
import {
  date,
  getUserName, joinOptions, prettyDate, prettyDateTime, renderLocationAddress,
} from 'services/Utils';
import { OnSpecOffer } from 'types/offerTypes';

const FIELD_TYPES = [
  'offer',
  'seller',
  'shipping',
  'product',
] as const;
type Field = typeof FIELD_TYPES[number];

interface IProps {
  offer: OnSpecOffer | null | undefined;
  fields?: Field[]
  viewMode?: 'base' | 'full'
}

const OfferDetails: FC<IProps> = ({
  offer,
  // by default render all fields
  fields = FIELD_TYPES,
  viewMode = 'full',
}) => {
  const isShowFull = viewMode === 'full';
  const isAlreadyProduced = offer?.type?.value === 'ALREADY_PRODUCED';
  const isToBeProduced = offer?.type?.value === 'TO_BE_PRODUCED';

  const packagesList = offer?.packages?.map((p) => p.label).join(',\n\n');

  if (!offer || (fields?.length < 1)) return null;

  return (
    <div className="details-grid">

      {fields.includes('offer') && (
      <div className="details-col">
        <h2>Offer</h2>
        {offer?.description && <DataItemPreview label="REMARKS FOR THE BUYER" value={renderMultiline(offer?.description)} />}
        <DataItemPreview label="OFFER TYPE" value={offer?.type?.label} />
        <DataItemPreview label="OFFER ID" value={offer.id} />
        <DataItemPreview label="OFFER PLACED" value={prettyDateTime(offer.created)} />
        <DataItemPreview label="OFFER EXPIRATION DATE" value={prettyDateTime(offer.endDate)} />
        <DataItemPreview label="STATUS" value={offer.status} />
        {
          offer.status === 'OFFLINE' && (
            <DataItemPreview label="Offline offer placed by" value={getUserName(offer?.createdBy)} />
          )
        }
        { offer.status === 'CANCELLED' && <DataItemPreview label="CANCELLATION REASON" value={offer.cancellationReason} /> }
        { offer.status === 'CANCELLED' && <DataItemPreview label="CANCELLATION TIME" value={prettyDateTime(offer?.cancellationInfo?.date)} /> }
        { offer.status === 'CANCELLED' && <DataItemPreview label="CANCELLED BY" value={offer?.cancellationInfo?.fullName} /> }
      </div>
      )}

      {fields.includes('seller') && (
      <div className="details-col">
        <h2>Seller</h2>
        <DataItemPreview label="SELLER COMPANY" value={offer.ownerOrg?.orgName} />
        <DataItemPreview label="COUNTRY" value={offer.ownerOrg?.country} />
        <DataItemPreview label="SELLER NAME" value={getUserName(offer.ownerUser)} />
        <DataItemPreview label="EMAIL" value={offer?.ownerUser?.profile?.email} />
        <DataItemPreview label="TELEPHONE" value={offer?.ownerUser?.profile?.phoneNumber} />
      </div>
      )}

      {fields.includes('shipping') && (
        <div className="details-col">
          <h2>Shipping</h2>
          <DataItemPreview label="SELLER INCOTERMS & LOCATION" value={`${offer?.incoterms?.label}.${renderLocationAddress(offer.logisticLocation)}`} />
          <DataItemPreview label="LOADING DETAILS" value={joinOptions(offer?.loadOptions)} />
          <DataItemPreview
            label="DEPARTURE"
            value={offer.departurePeriod?.value === 'MONTH'
              ? `${date(offer.departureFrom, 'MMMM YYYY')}`
              : `${date(offer.departureFrom)} - ${date(offer.departureTo)}`}
          />
        </div>
      )}

      {fields.includes('product') && (
        <div className="details-col">
          <h2>Product</h2>

          <DataItemPreview label="PRODUCT CATEGORY" value={offer?.productCategory?.label} />
          <DataItemPreview label="PRODUCT TYPE" value={offer?.productType?.label} />

          <DataItemPreview
            label="SELLER PRODUCT SPECIFICATION"
            value={(
              <ProductSpecsDetails
                characteristics={offer?.productCharacteristics}
                coaUsed={offer?.coaUsed}
              />
            )}
          />

          { isAlreadyProduced && (
            <DataItemPreview label="PACKAGING" value={offer?.packaging?.label} />
          )}

          {isToBeProduced && (
            <DataItemPreview label="PACKAGING" value={packagesList ? renderMultiline(packagesList) : null} />
          )}

          {isShowFull && isAlreadyProduced && (
            <>
              <DataItemPreview label="PRODUCTION DATE" value={prettyDate(offer.productionDate)} />
              <DataItemPreview label="EXPIRATION DATE" value={prettyDate(offer.expirationDate)} />
            </>
          )}

          {isShowFull && (
            <>
              <DataItemPreview label="DOCUMENTS INCLUDED" value={joinOptions(offer.documentCapabilities)} />
              <DataItemPreview label="PRODUCTION LOCATION" value={renderLocationAddress(offer.productionLocation)} />
            </>
          )}

        </div>
      )}

    </div>
  );
};

export default OfferDetails;
