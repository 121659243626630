import {
  FormControl,
  FormHelperText,
  FormLabel,
  IconButton,
  TextFieldProps, Typography,
} from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import React, { useState } from 'react';
import api from '../../services/api';
import { FileData } from '../../services/api/FileService';
import FilesIcon from '../icons';
import DownloadButton from './DownloadButton';

export interface DocumentInputProps {
  label: string,
  disabled?: false,
  file?: FileData | null | undefined
  onSave: (fileData: any) => void
  onDelete: () => void
}

export default function DocumentInput(props: TextFieldProps & DocumentInputProps) {
  const {
    label, disabled, file, onSave, onDelete,
  } = props;
  const [item, setItem] = useState(file);
  const [error, setError] = useState('');

  const handleDelete = () => {
    setError('');
    setItem(undefined);
    onDelete();
  };
  const handleSave = (e: any) => {
    setError('');
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      const formData = new FormData();
      formData.append('files', selectedFile, selectedFile.name);
      api.file.upload(formData)
        .then((res) => {
          setItem(res.data[0]);
          onSave(res.data[0]);
        })
        .catch((err) => setError(err.message));
    }
  };
  return (

    <FormControl variant="outlined" disabled={disabled} {...(error && { error: true })}>
      <FormLabel style={{ marginBottom: 15 }}>{label}</FormLabel>
      {
        item && item.fileName
          ? (
            <div style={{ display: 'flex' }}>
              <DownloadButton
                fileName={item.fileName}
                originalFileName={item.originalFileName}
              >
                <div style={{ display: 'inline-flex', color: '#009FD1', alignItems: 'center' }}>
                  <FilesIcon />
                  <Typography variant="body1">{item.originalFileName}</Typography>
                </div>
              </DownloadButton>
              <IconButton color="primary" component="span" onClick={handleDelete}>
                <Delete />
              </IconButton>
            </div>
          )
          : (
            <input type="file" onChange={handleSave} />
          )
      }
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>

  );
}
