import { AxiosResponse } from 'axios';
import { axios_Core } from 'SHARED/helpers/axios';
import notification from 'SHARED/helpers/notifications';
import { OnSpecOffer } from 'SHARED/types/offerTypes';

async function getDealData(dealId: string | number) {
  try {
    const responce: AxiosResponse<OnSpecOffer> = await axios_Core.get(`/v1/deals/${dealId}`);

    if (responce.status === 200) {
      return responce.data;
    }
    return null;
  } catch (error) {
    notification({
      type: 'danger',
      title: 'Error',
      message: 'Deal not found...',
    });

    console.error('Deal fetching error:', error);

    return null;
  }
}

export default getDealData;
