import React, { FC, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import ForwardIcon from '@material-ui/icons/Forward';
import colors from 'components/StyleConfig';

interface IProps {
  children: React.ReactNode;
}

const Accordion = withStyles({
  root: {
    boxShadow: 'none',
    display: 'flex',
    flexDirection: 'column-reverse',
    marginRight: 'auto',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    marginTop: '0px',
    marginBottom: -1,
    marginRight: 'auto',
    padding: '0',
    minHeight: 0,
    color: colors.primaryGreen,
    '&$expanded': {
      minHeight: 0,
    },
  },
  content: {
    padding: '0',
    margin: '0',
    '&$expanded': {
      margin: '0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
  },
}))(MuiAccordionDetails);

const ShowMore: FC<IProps> = ({ children }) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <Accordion
      expanded={expanded}
      onChange={() => setExpanded(!expanded)}
    >
      <AccordionSummary>
        <div className="text-block-with-icon">
          Show {!expanded ? 'more' : 'less'} seller
          <ForwardIcon color="secondary" />
          buyer
        </div>
      </AccordionSummary>

      <AccordionDetails>
        {children}
      </AccordionDetails>
    </Accordion>
  );
};

export default ShowMore;
