import { useEffect, useState } from 'react';
import api from 'services/api';
import { IOfferAnalytics } from 'services/api/OfferService';

const useOfferAnalytics = (offerId: number) => {
  const [analytics, setAnalytics] = useState<null | IOfferAnalytics>(null);
  const [error, setError] = useState<any>(null);

  useEffect(() => {
    let mounted = true;

    if (offerId && mounted) {
      api.offer.getOfferAnalyticsById(
        offerId,
        (resp: IOfferAnalytics) => {
          setAnalytics(resp);
        },
        setError,
      );
    }

    return () => {
      mounted = false;
    };
  }, [offerId]);

  return { analytics, analyticsError: error };
};

export default useOfferAnalytics;
