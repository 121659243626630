import { DEAL_ENDPOINTS } from 'SHARED/api/endpoints';
import { axios_Core } from 'SHARED/helpers/axios';
import { ListParams, ListResponse } from 'SHARED/types/offerTypes';
import { OfferEndpoints } from 'SHARED/types/routes';
import { AxiosResponse } from 'axios';

export interface IGetOffersDataArgs {
  params?: ListParams | null;
  offerTypes: string;
  isDeals?: boolean
}

export const defaultOffersListParams: ListParams = { params: { page: 0, size: 12 } };

export const getOffersData = async (args: IGetOffersDataArgs) => {
  const {
    params = defaultOffersListParams,
    offerTypes,
    isDeals = false,
  } = args;

  if (!params) {
    throw new Error('Params are not specified');
  }

  const url = isDeals ? DEAL_ENDPOINTS.getList : OfferEndpoints.getList;

  const res: AxiosResponse<ListResponse> = await axios_Core.get(url, { params: { ...params.params, types: offerTypes } });

  if (res.status === 200) {
    return res.data;
  }

  throw new Error(`Error while fetching ${isDeals ? 'deals' : 'offers'} data`);
};
