import React, { useState } from 'react';
import { Tab, Tabs } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { Link, useHistory, withRouter } from 'react-router-dom';
import pages from 'navigation/pages';
import exactLinkCheck from 'helpers/exactLinkCheck';
import routes from 'SHARED/types/routes';
import api from '../../services/api';
import { OfferOffSpecSimple, OfferTypes } from '../../services/api/OfferService';
import { PageRequest, SortOrder } from '../../services/api/RestUtil';
import DataTable from '../DataTable';
import { formatMoney, prettyDate } from '../../services/Utils';
import OfferFilter from './OfferFilter';
import { OnSpecOffer } from '../../SHARED/types/offerTypes';

const columns = [
  {
    field: 'created',
    label: 'PLACEDd',
    render: (row: OfferOffSpecSimple) => prettyDate(row.created),
  },
  {
    field: 'seller',
    label: 'SELLER',
    render: (row: OfferOffSpecSimple) => row.seller,
  },
  {
    field: 'type',
    label: 'TYPE',
    render: (row: OnSpecOffer) => (
      <>
        {row.anonymous && <i className="icon-eye-slash inline-icon no-transform" />}
        {row.type}
      </>
    ),
  },
  {
    field: 'title',
    label: 'PRODUCT',
    render: (row: any) => (row.productCategory && row.productType ? `${row.productCategory} (${row.productType})` : '-'),
  },
  {
    field: 'volume',
    label: 'VOLUME',
    render: (row: OfferOffSpecSimple) => (
      <>
        <span>{`${row.totalVolume} ${row.volumeUnits?.toLowerCase()}`}</span>
        <br />
        <span>{`(${row.remainingVolume} ${row.volumeUnits?.toLowerCase()} remaining)`}</span>
      </>
    ),
  },
  {
    field: 'price',
    label: 'PRICE',
    render: (row: OfferOffSpecSimple) => `${formatMoney(row.price)} ${row.currency} / ${row.volumeUnits}`,
  },
  {
    field: 'matches',
    label: 'MATCHES',
    render: (row: OfferOffSpecSimple) => row.matches,
  },
  {
    field: 'bidders',
    label: 'BIDDERS',
    render: (row: OfferOffSpecSimple) => row.bidders,
  },
  {
    field: 'status',
    label: 'STATUS',
    render: (row: any) => row.statusLabel,
  },
];

const dealColumns = [
  {
    field: 'created',
    label: 'DEAL ON',
    render: (row: OfferOffSpecSimple) => prettyDate(row?.deal?.created),
  },
  {
    field: 'seller',
    label: 'SELLER',
    render: (row: OfferOffSpecSimple) => row?.deal?.sellerOrg?.orgName,
  },
  {
    field: 'buyer',
    label: 'BUYER',
    render: (row: OfferOffSpecSimple) => row?.deal?.buyerOrg?.orgName,
  },
  {
    field: 'product',
    label: 'PRODUCT',
    render: (row: any) => (
      (row.productCategory && row.productType)
        ? `${row.productCategory} (${row.productType})` : '-'
    ),
  },
  {
    field: 'volume',
    label: 'VOLUME',
    render: (row: OfferOffSpecSimple) => `${row?.deal?.volume} ${row?.deal?.volumeUnits}`,
  },
  {
    field: 'price',
    label: 'OFFER PRICE',
    render: (row: OfferOffSpecSimple) => (
      `${formatMoney(row?.price)} ${row?.currency} / ${row?.volumeUnits}`
    ),
  },
  {
    field: 'dealPrice',
    label: 'DEAL PRICE',
    render: (row: OfferOffSpecSimple) => (
      `${formatMoney(row?.deal?.price)} ${row?.deal?.currency} / ${row?.deal?.volumeUnits}`
    ),
  },
  {
    field: 'status',
    label: 'STATUS',
    render: (row: any) => row?.statusLabel,
  },
];

function OfferTable(props: any) {
  const { location } = useHistory();

  const isActiveTab = exactLinkCheck(pages.offers);
  const isDealsTab = exactLinkCheck(pages.offers_deals);

  // stave value should be one of the keys of the tabs object
  const [reload, setReload] = useState(false);
  const [filter, setFilter] = useState({});

  const getColumns = () => {
    if (isDealsTab) {
      return dealColumns;
    }

    return columns;
  };

  const renderAdditions = () => (
    isDealsTab
      ? (<OfferFilter isDeal onSave={(f) => setFilter(f)} />)
      : (
        <div className="buttons-wrapper">
          <OfferFilter isDeal={false} onSave={(f) => setFilter(f)} />

          <Link
            to="/offer"
            className="btn btn--icon-start btn--compact btn-primary"
          >
            <Add /> Add New
          </Link>

          <Link
            to={routes.admin.offers_createSimpleOffer}
            className="btn btn--icon-start btn--compact btn-primary"
          >
            <Add /> Create Simple Offer
          </Link>
        </div>
      )
  );

  const loadData = (pageRequest: PageRequest) => {
    // if DEALS tab
    if (isDealsTab) {
      return api.offer.getDeals({
        ...pageRequest,
        types: [OfferTypes.ALREADY_PRODUCED, OfferTypes.TO_BE_PRODUCED, OfferTypes.SIMPLE_ALREADY_PRODUCED],
        sort: 'created',
        ...filter,
      });
    }

    return api.offer.getOffers({
      ...pageRequest,
      types: [
        OfferTypes.ALREADY_PRODUCED,
        OfferTypes.TO_BE_PRODUCED,
        OfferTypes.TO_BE_PRODUCED_BUNDLE,
        OfferTypes.SIMPLE_ALREADY_PRODUCED,
      ],
      active: isActiveTab,
      withDeals: undefined,
      ...filter,
    });
  };

  return (
    <div className="page-body">
      <Tabs
        value={location.pathname}
        onChange={() => { setFilter({}); setReload(!reload); }}
      >
        <Tab
          label="Active"
          value={pages.offers}
          to={pages.offers}
          component={Link}
        />
        <Tab
          label="Archived"
          value={pages.offers_archived}
          to={pages.offers_archived}
          component={Link}
        />
        <Tab
          label="Deals"
          value={pages.offers_deals}
          to={pages.offers_deals}
          component={Link}
        />
      </Tabs>

      <DataTable
        name={`On-spec ${isDealsTab ? 'deals' : 'offers'}`}
        columns={getColumns()}
        onRowClick={(offer) => (
          isDealsTab
            ? props.history.push(pages.goto_offerDealPage(offer?.deal?.id))
            : props.history.push(pages.goto_offerPage(offer?.id))
        )}
        reload={reload}
        addButton={renderAdditions()}
        defaultSort={
          isActiveTab
            ? { sort: 'created', order: SortOrder.Desc }
            : { sort: 'updated', order: SortOrder.Desc }
        }
        fetchRows={loadData}
      />
    </div>
  );
}

export default withRouter(OfferTable);
