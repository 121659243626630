import axios from 'api/axios';
import { IRequestParams } from 'api/types';
import { OfferOffSpec } from 'services/api/OfferService';
import { Page } from 'services/api/RestUtil';
import endpoints from '../endpoints';

interface IOfferParams extends IRequestParams {
  statuses?: string;
  active?: boolean;
  type: string;
}

const getOffers = async (params: IOfferParams): Promise<Page<OfferOffSpec>> => {
  const requestParams: IRequestParams = {
    // statuses: 'ACTIVE',
    // active: false,
    sort: 'created,DESC',
    // types: 'OFF_SPEC',
    ...params,
  };

  const res = await axios({
    method: 'get',
    url: endpoints.offers,
    params: requestParams,
  });

  if (res.status === 200) {
    return res.data;
  }
  throw new Error(`Cannot get offers list. Status: ${res.status}`);
};

export default getOffers;
