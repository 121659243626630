import React from 'react';
import moment from 'moment-timezone';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useMutation, useQuery } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import getContainerTypes from 'api/get/containerTypes';
import Button from 'components/hook-form/Button';
import FormDatePicker from 'components/hook-form/FormDatePicker';
import useLogisticsPricesStore from 'store/addLogisticsPrices';
import {
  FormContainer, IFormLanePrices, prepareLanePricesPayload, prepareLaneRequestParams,
} from 'helpers/logisticsLocations';
import updateLaneProviderPrices from 'api/patch/updateLaneProviderPrices';
import pages from 'navigation/pages';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import { AxiosError } from 'axios';
import getLogisticLanePrices from 'api/get/logisticLanePrices';
import validationRules from 'components/hook-form/validationRules';
import Container from './Container';
import ProviderSelector from './ProviderSelector';

const emptyContainer: FormContainer = {
  typeId: undefined,
  priceEur: null,
  priceUsd: null,
};

const formDefaultValues: IFormLanePrices = {
  provider: '',

  validFrom: moment().add(2, 'hour').toISOString(),
  validTo: moment().add(2, 'hour').add(3, 'month').toISOString(),

  departureFrom: moment().add(2, 'hour').toISOString(),
  departureTo: moment().add(2, 'hour').add(3, 'month').toISOString(),

  containers: [emptyContainer],
};

const AddNewPriceForm = () => {
  const history = useHistory();
  const {
    setContainerTypes,
    containerTypes,
    selectedLocations,
    setLaneData,
  } = useLogisticsPricesStore((state) => state);

  const methods = useForm<IFormLanePrices>({
    defaultValues: formDefaultValues,
    mode: 'all',
  });
  const {
    handleSubmit,
    control,
    watch,
    setValue,
    reset,
    getValues,
    trigger,
  } = methods;
  const providerValue = watch('provider');
  const departureFromValue = watch('departureFrom');
  const validFromValue = watch('validFrom');

  const { fields: containers, append, remove } = useFieldArray({ name: 'containers', control });

  const { isFetching } = useQuery(['containerTypes'], () => getContainerTypes(), {
    onSuccess: (data) => {
      toast.success('Container types loaded');

      // ? preselect 40" Dry container (Martijn request)
      const fortyFtDry = data.find((type: any) => type.code === '40_Dry');
      if (fortyFtDry) {
        setValue('containers.0.typeId', fortyFtDry.id);
      }
      setContainerTypes(data);
    },
    onError: (err: AxiosError) => toast.error(err.message),
  });

  const lanePricesMutation = useMutation(getLogisticLanePrices, {
    onError: (err: AxiosError) => toast.error(err.message),
    onSuccess: (data) => {
      toast.success('Lane prices fetched');
      setLaneData(data);
    },
  });

  const putPriceMutation = useMutation(updateLaneProviderPrices, {
    onError: (err: AxiosError) => toast.error(err.message),
    onSuccess: () => {
      toast.success('Prices added, form reset');
      reset(formDefaultValues);
      const { allParamsTrueCheck, laneRequestParams } = prepareLaneRequestParams({
        countryIdFrom: selectedLocations?.countryIdFrom,
        locationTypeFrom: selectedLocations?.locationTypeFrom,
        seaPortIdFrom: selectedLocations?.seaPortIdFrom,
        zipFrom: selectedLocations?.zipFrom,

        countryIdTo: selectedLocations?.countryIdTo,
        locationTypeTo: selectedLocations?.locationTypeTo,
        seaPortIdTo: selectedLocations?.seaPortIdTo,
        zipTo: selectedLocations?.zipTo,
      });

      if (allParamsTrueCheck) {
        lanePricesMutation.mutate(laneRequestParams);
      } else {
        toast.error('Data for refetching lane are invalid');
      }
    },
  });

  const putPriceMutationExitOnSuccess = useMutation(updateLaneProviderPrices, {
    onError: (err: AxiosError) => toast.error(err.message),
    onSuccess: () => {
      toast.success('Prices added');
      history.push(pages.catalogue_logisticPrices);
    },
  });

  const onSubmit = (data: IFormLanePrices) => {
    // console.log('data: ', data);
    const preparedData = prepareLanePricesPayload(data);
    putPriceMutation.mutate(preparedData);
  };

  // separate submit for exit button
  const onSubmitExitOnSuccess = () => {
    trigger().then((isValid) => {
      if (isValid) {
        const preparedData = prepareLanePricesPayload(getValues());
        putPriceMutationExitOnSuccess.mutate(preparedData);
      } else {
        toast.error('Form is invalid, perhaps some fields are empty');
      }
    });
  };

  // useEffect(() => {
  //   if (!providerValue) {
  //     setValue('containers', [emptyContainer]);
  //   } else {
  //     // ? preselect 40" Dry container (Martijn request)
  //     const fortyFtDry = containerTypes.find((type: any) => type.code === '40_Dry');
  //     if (fortyFtDry) {
  //       setValue('containers.0.typeId', fortyFtDry.id);
  //     }
  //   }
  // }, [providerValue]);

  return (
    <section>
      <h2>2. Add new price{isFetching && ': Loading...'}</h2>

      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          {/* PROVIDER with PREVIEW */}
          <ProviderSelector />

          {/* div is a wrapper for disabled status */}
          <div className={clsx(!providerValue && 'block--hidden')}>
            <h3>Price validity and application</h3>

            <h4>Price is valid on deals made</h4>
            <div className="two-col-grid two-col-grid--l">
              <div className="two-col-grid two-col-grid--s">
                <FormDatePicker
                  name="validFrom"
                  label="from"
                />
                <FormDatePicker
                  name="validTo"
                  label="to"
                  rules={{
                    ...validationRules.formDatePickerEndDate(validFromValue),
                    required: 'This field is required',
                  }}
                />
              </div>
              <div />
            </div>

            <h4>Price could be used on departures from</h4>
            <div className="two-col-grid two-col-grid--l">
              <div className="two-col-grid two-col-grid--s">
                <FormDatePicker
                  name="departureFrom"
                  label="from"
                />
                <FormDatePicker
                  name="departureTo"
                  label="to"
                  rules={{
                    ...validationRules.formDatePickerEndDate(departureFromValue),
                    required: 'This field is required',
                  }}
                />
              </div>
              <div />
            </div>

            <h3>Price per container type</h3>

            <div style={{ maxWidth: '480px' }}>
              {containers.map((item, index) => (
                <Container
                  key={item.id}
                  index={index}
                  remove={(idx: number) => remove(idx)}
                />
              ))}
            </div>

            <div style={{ marginBottom: 40 }}>
              <Button
                label="Add another container"
                variant="outlined"
                color="secondary"
                size="medium"
                onClick={() => append(emptyContainer)}
                disabled={(containers.length >= containerTypes.length) || !providerValue}
              />
            </div>

            <Button
              label="Save"
              type="submit"
              variant="contained"
              color="primary"
              size="medium"
              disabled={!providerValue || putPriceMutationExitOnSuccess.isLoading}
              loading={putPriceMutation.isLoading}
            />

            <span style={{ marginRight: 8 }} />

            <Button
              label="Save & exit"
              type="button"
              variant="contained"
              color="primary"
              size="medium"
              disabled={!providerValue || putPriceMutation.isLoading}
              loading={putPriceMutationExitOnSuccess.isLoading}
              onClick={onSubmitExitOnSuccess}
            />
          </div>
        </form>
      </FormProvider>
    </section>
  );
};

export default AddNewPriceForm;
