import React, { useEffect, useState } from 'react';
import { Button, Typography } from '@material-ui/core';
import { AttachMoney, Cancel, Edit } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import { CreditTransaction, emptyLine, ICreditLineCondition } from '../../services/api/CreditService';
import api from '../../services/api';
import colors from '../StyleConfig';
import TextField from '../controls/TextField';
import { formatMoney, prettyDate, prettyDateTime } from '../../services/Utils';
import Controls from '../controls/Controls';
import CreditLineForm from './CreditLineForm';

export interface CreditLineViewProps{
  lineId: number
  setOpen: (val: boolean) => void
}

const PAGE = {
  VIEW: 'VIEW',
  PAYMENT: 'PAYMENT',
  EDIT: 'EDIT',
};

export default function CreditLineView(props: CreditLineViewProps) {
  const { lineId, setOpen } = props;
  const [line, setLine] = useState(emptyLine);
  const [amount, setAmount] = useState(0);
  const [page, setPage] = useState(PAGE.VIEW);
  const [error, setError] = useState('');

  useEffect(() => {
    if (lineId <= 0) {
      setPage(PAGE.EDIT);
    } else {
      (page !== PAGE.PAYMENT) && api.credit.getLine(lineId)
        .then(setLine)
        .catch((err) => setError(err.message));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const renderConditionItem = (val: ICreditLineCondition, idx: number) => (
    <div style={{ display: 'flex' }} key={`credit-line-condition-${idx + 1}`}>
      <TextField style={{ width: '20%' }} label="SELLER" value={val.seller.label} />
      <TextField style={{ width: '20%' }} label="ANNUAL INTEREST RATE" value={`${val.interestRate}%`} />
      <TextField style={{ width: '20%' }} label="FIXED COSTS FOR EACH DEAL" value={`${val.currency} ${formatMoney(val.commissionPerDeal)}`} />
      <TextField style={{ width: '20%' }} label="FIXED COSTS PER VOLUME UNIT" value={`${val.currency} ${formatMoney(val.commissionPerUnit)}`} />
      <TextField style={{ width: '20%' }} label="NUMBER OF DAYS FOR EXTENDED PAYMENT PERIOD" value={val.maxCreditTerm} />
    </div>
  );

  const renderHistoryItem = (item: CreditTransaction, idx: number) => (
    <div style={{ display: 'flex' }} key={`credit-line-history-${idx + 1}`}>
      <TextField style={{ width: '16%' }} label="TIME" value={prettyDateTime(item.created)} />
      <TextField style={{ width: '16%' }} label="ACTION" value={item.type.label} />
      <TextField style={{ width: '16%' }} label="BY" value={item.createdBy.label} />
      <TextField style={{ width: '16%' }} label="CREDIT BEFORE" value={`${line.currency} ${formatMoney(item.limitBefore)}`} />
      <TextField style={{ width: '16%' }} label="TRANSACTION" value={`${line.currency} ${formatMoney(item.amount)}`} />
      <TextField style={{ width: '16%' }} label="CREDIT LEFT" value={`${line.currency} ${formatMoney(item.limitAfter)}`} />
    </div>
  );

  const renderLineDetails = () => (
    <>
      <div style={{ display: 'flex', marginBottom: 16 }}>
        <Typography variant="h5" style={{ flexGrow: 1 }}>Details</Typography>
        {((line.status === 'ACTIVE') || (line.status === 'EXPIRED')) && (
          <>
            <Button
              variant="contained"
              color="primary"
              style={{ background: colors.primaryGreen, marginRight: 8 }}
              startIcon={<Cancel />}
              onClick={handleWithdraw}
            >
              Withdraw
            </Button>
            <Button
              variant="contained"
              color="primary"
              style={{ background: colors.primaryGreen }}
              startIcon={<Edit />}
              onClick={() => setPage(PAGE.EDIT)}
            >
              Edit
            </Button>
          </>
        ) }
      </div>
      <div style={{ display: 'flex', marginBottom: 16 }}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <TextField label="BUYER" value={line.buyer.label} />
          <TextField label="FINANCE PROVIDER TYPE" value={line.provider.providerType.label} />
          <TextField label="TOTAL CREDIT" value={`${line.currency} ${formatMoney(line.totalLimit)}`} />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 16 }}>
          <TextField style={line.status !== 'ACTIVE' ? { color: 'red' } : { color: colors.primaryGreen }} label="CREDIT LINE STATUS" value={line.status} />
          <TextField label="FINANCE PROVIDER TYPE" value={line.provider.label} />
          <TextField label="CREDIT VALID TILL" value={prettyDate(line.validTo)} />
        </div>
      </div>
    </>
  );

  const renderConditions = () => (
    <>
      <Typography variant="h5" style={{ flexGrow: 1 }}>Conditions</Typography>
      <br />
      {
        line.conditions
          ? line.conditions.map(renderConditionItem)
          : <Typography>Failed to load</Typography>
      }
    </>
  );

  const renderHistory = () => (
    <>
      <div style={{ display: 'flex' }}>
        <Typography variant="h5" style={{ flexGrow: 1 }}>Credit line history</Typography>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AttachMoney />}
          style={{ background: colors.primaryGreen }}
          onClick={() => setPage(PAGE.PAYMENT)}
        >
          Make payment
        </Button>
      </div>
      <br />
      {
        line.transactions.length > 0
          ? line.transactions.map(renderHistoryItem)
          : <Typography>Currently credit line history is empty</Typography>
      }
    </>
  );

  const renderCreditLine = () => (
    <>
      {error && <Alert severity="error">{error}</Alert>}
      { renderLineDetails() }
      { renderConditions() }
      { renderHistory() }
      <br />
      <Button
        variant="contained"
        color="primary"
        style={{ background: colors.primaryBlack }}
        onClick={() => setOpen(false)}
      >
        Cancel
      </Button>
    </>
  );

  const renderCreditPayment = () => (
    <>
      <Typography variant="h5">Add loan payment</Typography>
      {error && <Alert severity="error">{error}</Alert>}
      <br />
      <TextField label="CREDIT LIMIT" value={`${line.currency} ${formatMoney(line.totalLimit)}`} />
      <TextField label="CREDIT LEFT" value={`${line.currency} ${formatMoney(line.availableLimit)}`} />
      <Controls.Input
        name="totalLimit"
        label="LOAN PAYMENT AMOUNT"
        value={amount}
        onChange={(e: any) => {
          setError('');
          setAmount(e.target.value);
        }}
        type="number"
        InputProps={{
          endAdornment: line.currency,
          inputProps: { min: 0 },
        }}
        required
      />
      <TextField label="UPDATED CREDIT LIMIT" value={`${line.currency} ${formatMoney(line.availableLimit + +amount)}`} />
      <br />
      <div>
        <Button
          variant="contained"
          color="primary"
          style={{ background: colors.primaryBlack, marginRight: 8 }}
          onClick={handlePayment}
        >
          Update
        </Button>
        <Button
          variant="outlined"
          color="primary"
          onClick={() => { setPage(PAGE.VIEW); setError(''); }}
        >
          Cancel
        </Button>
      </div>
    </>
  );

  const handleWithdraw = () => {
    api.credit.withdrawLine(line.id)
      .then(() => setOpen(false))
      .catch((err) => setError(err.message));
  };

  const handlePayment = () => {
    if (amount !== 0) {
      api.credit.makeTransaction(lineId, amount)
        .then(() => setPage(PAGE.VIEW))
        .catch((err) => setError(err.message));
    } else {
      setError('Amount should be positive');
    }
  };

  const renderPage = () => {
    switch (page) {
      case PAGE.PAYMENT:
        return renderCreditPayment();
      case PAGE.EDIT:
        return <CreditLineForm lineId={lineId} setOpen={setOpen} setPage={setPage} />;
      default:
        return renderCreditLine();
    }
  };

  return renderPage();
}
