type IDealId = number | string;
type IThreadId = number | string;

// ***** deals *****
export const DEAL_ENDPOINTS = {
  getList: '/v1/deals/',
} as const;

export const AUCTION_ENDPOINTS = {
  // post
  postAcceptBid: (offerId: number | string, bidId: number | string) => `/v1/offers/off-spec/${offerId}/bids/accept/${bidId}`,
} as const;

export const OAUTH_ENDPOINTS = {
  refreshToken: '/v1/auth/refresh-token',
  exchangeCode: '/v1/auth/exchange-code',
};

export const CORE_ENDPOINTS = {
  getUser: '/v1/users/me',
  fileUpload: '/v1/file-upload',
  fileDownload: (name: string) => `/v1/file-download/${name}`,
  getOfferPrices: '/v1/offers/prices',
  offersBase: '/v1/offers',
  buyers: '/v1/organizations/buyer',
  product: '/v1/product',
  buyerGroups: '/v1/buyer-group',
  specificationsList: '/v1/product/grouped',
  productCategories: '/v1/product/categories',
  dictionaryDocuments: '/v1/dictionary?type=DOCUMENT_TYPE',
  dictionaryPackagingOptions: '/v1/dictionary?type=PACKAGING_OPTIONS',

  productById: (id: number | string) => `/v1/product/${id}`,
  offerById: (id: number | string) => `/v1/offers/${id}`,

  // get
  getOrganizationUsers: (orgId: number | string) => `/v1/organizations/${orgId}/users`,
  getDictionaries: '/v1/dictionary/group',
  getLocations: (args: {
    type: string,
    orgId: number,
  }) => `/v1/organizations/${args.orgId}/locations/${args.type}`,
  getProducts: (args: {
    category: string,
    type: string,
  }) => `v1/product?category=${args.category}&type=${args.type}`,
  getCharacteristicsList: (type: string) => `/v1/product/specifications?productType=${type}`,
  getOfferHistory: (offerId: number | string) => `/v1/offers/${offerId}/history`,
  getContainers: (orgId: number | string) => `/v1/organization/${orgId}/containers`,

  // post
  postAuth: (authCode: string, redirectUrl: string) => `/v1/auth/exchange-code?code=${authCode}&redirect_uri=${redirectUrl}`,
  // postOfferBid: (offerId: number | string) => `/v1/offers/off-spec/${offerId}/bids`,
} as const;

// ***** document exchange process *****
export const DOCUMENT_ENDPOINTS = {
  getDocumentTypes: '/v1/document-types',
  fileUpload: '/v1/file-upload',

  // get
  getDocuments: (dealId: IDealId) => `/v1/documents/${dealId}`,
  getDownloadDocumentFile: (dealId: IDealId, threadId: IThreadId) => `/v1/documents/${dealId}/download?threadId=${threadId}`,

  // post
  postDocumentUpload: (dealId: IDealId) => `/v1/documents/${dealId}/upload`,

  // put
  putDocumentsApprove: (dealId: IDealId) => `/v1/documents/${dealId}/approve`,
  putDocumentsReject: (dealId: IDealId) => `/v1/documents/${dealId}/reject`,

  // delete
  deleteFileFromDocument: (dealId: IDealId, threadId: IThreadId) => `/v1/documents/${dealId}/file?threadId=${threadId}`,
} as const;

export const BASE_ENDPOINTS = {
  // get
  getOrganizations: (type: 'seller' | 'buyer') => `/v1/organizations/${type}`,
  getOrgUsers: (orgId: number | string | undefined) => `/v1/organizations/${orgId}/users`,
  getDictionary: '/v1/dictionary/group',
} as const;
