import { useQuery } from '@tanstack/react-query';
import { axios_Core } from 'SHARED/helpers/axios';
import { IOrganizationPageRes } from 'SHARED/redux/models/IOrganization';
import toast from 'react-hot-toast';
import { IOrgUserResponsePage } from 'SHARED/redux/models/IUser';
import { BASE_ENDPOINTS } from '../endpoints';
import { QUERY_KEYS } from '../_query-keys';

//-----------------------------------------------

export const useOrgListQuery = (orgType: 'seller' | 'buyer' = 'seller') => {
  const { data, isLoading, isInitialLoading, isRefetching, isError, error, refetch } = useQuery({
    queryKey: [QUERY_KEYS.org.fetchOrgs, orgType],
    queryFn: async () => {
      const res = await axios_Core.get<IOrganizationPageRes>(BASE_ENDPOINTS.getOrganizations(orgType));
      return res.data;
    },
    onError: (err) => {
      console.error(err);
      toast.error('Could not fetch organizations');
    },
  });

  return {
    data,
    isLoading: isLoading || isInitialLoading || isRefetching,
    isError,
    error,
    refetch,
  };
};

//-----------------------------------------------

export const useOrgUserListQuery = (orgId: number | string | undefined = undefined) => {
  const isValidOrgId = typeof orgId === 'number' || typeof orgId === 'string';

  const { data, isLoading, isInitialLoading, isRefetching, isError, error, refetch } = useQuery({
    queryKey: [QUERY_KEYS.org.fetchOrgUsers, orgId],
    queryFn: async () => {
      const res = await axios_Core.get<IOrgUserResponsePage>(BASE_ENDPOINTS.getOrgUsers(orgId));
      return res.data;
    },
    onError: (err) => {
      console.error(err);
      toast.error('Could not fetch organization users');
    },
    enabled: isValidOrgId,
  });

  return {
    data,
    // FIXME: for whatever reason without `isValidOrgId` isLoading is always `true`
    // even if the query is not enabled
    isLoading: (isLoading || isInitialLoading || isRefetching) && isValidOrgId,
    isError,
    error,
    refetch,
  };
};
