import React, { FC } from 'react';
import { TextField } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import clsx from 'clsx';
import { IFormFieldBase } from './FormSelect';
import validationRules from './validationRules';

interface IFormInputProps extends IFormFieldBase {
  type?: string;
  defaultValue?: string | number;
}

const FormInput: FC<IFormInputProps> = ({
  name,
  placeholder,
  label,
  rules = validationRules.required,
  disabled,
  defaultValue,
  type = 'text',
  className,
  error,
  required = false,
  suffix,
}) => {
  const { formState, control } = useFormContext();
  const { errors } = formState;
  const isRequired = (rules && rules.required) || required;

  return (
    <div className={clsx('form-field', className)}>
      {label && <div className="label-text">{isRequired ? `${label} *` : label}</div>}

      <div className="item-suffix-grid">
        <Controller
          name={name}
          control={control}
          rules={rules}
          defaultValue={defaultValue}
          render={({ field: { onChange, value } }) => (
            <TextField
              id={`${name}-input`}
              value={value}
              placeholder={placeholder}
              onChange={onChange}
              variant="outlined"
              size="small"
              disabled={disabled}
              error={!!errors[name] || error}
              type={type}
            />
          )}
        />

        {suffix && <div className="suffix">{suffix}</div>}
      </div>

      {errors[name]?.message && <div className="form-field__error">{errors[name]?.message}</div>}
    </div>
  );
};

export default FormInput;
