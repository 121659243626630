import { CircularProgress, Snackbar, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React, { useEffect, useState } from 'react';
import { Form } from '../../hooks/useForm';
import useToggle from '../../hooks/useToggle';
import api from '../../services/api';
import {
  buildContainerTemplate,
  ContainerType,
} from '../../services/api/TransportationServie';
import Controls from '../controls/Controls';

export default function TransportationForm({ orgId }: any) {
  const [types, setTypes] = useState([]);
  const [containers, setContainers] = useState([]);
  const [products, setProducts] = useState([]);
  const [templates, setTemplates] = useState({});
  const [errors, setErrors] = useState({});
  const [open, setOpen] = useState(false);
  const { toggle, setToggle } = useToggle();
  const [load, setLoad] = useState(true);
  useEffect(() => {
    api.transportation.getContainerTypes(setTypes, setErrors);
    api.transportation.getContainers(orgId, setContainers, setErrors);
    setLoad(true);
    api.productSpec.getProducts(orgId)
      .then(setProducts)
      .then(() => setLoad(false))
      .catch(() => setLoad(false));
  }, [orgId, toggle]);
  useEffect(() => {
    // structure of templates example
    // const containerTemplates = {
    //   SMP: {
    //     SEA: buildContainerTemplate(orgId, 'SMP', 'SEA', containers),
    //     LAND: buildContainerTemplate(orgId, 'SMP', 'LAND', containers),
    //   },
    // };

    const containerTemplates: any = {};
    products.forEach((prod: any) => {
      const { productCategory } = prod;
      containerTemplates[productCategory.value] = {
        SEA: buildContainerTemplate(orgId, productCategory.value, 'SEA', containers),
        LAND: buildContainerTemplate(orgId, productCategory.value, 'LAND', containers),
      };
    });

    setTemplates(containerTemplates);
  }, [orgId, containers, products]);

  const getContainerTypeOptions = (category: 'SEA' | 'LAND') => (types
    ? types
      .filter((it: ContainerType) => it.category === category)
      .map((it: ContainerType) => ({ label: it.label, value: it.code }))
    : []);

  const handleSubmit = (e: Event, item: any) => {
    e.preventDefault();
    if (item.containerType && item.volume > 0) {
      item.id
        ? api.transportation.updateContainer(item.id, orgId, item).then(() => {
          setOpen(true);
          setToggle();
        })
        : api.transportation.createContainer(orgId, item).then(() => {
          setOpen(true);
          setToggle();
        });
    } else {
      // @ts-ignore
      Object.assign(errors, { [item.productCategory]: { [item.containerCategory]: 'This field is required' } });
      setErrors({ ...errors });
    }
  };

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    const nameArr = name.split('.');
    const category = nameArr[0];
    const type = nameArr[1];
    const fieldName = nameArr[2];
    // @ts-ignore
    templates[category][type][fieldName] = value;
    setTemplates({ ...templates });
    // @ts-ignore
    Object.assign(errors, { [category]: { [type]: undefined } });
    setErrors({ ...errors });
  };

  const renderContainerBlock = (type: 'SEA' | 'LAND', category: string) => {
    const success = false;
    // @ts-ignore
    const { id, containerType, volume } = templates[category][type];
    return (
      <>
        <div style={{ display: 'flex' }}>
          <Typography variant="body1">{ type === 'SEA' ? 'Sea' : 'Land' }</Typography>
        </div>
        <Form name="production-containers">
          <div style={{ display: 'inline-flex', alignItems: 'center', minWidth: '100%' }}>
            <div style={{ minWidth: '80%', display: 'flex', alignItems: 'center' }}>
              <Controls.Select
                name={`${category}.${type}.containerType`}
                label={type === 'SEA' ? 'CONTAINER TYPE' : 'TRUCK LOAD TYPE'}
                  // @ts-ignore
                value={containerType}
                  // @ts-ignore
                error={errors?.[category]?.[type]}
                onChange={handleInputChange}
                options={getContainerTypeOptions(type)}
              />
              <Controls.Input
                name={`${category}.${type}.volume`}
                label="VOLUME"
                  // @ts-ignore
                value={volume}
                  // @ts-ignore
                error={errors?.[category]?.[type]}
                onChange={handleInputChange}
                type="number"
                style={{ width: '20%' }}
              />
              <Typography variant="body1">MT</Typography>
            </div>

            <div style={{ marginLeft: 16, display: 'flex' }}>
              <Controls.Button
                type="submit"
                  // @ts-ignore
                onClick={(e) => handleSubmit(e, templates[category][type], success)}
              >
                Submit
              </Controls.Button>
              { id && (
              <Controls.Button
                onClick={() => {
                  api.transportation.deleteContainer(id, orgId)
                    .then(() => setOpen(true))
                    .then(setToggle)
                    .catch();
                }}
              >
                Delete
              </Controls.Button>
              ) }
            </div>
          </div>
        </Form>
      </>
    );
  };

  const renderProductCategoryBlock = (category: string, label: string) => (
    <div style={{ paddingLeft: 20 }}>
      <Typography variant="h6">{`Transportation for ${label}`}</Typography>
      <br />
      { renderContainerBlock('SEA', category) }
      { renderContainerBlock('LAND', category) }
      <br />
    </div>
  );

  return (
    <>
      <Snackbar open={open} autoHideDuration={3000} onClose={() => setOpen(false)} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
        <Alert onClose={() => setOpen(false)} severity="success" variant="filled">
          Success!
        </Alert>
      </Snackbar>
      <Typography variant="h5">Transportation</Typography>
      <br />
      {load ? <CircularProgress /> : (
        <>
          {products?.map((prod: any) => (
            renderProductCategoryBlock(prod.productCategory.value, prod.productCategory.label)
          ))}
        </>
      )}
      <br />
    </>
  );
}
