import { useQuery } from '@tanstack/react-query';
import { toast } from 'react-hot-toast';
import type { IGetOfferFinancingParams } from 'api/get/getOfferFinancing';
import { getOfferFinancing } from 'api/get/getOfferFinancing';

export const useUserPaymentTermsQuery = (params: IGetOfferFinancingParams) => {
  const {
    offerId,
    volume,
    price,
    orgId,
  } = params;

  const baseFlags = (
    !!offerId
    && (!!volume || volume === 0)
    && !!price
    && !!orgId
  );

  const isEnabled = baseFlags;

  return useQuery({
    queryKey: ['availablePaymentTerms', offerId, volume, price, orgId],
    queryFn: () => getOfferFinancing(params),
    onError: (error) => {
      console.error(error);
      toast.error('Error loading available payment terms');
    },
    enabled: isEnabled,
  });
};
