import { removeTokens, setTokens } from 'SHARED/helpers/axios';
import isDevEnv from 'helpers/isDevEnv';
import toast from 'react-hot-toast';

const { APP_CONFIG } = window;
// export const apiUrl = APP_CONFIG.local_url;
export const apiUrl = APP_CONFIG.api_url;
export const authUrl = APP_CONFIG.oauth_url;
export const FILE_DOWNLOAD_URL = '/v1/file-download/';

export const createFileDownloadUrl = (odFileName: string) => `${FILE_DOWNLOAD_URL}${odFileName}`;

export interface Page<T> {
  rows: Array<T>,
  totalRowCount: number,
  totalPageCount: number
}

export interface Error {
  code: number,
  message: string
}

export interface PageRequest {
  page?: number | 0
  size?: number | 20
  sort?: string | 'id'
  order?: SortOrder | SortOrder.Desc
}

export enum SortOrder {
  Asc = 'asc',
  Desc = 'desc',
}

export function get(resource: string, qs: any = undefined) {
  return rest('GET', resource, qs, undefined);
}

export function post(resource: string, body: any = undefined, qs?: any) {
  return rest('POST', resource, qs, body);
}

export function put(resource: string, body = {}) {
  return rest('PUT', resource, undefined, body);
}

export function patch(resource: string, body = {}) {
  return rest('PATCH', resource, undefined, body);
}

export function del(resource: string, body = {}) {
  return rest('DELETE', resource, undefined, body);
}

export function download(resource: string, qs: any = undefined, fileName: string = ''): Promise<void> {
  return get(resource, qs)
    .then((response) => response.blob())
    .then((blob) => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      a.remove(); // afterwards we remove the element again
    })
    .catch(() => {});
}

export function rest(
  method: string,
  url: string,
  queryString: any,
  body: any,
): Promise<any> {
  if (url.indexOf('refresh-token') === -1) {
    const prevRequest = {
      method, url, queryString, body,
    };
    localStorage.setItem('prevRequest', JSON.stringify(prevRequest));
  }

  const urli = `${apiUrl}${url}${renderQueryString(queryString)}`;

  const headers: any = {};
  let data = body;

  if (typeof data === 'object' || typeof data === 'number') {
    // JSON request
    if (!(data instanceof FormData)) {
      headers['Content-Type'] = 'application/json';
      data = JSON.stringify(data);
    }
  }
  const token = localStorage.getItem('token');
  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }

  return new Promise((resolve, reject) => {
    fetch(urli, {
      method,
      headers,
      body: data,
      cache: 'no-cache',
      credentials: 'same-origin',
    })
      .then(confirmSuccessResponse)
      .then(parseResponse)
      .then((response: Response) => resolve(response))
      .catch((error: Response) => reject(error));
  });
}

async function confirmSuccessResponse(response: Response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }

  if (response.status === 401) {
    const refToken = localStorage.getItem('refresh-token');
    if (refToken) {
      return refreshToken().then(() => retryRequest());
    }
    redirectToAuth();
    return null;
  }

  // IF NOT SUCCESS (error handling logs)
  let errorMessageText = 'Something went wrong';
  try {
    const parsedRes = await response.json();
    errorMessageText = parsedRes.message;
  } catch (err) {
    console.error('Failed to parse response', err);
  }
  toast.error(errorMessageText);

  // IF NOT SUCCESS === END
  // throw new Error(errorMessageText);
  return null;

  // const text = await response.text();
  // const errorMessage = JSON.parse(text);
  // throw new Error(errorMessage ? parseError(errorMessage) : text);
}

const retryRequest = () => {
  const prevRequest = localStorage.getItem('prevRequest');
  if (prevRequest) {
    const {
      method, url, queryString, body,
    } = JSON.parse(prevRequest);
    return rest(method, url, queryString, body);
  }
  return null;
};

export const refreshToken = async () => {
  const refToken = localStorage.getItem('refresh-token');
  if (refToken) {
    localStorage.removeItem('token');
    await post('/v1/auth/refresh-token', undefined, { refresh_token: refToken })
      .then((resp: any) => {
        localStorage.setItem('token', resp.access_token);
        localStorage.setItem('refresh-token', resp.refresh_token);

        setTokens(resp.access_token, resp.refresh_token);
      })
      .catch(() => redirectToAuth());
  } else {
    redirectToAuth();
  }
};

const clearTokenData = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('refresh-token');
  removeTokens();
};

export const redirectToAuth = () => {
  if (isDevEnv) return; // ? disable annoying redirect in Dev mode

  localStorage.setItem('prevLocation', window.location.href);
  clearTokenData();
  window.location.replace(authUrl);
};

export const exchangeCode = (code: string | null, redirectUri: string): any => post('/v1/auth/exchange-code', undefined, { code, redirect_uri: redirectUri });

export const logout = () => {
  const token = localStorage.getItem('token');
  const form = document.createElement('form');
  form.method = 'POST';
  form.action = `${authUrl}/oauth/logout?token=${token}`;
  document.body.appendChild(form);
  form.submit();
};

async function parseResponse(response: any) {
  const contentType = response?.headers?.get('content-type');

  if (!contentType || contentType.indexOf('application/json') === -1) {
    return response;
  }

  const text = await response.text();

  // convert LocalDate, LocalDateTime to js Date object
  return JSON.parse(text, (key, val) => {
    if (typeof val === 'string') {
      // LocalDateTime
      if (/^\d\d\d\d-\d\d-\d\dT\d\d:\d\d:\d\d.\d\d\dZ$/.test(val)) {
        return new Date(val);
      }

      // LocalDate
      if (/^\d\d\d\d-\d\d-\d\d$/.test(val)) {
        return new Date(val);
      }
    }

    return val;
  });
}

export function formatParam(param: any) {
  if (param instanceof Date) {
    // 2007-12-03 is backend accepted format, see JacksonConfiguration
    return new Date(param).toISOString().substring(0, 10);
  }

  return `${param}`;
}

export function renderQueryString(query: any) {
  if (!query) return '';

  let result = '';

  Object.keys(query)
    .forEach((queryParam) => {
      if ([null, undefined, NaN].includes(query[queryParam])) {
        return;
      }

      if (result !== '') {
        result += '&';
      }

      result += `${queryParam}=${encodeURIComponent(formatParam(query[queryParam]))}`;
    });

  return `?${result}`;
}

export function parseError(e: any) {
  return e.errors?.length > 0
    ? e.errors.map((it: any) => `Field: ${it.field}, error: ${it.message}`).join('\n')
    : e.message;
}
