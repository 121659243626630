import React from 'react';
import clsx from 'clsx';
import { Controller, useFormContext } from 'react-hook-form';
import { NumericFormat } from 'react-number-format';
import prepareDataAttr from 'SHARED/helpers/prepareDataAttr';
import Tooltip from '../Tooltip';

const INPUT_MAX_LENGTH = 500;

interface FormInputProps {
  name: `${string}` | `${string}.${string}` | `${string}.${number}`,
  type?: string,
  rules?: any,
  value?: string | number,
  label?: string,
  placeholder?: string,
  tooltip?: string,
  disabled?: boolean,
  textarea?: boolean,
  className?: string,
  suffix?: string,
  maxLength?: number,
  testingName?: string,
  caption?: string,
  fullWidth?: boolean,

  // number input props
  isDecimal?: boolean,
  minValue?: number,
  maxValue?: number,
  decimalSeparator?: ',' | '.',
  decimalScale?: number,
}

const FormInput: React.FC<FormInputProps> = (props) => {
  const { control } = useFormContext();

  const {
    name,
    type = 'text',
    rules = undefined,
    label,
    placeholder,
    tooltip,
    disabled = false,
    value,
    className = '',
    suffix,
    textarea = false,
    maxLength = INPUT_MAX_LENGTH,
    testingName,
    caption,
    fullWidth = false,

    // number input props
    isDecimal = true,
    decimalSeparator = '.',
    decimalScale = 2,
    minValue = 0.01,
  } = props;

  const isNumberField = type === 'number' && !textarea;
  const isTextField = type !== 'number' && !textarea;

  return (
    <>
      <Controller
        name={name}
        control={control}
        rules={rules}
        defaultValue={value}
        render={({ field, fieldState: { error } }) => (
          <div
            className={clsx('form-input', error && 'invalid', fullWidth && 'full-width', className)}
            data-testing={prepareDataAttr(testingName || name)}
          >

            {label && (
              <label htmlFor={name}>
                {rules && rules.required ? `${label} *` : label}

                {
                  tooltip && tooltip.length > 0 && <Tooltip text={tooltip} />
                }
              </label>
            )}

            <div className="form-input-wrapper">
              {isNumberField && (
                <NumericFormat
                  id={name}
                  min={minValue}
                  value={field.value}
                  onChange={field.onChange}
                  displayType="input"
                  disabled={disabled}
                  allowNegative={false}
                  allowedDecimalSeparators={[',', '.']}
                  fixedDecimalScale={false}
                  decimalSeparator={decimalSeparator}
                  decimalScale={isDecimal ? decimalScale : 0}
                  placeholder={placeholder}
                  getInputRef={field.ref}
                  data-testing={prepareDataAttr(testingName || name)}
                  autoComplete="off"
                />
              )}

              {isTextField && (
                <input
                  id={name}
                  data-testing={prepareDataAttr(testingName || name)}
                  type={type}
                  placeholder={placeholder}
                  aria-invalid={error ? 'true' : 'false'}
                  disabled={disabled}
                  maxLength={maxLength}
                  autoComplete={type === 'password' ? 'on' : 'off'}
                  {...field}
                />
              )}

              {textarea && (
                <textarea
                  placeholder={placeholder}
                  aria-invalid={error ? 'true' : 'false'}
                  id={name}
                  disabled={disabled}
                  maxLength={maxLength}
                  minLength={3}
                  data-testing={prepareDataAttr(testingName || name)}
                  {...field}
                />
              )}
              <div className="suffix">{suffix}</div>

            </div>

            {caption && <div className="field-caption">{caption}</div>}

            {
              error
              && <div className="error-message">{error?.message}</div>
            }

          </div>
        )}
      />
    </>
  );
};

export default FormInput;
