/* eslint-disable react/jsx-props-no-spreading */
import React               from 'react';

interface TooltipProps {
  text: string,
}

const Tooltip: React.FC<TooltipProps> = (props) => {
  const { text } = props;

  return (
    <div className="tooltip-wrapper">
      <i className="icon-question" />
      <div className="tooltip-text">
        {text}
      </div>
    </div>

  );
};

export default Tooltip;
